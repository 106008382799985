import { Component, Inject } from '@angular/core';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { Warning } from '@checklistfacil/shared/ui/warnings';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isDemonstrationCompany } from '@checklistfacil/shared/data-access/company-configs';
import { Store } from '@ngrx/store';
import { ProviderScope, TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cl-warning-bar',
  templateUrl: './warning-bar.component.html',
  styleUrls: ['./warning-bar.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'warningBarComponent'
    ),
  ],
})
export class WarningBarComponent {
  typeWarning = Warning.WarningBarMedium;

  warningTextIsDemo$ = this.translocoService
    .selectTranslate('warningTextIsDemo', {}, this.scope)
    .pipe(map((warningTextIsDemo) => warningTextIsDemo));

  isDemonstrationCompany$: Observable<boolean> = this.store.select(
    isDemonstrationCompany
  );

  constructor(
    private store: Store,
    @Inject(TRANSLOCO_SCOPE) private scope: ProviderScope,
    private translocoService: TranslocoService
  ) {}
}
