import { Component, EventEmitter, Input, Output } from '@angular/core';
import { translocoLoader } from '@checklistfacil/shared/util/translate';

@Component({
  selector: 'cl-ui-area-header',
  templateUrl: './area-header.component.html',
  styleUrls: ['./area-header.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiAreaHeader'
    ),
  ],
})
export class AreaHeaderComponent {
  @Input() viewMode = false;
  @Input() newItemDisabled = false;
  @Input() newSubAreaDisabled = false;
  @Input() newSubAreaAvailable = true;
  @Input() isSubArea = false;
  @Input() hasActionPlan = true;
  @Input() hasImportItems = true;
  @Input() collapsed = false;
  @Input() showCollapse = true;
  @Input() deleteDisabled = false;
  @Input() deleteDisabledMessage = '';
  @Input() areaTitleSuffix = '';

  @Output() newItemClicked = new EventEmitter<MouseEvent>();
  @Output() newSubAreaClicked = new EventEmitter<MouseEvent>();
  @Output() collapseButtonClicked = new EventEmitter<{
    collapsed: boolean;
    areaLabel: string;
  }>();
  @Output() moreOptionsButtonClicked = new EventEmitter<MouseEvent>();

  @Output() optionClicked = new EventEmitter<{
    event: MouseEvent;
    type: string;
  }>();

  foldUnfoldClick(event: MouseEvent, areaTitleSuffix: string) {
    this.collapsed = !this.collapsed;
    this.collapseButtonClicked.emit({
      collapsed: this.collapsed,
      areaLabel: areaTitleSuffix,
    });
  }

  newItemClick(event: MouseEvent) {
    this.newItemClicked.emit(event);
  }

  newSubAreaClick(event: MouseEvent) {
    this.newSubAreaClicked.emit(event);
  }

  moreOptionsButtonClick(event: MouseEvent) {
    this.moreOptionsButtonClicked.emit(event);
  }

  optionClick(event: MouseEvent, type: string) {
    this.optionClicked.emit({ event, type });
  }
}
