import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { USER_CONFIGS_FEATURE_KEY } from './state/selectors';
import { userConfigsReducer } from './state/reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_CONFIGS_FEATURE_KEY, userConfigsReducer),
  ],
})
export class SharedDataAccessUserModule {}
