import { HttpHeaders } from '@angular/common/http';

const SKIP_AUTH_KEY = 'skip-authentication';

interface Header {
  [x: string]: string;
}

export function addSkipAuthenticationHeader(headers = {} as Header): Header {
  return {
    ...headers,
    [SKIP_AUTH_KEY]: 'yes',
  };
}

export function headerHasSkipAuthentication(header: HttpHeaders) {
  return (
    header && header.has(SKIP_AUTH_KEY) && header.get(SKIP_AUTH_KEY) === 'yes'
  );
}

export function removeSkipAuthenticationHeader(header: HttpHeaders) {
  return header.delete(SKIP_AUTH_KEY);
}
