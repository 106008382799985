import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuListItem } from './types';

@Component({
  selector: 'cl-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent {
  @Input() items: MenuListItem[] = [];
  @Output() menuListItemClicked = new EventEmitter<MenuListItem>();
  @Output() upgradeIconClicked = new EventEmitter<MenuListItem>();

  handleListItemClicked(item: MenuListItem) {
    this.menuListItemClicked.emit(item);
  }

  handleUpgradeIconClicked(item: MenuListItem) {
    this.upgradeIconClicked.emit(item);
  }
}
