import { createAction, props } from '@ngrx/store';

export const loadTranslateSuccess = createAction(
  '[Translate] Load Translate Success'
);

export const checkTranslateVersion = createAction(
  '[Translate] Check Translate Version',
  props<{ version: string }>()
);

export const loadTranslateFailure = createAction(
  '[Translate] Load Translate Failure',
  props<{ error: any }>()
);
