import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ViewContainerRef,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  Overlay,
  OverlayRef,
  PositionStrategy,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'cl-menu-surface',
  templateUrl: './menu-surface.component.html',
  styleUrls: ['./menu-surface.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuSurfaceComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() opened = false;
  @Input() config: any;
  @ViewChild('surfaceContent', { static: true }) surfaceContent:
    | TemplateRef<any>
    | undefined;

  overlayInstance: OverlayRef | undefined;
  templatePortal: TemplatePortal | undefined;

  constructor(
    private overlayService: Overlay,
    private viewContainerRef: ViewContainerRef,
    private scrollOptions: ScrollStrategyOptions
  ) {}

  ngOnInit() {
    this.overlayInstance = this.overlayService.create({
      disposeOnNavigation: true,
      panelClass: 'menu-surface-wrapper',
      scrollStrategy: this.scrollOptions.close(),
      hasBackdrop: true,
      backdropClass: 'menu-surface-backdrop',
    });

    this.overlayInstance
      .backdropClick()
      .pipe()
      .subscribe(() => {
        this.close();
      });
  }

  ngAfterViewInit() {
    if (!this.surfaceContent) {
      return;
    }
    this.templatePortal = new TemplatePortal(
      this.surfaceContent,
      this.viewContainerRef
    );
    if (this.opened) {
      this.open();
    }
  }

  open() {
    if (!this.overlayInstance?.hasAttached()) {
      this.overlayInstance?.attach(this.templatePortal);
    } else {
      this.close();
    }
  }

  close() {
    this.overlayInstance?.detach();
  }

  setPositionInstance(position: PositionStrategy) {
    this.overlayInstance?.updatePositionStrategy(position);
  }

  ngOnDestroy() {
    this.overlayInstance?.dispose();
  }
}
