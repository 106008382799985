import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-ui-card-field',
  templateUrl: './card-field.component.html',
  styleUrls: ['./card-field.component.scss'],
})
export class CardFieldComponent {
  @Input() label: string | undefined;
  @Input() disabled = false;
}
