import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'cl-ui-button-with-loading',
  templateUrl: './button-with-loading.component.html',
  styleUrls: ['./button-with-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonWithLoadingComponent {
  @Input()
  set isProcessing(processing: boolean) {
    this.processing$.next(processing);
  }
  get isProcessing(): boolean {
    return this.processing$.value;
  }
  @Input()
  set disabled(disabled: boolean) {
    this.disabled$.next(disabled);
  }
  get disabled(): boolean {
    return this.disabled$.value;
  }
  @Input() type = 'button';
  @Input() matButtonType:
    | 'mat-button'
    | 'mat-raised-button'
    | 'mat-stroked-button'
    | 'mat-flat-button'
    | undefined = 'mat-button';
  @Input() color: ThemePalette;
  @Input() form = '';

  disabled$ = new BehaviorSubject<boolean>(false);
  processing$ = new BehaviorSubject<boolean>(false);
  isBlocked$ = this.disabled$.pipe(
    switchMap((disabled) =>
      this.processing$.pipe(map((processing) => disabled || processing))
    )
  );

  get isColoredVariant() {
    return (
      this.color !== undefined &&
      (this.matButtonType === 'mat-flat-button' ||
        this.matButtonType === 'mat-raised-button')
    );
  }
}
