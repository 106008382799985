import {
  AfterViewInit,
  Attribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ErrorMessages } from './types';

@Component({
  selector: 'cl-form-textfield',
  templateUrl: './textfield.component.html',
  styleUrls: ['./textfield.component.scss'],
})
export class TextfieldComponent implements ControlValueAccessor, AfterViewInit {
  @Input() placeholder = '';
  @Input() label = '';
  @Input() value = '';
  @Input() hint: string | undefined;
  @Input() suffixIcon: string | undefined;
  @Input() prefixIcon: string | undefined;
  @Input() required = false;
  @Input() readonly = false;
  @Input() condensed = false;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Input() autocomplete = 'on';
  @Input() errorMessages: ErrorMessages = {};
  @Input() maxlength: number | null = null;
  @Input() max: number | undefined;
  @Input() min: number | undefined;
  @Input() showCounter = true;
  @Input() autofocus = false;
  @Input() errorStateMatcher!: ErrorStateMatcher;
  @Output() suffixIconClick = new EventEmitter();
  @Output() prefixIconClick = new EventEmitter();
  @Output() valueChangeEvent = new EventEmitter();
  private disabledControlHelper = false;
  private touched = false;

  @ViewChild('textfieldInput', { static: false }) matInputDir:
    | MatInput
    | undefined;

  constructor(
    @Self() @Optional() public control: NgControl,
    @Attribute('type') public type = 'text',
    private cd: ChangeDetectorRef
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.matInputDir?.focus();
      this.cd.detectChanges();
    }
  }

  @Input() set disabled(disabled) {
    this.disabledControlHelper = disabled;

    if (this.control) {
      if (disabled) {
        this.control.control?.disable({ emitEvent: false, onlySelf: true });
      } else {
        this.control.control?.enable({ emitEvent: false, onlySelf: true });
      }
    }
  }

  get disabled(): boolean {
    return this.control
      ? this.control.disabled || false
      : this.disabledControlHelper;
  }

  get count(): number {
    if (this.control) {
      return (this.control.value && this.control.value.length) || 0;
    }
    return (this.value && this.value.length) || 0;
  }

  get showCount(): boolean {
    // return this.control !== null && this.maxlength !== undefined && !this.condensed;
    return (
      this.showCounter &&
      this.maxlength !== null &&
      this.maxlength !== undefined &&
      !this.condensed
    );
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange(delta: any) {}

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onTouched() {
    this.touched = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.disabled !== isDisabled) {
      this.disabled = isDisabled;
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  emitSuffixIconClick($event: MouseEvent) {
    this.suffixIconClick.emit($event);
  }

  emitPrefixIconClick($event: MouseEvent) {
    this.prefixIconClick.emit($event);
  }

  handleChange(event: Event) {
    this.valueChangeEvent.emit((<HTMLInputElement>event.target).value);
  }

  get errorMessage(): string {
    if (!this.control) {
      return '';
    }
    const resultMessages = [];
    for (const errorName in this.control.errors) {
      if (
        Object.prototype.hasOwnProperty.call(this.control.errors, errorName) &&
        this.errorMessages
      ) {
        resultMessages.push(this.errorMessages[errorName]);
      }
    }
    return resultMessages.join(' ');
  }

  abstractControlAsFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }
}
