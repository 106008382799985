import { Component, Inject } from '@angular/core';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cl-dialog-limit-files',
  templateUrl: './dialog-limit-files.component.html',
  styleUrls: ['./dialog-limit-files.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'dialogLimitFilesComponent'
    ),
  ],
})
export class DialogLimitFilesComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { limit: number }) {}
}
