import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GrowthBookService } from './growthbook.service';

@Directive({
  selector: '[clFeatureShow]',
})
export class FeatureShowDirective {
  private hasView = false;
  private featureKey$ = new BehaviorSubject<string>('');

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private service: GrowthBookService
  ) {
    this.featureKey$
      .pipe(switchMap((key: string) => this.service.featureIsOn(key)))
      .subscribe((featureIsOn) => {
        if (featureIsOn && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!featureIsOn && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

  @Input() set clFeatureShow(featureKey: string) {
    this.featureKey$.next(featureKey);
  }
}
