import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@checklistfacil/shared/data-access/loading';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';

@Component({
  selector: 'cl-not-found-error',
  templateUrl: './not-found-error.component.html',
  styleUrls: ['./not-found-error.component.scss'],
})
export class NotFoundErrorComponent implements OnInit {
  private appConfig: Configuration | undefined;

  constructor(
    private loadingService: LoadingService,
    configService: ConfigAssetLoaderService
  ) {
    configService.loadConfigurations().subscribe((config) => {
      this.appConfig = config;
    });
  }

  ngOnInit() {
    this.loadingService.appHasError();
  }

  handleBackButtonClicked() {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    window.location.href = this.appConfig.appUrl;
  }
}
