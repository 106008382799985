import { Component, Input } from '@angular/core';
import { DialogTerms } from './types';

@Component({
  selector: 'cl-ui-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() title: string | undefined;
  @Input() titleIcon: string | undefined;
  @Input() terms: Partial<DialogTerms> = {};
}
