import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromUser from './+state/reducer';
import { UserAchievementsEffects } from './+state/effects';
import { SharedDataAccessEnvironmentModule } from '@checklistfacil/shared/data-access/environment';

@NgModule({
  imports: [
    CommonModule,
    SharedDataAccessEnvironmentModule,
    StoreModule.forFeature(
      fromUser.USER_ACHIEVEMENTS_FEATURE_KEY,
      fromUser.reducer
    ),
    EffectsModule.forFeature([UserAchievementsEffects]),
  ],
})
export class UserAchievementsDataAccessModule {}
