import { userAccesses } from '@checklistfacil/shared/data-access/company-configs';
import { getUser } from '@checklistfacil/shared/data-access/user';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './models';
import { mainMenuFeatureKey } from './reducers';

export const featureState = createFeatureSelector<State>(mainMenuFeatureKey);

export const getMenuCollapsed = createSelector(featureState, (state) => {
  return state.menuCollapsed;
});

export const getMenuItems = createSelector(featureState, (state) => {
  return state.menus;
});

export const userCanEditConcludedChecklists = createSelector(
  featureState,
  userAccesses,
  getUser,
  (state, accesses, user) =>
    Boolean(
      (user.isAdmin || accesses.includes(61)) &&
        user.companyId &&
        [566, 610].includes(user.companyId)
    )
);
