import { createSelector } from '@ngrx/store';
import { featureSelector } from '../selectors';

export const siengeData = createSelector(
  featureSelector,
  (state) => state.sienge
);

export const siengeModules = createSelector(
  siengeData,
  (sienge) => sienge?.modules
);

export const unitSalesEnabled = createSelector(siengeModules, (modules) =>
  Boolean(modules?.units?.enabled)
);

export const supplyContractsEnabled = createSelector(siengeModules, (modules) =>
  Boolean(modules?.supplyContracts?.enabled)
);

export const purchaseOrdersEnabled = createSelector(siengeModules, (modules) =>
  Boolean(modules?.purchaseOrders?.enabled)
);
