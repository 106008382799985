import { Component, Input } from '@angular/core';
import { Warning } from './enums';

@Component({
  selector: 'cl-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent {
  @Input() type = Warning.WarningModalMedium;
  @Input() title = '';
  @Input() text = '';

  activeTypeIcon = Warning.IconWarning;

  get nameIcon() {
    if (
      this.type === Warning.WarningBarLow ||
      this.type === Warning.WarningModalLow ||
      this.type === Warning.WarningSectionLow
    ) {
      return (this.activeTypeIcon = Warning.IconError);
    } else {
      return (this.activeTypeIcon = Warning.IconWarning);
    }
  }

  get showWarningBarType() {
    if (
      this.type === Warning.WarningBarLow ||
      this.type === Warning.WarningBarHigh ||
      this.type === Warning.WarningBarMedium
    ) {
      return true;
    } else {
      return false;
    }
  }

  get showWarningModalType() {
    if (
      this.type === Warning.WarningModalHigh ||
      this.type === Warning.WarningModalMedium ||
      this.type === Warning.WarningModalLow
    ) {
      return true;
    } else {
      return false;
    }
  }

  get showWarningSectionType() {
    if (
      this.type === Warning.WarningSectionHigh ||
      this.type === Warning.WarningSectionMedium ||
      this.type === Warning.WarningSectionLow
    ) {
      return true;
    } else {
      return false;
    }
  }
}
