import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { userIsAccountManager } from '@checklistfacil/shared/data-access/user';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserIsAccountManagerGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(userIsAccountManager),
      first(),
      map((permission) => {
        if (!permission) {
          this.router.navigate(['error']);
          return false;
        }
        return true;
      })
    );
  }
}
