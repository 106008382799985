<input
  *ngIf="editing && !disabled"
  #internalInput
  [type]="type"
  [value]="value"
  [placeholder]="placeholderText"
  [required]="required"
  [readOnly]="readonly"
  [attr.maxlength]="maxlength"
  (blur)="inputBlur($event)"
  (focusout)="changeToView()"
  (input)="handleChange($event)"
/>
<button
  *ngIf="!(editing && !disabled)"
  type="button"
  class="overlay"
  (focus)="changeToEdit()"
  (click)="changeToEdit()"
>
  <span class="overlay__placeholder" *ngIf="!value">{{ placeholderText }}</span>
  <ng-container *ngIf="value">{{ value }}</ng-container>
</button>
