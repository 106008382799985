import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UploadQueueItem,
  UploadQueueItemStatus,
} from '@checklistfacil/shared/util/general';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { TranslocoService } from '@ngneat/transloco';
import { first, map, switchMap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'cl-ui-upload-queue',
  templateUrl: './upload-queue.component.html',
  styleUrls: ['./upload-queue.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiUploadQueue'
    ),
  ],
})
export class UploadQueueComponent {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Input() @HostBinding('class.upload-queue--show') show = false;
  @Input() @HostBinding('class.upload-queue--collapsed') collapsed = false;
  @Input() items: UploadQueueItem[] = [];

  @Output() uploadItemRetry = new EventEmitter<UploadQueueItem>();
  @Output() uploadItemCancel = new EventEmitter<UploadQueueItem>();
  @Output() uploadsCancel = new EventEmitter();
  @Output() uploadsCollapsed = new EventEmitter();

  @ViewChild('confirmation', { static: true }) confirmationDialog:
    | ConfirmationDialogComponent
    | undefined;
  @ViewChild('confirmationFailedItems', { static: true })
  confirmationFailedItemsDialog: ConfirmationDialogComponent | undefined;

  constructor(private translocoService: TranslocoService) {}

  get pendingItems() {
    return this.items.filter(
      (item) => item.status === UploadQueueItemStatus.Pending
    );
  }

  get failedItems() {
    return this.items.filter(
      (item) => item.status === UploadQueueItemStatus.Error
    );
  }

  get hasPendingItems() {
    return this.pendingItems.length > 0;
  }

  get hasFailedItems() {
    return this.failedItems.length > 0;
  }

  toggleCollapsed() {
    this.uploadsCollapsed.emit();
  }

  close() {
    if (this.hasFailedItems) {
      this.translocoService
        .selectTranslateObject(
          'closeFailedDialogConfirmation',
          {},
          'clUiUploadQueue'
        )
        .pipe(
          first(),
          map((terms) => ({
            requiresCode: false,
            title: terms.title,
            content: terms.content,
            cancelButtonText: terms.cancel,
            confirmButtonText: terms.confirm,
            width: '600px',
            reverseButtons: true,
          })),
          switchMap((confirmCloseFailedDialogData) =>
            (<ConfirmationDialogComponent>this.confirmationFailedItemsDialog)
              .open(confirmCloseFailedDialogData)
              .afterClosed()
          )
        )
        .subscribe((response) => {
          if (!response) {
            this.uploadsCancel.emit();
          }
        });

      return;
    }

    if (!this.hasPendingItems) {
      this.uploadsCancel.emit();
      return;
    }

    this.translocoService
      .selectTranslateObject('closeDialogConfirmation', {}, 'clUiUploadQueue')
      .pipe(
        first(),
        map((terms) => ({
          requiresCode: false,
          title: terms.title,
          content: terms.content,
          cancelButtonText: terms.cancel,
          confirmButtonText: terms.confirm,
          width: '600px',
          reverseButtons: true,
        })),
        switchMap((confirmCloseDialogData) =>
          (<ConfirmationDialogComponent>this.confirmationDialog)
            .open(confirmCloseDialogData)
            .afterClosed()
        )
      )
      .subscribe((response) => {
        if (!response) {
          this.uploadsCancel.emit();
        }
      });
  }

  handleItemRetry(item: UploadQueueItem) {
    this.uploadItemRetry.emit(item);
  }

  handleItemCancel(item: UploadQueueItem) {
    this.uploadItemCancel.emit(item);
  }
}
