import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cl-form-file-textfield',
  templateUrl: './file-textfield.component.html',
  styleUrls: ['./file-textfield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileTextfieldComponent),
      multi: true,
    },
  ],
})
export class FileTextfieldComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() value = '';
  @Input() disabled = false;
  @Input() accept: string | undefined;
  @ViewChild('internalFile', { static: true }) internalFile:
    | ElementRef<HTMLInputElement>
    | undefined;
  @Output() fileSelected = new EventEmitter<FileList | null>();

  get internalFileInput(): HTMLInputElement {
    return <HTMLInputElement>this.internalFile?.nativeElement;
  }

  get selectedFile(): File | null {
    if (this.internalFileInput.files) {
      return this.internalFileInput.files[0];
    }

    return null;
  }

  get displayText() {
    return this.selectedFile ? this.selectedFile.name : this.value;
  }

  handleFileChange() {
    this.fileSelected.emit(this.internalFileInput.files);
    this.onChange(this.internalFileInput.files);
  }

  handleUploadClick(event: Event) {
    if (!this.disabled) {
      this.internalFileInput.click();
    }
    event.stopPropagation();
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange = (param: any) => {};
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onTouched = () => {};

  writeValue(obj: any): void {
    if (obj === null) {
      this.internalFileInput.type = '';
      this.internalFileInput.type = 'file';
    }
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
