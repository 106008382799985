import { Directive, HostListener, Input, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive({
  selector: '[clUiDialogTrigger]',
  exportAs: 'DialogTrigger',
})
export class DialogTriggerDirective {
  @Input() dialog: ComponentType<any> | TemplateRef<any> | undefined;
  @Input() dialogData: Record<string, unknown> | undefined;
  @Input() dialogConfig: MatDialogConfig<Record<string, unknown>> | undefined;

  constructor(public matDialog: MatDialog) {}

  @HostListener('click')
  handleTriggerClick() {
    if (!this.dialog) {
      throw new Error('this.dialog is undefined');
    }

    const defaultConfig = {
      width: '400px',
      panelClass: 'cl-custom-border-radius',
      data: this.dialogData,
    };
    const finalConfig = Object.assign({}, defaultConfig, this.dialogConfig);
    this.matDialog.open(this.dialog, finalConfig);
  }
}
