<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <textarea
    #textareaInput
    cdkTextareaAutosize
    matInput
    [placeholder]="placeholder"
    [disabled]="disabled"
    [readonly]="readonly"
    [required]="required"
    [value]="value"
    [attr.maxLength]="maxlength !== undefined ? maxlength : null"
    (input)="onFieldInput(textareaInput.value)"
    (change)="handleChange(textareaInput.value)"
  ></textarea>
  <mat-hint *ngIf="showCount" align="end"
    >{{ textareaInput.value.length }} / {{ maxlength }}</mat-hint
  >
  <mat-hint>
    <ng-content select="mat-error"></ng-content>
  </mat-hint>
</mat-form-field>
