import { Injectable } from '@angular/core';
import { JivoAPI, JivoUserInfo } from './types';

export interface JivoWindow extends Window {
  jivo_api?: JivoAPI;
}

declare let window: JivoWindow;

@Injectable({
  providedIn: 'root',
})
export class JivoChatService {
  private contactInfoSet = false;

  setup(src: string, open = false, contactInfo?: JivoUserInfo): Promise<void> {
    if (document.querySelectorAll('.jivochat-script').length === 0) {
      const jivoId = src.split('/').pop();

      if (jivoId && contactInfo?.id) {
        this.preSetup(jivoId, contactInfo.id);
      }

      const jivoScript = document.createElement('script');
      jivoScript.classList.add('jivochat-script');
      jivoScript.src = src;

      document.body.append(jivoScript);
    }

    return this.whenReady().then(() => {
      if (contactInfo && !this.contactInfoSet) {
        this.setContactInfo(contactInfo);
        this.contactInfoSet = true;
      }

      if (open) {
        this.open();
      }
    });
  }

  open() {
    return window.jivo_api && window.jivo_api.open();
  }

  close() {
    return window.jivo_api && window.jivo_api.close();
  }

  setContactInfo(info: JivoUserInfo) {
    if (window.jivo_api) {
      window.jivo_api.setContactInfo(info);
      window.jivo_api.setUserToken(String(info.id));
    }
  }

  private preSetup(jivoId: string, currentUserId: number) {
    const storedData = localStorage.getItem(`jv_store_${jivoId}_client`);

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      if (parsedData.user_token !== String(currentUserId)) {
        this.cleanupJivoData();
      }
    }
  }

  private cleanupJivoData() {
    Object.getOwnPropertyNames(window.localStorage)
      .filter((name) => name.startsWith('jv_'))
      .forEach((propName) => window.localStorage.removeItem(propName));
  }

  private async whenReady(timeout = 200) {
    return new Promise((resolve) => {
      function checkAPIAvailable() {
        if (window.jivo_api) {
          resolve(true);
        } else {
          setTimeout(() => checkAPIAvailable(), timeout);
        }
      }

      checkAPIAvailable();
    });
  }
}
