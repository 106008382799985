import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCompanyRegistration from './+state/reducer';
import { CompanyRegistrationEffects } from './+state/effects';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    StoreModule.forFeature(
      fromCompanyRegistration.COMPANY_REGISTRATION_FEATURE_KEY,
      fromCompanyRegistration.reducer
    ),
    EffectsModule.forFeature([CompanyRegistrationEffects]),
  ],
  providers: [CookieService],
})
export class CompanyRegistrationDataAccessModule {}
