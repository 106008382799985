import { Directive, Input, HostListener } from '@angular/core';
import { DependenciesDialogComponent } from './dependencies-dialog.component';
import { DependenciesDialogData } from './types';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Directive({
  selector: '[clUiDependenciesDialogTrigger]',
  exportAs: 'DependenciesDialogTrigger',
})
export class DependenciesDialogTriggerDirective {
  dialogInstance: MatDialogRef<DependenciesDialogComponent> | undefined;

  @Input() dependenciesDialogData: DependenciesDialogData | undefined;

  constructor(public matDialog: MatDialog) {}

  @HostListener('click')
  handleTriggerClick() {
    this.dialogInstance = this.matDialog.open(DependenciesDialogComponent, {
      width: '400px',
      data: this.dependenciesDialogData,
    });
  }
}
