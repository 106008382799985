import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AchievementsIconsComponent } from './achievements-icons/achievements-icons.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AchievementsIconsComponent],
  exports: [AchievementsIconsComponent],
})
export class SharedUserAchievementsUiModule {}
