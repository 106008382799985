export interface ConfirmationDialogData {
  requiresCode: boolean;
  codeInputLabel?: string;
  title: string;
  titleIconName?: string;
  content: string;
  cancelButtonText: string;
  confirmButtonText: string;
  alertMessage?: string;
  width?: string;
  reverseButtons?: boolean;
}
