import { Injectable } from '@angular/core';
import {
  newTracker,
  trackPageView,
  setUserId,
} from '@snowplow/browser-tracker';
import {
  LinkClickTrackingPlugin,
  trackLinkClick,
} from '@snowplow/browser-plugin-link-click-tracking';

import { ConfigAssetLoaderService } from '@checklistfacil/shared/util/environment';

@Injectable({ providedIn: 'root' })
export class SnowplowService {
  private enabled = false;

  constructor(private configService: ConfigAssetLoaderService) {
    this.setEnableSnowPlow();
  }

  private setEnableSnowPlow() {
    this.configService.loadConfigurations().subscribe((config) => {
      if (config.enableSnowPlow) {
        this.enableTracking();
        this.setupTracker();
      }
    });
  }

  private setupTracker() {
    newTracker('cf', 'https://snowplow.checklistfacil.com.br:9543', {
      appId: 'checklistfacilSPA',
      contexts: {
        webPage: true,
      },
      plugins: [LinkClickTrackingPlugin()],
    });
  }

  enableTracking() {
    this.enabled = true;
  }

  disableTracking() {
    this.enabled = false;
  }

  trackPageView(): void {
    if (this.enabled) {
      trackPageView();
    }
  }

  setUserId(userId: number | string) {
    if (this.enabled) {
      setUserId(String(userId));
    }
  }

  trackLinkClick(payload: {
    targetUrl: string;
    elementId?: string;
    elementClasses?: string[];
    elementTarget?: string;
    elementContent?: string;
  }) {
    if (this.enabled) {
      trackLinkClick(payload);
    }
  }
}
