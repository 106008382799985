<ng-template
  transloco
  let-t
  translocoRead="userTermsNotice"
  [translocoLang]="currentUserLanguage"
>
  <div class="user-terms">
    <span class="user-terms__message" [innerHTML]="t('message')"></span>
    <button
      class="user-terms__dismiss"
      type="button"
      (click)="handleUserDismissed()"
      >{{ t('confirm') }}</button
    >
  </div>
</ng-template>
