import { Action, createReducer, on } from '@ngrx/store';
import {
  setCompanyConfigs,
  updateBillingEmailState,
  updateRemainingTrialDays,
  updateSiengeIntegrationData,
  upgradeTrialToBasicPlan,
} from './actions';
import { CompanyData, defaultCompanyFeatures, PlanType, State } from './models';

export const companyInitialState: CompanyData = {
  billingEmailConfirmed: false,
  biggestEvaluationScore: undefined,
  canExtendTrial: false,
  canExtendTrialDays: null,
  companyRegisterFilled: false,
  contractLink: null,
  contractDate: null,
  currentPlanType: null,
  googleAnalyticsTag: null,
  hasContactInformation: false,
  isDemo: false,
  isTrial: false,
  planId: null,
  remainingTrialDays: null,
  selfSubscribed: false,
  ticketsPlatformUrl: '',
  segmentId: 0,
};

export const initialState: State = {
  companyConfigs: [],
  companyFeatures: defaultCompanyFeatures,
  accesses: [],
  company: companyInitialState,
  sienge: null,
};

const companyConfigsReducer = createReducer(
  initialState,
  on(setCompanyConfigs, (state, action): State => {
    return {
      companyConfigs: action.configs,
      companyFeatures: action.features,
      accesses: action.accesses,
      company: action.company,
      sienge: action.sienge,
    };
  }),
  on(upgradeTrialToBasicPlan, (state) => {
    return {
      ...state,
      company: {
        ...state.company,
        currentPlanType: PlanType.BASIC,
      },
    };
  }),
  on(updateBillingEmailState, (state, { billingEmailConfirmedChanged }) => ({
    ...state,
    company: {
      ...state.company,
      billingEmailConfirmed: billingEmailConfirmedChanged,
    },
  })),
  on(updateRemainingTrialDays, (state, { remainingTrialDays }) => ({
    ...state,
    company: {
      ...state.company,
      remainingTrialDays,
    },
  })),
  on(updateSiengeIntegrationData, (state, integrationData) => ({
    ...state,
    sienge: integrationData.data,
  }))
);

export function reducer(state: State, action: Action) {
  return companyConfigsReducer(state, action);
}
