export function trimFilenameToLength(filename: string, desiredLength: number) {
  if (filename.length <= desiredLength || desiredLength <= 0) {
    return filename;
  }

  const explodedName = filename.split('.');
  const extension =
    explodedName.length > 1 ? explodedName[explodedName.length - 1] : '';

  if (extension === '') {
    return filename.substring(0, desiredLength);
  }
  const separator = '...';
  const trueFilename = explodedName[0];
  const desiredAfterSeparator = desiredLength - separator.length;

  if (desiredAfterSeparator <= 0) {
    return filename;
  }

  const resultEachSide = desiredAfterSeparator / 2;
  let firstPart = resultEachSide;
  let lastPart = resultEachSide;

  if (!Number.isInteger(resultEachSide)) {
    firstPart += 1;
    lastPart = Math.floor(lastPart);
  }

  return (
    trueFilename.substring(0, firstPart) +
    separator +
    trueFilename.substr(-lastPart) +
    '.' +
    extension
  );
}
