import FileSaver from 'file-saver';

async function checkCORS(url: string, useCredentials: boolean) {
  return new Promise<boolean>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = useCredentials;
    xhr.open('OPTIONS', url);

    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status <= 299) {
        resolve(true);
      }
    });

    xhr.addEventListener('error', () => {
      if (xhr.status >= 200 && xhr.status <= 299) {
        reject(false);
      }
    });

    xhr.send();
  });
}

/**
 * @param url
 * @param name
 * @param useCredentials
 */
export async function downloadFileFromUser(
  url: string,
  name: string | undefined,
  useCredentials = true
) {
  const corsCheck = await checkCORS(url, useCredentials);
  const filename = name || 'download';

  return new Promise<boolean>((resolve, reject) => {
    if (corsCheck) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.withCredentials = useCredentials;
      xhr.responseType = 'blob';

      xhr.addEventListener('load', () => {
        // chama a função original, mas passando com o Blob
        FileSaver.saveAs(xhr.response, filename);
        resolve(true);
      });
      xhr.addEventListener('error', () => {
        console.error('Não foi possível salvar o arquivo.');
        reject(false);
      });

      xhr.send();
    } else {
      /*
       se existem erros de CORS, fallback para a funcao original, para
       pelo menos abrir em outra aba
      */
      FileSaver.saveAs(url, filename);
      resolve(true);
    }
  });
}
