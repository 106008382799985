<div class="queue-item">
  <span class="queue-item__icon">
    <mat-icon fontSet="material-icons-outlined">{{ typeIconName }}</mat-icon>
  </span>
  <div class="queue-item__name">
    <span [matTooltip]="item.name">
      {{ item.name }}
    </span>
  </div>

  <span
    *ngIf="itemError"
    [matTooltip]="item.errorMessage ?? ''"
    class="queue-item__error"
  >
    {{ 'clUiUploadQueueItem.shortErrorMessage' | transloco }}
  </span>

  <div class="queue-item__action-toggler" *ngIf="itemError">
    <span class="queue-item__action">
      <mat-icon color="warn" fontSet="material-icons-outlined"
        >error_outline</mat-icon
      >
    </span>
    <button (click)="handleRetry()" mat-icon-button class="queue-item__action">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <div class="queue-item__action-toggler" *ngIf="itemPending">
    <span class="queue-item__action">
      <mat-spinner diameter="20"></mat-spinner>
    </span>
    <button (click)="handleCancel()" mat-icon-button class="queue-item__action">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <span class="queue-item__indicator" *ngIf="itemSuccess">
    <mat-icon color="primary">check_circle_outline</mat-icon>
  </span>
</div>
