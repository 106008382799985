import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUtilNotificationsModule } from '@checklistfacil/shared/util/notifications';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompanyPlansResolverService } from './resolvers/company-plans.resolver.service';
import { CompanySubscriptionResolverService } from './resolvers/company-subscription.resolver.service';
import { CompanySubscriptionEffects } from './state/effects';
import {
  COMPANY_SUBSCRIPTION_FEATURE_KEY,
  companySubscriptionReducer,
} from './state/reducers';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    SharedUtilNotificationsModule,
    StoreModule.forFeature(
      COMPANY_SUBSCRIPTION_FEATURE_KEY,
      companySubscriptionReducer
    ),
    EffectsModule.forFeature([CompanySubscriptionEffects]),
  ],
  providers: [CompanySubscriptionResolverService, CompanyPlansResolverService],
})
export class CompanySubscriptionDataAccessModule {}
