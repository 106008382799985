import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingService } from './tracking/tracking.service';
import { SnowplowService } from './snowplow/snowplow.service';
import { OnboardingService } from './onboarding/onboarding.service';
import { HelperFlowDirective } from './helper-flow/helper-flow.directive';

@NgModule({
  imports: [CommonModule],
  providers: [TrackingService, SnowplowService, OnboardingService],
  declarations: [HelperFlowDirective],
  exports: [HelperFlowDirective],
})
export class SharedUtilTrackingModule {}
