import { ComponentHarness } from '@angular/cdk/testing';

export class ConfirmationDialogContentHarness extends ComponentHarness {
  static hostSelector = 'cl-ui-confirmation-dialog-content';

  protected confirmButton = this.locatorFor('.dialog-confirm-button');

  protected cancelButton = this.locatorFor('.dialog-cancel-button');

  async confirmDialog() {
    const button = await this.confirmButton();
    return button.click();
  }

  async cancelDialog() {
    const button = await this.cancelButton();
    return button.click();
  }
}
