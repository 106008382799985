import { Configuration } from '@checklistfacil/shared/util/environment';

export const endpoints = {
  notFoundRedirect: (environment: Configuration) => `${environment.appUrl}`,
  appDashboard: (environment: Configuration) => `${environment.appUrl}`,
  userInfo: (environment: Configuration) => `${environment.apiUrl}/me`,
  loginUrl: (environment: Configuration) => `${environment.atualUrl}/login`,
  users: (id?: number) => {
    const url = (environment: Configuration) => `${environment.apiUrl}/users`;
    return id ? `${url}/${id}` : url;
  },
  usersFilters: (environment: Configuration, id?: number) => {
    const url = `${environment.apiUrl}/users/filters`;
    return id ? `${url}/${id}` : url;
  },
  userTypes: (environment: Configuration, id?: number) => {
    const url = `${environment.apiUrl}/user-types`;
    return id ? `${url}/${id}` : url;
  },
  userTypesFilters: (environment: Configuration, id?: number) => {
    const url = `${environment.apiUrl}/user-types/filters`;
    return id ? `${url}/${id}` : url;
  },
  translations: (environment: Configuration) =>
    `${environment.apiUrl}/system/translations`,
  productCategories: (environment: Configuration) =>
    `${environment.apiUrl}/product-categories`,
  profilePage: (environment: Configuration) =>
    `${environment.appUrl}/me/profile`,
  changePasswordPage: (environment: Configuration) =>
    `${environment.appUrl}/me/profile#change-password`,
  logoutPage: (environment: Configuration) => `${environment.appUrl}/logout`,
  helpCenterUrl: 'javascript:void(0)',
  evaluations: (environment: Configuration) =>
    `${environment.appUrl}/evaluations`,
  footerMessages: (environment: Configuration) =>
    `${environment.appUrl}/messages`,
  footerNotifications: (environment: Configuration) =>
    `${environment.appUrl}/notifications`,
  footerImportExport: (environment: Configuration) =>
    `${environment.appUrl}/settings/queues`,
};
