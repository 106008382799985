import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { userValid } from './+state/selectors';

@Injectable({
  providedIn: 'root',
})
export class UserCanAccessCompanyGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(userValid),
      first(),
      map((hasAccess) => {
        if (!hasAccess && hasAccess === null) {
          this.router.navigate(['company-registration']);
          return false;
        }
        return true;
      })
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
