<div class="image-mapper-showcase">
  <ng-container *ngIf="image">
    <div class="mapper-screen">
      <div
        class="mapper-item-wrapper"
        *ngFor="let item of items; index as i"
        (click)="handleItemClick(item)"
        [ngStyle]="{
          'top.%': item.y,
          'left.%': item.x,
          backgroundColor: item?.color
        }"
      >
        <span [matTooltip]="item?.name ?? ''" class="mapper-item">{{ i + 1 }}</span>
      </div>
    </div>
  </ng-container>
  <img
    *ngIf="image"
    [src]="image"
    alt="source-image"
    [width]="IMAGE_WIDTH"
    [height]="IMAGE_HEIGHT"
  />
</div>
