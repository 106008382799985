<div class="addon" [ngStyle]="{ height: boxHeight, width: boxWidth }">
  <div
    *ngIf="data.comment"
    class="icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.height]="iconHeight"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.99 2C19.99 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20L19.99 2ZM18 2V15.17L16.83 14H2V2H18ZM16 10H4V12H16V10ZM4 7H16V9H4V7ZM16 4H4V6H16V4Z"
        fill="black"
        fill-opacity="0.6"
      />
    </svg>
  </div>
  <div
    *ngIf="data.actionPlan"
    class="icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.height]="iconHeight"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.27 0H12.73L18 5.27V12.73L12.73 18H5.27L0 12.73V5.27L5.27 0ZM11.9 16L16 11.9V6.1L11.9 2H6.1L2 6.1V11.9L6.1 16H11.9ZM9 12C8.44771 12 8 12.4477 8 13C8 13.5523 8.44771 14 9 14C9.55229 14 10 13.5523 10 13C10 12.4477 9.55229 12 9 12ZM10 4H8V11H10V4Z"
        fill="black"
        fill-opacity="0.6"
      />
    </svg>
  </div>
  <div
    *ngIf="data.attachment"
    class="icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.height]="iconHeight"
      viewBox="0 0 12 22"
      fill="none"
    >
      <path
        d="M10 5V16.5C10 18.71 8.21 20.5 6 20.5C3.79 20.5 2 18.71 2 16.5V4C2 2.62 3.12 1.5 4.5 1.5C5.88 1.5 7 2.62 7 4V14.5C7 15.05 6.55 15.5 6 15.5C5.45 15.5 5 15.05 5 14.5V5H3.5V14.5C3.5 15.88 4.62 17 6 17C7.38 17 8.5 15.88 8.5 14.5V4C8.5 1.79 6.71 0 4.5 0C2.29 0 0.5 1.79 0.5 4V16.5C0.5 19.54 2.96 22 6 22C9.04 22 11.5 19.54 11.5 16.5V5H10Z"
        fill="black"
        fill-opacity="0.6"
      />
    </svg>
  </div>
  <div
    *ngIf="data.signature"
    class="icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.height]="iconHeight"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.37992 2.67999C4.06992 2.54999 3.36992 3.18999 2.66992 3.89999L0.919922 2.18999C1.13992 1.91999 1.43992 1.61999 1.79992 1.25999C2.04992 1.00999 3.19992 0.00999451 4.51992 0.00999451C5.38992 0.00999451 7.02992 0.699994 7.02992 2.86999C7.02992 4.22999 6.50992 5.00999 5.72992 6.14999C5.27992 6.80999 4.22992 8.57999 3.87992 9.66999C3.51992 10.76 3.78992 11.59 4.23992 11.59C4.65213 11.59 5.06434 11.0938 5.31798 10.7884C5.32893 10.7752 5.33958 10.7624 5.34992 10.75C5.57992 10.51 7.05992 8.75999 7.63992 8.02999C8.39992 7.1 10.3299 5.18999 12.5799 5.18999C15.5199 5.18999 16.4599 7.73999 16.6099 9.38999H19.0799V11.89H16.6199C16.2199 16.66 13.5599 17.99 11.9299 17.99C10.1599 17.99 8.71992 16.6 8.71992 14.9C8.71992 13.2 10.3199 10.17 14.0999 9.52999C14.0928 9.47968 14.0861 9.4284 14.0793 9.37651C13.9806 8.62389 13.8648 7.73999 12.3399 7.73999C11.0899 7.73999 9.46992 9.68999 8.25992 11.18L8.24725 11.1956C7.14322 12.5583 6.26585 13.6412 5.19992 13.95C4.29992 14.22 3.30992 14.05 2.55992 13.49C1.69992 12.85 1.21992 11.79 1.21992 10.51C1.21992 8.40258 3.19921 5.4989 3.87563 4.50657C3.97596 4.35938 4.04763 4.25425 4.07992 4.19999C4.37992 3.70999 4.87992 2.87999 4.37992 2.67999ZM11.2199 14.84C11.2199 15.3 11.6499 15.56 11.9599 15.56C12.6599 15.56 13.7899 14.77 14.0899 12.08C11.9499 12.64 11.2199 14.24 11.2199 14.84Z"
        fill="black"
        fill-opacity="0.6"
      />
    </svg>
  </div>
</div>
