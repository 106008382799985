import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEsExtra from '@angular/common/locales/extra/es';
import localePtExtra from '@angular/common/locales/extra/pt';
import localePtPtExtra from '@angular/common/locales/extra/pt-PT';
import localePt from '@angular/common/locales/pt';
import localePtPt from '@angular/common/locales/pt-PT';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ClUiModule } from '@checklistfacil/cl-ui';
import { LanguageInterceptorService } from '@checklistfacil/company/registration/data-access';
import { LanguageApiInterceptorService } from '@checklistfacil/shared/data-access/api-i18n';
import { DataAccessCompanyConfigsModule } from '@checklistfacil/shared/data-access/company-configs';
import {
  DeprecatedConfigAssetLoaderService,
  SharedDataAccessEnvironmentModule,
} from '@checklistfacil/shared/data-access/environment';
import { SharedDataAccessLoadingModule } from '@checklistfacil/shared/data-access/loading';
import { SharedDataAccessUploadQueueModule } from '@checklistfacil/shared/data-access/upload-queue';
import {
  LocaleFromUserService,
  SharedDataAccessUserModule,
} from '@checklistfacil/shared/data-access/user';
import { SharedFeatureWarningBarsModule } from '@checklistfacil/shared/feature/warning-bars';
import { SharedMainMenuDataAccessModule } from '@checklistfacil/shared/main-menu/data-access';
import { SharedMainMenuFeatureModule } from '@checklistfacil/shared/main-menu/feature';
import { SharedMainMenuUiModule } from '@checklistfacil/shared/main-menu/ui';
import { SharedUiErrorsModule } from '@checklistfacil/shared/ui/errors';
import { SharedUtilEnvironmentModule } from '@checklistfacil/shared/util/environment';
import { AppReleasesWatcherService } from '@checklistfacil/shared/util/general';
import {
  growthBookRootProvider,
  SharedUtilGrowthbookModule,
} from '@checklistfacil/shared/util/growthbook';
import { SharedUtilNotificationsModule } from '@checklistfacil/shared/util/notifications';
import {
  ClPaginatorIntl,
  SharedUtilTranslateModule,
} from '@checklistfacil/shared/util/translate';
import { UserAchievementsDataAccessModule } from '@checklistfacil/user/achievements/data-access';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
import { NotFoundErrorComponent } from './_errors/not-found-error';
import { AuthenticationInterceptorService } from './_interceptors/authentication-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { HallComponent } from './hall/hall.component';
import { ChecklistAppEffects } from './state/effects';
import { reducer as appReducer } from './state/reducers';
import { SharedTrackingDataAccessSentryModule } from '@checklistfacil/shared/tracking/data-access/sentry';

registerLocaleData(localeEn, localeEnExtra);
registerLocaleData(localeEs, localeEsExtra);
registerLocaleData(localePt, localePtExtra);
registerLocaleData(localePtPt, localePtPtExtra);

const CHECKLIST_MAT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'L',
  },
  display: {
    ...MAT_MOMENT_DATE_FORMATS.display,
    dateInput: 'L',
  },
};

@NgModule({
  declarations: [AppComponent, NotFoundErrorComponent, HallComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatTabsModule,
    ClUiModule,
    DataAccessCompanyConfigsModule,
    SharedDataAccessEnvironmentModule,
    SharedDataAccessLoadingModule,
    SharedDataAccessUserModule,
    SharedMainMenuUiModule,
    SharedMainMenuDataAccessModule,
    SharedUiErrorsModule,
    SharedUtilEnvironmentModule,
    SharedUtilNotificationsModule,
    SharedUtilTranslateModule,
    UserAchievementsDataAccessModule,
    AppRoutingModule,
    SharedFeatureWarningBarsModule,
    SharedUtilGrowthbookModule.forRoot(environment.growthBook),
    EffectsModule.forRoot([ChecklistAppEffects]),
    StoreModule.forRoot(
      { app: appReducer },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      name: 'ConfigurarChecklist',
    }),
    SharedDataAccessUploadQueueModule,
    SharedTrackingDataAccessSentryModule.forRoot(),
    SharedMainMenuFeatureModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    AppService,
    AppReleasesWatcherService,
    growthBookRootProvider(),
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: DeprecatedConfigAssetLoaderService) => () => {
        return configService.init().toPromise();
      },
      deps: [DeprecatedConfigAssetLoaderService, Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageApiInterceptorService,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CHECKLIST_MAT_DATE_FORMATS,
    },
    {
      provide: LOCALE_ID,
      deps: [LocaleFromUserService],
      useFactory: (service: LocaleFromUserService) => service.locale(),
    },
    {
      provide: 'environments',
      useValue: environment,
    },
    { provide: MatPaginatorIntl, useClass: ClPaginatorIntl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
