import { Component, EventEmitter, Input, Output } from '@angular/core';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { OptionItem } from './types';

@Component({
  selector: 'cl-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiHeader'
    ),
  ],
})
export class HeaderComponent {
  @Input() title: string | undefined;
  @Input() breadcrumbs: string[] = [];
  @Input() moreOptions: OptionItem[] = [];
  @Input() disableBorder = false;
  @Input() titleTooltip: string | undefined;
  @Input() hasActionButton = false;
  @Input() actionButtonLabel = '';
  @Input() usePopover = false;
  @Input() icon = 'arrow_back';

  @Output() buttonNavClick = new EventEmitter<void>();
  @Output() buttonMoreClick = new EventEmitter<void>();
  @Output() optionClick = new EventEmitter<{
    event: MouseEvent;
    type: string | number;
  }>();
  @Output() actionButtonClick = new EventEmitter<void>();

  handleButtonMoreClick() {
    this.buttonMoreClick.emit();
  }

  handleButtonNavClick() {
    this.buttonNavClick.emit();
  }

  optionClicked(event: MouseEvent, type: string | number) {
    this.optionClick.emit({ event, type });
  }

  handleActionButtonClick() {
    this.actionButtonClick.emit();
  }
}
