import { Injectable } from '@angular/core';
import { UploadQueueItem } from '@checklistfacil/shared/util/general';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of, Subscription } from 'rxjs';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { FormDataUploadService } from '../form-data-upload.service';
import * as actions from './actions';
import { State as UploadQueueState } from './reducers';
import * as selectors from './selectors';

@Injectable()
export class UploadQueueEffects {
  constructor(
    private store: Store<UploadQueueState>,
    private actions$: Actions,
    private uploadService: FormDataUploadService
  ) {}

  timeoutOverlay: number | null = null;
  currentUploadItem: UploadQueueItem | null = null;
  currentUploadSubscription: Subscription | null = null;

  startQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.startQueue.type, actions.nextQueueItem.type),
      withLatestFrom(this.store.pipe(select(selectors.pendingItems))),
      mergeMap(([action, pendingItems]) => {
        if (this.timeoutOverlay !== null) {
          clearTimeout(this.timeoutOverlay);
        }

        if (!pendingItems.length) {
          return of(actions.endQueue());
        }
        return of(
          actions.queueItemUpload({
            item: pendingItems[0],
          })
        );
      })
    )
  );

  uploadQueueItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.queueItemUpload.type),
        mergeMap(({ item }: { item: UploadQueueItem }) => {
          this.currentUploadItem = item;
          this.currentUploadSubscription = this.uploadService
            .send(item.endpoint, item.file)
            .pipe(
              tap(
                (response) => {
                  this.store.dispatch(
                    actions.queueItemUploadSuccess({ item, payload: response })
                  );
                },
                (error) => {
                  this.store.dispatch(
                    actions.queueItemUploadError({ error, item })
                  );
                }
              )
            )
            .subscribe();
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  uploadQueueItemEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        actions.queueItemUploadError.type,
        actions.queueItemUploadSuccess.type
      ),
      withLatestFrom(this.store.pipe(select(selectors.hasPendingItems))),
      map(([action, hasPendingItems]) => {
        if (this.currentUploadSubscription) {
          this.currentUploadSubscription.unsubscribe();
          this.currentUploadSubscription = null;
        }

        if (hasPendingItems) {
          return actions.nextQueueItem();
        }
        return actions.endQueue();
      })
    )
  );

  retryUploadItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.retryQueueItem.type),
      withLatestFrom(this.store.pipe(select(selectors.isUploading))),
      mergeMap(([action, isUploading]) => {
        if (!isUploading) {
          return of(actions.startQueue());
        }
        return EMPTY;
      })
    )
  );

  cancelUploadItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.cancelQueueItem.type),
      mergeMap(({ item }: { item: UploadQueueItem }) => {
        if (
          this.currentUploadItem &&
          this.currentUploadItem.id === item.id &&
          this.currentUploadSubscription !== null
        ) {
          this.currentUploadSubscription.unsubscribe();
          return of(actions.nextQueueItem());
        }
        return EMPTY;
      })
    )
  );

  cancelAllUploads = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.cancelAllQueueItems.type),
        mergeMap(() => {
          if (this.currentUploadSubscription) {
            this.currentUploadSubscription.unsubscribe();
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  endQueue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.endQueue.type),
        mergeMap(() => {
          this.currentUploadItem = null;
          this.currentUploadSubscription = null;

          this.timeoutOverlay = setTimeout(() => {
            this.store.dispatch(actions.closeUploadQueue());
          }, 4000);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );
}
