import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { MenuListItem } from '../types';

@Component({
  selector: 'cl-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiMenuListItem'
    ),
  ],
})
export class MenuListItemComponent implements OnInit {
  @Input() item: MenuListItem | undefined;
  @Output() listItemClicked = new EventEmitter<MenuListItem>();
  @Output() upgradeIconClicked = new EventEmitter<MenuListItem>();

  target = '_self';

  get hasChildren() {
    return this.item
      ? this.item.children !== undefined && this.item.children.length
      : false;
  }

  get toggleSubmenu() {
    return this.item && this.item.childrenToggled;
  }

  ngOnInit() {
    this.target = this.item?.target || this.target;
  }

  handleListItemClick(event: Event) {
    if (this.hasChildren) {
      event.preventDefault();
    }
    this.listItemClicked.emit(this.item);
  }

  handleUpgradeIconClick(event: Event) {
    if (this.item?.availableInPlan) {
      event.preventDefault();
      this.upgradeIconClicked.emit(this.item);
    }
  }

  handleSubmenuUpgradeIconClick(item: MenuListItem) {
    this.upgradeIconClicked.emit(item);
  }
}
