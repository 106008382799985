import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
/**
 * @TODO: remover o disabled à baixo quando atualizar a versão do ESLint para  v7.20.0
 * https://github.com/angular-eslint/angular-eslint/issues/662#issuecomment-939660455
 */

// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    const phone = value.replace(/\D/g, '');
    return phone.replace(
      /(\d{2})?(\d{2})?(\d)?(\d{4})?(\d{4})/,
      '+$1 ($2) $3 $4-$5'
    );
  }
}
