import {
  companyPlanTypeIsBasic,
  companyPlanTypeIsTrial,
  userIsAdmin
} from '@checklistfacil/shared/data-access/company-configs';
import { AchievementIcon } from '@checklistfacil/shared/user-achievements/util';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AchievementGroups, AchievementIconWithActive } from './models';
import { State, USER_ACHIEVEMENTS_FEATURE_KEY } from './reducer';

// Lookup the 'User' feature state managed by NgRx
export const getUserAchievementsState = createFeatureSelector<State>(
  USER_ACHIEVEMENTS_FEATURE_KEY
);

const isAchievementCompleted = (
  groups: AchievementGroups[] | null,
  groupName: string,
  achievementToken: string
) => {
  const groupIntroduction = groups?.find(
    (achievementGroup) => achievementGroup.group === groupName
  );

  if (groupIntroduction) {
    const achievementItem = groupIntroduction.achievements.find(
      (achievementItem) => achievementItem.token === achievementToken
    );

    return achievementItem ? achievementItem.completed : false;
  }
  return false;
};

const isGroupCompleted = (
  groups: AchievementGroups[] | null,
  groupName: string
) => {
  const group = groups?.find(
    (achievementGroup) => achievementGroup.group === groupName
  );
  if (group) {
    return group.completed;
  }
  return undefined;
};

export const achievementGroups = createSelector(
  getUserAchievementsState,
  (state) => state.achievementGroups
);

export const companyNumbers = createSelector(
  getUserAchievementsState,
  (state) => state.companyNumbers
);

export const completedAchievementsNameToken = createSelector(
  achievementGroups,
  (groups) =>
    groups?.map((value) =>
      value?.achievements
        .filter((value) => value.completed === true)
        .map((value) => value.token)
    )
);

// Achievement Group: Introduction
export const applyChecklistIsActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupIntroduction,
      AchievementIcon.ApplyChecklist
    )
);

export const quickstartIsActive = createSelector(achievementGroups, (groups) =>
  isAchievementCompleted(
    groups,
    AchievementIcon.GroupIntroduction,
    AchievementIcon.Quickstart
  )
);

export const configChecklistIsActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupIntroduction,
      AchievementIcon.SetupChecklistBySettingsScreen
    )
);

export const configUnitIsActive = createSelector(achievementGroups, (state) =>
  isAchievementCompleted(
    state,
    AchievementIcon.GroupIntroduction,
    AchievementIcon.SetupUnitBySettingsScreen
  )
);

export const viewReportIsActive = createSelector(achievementGroups, (state) =>
  isAchievementCompleted(
    state,
    AchievementIcon.GroupIntroduction,
    AchievementIcon.ViewReportOfAppliedChecklists
  )
);

export const accessHelpCenterIsActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupIntroduction,
      AchievementIcon.AccessToHelpCenter
    )
);

// Achievement Group: Learning More Features
export const createAUserTypeActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupLearningMoreFeatures,
      AchievementIcon.CreateAUserType
    )
);

export const createAUserActive = createSelector(achievementGroups, (state) =>
  isAchievementCompleted(
    state,
    AchievementIcon.GroupLearningMoreFeatures,
    AchievementIcon.CreateAUser
  )
);

export const createAnActionPlanActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupLearningMoreFeatures,
      AchievementIcon.CreateAnActionPlan
    )
);

export const viewActionPlansActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupLearningMoreFeatures,
      AchievementIcon.ViewActionPlans
    )
);

export const viewTheActionPlansReportActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupLearningMoreFeatures,
      AchievementIcon.ViewTheActionPlansReport
    )
);

export const addEvidenceToTheActionPlanActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupLearningMoreFeatures,
      AchievementIcon.AddEvidenceToTheActionPlan
    )
);

export const completeAnActionPlanActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupLearningMoreFeatures,
      AchievementIcon.CompleteAnActionPlan
    )
);

export const viewTheRecidivismReportActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupLearningMoreFeatures,
      AchievementIcon.ViewTheRecidivismReport
    )
);

export const applyTenChecklistsActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupLearningMoreFeatures,
      AchievementIcon.ApplyTenChecklists
    )
);

export const applyChecklistOnMobileActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupLearningMoreFeatures,
      AchievementIcon.ApplyChecklistOnMobile
    )
);

// Achievement Group: Becoming an expert

export const viewIndicatorsReportActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupBecomingAnExpert,
      AchievementIcon.ViewIndicatorsReport
    )
);

export const viewUnitAuditReportActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupBecomingAnExpert,
      AchievementIcon.ViewUnitAuditReport
    )
);

export const viewUserAuditReportActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupBecomingAnExpert,
      AchievementIcon.ViewUserAuditReport
    )
);

export const configureADashboardActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupBecomingAnExpert,
      AchievementIcon.ConfigureADashboard
    )
);

export const manageYourNotificationsActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupBecomingAnExpert,
      AchievementIcon.ManageYourNotifications
    )
);

export const accessOtherSettingsActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupBecomingAnExpert,
      AchievementIcon.AccessOtherSettings
    )
);

export const importAChecklistActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupBecomingAnExpert,
      AchievementIcon.ImportAChecklist
    )
);

export const exportAnAppliedChecklistActive = createSelector(
  achievementGroups,
  (groups) =>
    isAchievementCompleted(
      groups,
      AchievementIcon.GroupBecomingAnExpert,
      AchievementIcon.ExportAnAppliedChecklist
    )
);

export const isAchievementBoardIntroductionCompleted = createSelector(
  achievementGroups,
  (groups) => isGroupCompleted(groups, AchievementIcon.GroupIntroduction)
);

export const iconsIntroduction = createSelector(
  isAchievementBoardIntroductionCompleted,
  quickstartIsActive,
  applyChecklistIsActive,
  configChecklistIsActive,
  configUnitIsActive,
  viewReportIsActive,
  accessHelpCenterIsActive,
  (
    isAchievementBoardIntroductionCompleted,
    quickstartIsActive,
    applyChecklistIsActive,
    configChecklistIsActive,
    configUnitIsActive,
    viewReportIsActive,
    accessHelpCenterIsActive
  ) => {
    return [
      {
        icon: AchievementIcon.Quickstart,
        active: quickstartIsActive,
      },
      {
        icon: AchievementIcon.ApplyChecklist,
        active: applyChecklistIsActive,
      },
      {
        icon: AchievementIcon.SetupChecklistBySettingsScreen,
        active: configChecklistIsActive,
      },
      {
        icon: AchievementIcon.SetupUnitBySettingsScreen,
        active: configUnitIsActive,
      },
      {
        icon: AchievementIcon.ViewReportOfAppliedChecklists,
        active: viewReportIsActive,
      },
      {
        icon: AchievementIcon.AccessToHelpCenter,
        active: accessHelpCenterIsActive,
      },
      {
        icon: AchievementIcon.SilverTrophy,
        active: isAchievementBoardIntroductionCompleted,
      },
    ];
  }
);

export const applicationsMade = createSelector(
  companyNumbers,
  (state) => state.appliedChecklists
);

export const createdChecklists = createSelector(
  companyNumbers,
  (state) => state.createdChecklists
);

export const unitsCreated = createSelector(
  companyNumbers,
  (state) => state.createdUnits
);

export const registeredUsers = createSelector(
  companyNumbers,
  (state) => state.registeredUsers
);

export const actionsPlansCreated = createSelector(
  companyNumbers,
  (state) => state.createdActionPlans
);

export const actionPlansCompleted = createSelector(
  companyNumbers,
  (state) => state.completedActionPlans
);

export const isAchievementBoardLearningMoreFeatureCompleted = createSelector(
  achievementGroups,
  (groups) =>
    isGroupCompleted(groups, AchievementIcon.GroupLearningMoreFeatures)
);

export const iconsLearningMoreFeature01 = createSelector(
  createAUserTypeActive,
  createAUserActive,
  createAnActionPlanActive,
  viewActionPlansActive,
  viewTheActionPlansReportActive,
  (
    createAUserTypeActive,
    createAUserActive,
    createAnActionPlanActive,
    viewActionPlansActive,
    viewTheActionPlansReportActive
  ): AchievementIconWithActive[] => {
    return [
      {
        icon: AchievementIcon.CreateAUserType,
        active: createAUserTypeActive,
      },
      {
        icon: AchievementIcon.CreateAUser,
        active: createAUserActive,
      },
      {
        icon: AchievementIcon.CreateAnActionPlan,
        active: createAnActionPlanActive,
      },
      {
        icon: AchievementIcon.ViewActionPlans,
        active: viewActionPlansActive,
      },
      {
        icon: AchievementIcon.ViewTheActionPlansReport,
        active: viewTheActionPlansReportActive,
      },
    ];
  }
);

export const iconsLearningMoreFeature02 = createSelector(
  isAchievementBoardLearningMoreFeatureCompleted,
  addEvidenceToTheActionPlanActive,
  completeAnActionPlanActive,
  viewTheRecidivismReportActive,
  applyTenChecklistsActive,
  applyChecklistOnMobileActive,
  (
    isAchievementBoardLearningMoreFeatureCompleted,
    addEvidenceToTheActionPlanActive,
    completeAnActionPlanActive,
    viewTheRecidivismReportActive,
    applyTenChecklistsActive,
    applyChecklistOnMobileActive
  ): AchievementIconWithActive[] => {
    return [
      {
        icon: AchievementIcon.AddEvidenceToTheActionPlan,
        active: addEvidenceToTheActionPlanActive,
      },
      {
        icon: AchievementIcon.CompleteAnActionPlan,
        active: completeAnActionPlanActive,
      },
      {
        icon: AchievementIcon.ViewTheRecidivismReport,
        active: viewTheRecidivismReportActive,
      },
      {
        icon: AchievementIcon.ApplyTenChecklists,
        active: applyTenChecklistsActive,
      },
      {
        icon: AchievementIcon.ApplyChecklistOnMobile,
        active: applyChecklistOnMobileActive,
      },
      {
        icon: AchievementIcon.GoldTrophy,
        active: isAchievementBoardLearningMoreFeatureCompleted,
      },
    ];
  }
);

export const allIconsLearningMoreFeature = createSelector(
  iconsLearningMoreFeature01,
  iconsLearningMoreFeature02,
  (iconsFeature01, iconsFeature02) => {
    return iconsFeature01.concat(iconsFeature02);
  }
);

export const isAchievementBecomingAnExpertCompleted = createSelector(
  achievementGroups,
  (groups) => isGroupCompleted(groups, AchievementIcon.GroupBecomingAnExpert)
);

export const iconsBecomingAnExpert01 = createSelector(
  viewIndicatorsReportActive,
  viewUnitAuditReportActive,
  viewUserAuditReportActive,
  configureADashboardActive,
  (
    viewIndicatorsReportActive,
    viewUnitAuditReportActive,
    viewUserAuditReportActive,
    configureADashboardActive
  ): AchievementIconWithActive[] => {
    return [
      {
        icon: AchievementIcon.ViewIndicatorsReport,
        active: viewIndicatorsReportActive,
      },
      {
        icon: AchievementIcon.ViewUnitAuditReport,
        active: viewUnitAuditReportActive,
      },
      {
        icon: AchievementIcon.ViewUserAuditReport,
        active: viewUserAuditReportActive,
      },
      {
        icon: AchievementIcon.ConfigureADashboard,
        active: configureADashboardActive,
      },
    ];
  }
);

export const iconsBecomingAnExpert02 = createSelector(
  manageYourNotificationsActive,
  accessOtherSettingsActive,
  importAChecklistActive,
  exportAnAppliedChecklistActive,
  isAchievementBecomingAnExpertCompleted,
  (
    manageYourNotificationsActive,
    accessOtherSettingsActive,
    importAChecklistActive,
    exportAnAppliedChecklistActive,
    isAchievementBecomingAnExpertCompleted
  ): AchievementIconWithActive[] => {
    return [
      {
        icon: AchievementIcon.ManageYourNotifications,
        active: manageYourNotificationsActive,
      },
      {
        icon: AchievementIcon.AccessOtherSettings,
        active: accessOtherSettingsActive,
      },
      {
        icon: AchievementIcon.ImportAChecklist,
        active: importAChecklistActive,
      },
      {
        icon: AchievementIcon.ExportAnAppliedChecklist,
        active: exportAnAppliedChecklistActive,
      },
      {
        icon: AchievementIcon.Diamond,
        active: isAchievementBecomingAnExpertCompleted,
      },
    ];
  }
);

export const allIconsBecomingAnExpert = createSelector(
  iconsBecomingAnExpert01,
  iconsBecomingAnExpert02,
  (iconsExpert01, iconsExpert02) => {
    return iconsExpert01.concat(iconsExpert02);
  }
);

export const calcUserJourney = createSelector(achievementGroups, (groups) => {
  if (!groups || Object.keys(groups).length === 0) {
    return 0;
  }

  const totalAchievements = groups
    .map((elem) => elem.achievements.length)
    .reduce((prev, curr) => prev + curr, 0);

  const totalAchievementsCompleted = groups
    .map(
      (value) =>
        value?.achievements.filter((value) => value.completed === true).length
    )
    .reduce((prev, curr) => prev + curr, 0);

  return Math.round((totalAchievementsCompleted * 100) / totalAchievements);
});

export const userTrophy = createSelector(
  isAchievementBoardIntroductionCompleted,
  isAchievementBoardLearningMoreFeatureCompleted,
  isAchievementBecomingAnExpertCompleted,
  (introduction, learningMore, expert) => {
    if (
      introduction === undefined &&
      learningMore === undefined &&
      expert === undefined
    ) {
      return null;
    }

    if (introduction && !learningMore) {
      return AchievementIcon.SilverTrophy;
    }
    if (introduction && expert && !learningMore) {
      return AchievementIcon.SilverTrophy;
    }
    if (learningMore && !expert) {
      return AchievementIcon.GoldTrophy;
    }
    if (introduction && learningMore && expert) {
      return AchievementIcon.Diamond;
    }

    return AchievementIcon.BronzeTrophy;
  }
);

export const userHasAccessToAchievements = createSelector(
  userIsAdmin,
  companyPlanTypeIsTrial,
  companyPlanTypeIsBasic,
  (isAdmin, companyIsTrial, companyIsBasic) => {
    return isAdmin && (companyIsTrial || companyIsBasic);
  }
);
