<ng-container
  *transloco="let t; read: 'clUiFileUploadGalleryInvalidFilesDialog'"
>
  <div mat-dialog-title>
    <mat-icon color="primary" fontSet="material-icons-outlined"
      >report_problem</mat-icon
    >
    <span class="dialog-title">{{ t('titleErrorSending') }}</span>
  </div>
  <mat-dialog-content>
    <mat-list dense>
      <mat-list-item *ngFor="let file of invalidFilesBySize">
        <span class="item-name" mat-line
          >"{{ shorten(file?.file?.name) }}"</span
        >
        <span mat-line>{{
          t('fileCannotBeLargerThan', { value: allowedMaxSize + 'MB' })
        }}</span>
      </mat-list-item>
      <mat-list-item *ngFor="let file of invalidFilesByType">
        <span class="item-name" mat-line
          >"{{ shorten(file?.file?.name) }}"</span
        >
        <span mat-line class="invalid-type-list">{{
          t('uploadInvalidType') + ' (' + allowedTypes + ')'
        }}</span>
      </mat-list-item>
      <mat-list-item *ngFor="let file of invalidFilesByDimension">
        <span class="item-name" mat-line
          >"{{ shorten(file?.file?.name) }}"</span
        >
        <span mat-line>{{
          t('imageDimensionError', { value: allowedImageDimension })
        }}</span>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button (click)="confirm()" color="primary">{{
      t('ok')
    }}</button>
  </mat-dialog-actions>
</ng-container>
