import { createAction, props } from '@ngrx/store';
import { AchievementGroups, CompanyNumbers } from './models';

export const init = createAction('[User Achievements Page] Init');

export const loadUserAchievements = createAction(
  '[User Achievements] load user achievements'
);

export const loadUserAchievementsSuccess = createAction(
  '[User Achievements] load user achievements Success',
  props<{ achievementGroups: AchievementGroups[] }>()
);

export const loadUserAchievementsError = createAction(
  '[User Achievements] load user achievements Error',
  props<{ error: { message: string } }>()
);

export const loadCompanyNumbers = createAction(
  '[User Achievements] load company numbers'
);

export const loadCompanyNumbersSuccess = createAction(
  '[User Achievements] load company numbers Success',
  props<{ companyNumbers: CompanyNumbers }>()
);

export const loadCompanyNumbersError = createAction(
  '[User Achievements] load company numbers Error',
  props<{ error: { message: string } }>()
);
