import { Component, EventEmitter, Input, Output } from '@angular/core';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { ThemePalette } from '@angular/material/core';
import { FileGalleryItem } from './types';

@Component({
  selector: 'cl-ui-file-gallery',
  templateUrl: './file-gallery.component.html',
  styleUrls: ['./file-gallery.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiFileGallery'
    ),
  ],
})
export class FileGalleryComponent {
  @Input() disabled = false;
  @Input() files: FileGalleryItem[] = [];
  @Input() hasNewFileControl = false;
  @Input() hasRemoveControls = false;
  @Input() thumbSize = '56px';
  @Input() hasSlideshow = true;
  @Input() buttonColor: ThemePalette | undefined;
  @Output() fileAddClicked = new EventEmitter();
  @Output() fileRemoveClicked = new EventEmitter<FileGalleryItem>();
  @Output() fileClicked = new EventEmitter<FileGalleryItem>();

  showFileSlider = false;
  sliderIndex = 0;

  isImage(file: FileGalleryItem) {
    return (
      file.type.indexOf('jpg') !== -1 ||
      file.type.indexOf('jpeg') !== -1 ||
      file.type.indexOf('png') !== -1 ||
      file.type.indexOf('gif') !== -1
    );
  }

  handleFileAddClick() {
    if (this.disabled) {
      return;
    }
    this.fileAddClicked.emit();
  }

  handleFileRemoveClick(fileRemoved: FileGalleryItem) {
    if (this.disabled) {
      return;
    }
    this.fileRemoveClicked.emit(fileRemoved);
  }

  handleFileViewClick(file: FileGalleryItem) {
    this.handleFileClicked(file);
  }

  handleFileClicked(fileClicked: FileGalleryItem) {
    if (this.disabled) {
      return;
    }
    this.fileClicked.emit(fileClicked);
    if (!this.hasSlideshow) {
      return;
    }
    this.sliderIndex = this.files.findIndex((file) => file === fileClicked);
    this.showFileSlider = true;
  }

  handleSliderClosed() {
    this.showFileSlider = false;
  }

  fileTrackBy(index: number, item: FileGalleryItem) {
    return index;
  }
}
