import { createSelector } from '@ngrx/store';
import { featureSelector } from '../selectors';

// @todo casos de testes para os seletores de planos
export const companyFeatures = createSelector(
  featureSelector,
  (state) => state.companyFeatures
);

export const enableChecklistItemQRCode = createSelector(
  companyFeatures,
  (features) => {
    return features.checklist_campos_adicionais_item === true;
  }
);

/** bloco Relatório PDF */
export const enableConfigurarRelatorioPDF = createSelector(
  companyFeatures,
  (features) => features.checklist_personaliza_pdf === true
);
export const enableConfigurarExibirCapaPDF = createSelector(
  companyFeatures,
  (features) => features.checklist_capa_pdf === true
);
export const enableConfigurarExibirQuadroDeNotas = createSelector(
  companyFeatures,
  (features) => features.checklist_quadro_notas === true
);
export const enableConfigurarCorCategorias = createSelector(
  companyFeatures,
  (features) => features.checklist_cor_area_pdf === true
);
export const enableConfigurarExibirDataEHoraRespostas = createSelector(
  companyFeatures,
  (features) => features.checklist_exibir_data_hora_respostas === true
);
export const enableConfigurarExibirComentariosNoPDF = createSelector(
  companyFeatures,
  (features) => features.checklist_comentario_resposta_pdf === true
);
export const enableConfigurarExbirImagensNoPDF = createSelector(
  companyFeatures,
  (features) => features.checklist_fotos_resposta_pdf === true
);
export const enableConfigurarExibirPlanoAcaoNoPDF = createSelector(
  companyFeatures,
  (features) => features.checklist_pa_resposta_pdf === true
);
export const enableConfigurarExibirHistoricoNoPDF = createSelector(
  companyFeatures,
  (features) => features.checklist_historico_pdf === true
);
export const enableClassificacaoDoChecklist = createSelector(
  companyFeatures,
  (features) => features.checklist_classificacao === true
);
export const enableEscalaAvaliativaTextoPersonalizado = createSelector(
  companyFeatures,
  (features) => features.checklist_modo_exibicao_respostas_pdf === true
);
export const enableEscalaAvaliativaValorPersonalizado = createSelector(
  companyFeatures,
  (features) => features.checklist_valor_personalizado_avaliativas === true
);
export const enableEscalaAvaliativaExibirNoPDF = createSelector(
  companyFeatures,
  (features) => features.checklist_configura_respostas_pdf === true
);
export const enableEscalaAvaliativaExibirDicaDoItem = createSelector(
  companyFeatures,
  (features) => features.checklist_dica_item_pdf === true
);
export const enableEscalaAvaliativaPersonalizar = createSelector(
  enableEscalaAvaliativaExibirDicaDoItem,
  enableEscalaAvaliativaExibirNoPDF,
  enableEscalaAvaliativaValorPersonalizado,
  enableEscalaAvaliativaTextoPersonalizado,
  (dicaDoItem, exibirNoPDF, valorPersonalizado, textPersonalizado) => {
    // Se nenhuma das features está disponível, então o bloco deve ser ocultado.
    return !(
      !dicaDoItem &&
      !exibirNoPDF &&
      !valorPersonalizado &&
      !textPersonalizado
    );
  }
);
export const enableConfigEtiquetaSequencial = createSelector(
  companyFeatures,
  (features) => features.checklist_etiqueta_sequencial === true
);
/** bloco Relatório PDF end */

export const enableConfigurarPlanoAcaoGeral = createSelector(
  companyFeatures,
  (features) => features.checklist_pa_geral === true
);

export const enableConfigurarExibirCarroselImagensAprovacao = createSelector(
  companyFeatures,
  (features) => features.checklist_carrousel_aprovacao === true
);

export const enableConfigurarBloquearAlteracoesNaAprovacao = createSelector(
  companyFeatures,
  (features) => features.checklist_bloqueia_alteracao_aprovacao === true
);

export const enableConfigurarComentarioObrigatorioNaAprovacao = createSelector(
  companyFeatures,
  (features) => {
    return features.checklist_comentario_obrigatorio_aprovacao === true;
  }
);

export const enableConfigurarPrazoParaAprovacao = createSelector(
  companyFeatures,
  (features) => features.checklist_prazo_aprovacao === true
);

export const enableConfigurarQuantidadeDeAprovacoes = createSelector(
  companyFeatures,
  (features) => features.checklist_quantidade_aprovacao === true
);

export const enableConfigurarFluxoDeAprovacao = createSelector(
  companyFeatures,
  (features) => features.checklist_fluxo_aprovacao === true
);

export const enableConfiguracaoAvancadaChecklist = createSelector(
  companyFeatures,
  (features) => features.checklist_configuracao_avancada === true
);

export const enableConfigurarItensDependentes = createSelector(
  companyFeatures,
  (features) => features.checklist_dependencia === true
);

export const enableConfigurarQuantidadeDeAssinaturas = createSelector(
  companyFeatures,
  (features) => features.checklist_quantidade_assinaturas === true
);

export const enableItemEscalaGPS = createSelector(
  companyFeatures,
  (features) => features.checklist_escala_gps === true
);

export const enableItemEscalaCodigoDeBarras = createSelector(
  companyFeatures,
  (features) => features.checklist_escala_codigo_barra === true
);

export const enableConfigurarExibicaoItensOcultos = createSelector(
  companyFeatures,
  (features) => features.checklist_visualiza_itens_ocultos === true
);

export const enableConfigurarDicaDoItem = createSelector(
  companyFeatures,
  (features) => features.checklist_dica_item === true
);

export const enableConfigurarAssinaturaNoItem = createSelector(
  companyFeatures,
  (feature) => feature.checklist_assinatura_item === true
);

export const enableConfigurarPlanoAcaoDoItem = createSelector(
  companyFeatures,
  (features) => features.checklist_pa_item === true
);

export const enableConfigurarPlanoAcaoDaCategoria = createSelector(
  companyFeatures,
  (features) => features.checklist_pa_area === true
);

export const enableAnexosGeraisDoChecklist = createSelector(
  companyFeatures,
  (features) => features.checklist_arquivo_dica === true
);

export const enableSubCategorias = createSelector(
  companyFeatures,
  (features) => features.checklist_subarea === true
);

/**
 * bloco Penalidades
 */
export const enableConfigurarPenalidadeReincidencia = createSelector(
  companyFeatures,
  (features) => features.checklist_penalidade_reincidencia === true
);
export const enableConfigurarPenalidadeDeItem = createSelector(
  companyFeatures,
  (features) => features.checklist_penalidade_item === true
);
export const enableChecklistPenalidadeAgendamentoAtrasado = createSelector(
  companyFeatures,
  (features) => {
    return features.checklist_penalidade_agendamento_atrasado === true;
  }
);

export const enableConfigurarPenalidades = createSelector(
  enableConfigurarPenalidadeReincidencia,
  enableConfigurarPenalidadeDeItem,
  enableChecklistPenalidadeAgendamentoAtrasado,
  (
    enabledPenalidadeReincidencias,
    enabledPenalidadeItem,
    enabledPenalidadeAgendamento
  ) => {
    return (
      enabledPenalidadeReincidencias ||
      enabledPenalidadeItem ||
      enabledPenalidadeAgendamento
    );
  }
);
/** end bloco Penalidades */

/**
 * features do bloco "Pós-conclusão"
 */
export const enableConfigurarComentarioChecklistConcluido = createSelector(
  companyFeatures,
  (features) => features.checklist_avaliacao_concluida_comentario === true
);
export const enableConfigurarAgruparPlanosDeAcaoReincidentes = createSelector(
  companyFeatures,
  (features) => features.checklist_agrupar_pa === true
);
export const enableConfigurarReaberturaChecklist = createSelector(
  companyFeatures,
  (features) => features.checklist_reabrir === true
);
export const enableConfigurarClonarChecklist = createSelector(
  featureSelector,
  ({ companyFeatures, companyConfigs }) => {
    const configModule = companyConfigs.find((c) => {
      return c.config === 'clonar_checklists_concluidos';
    });
    const clonarChecklistEnabled = configModule && configModule.value;

    return (
      companyFeatures.clonar_checklists_concluidos === true &&
      clonarChecklistEnabled
    );
  }
);
export const enableConfigurarPosConclusao = createSelector(
  enableConfigurarComentarioChecklistConcluido,
  enableConfigurarAgruparPlanosDeAcaoReincidentes,
  enableConfigurarReaberturaChecklist,
  (enabledComentario, enabledAgrupar, enabledReabertura) => {
    return enabledComentario || enabledAgrupar || enabledReabertura;
  }
);
/** end features do bloco "Pós-conclusão" */

export const enableConfigurarAprovacaoChecklist = createSelector(
  companyFeatures,
  (features) => features.checklist_aprovar === true
);

export const enableCategoriaDoTipoImagem = createSelector(
  companyFeatures,
  (features) => features.checklist_imagem === true
);

export const enableConfigurarAprovacaoDeAgendamento = createSelector(
  companyFeatures,
  (features) => features.checklist_aprovacao_agendamentos === true
);
export const enableConfigurarBloqueioAgendamentoDuplicado = createSelector(
  companyFeatures,
  (features) => features.checklist_bloqueio_agendamento_duplicado === true
);

export const enableConfigurarAplicarSomenteAgendado = createSelector(
  companyFeatures,
  (features) => features.checklist_somente_agendado === true
);

export const enableConfigurarAgendamentos = createSelector(
  enableConfigurarAplicarSomenteAgendado,
  enableConfigurarAprovacaoDeAgendamento,
  (enabledSomenteAgendado, enabledAprovacao) => {
    return enabledSomenteAgendado || enabledAprovacao;
  }
);
/**
 * Conjunto de features do bloco "Preenchimento"
 */
export const enableConfigurarUsarGaleriaDoDispositivo = createSelector(
  companyFeatures,
  (features) => features.checklist_galeria_imagens_app === true
);

export const enableConfigurarExibirResultadoParcial = createSelector(
  companyFeatures,
  (features) => features.checklist_resultado_parcial === true
);

export const enableConfigurarTelaDeConclusao = createSelector(
  companyFeatures,
  (features) => features.checklist_configurar_tela_conclusao === true
);

export const enableConfigurarContinuarNaWeb = createSelector(
  companyFeatures,
  (features) => features.checklist_continuar_web === true
);

export const enableConfigurarExibirNotaDaCategoria = createSelector(
  companyFeatures,
  (features) => features.checklist_exibir_nota_area_aplicacao === true
);

export const enableConfigurarPreenchimento = createSelector(
  enableConfigurarUsarGaleriaDoDispositivo,
  enableConfigurarExibirResultadoParcial,
  enableConfigurarTelaDeConclusao,
  enableConfigurarContinuarNaWeb,
  enableConfigurarExibirNotaDaCategoria,
  (
    enabledGaleria,
    enabledParcial,
    enabledTelaConclusao,
    enableContinuarWeb,
    enableNotaCategoria
  ) => {
    return (
      enabledGaleria ||
      enabledParcial ||
      enabledTelaConclusao ||
      enableContinuarWeb ||
      enableNotaCategoria
    );
  }
);

/**
 * Features do bloco "Notificações"
 */
export const enableConfigurarUnidadeRecebeEmailDoChecklist = createSelector(
  companyFeatures,
  (features) => features.checklist_unidade_recebe_email === true
);

export const enableConfigurarAplicadorRecebeEmail = createSelector(
  companyFeatures,
  (features) => features.checklist_aplicador_recebe_email === true
);

export const enableConfigurarEnvioEmailChecklistNaoConcluido = createSelector(
  companyFeatures,
  (features) => features.checklist_email_nao_concluido === true
);

export const enableConfigurarUnidadeRecebeCopiaEmailAgendamento =
  createSelector(
    companyFeatures,
    (features) => features.checklist_unidade_recebe_email_agendamento === true
  );

export const enableConfigurarNotificacoes = createSelector(
  enableConfigurarUnidadeRecebeEmailDoChecklist,
  enableConfigurarAplicadorRecebeEmail,
  enableConfigurarEnvioEmailChecklistNaoConcluido,
  enableConfigurarUnidadeRecebeCopiaEmailAgendamento,
  (
    enabledUnidadeEmail,
    enabledAplicadorEmail,
    enabledNaoConcluido,
    enableUnidadeRecebeCopia
  ) => {
    return (
      enabledUnidadeEmail ||
      enabledAplicadorEmail ||
      enabledNaoConcluido ||
      enableUnidadeRecebeCopia
    );
  }
);
/** features do bloco Notificações end */

export const enableConfigurarMensagemPesquisaSatisfacao = createSelector(
  companyFeatures,
  (features) => features.outras_configuracoes_mensagem_pesquisa === true
);

export const enableItemEscalaTemperatura = createSelector(
  companyFeatures,
  (features) => features.checklist_escala_temperatura === true
);

export const enableConfigurarExibicaoCamposPlanoDeAcao = createSelector(
  companyFeatures,
  (features) => features.plano_acao_exibir_campos === true
);

export const enableConfigurarObrigatoriedadeCamposPlanoDeAcao = createSelector(
  companyFeatures,
  (features) => features.plano_acao_campos_obrigatorios === true
);

export const enableConfigurarPrePreenchimentoCamposPlanoDeAcao = createSelector(
  companyFeatures,
  (features) => features.plano_acao_pre_preencher === true
);

export const enableConfigurarBloqueioCamposPlanoDeAcao = createSelector(
  companyFeatures,
  (features) => features.plano_acao_bloquear_campos === true
);

export const enableConfigurarFinalizarPAConcluirAvaliacao = createSelector(
  companyFeatures,
  (features) => features.finalizar_plano_acao_apos_concluir_avaliacao === true
);

export const enableConfigurarPlanoDeAcaoSolucaoObrigatoria = createSelector(
  companyFeatures,
  (features) => features.plano_acao_solucao_obrigatoria === true
);

export const enableConfigurarPlanoDeAcaoArquivoObrigatorio = createSelector(
  companyFeatures,
  (features) => features.plano_acao_arquivo_obrigatorio_solucao === true
);

export const enableConfigurarPlanoDeAcaoSolucaoAprovaAutomatico =
  createSelector(
    companyFeatures,
    (features) => features.plano_acao_solucao_aprova_automatico === true
  );

export const enableConfigurarPlanoDeAcaoResponsavelPreencheAposConclusao =
  createSelector(
    companyFeatures,
    (features) => features.plano_acao_responsavel_preenche === true
  );

export const limitQuantidadeDeChecklists = createSelector(
  companyFeatures,
  (features) => features.checklist_quantidade_limite
);

export const enableAcessarCentralDeAjuda = createSelector(
  companyFeatures,
  (features) => features.home_central_ajuda === true
);

export const enableItemEscalaPersonalizada = createSelector(
  companyFeatures,
  (features) => features.checklist_escala_personalizada === true
);

export const enableDepartamentos = createSelector(
  companyFeatures,
  (features) => features.departamento === true
);

export const enableTipoUnidade = createSelector(
  companyFeatures,
  (features) => features.tipo_unidade === true
);

export const enableReconhecimentoPadraoImagem = createSelector(
  companyFeatures,
  (features) => features.checklist_padrao_imagem === true
);

export const enableWorkflows = createSelector(
  companyFeatures,
  (features) => features.workflows === true
);

export const enableWorkflowBloqueioPA = createSelector(
  companyFeatures,
  (features) => features.workflow_bloqueio_pa === true
);

export const enableRegioes = createSelector(
  companyFeatures,
  (features) => features.regiao === true
);

export const enableOperacaoMatematica = createSelector(
  companyFeatures,
  (features) => features.operacao_matematica === true
);

export const enableRelatorioArquivos = createSelector(
  companyFeatures,
  (features) => features.relatorio_arquivos === true
);

export const enableRelatorioIndicadores = createSelector(
  companyFeatures,
  (features) => features.relatorio_indicadores === true
);

export const enableRelatorioAuditoria = createSelector(
  companyFeatures,
  (features) => features.relatorio_auditoria === true
);

export const enablePreenchimentoPARequerAnalise = createSelector(
  companyFeatures,
  (features) => features.plano_acao_aprovacao === true
);

export const enableContestacaoItem = createSelector(
  companyFeatures,
  ({ checklist_contestacao }) => checklist_contestacao === true
);

export const enableIntegracaoSienge = createSelector(
  companyFeatures,
  (features) => features.integracao_sienge === true
);

export const enableAnalyticsBI = createSelector(
  companyFeatures,
  (features) => features.analytics_bi === true
);

export const enableConfigurarJanelaNotifcacoesChecklist = createSelector(
  featureSelector,
  ({ companyFeatures, companyConfigs }) => {
    const configModule = companyConfigs.find((c) => {
      return c.config === 'janela_notificacoes';
    });
    return configModule && configModule.value;
  }
);

export const enableAplicarChecklistSomenteApp = createSelector(
  featureSelector,
  ({ companyConfigs }) => {
    const configModule = companyConfigs.find((c) => {
      return c.config === 'aplicar_checklist_somente_app';
    });
    return configModule && configModule.value;
  }
);
