import { Router } from '@angular/router';
import { Action, FunctionWithParametersType } from '@ngrx/store';
import { ActionCreator } from '@ngrx/store/src/models';
import { EMPTY, Observable, of } from 'rxjs';

/**
 * @prop messageTokens redirect é feito quando o message do erro está nesse array
 * @prop errorCodes redirect é feito quando o código de erro está nesse array, independendo do message
 * @prop fallbackAction action a ser disparada caso o redirect não aconteça
 */
export interface ErrorEffectMapperOptions {
  messageTokens: string[];
  errorCodes: number[];
  fallbackAction: ActionCreator<
    string,
    FunctionWithParametersType<[RequestError], Action>
  > | null;
}

const defaultOptions: ErrorEffectMapperOptions = {
  messageTokens: [],
  errorCodes: [404],
  fallbackAction: null,
};

interface RequestError {
  status: number;
  message?: string;
}

function errorEffectMapper(
  messageScope: string,
  router: Router,
  errorCode: number,
  messageToken?: string,
  mapperOptions?: Partial<ErrorEffectMapperOptions>
): Observable<Action> {
  const options: ErrorEffectMapperOptions = {
    ...defaultOptions,
    ...mapperOptions,
  };

  if (
    options.errorCodes.includes(errorCode) ||
    (messageToken && options.messageTokens.includes(messageToken))
  ) {
    const routeCommands = ['/', 'common-error', errorCode];
    if (messageToken) {
      routeCommands.push(`${messageScope}.${messageToken}`);
    }

    router.navigate(routeCommands, { replaceUrl: true });
  } else if (options.fallbackAction) {
    return of(
      options.fallbackAction({ status: errorCode, message: messageToken })
    );
  }

  return EMPTY;
}

/**
 * Cria mapper para usar em effects, faz redirect para a página de erro de acordo com options
 * @param messageScope prefixo dos tokens de erro
 * @param router para fazer o redirect automaticamente
 * @param options definições para o redirect
 */
export function createErrorEffectMapper(
  messageScope: string,
  router: Router,
  options: Partial<ErrorEffectMapperOptions>
) {
  return (error: RequestError) =>
    errorEffectMapper(
      messageScope,
      router,
      error.status,
      error.message,
      options
    );
}
