import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageInterceptorService implements HttpInterceptor {
  private appConfig: Configuration | undefined;

  constructor(
    private translocoService: TranslocoService,
    configService: ConfigAssetLoaderService
  ) {
    configService
      .loadConfigurations()
      .subscribe((config) => (this.appConfig = config));
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      this.appConfig &&
      (req.url.startsWith(`${this.appConfig.spaApiUrl}/trial`) ||
        req.url.startsWith(`${this.appConfig.spaApiUrl}/geo`))
    ) {
      const update = {
        headers: req.headers
          .delete('Authorization')
          .set('CHK-Language', this.translocoService.getActiveLang()),
      };

      const reqClone = req.clone(update);
      return next.handle(reqClone);
    }

    return next.handle(req);
  }
}
