import { Directive, HostListener, Input } from '@angular/core';
import { OnboardingService } from '../onboarding/onboarding.service';

@Directive({
  selector: '[clHelperFlow]',
})
export class HelperFlowDirective {
  constructor(private onboardingService: OnboardingService) {}

  @Input() clHelperFlow: string | undefined;

  @HostListener('click')
  onClick() {
    if (!this.clHelperFlow) {
      return;
    }
    this.onboardingService.startFlow(this.clHelperFlow);
  }
}
