<cl-ui-file-gallery
  [disabled]="disabled"
  [hasNewFileControl]="(showButtonAddMore$ | async) ?? true"
  [hasRemoveControls]="true"
  [buttonColor]="buttonColor"
  (fileAddClicked)="handleFileAddClicked()"
  (fileRemoveClicked)="handleFileRemoveClicked($event)"
  [files]="files"
></cl-ui-file-gallery>
<input
  #internalFile
  (change)="handleFileSelection()"
  type="file"
  [name]="name"
  multiple
  style="display: none"
/>

<cl-ui-confirmation-dialog #removeConfirmation></cl-ui-confirmation-dialog>
