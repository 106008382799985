export enum AchievementIcon {
  GroupIntroduction = 'introduction',
  Quickstart = 'complete_quickstart',
  ApplyChecklist = 'apply_checklist',
  SetupChecklistBySettingsScreen = 'setup_checklist_by_settings_screen',
  SetupUnitBySettingsScreen = 'setup_unit_by_settings_screen',
  AchievementCompleted = 'achievement_completed',
  ViewReportOfAppliedChecklists = 'view_a_report_of_applied_checklists',
  AccessToHelpCenter = 'enter_the_help_center',
  GoldTrophy = 'gold_trophy',
  SilverTrophy = 'silver_trophy',
  BronzeTrophy = 'bronze_trophy',
  Diamond = 'diamond',
  AchievementBoardCompleted = 'achievement_board_completed',
  GroupLearningMoreFeatures = 'learning_more_features',
  CreateAUserType = 'create_a_user_type',
  CreateAUser = 'create_a_user',
  CreateAnActionPlan = 'create_an_action_plan',
  ViewActionPlans = 'view_action_plans',
  ViewTheActionPlansReport = 'view_the_action_plans_report',
  AddEvidenceToTheActionPlan = 'add_evidence_to_the_action_plan',
  CompleteAnActionPlan = 'complete_an_action_plan',
  ViewTheRecidivismReport = 'view_the_recidivism_report',
  ApplyTenChecklists = 'apply_ten_checklists',
  ApplyChecklistOnMobile = 'apply_checklist_on_mobile',
  GroupBecomingAnExpert = 'becoming_an_expert',
  ViewIndicatorsReport = 'view_indicators_report',
  ViewUnitAuditReport = 'view_unit_audit_report',
  ViewUserAuditReport = 'view_user_audit_report',
  ConfigureADashboard = 'configure_a_dashboard',
  ManageYourNotifications = 'manage_your_notifications',
  AccessOtherSettings = 'access_other_settings',
  ImportAChecklist = 'import_a_checklist',
  ExportAnAppliedChecklist = 'export_an_applied_checklist',
}
