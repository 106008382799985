import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cl-form-borderless-textarea',
  templateUrl: './borderless-textarea.component.html',
  styleUrls: ['./borderless-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BorderlessTextareaComponent),
      multi: true,
    },
  ],
})
export class BorderlessTextareaComponent implements ControlValueAccessor {
  @Input() placeholder: string | undefined;
  @Input() value = '';
  @Input() disabled = false;
  @Input() required = false;
  @Input() readonly = false;
  @Input() maxlength = '';

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Input() @HostBinding('class.borderless-textarea--invalid') invalid = false;
  @Output() blurEvent = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange: ((delta: any) => void) | undefined;
  private touched = false;

  get placeholderText() {
    return this.placeholder + (this.required ? '*' : '');
  }

  handleChange(value: any) {
    this.value = value;
    if (!this.onChange) {
      return;
    }
    this.onChange(value);
  }

  inputBlur($event: FocusEvent) {
    this.blurEvent.emit($event);
  }

  onTouched() {
    this.touched = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
