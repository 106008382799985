import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageMapperShowcaseItem } from './types';

@Component({
  selector: 'cl-image-mapper-showcase',
  templateUrl: './image-mapper-showcase.component.html',
  styleUrls: ['./image-mapper-showcase.component.scss'],
})
export class ImageMapperShowcaseComponent {
  @Input() image = '';
  @Input() items: ImageMapperShowcaseItem[] = [];
  @Output() itemClicked = new EventEmitter<ImageMapperShowcaseItem>();
  IMAGE_HEIGHT = 450;
  IMAGE_WIDTH = 600;

  handleItemClick(item: ImageMapperShowcaseItem) {
    this.itemClicked.emit(item);
  }
}
