<div
  *transloco="let t; read: 'clUiMenuFooter'"
  class="menu-footer"
  [class.menu-footer--hide-icons]="hideIcons"
>
  <!-- ODT-10134 -->
  <!--  <a-->
  <!--    class="menu-footer__button"-->
  <!--    *ngIf="showMessages"-->
  <!--    href="{{ menuFooterInfo.messagesUrl }}"-->
  <!--    (click)="messagesButtonClicked($event)"-->
  <!--    [title]="t('messagesLabel')"-->
  <!--  >-->
  <!--    <mat-icon aria-hidden="false" [attr.aria-label]="t('messagesLabel')">mail_outline</mat-icon>-->
  <!--  </a>-->
  <a
    class="menu-footer__button"
    *ngIf="userHasAccessToAchievements"
    href="/achievements"
    (click)="handleMyAchievementsClick('/achievements')"
    [title]="t('achievementsLabel')"
  >
    <mat-icon class="material-icons-outlined" aria-hidden="false" [attr.aria-label]="t('achievementsLabel')"
    >emoji_events</mat-icon
    >
  </a>
  <a
    class="menu-footer__button"
    *ngIf="showImportExport"
    href="{{ menuFooterInfo.importExportUrl }}"
    (click)="importExportButtonClicked($event)"
    [title]="t('importExportLabel')"
  >
    <mat-icon aria-hidden="false" [attr.aria-label]="t('importExportLabel')"
      >import_export</mat-icon
    >
  </a>
  <a
    class="menu-footer__button"
    *ngIf="showHelperCenter"
    href="{{ menuFooterInfo.helpCenterUrl }}"
    (click)="helpCenterButtonClicked($event)"
    [title]="t('helpCenterLabel')"
  >
    <mat-icon aria-hidden="false" [attr.aria-label]="t('helpCenterLabel')"
      >help_outline</mat-icon
    >
  </a>
</div>
