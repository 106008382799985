<div class="achievements-icons">

  <!-- icon: achievement_completed-->
  <div
    class="icon-check"
    *ngIf="icon === icons.AchievementCompleted"
    [class.icon--active]="active"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.62 12.96L17.18 5.40002L20 8.22002L9.62 18.6L4 12.98L6.82 10.16L9.62 12.96ZM18.1133 8.22002L17.18 7.28669L9.62 14.8467L6.82 12.0467L5.88667 12.98L9.62 16.7134L18.1133 8.22002Z" fill="white"/>
    </svg>
  </div>

  <!-- icon: complete_quickstart-->
  <div
    class="icon"
    *ngIf="icon === icons.Quickstart"
    [class.icon--active]="active"
  >
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.6157" cy="20" r="20" />
      <circle cx="20.6157" cy="20" r="17.9221" fill="white"/>
      <path d="M13.949 8.33331V31.6666L32.2823 20L13.949 8.33331Z" />
    </svg>
  </div>

  <!-- icon: apply_checklist-->
  <div
    class="icon"
    *ngIf="icon === icons.ApplyChecklist"
    [class.icon--active]="active"
  >
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.6157" cy="20.6765" r="20" />
      <circle cx="20.6157" cy="20.6765" r="17.9221" fill="white"/>
      <g clip-path="url(#clip0_2592_8995)">
        <path d="M17.342 10.1953C17.7102 10.5823 18.5393 11.0601 20.3883 11.0601C23.2554 11.0601 23.7181 9.61881 23.7837 9.31893H21.7419C21.7419 8.95976 21.5997 8.61529 21.3465 8.36132C21.0934 8.10734 20.7502 7.96466 20.3922 7.96466C20.0342 7.96466 19.691 8.10734 19.4379 8.36132C19.1847 8.61529 19.0425 8.95976 19.0425 9.31893H16.9988C16.9988 9.33248 16.9988 9.34602 16.9988 9.3615C17.0011 9.67358 17.1242 9.97256 17.342 10.1953Z" />
        <path d="M28.1604 9.3112H24.7554C24.7563 9.32731 24.7563 9.34346 24.7554 9.35957C24.7438 9.46984 24.4372 12.0275 20.3902 12.0275C18.6029 12.0275 17.3303 11.6231 16.6111 10.8241C16.2301 10.4142 16.0227 9.8717 16.0327 9.3112H13.0827C12.6737 9.3112 12.2813 9.47426 11.9921 9.76452C11.7028 10.0548 11.5403 10.4485 11.5403 10.8589V30.8074C11.5403 31.2178 11.7028 31.6115 11.9921 31.9018C12.2813 32.192 12.6737 32.3551 13.0827 32.3551H28.1719C28.581 32.3551 28.9733 32.192 29.2626 31.9018C29.5519 31.6115 29.7144 31.2178 29.7144 30.8074V10.8628C29.7149 10.6582 29.675 10.4556 29.597 10.2666C29.519 10.0776 29.4044 9.90604 29.2599 9.76176C29.1154 9.61747 28.9438 9.50337 28.7551 9.42603C28.5663 9.34869 28.3642 9.30967 28.1604 9.3112Z" />
        <path d="M18.2182 24.7526L15.1362 21.6601L14.0867 22.7058L18.2182 26.8514L27.0874 17.9519L26.0453 16.9062L18.2182 24.7526Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2592_8995">
          <rect width="18.1818" height="24.4156" fill="white" transform="translate(11.5249 7.94919)"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!-- icon: setup_checklist_by_settings_screen-->
  <div
    class="icon"
    *ngIf="icon === icons.SetupChecklistBySettingsScreen"
    [class.icon--active]="active"
  >
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.6157" cy="20" r="20" />
      <circle cx="20.6157" cy="20" r="17.9221" fill="white"/>
      <path d="M22.8114 17.7861H29.7514V21.8405H22.8114V29.7484H18.5014V21.8405H11.5249V17.7861H18.5014V10.3896H22.8114V17.7861Z" />
    </svg>
  </div>

  <!-- icon: setup_unit_by_settings_screen-->
  <div
    class="icon"
    *ngIf="icon === icons.SetupUnitBySettingsScreen"
    [class.icon--active]="active"
  >
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.6157" cy="20" r="20" />
      <circle cx="20.6157" cy="20" r="17.9221" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9145 8.87444C17.8099 8.87444 14.4902 12.1941 14.4902 16.2987C14.4902 21.8669 21.9145 30.0866 21.9145 30.0866C21.9145 30.0866 29.3387 21.8669 29.3387 16.2987C29.3387 12.1941 26.019 8.87444 21.9145 8.87444ZM16.6114 16.2987C16.6114 13.3714 18.9872 10.9956 21.9145 10.9956C24.8417 10.9956 27.2175 13.3714 27.2175 16.2987C27.2175 19.3532 24.163 23.9244 21.9145 26.7775C19.7084 23.9456 16.6114 19.3214 16.6114 16.2987ZM19.263 16.2987C19.263 14.8343 20.4501 13.6472 21.9145 13.6472C22.8618 13.6472 23.7371 14.1525 24.2108 14.9729C24.6844 15.7933 24.6844 16.8041 24.2108 17.6244C23.7371 18.4448 22.8618 18.9502 21.9145 18.9502C20.4501 18.9502 19.263 17.7631 19.263 16.2987Z" />
      <path d="M13.6908 24.3751H16.46V26.0615H13.6908V29.3506H11.971V26.0615H9.18726V24.3751H11.971V21.2987H13.6908V24.3751Z" />
    </svg>
  </div>

  <!-- icon: view_report_of_applied_checklists-->
  <div
    class="icon"
    *ngIf="icon === icons.ViewReportOfAppliedChecklists"
    [class.icon--active]="active"
  >
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.5" cy="20.5" r="20.5"/>
      <circle cx="20.5" cy="20.5" r="18.3701" fill="white"/>
      <rect x="6.38965" y="15.4415" width="3.99351" height="10.9156"/>
      <rect x="12.5129" y="18.9026" width="3.99351" height="7.45455"/>
      <rect x="18.6365" y="16.5065" width="3.99351" height="9.85065"/>
      <rect x="24.7598" y="20.7662" width="3.99351" height="5.59091"/>
      <rect x="30.8833" y="13.0454" width="3.99351" height="13.3117"/>
      <rect x="6.38965" y="27.1558" width="28.487" height="0.798701"/>
    </svg>
  </div>

  <!-- icon: enter_the_help_center-->
  <div
    class="icon"
    *ngIf="icon === icons.AccessToHelpCenter"
    [class.icon--active]="active"
  >
    <svg width="40" height="41" viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20.5117" r="20"/>
      <circle cx="20" cy="20.5117" r="17.9221" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.09521 20.252C8.09521 13.6805 13.4285 8.34721 20 8.34721C26.5714 8.34721 31.9047 13.6805 31.9047 20.252C31.9047 26.8234 26.5714 32.1567 20 32.1567C13.4285 32.1567 8.09521 26.8234 8.09521 20.252ZM21.1905 25.0139V27.3948H18.8095V25.0139H21.1905ZM20 29.7758C14.75 29.7758 10.4762 25.502 10.4762 20.252C10.4762 15.002 14.75 10.7282 20 10.7282C25.25 10.7282 29.5238 15.002 29.5238 20.252C29.5238 25.502 25.25 29.7758 20 29.7758ZM15.2381 17.871C15.2381 15.2401 17.369 13.1091 20 13.1091C22.6309 13.1091 24.7619 15.2401 24.7619 17.871C24.7619 19.3983 23.8214 20.2202 22.9056 21.0205C22.0369 21.7797 21.1905 22.5194 21.1905 23.8234H18.8095C18.8095 21.6552 19.9311 20.7955 20.9172 20.0397C21.6907 19.4467 22.3809 18.9177 22.3809 17.871C22.3809 16.5615 21.3095 15.4901 20 15.4901C18.6905 15.4901 17.619 16.5615 17.619 17.871H15.2381Z"/>
    </svg>
  </div>

  <!-- icon: gold_trophy-->
  <div
    class="icon-award"
    *ngIf="icon === icons.GoldTrophy"
    [class.icon-award-active]="active"
    [class.icon-award-menu]="menuTrophy"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3501_11092)">
        <path d="M5.69222 5.5555H12.3589C13.5856 5.5555 14.4444 5.43995 14.4444 6.66661V4.44439C14.4444 3.21772 13.5856 2.22217 12.3589 2.22217H3.47C1.24778 2.22217 -0.511107 4.58328 0.13667 7.77772C0.13667 7.77772 2.12667 18.1944 2.26667 18.8422C2.63667 20.5555 4.62667 22.2222 6.75667 22.2222H14.4878C15.7156 22.2222 14.4444 20.1155 14.4444 18.8888V16.6666C14.4444 17.8933 13.5856 18.8888 12.3589 18.8888H7.91445C6.68778 18.8888 5.87778 17.7311 5.69222 16.6666C5.50667 15.6022 3.93223 8.51884 3.93223 8.51884C3.56223 6.38884 4.46445 5.5555 5.69222 5.5555ZM34.3722 5.5555H27.7056C26.4789 5.5555 24.4444 5.43995 24.4444 6.66661V4.44439C24.4444 3.21772 26.4789 2.22217 27.7056 2.22217H36.5944C38.8167 2.22217 40.5767 4.58328 39.9278 7.77772C39.9278 7.77772 38.1133 18.2433 37.96 18.8888C37.5667 20.5555 35.6911 22.2222 33.5156 22.2222H25.5756C24.3489 22.2222 24.4444 20.1155 24.4444 18.8888V16.6666C24.4444 17.8933 26.4789 18.8888 27.7056 18.8888H32.15C33.3767 18.8888 34.1878 17.7311 34.3722 16.6666C34.5567 15.6022 36.1322 8.51884 36.1322 8.51884C36.5011 6.38884 35.6 5.5555 34.3722 5.5555V5.5555ZM23.1467 24.4444C23.1467 16.7133 20.1367 24.4444 20.1367 24.4444C20.1367 24.4444 16.8033 16.7133 16.8033 24.4444C16.8033 32.1755 13.1467 35.5555 13.1467 35.5555H26.8044C26.8033 35.5555 23.1467 32.1755 23.1467 24.4444Z" fill="#FFAC33"/>
        <path d="M32.3589 7.30784C32.3589 14.8356 24.8367 27.5212 20.1367 27.5212C15.4356 27.5212 7.91446 14.8356 7.91446 7.30784C7.91446 1.53562 9.02557 0.286728 11.2478 0.286728C12.7745 0.286728 17.9422 0.256728 20.1367 0.256728L27.9145 0.255616C31.1556 0.254505 32.3589 1.01562 32.3589 7.30784V7.30784Z" fill="#FFCC4D"/>
        <path d="M30 36.6667C30 37.8934 30.2522 38.8889 29.0256 38.8889H11.2478C10.02 38.8889 10 37.8934 10 36.6667V35.5556C10 34.3289 11.2933 33.3334 12.4511 33.3334H27.9144C29.0722 33.3334 30 34.3289 30 35.5556V36.6667V36.6667Z" fill="#C1694F"/>
        <path d="M32.2222 38.4722C32.2222 39.3166 32.4055 40 30.8311 40H9.44222C8.00666 40 7.77777 39.3166 7.77777 38.4722V38.1944C7.77777 37.35 8.59777 36.6666 9.44222 36.6666H30.8311C31.6744 36.6666 32.2222 37.35 32.2222 38.1944V38.4722V38.4722Z" fill="#C1694F"/>
      </g>
      <defs>
        <clipPath id="clip0_3501_11092">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!-- icon: silver_trophy-->
  <div
    class="icon-award"
    *ngIf="icon === icons.SilverTrophy"
    [class.icon-award-active]="active"
    [class.icon-award-menu]="menuTrophy"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3501_11097)">
        <path d="M5.69222 5.5555H12.3589C13.5856 5.5555 14.4444 5.43995 14.4444 6.66661V4.44439C14.4444 3.21772 13.5856 2.22217 12.3589 2.22217H3.47C1.24778 2.22217 -0.511107 4.58328 0.13667 7.77772C0.13667 7.77772 2.12667 18.1944 2.26667 18.8422C2.63667 20.5555 4.62667 22.2222 6.75667 22.2222H14.4878C15.7156 22.2222 14.4444 20.1155 14.4444 18.8888V16.6666C14.4444 17.8933 13.5856 18.8888 12.3589 18.8888H7.91445C6.68778 18.8888 5.87778 17.7311 5.69222 16.6666C5.50667 15.6022 3.93223 8.51884 3.93223 8.51884C3.56223 6.38884 4.46445 5.5555 5.69222 5.5555ZM34.3722 5.5555H27.7056C26.4789 5.5555 24.4444 5.43995 24.4444 6.66661V4.44439C24.4444 3.21772 26.4789 2.22217 27.7056 2.22217H36.5944C38.8167 2.22217 40.5767 4.58328 39.9278 7.77772C39.9278 7.77772 38.1133 18.2433 37.96 18.8888C37.5667 20.5555 35.6911 22.2222 33.5156 22.2222H25.5756C24.3489 22.2222 24.4444 20.1155 24.4444 18.8888V16.6666C24.4444 17.8933 26.4789 18.8888 27.7056 18.8888H32.15C33.3767 18.8888 34.1878 17.7311 34.3722 16.6666C34.5567 15.6022 36.1322 8.51884 36.1322 8.51884C36.5011 6.38884 35.6 5.5555 34.3722 5.5555V5.5555ZM23.1467 24.4444C23.1467 16.7133 20.1367 24.4444 20.1367 24.4444C20.1367 24.4444 16.8033 16.7133 16.8033 24.4444C16.8033 32.1755 13.1467 35.5555 13.1467 35.5555H26.8044C26.8033 35.5555 23.1467 32.1755 23.1467 24.4444Z" fill="#928484"/>
        <path d="M32.3589 7.30784C32.3589 14.8356 24.8367 27.5212 20.1367 27.5212C15.4355 27.5212 7.91443 14.8356 7.91443 7.30784C7.91443 1.53562 9.02554 0.286728 11.2478 0.286728C12.7744 0.286728 17.9422 0.256728 20.1367 0.256728L27.9144 0.255616C31.1555 0.254505 32.3589 1.01562 32.3589 7.30784V7.30784Z" fill="#D6D6E4"/>
        <path d="M30 36.6667C30 37.8934 30.2522 38.8889 29.0256 38.8889H11.2478C10.02 38.8889 10 37.8934 10 36.6667V35.5556C10 34.3289 11.2933 33.3334 12.4511 33.3334H27.9144C29.0722 33.3334 30 34.3289 30 35.5556V36.6667V36.6667Z" fill="#D6D6E4"/>
        <path d="M32.2222 38.4722C32.2222 39.3166 32.4055 40 30.8311 40H9.44222C8.00666 40 7.77777 39.3166 7.77777 38.4722V38.1944C7.77777 37.35 8.59777 36.6666 9.44222 36.6666H30.8311C31.6744 36.6666 32.2222 37.35 32.2222 38.1944V38.4722V38.4722Z" fill="#D6D6E4"/>
      </g>
      <defs>
        <clipPath id="clip0_3501_11097">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!-- icon: bronze_trophy-->
  <div
    class="icon-award"
    *ngIf="icon === icons.BronzeTrophy"
    [class.icon-award-active]="active"
    [class.icon-award-menu]="menuTrophy"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3501_11102)">
        <path d="M5.69222 5.5555H12.3589C13.5856 5.5555 14.4444 5.43995 14.4444 6.66661V4.44439C14.4444 3.21772 13.5856 2.22217 12.3589 2.22217H3.47C1.24778 2.22217 -0.511107 4.58328 0.13667 7.77772C0.13667 7.77772 2.12667 18.1944 2.26667 18.8422C2.63667 20.5555 4.62667 22.2222 6.75667 22.2222H14.4878C15.7156 22.2222 14.4444 20.1155 14.4444 18.8888V16.6666C14.4444 17.8933 13.5856 18.8888 12.3589 18.8888H7.91445C6.68778 18.8888 5.87778 17.7311 5.69222 16.6666C5.50667 15.6022 3.93223 8.51884 3.93223 8.51884C3.56223 6.38884 4.46445 5.5555 5.69222 5.5555ZM34.3722 5.5555H27.7056C26.4789 5.5555 24.4444 5.43995 24.4444 6.66661V4.44439C24.4444 3.21772 26.4789 2.22217 27.7056 2.22217H36.5944C38.8167 2.22217 40.5767 4.58328 39.9278 7.77772C39.9278 7.77772 38.1133 18.2433 37.96 18.8888C37.5667 20.5555 35.6911 22.2222 33.5156 22.2222H25.5756C24.3489 22.2222 24.4444 20.1155 24.4444 18.8888V16.6666C24.4444 17.8933 26.4789 18.8888 27.7056 18.8888H32.15C33.3767 18.8888 34.1878 17.7311 34.3722 16.6666C34.5567 15.6022 36.1322 8.51884 36.1322 8.51884C36.5011 6.38884 35.6 5.5555 34.3722 5.5555V5.5555ZM23.1467 24.4444C23.1467 16.7133 20.1367 24.4444 20.1367 24.4444C20.1367 24.4444 16.8033 16.7133 16.8033 24.4444C16.8033 32.1755 13.1467 35.5555 13.1467 35.5555H26.8044C26.8033 35.5555 23.1467 32.1755 23.1467 24.4444Z" fill="#CA633A"/>
        <path d="M32.3589 7.30784C32.3589 14.8356 24.8367 27.5212 20.1367 27.5212C15.4355 27.5212 7.91443 14.8356 7.91443 7.30784C7.91443 1.53562 9.02554 0.286728 11.2478 0.286728C12.7744 0.286728 17.9422 0.256728 20.1367 0.256728L27.9144 0.255616C31.1555 0.254505 32.3589 1.01562 32.3589 7.30784V7.30784Z" fill="#ECAD7D"/>
        <path d="M30 36.6667C30 37.8934 30.2522 38.8889 29.0256 38.8889H11.2478C10.02 38.8889 10 37.8934 10 36.6667V35.5556C10 34.3289 11.2933 33.3334 12.4511 33.3334H27.9144C29.0722 33.3334 30 34.3289 30 35.5556V36.6667V36.6667Z" fill="#ECAD7D"/>
        <path d="M32.2222 38.4722C32.2222 39.3166 32.4055 40 30.8311 40H9.44222C8.00666 40 7.77777 39.3166 7.77777 38.4722V38.1944C7.77777 37.35 8.59777 36.6666 9.44222 36.6666H30.8311C31.6744 36.6666 32.2222 37.35 32.2222 38.1944V38.4722V38.4722Z" fill="#ECAD7D"/>
      </g>
      <defs>
        <clipPath id="clip0_3501_11102">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!-- icon: diamond-->
  <div
    class="icon-award"
    *ngIf="icon === icons.Diamond"
    [class.icon-award-active]="active"
    [class.icon-award-menu]="menuTrophy"
  >
    <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3501_11107)">
        <path d="M1.26746 8.50269H14.2956L21.1893 36.5634L1.26746 8.50269Z" fill="#86F0FC"/>
        <path d="M28.0797 8.50269H14.2956L21.1893 36.5634L21.6203 36.4249L28.0797 8.50269Z" fill="#30DCFB"/>
        <path d="M41.8638 8.50269L22.7944 36.425H21.6203L28.0797 8.50269H41.8638Z" fill="#039CD4"/>
        <path d="M1.26746 8.50277L7.56278 1.34558L14.2956 8.50277H1.26746Z" fill="#CCFAFF"/>
        <path d="M14.2956 8.50277L21.1893 1.34558L28.0797 8.50277H14.2956Z" fill="#88EFFE"/>
        <path d="M41.8638 8.5027L35.0023 1.0686L28.0797 8.5027H41.8638Z" fill="#2FDCFE"/>
        <path d="M21.1893 1.34552L35.0023 1.0686L28.0797 8.5027L21.1893 1.34552Z" fill="#B3FBF3"/>
        <path d="M7.56274 1.34558H21.1892L14.2956 8.50277L7.56274 1.34558Z" fill="#BDFFFF"/>
        <path d="M21.6235 40.0071L0 8.41042L7.15101 0.280485L35.3948 -0.00708008L43.1441 8.38912L21.6235 40.0071ZM2.53808 8.59148L21.6203 36.4569L40.5996 8.60568L34.626 2.13723L7.98737 2.40705L2.53808 8.59148Z" fill="black"/>
        <path d="M41.526 8.3252H2.50592V8.68022H41.526V8.3252Z" fill="black"/>
        <path d="M28.0829 8.74766L21.1893 1.59048L14.302 8.74056L7.67859 2.45318L7.88768 2.18336L14.2924 8.26129L21.1893 1.1041L28.0797 8.25419L35.012 0.812988L41.0822 8.38199L40.8409 8.61986L34.9959 1.32422L28.0829 8.74766Z" fill="black"/>
        <path d="M21.7168 37.4652L21.2504 37.0534L14.1348 8.46721L28.2888 8.32166L21.7779 36.4569L21.7168 37.4652ZM14.5561 8.81868L21.4659 36.5812V36.4108L27.877 8.68377L14.5561 8.81868Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_3501_11107">
          <rect width="43.1441" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!-- icon: achievement_board_completed-->
  <div
    class="icon-board-completed"
    *ngIf="icon === icons.AchievementBoardCompleted"
    [class.icon-board-completed-active]="active"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" />
      <path d="M16.6667 7.33333H15.3333V6H8.66667V7.33333H7.33333C6.6 7.33333 6 7.93333 6 8.66667V9.33333C6 11.0333 7.28 12.42 8.92667 12.6267C9.34667 13.6267 10.2467 14.38 11.3333 14.6V16.6667H8.66667V18H15.3333V16.6667H12.6667V14.6C13.7533 14.38 14.6533 13.6267 15.0733 12.6267C16.72 12.42 18 11.0333 18 9.33333V8.66667C18 7.93333 17.4 7.33333 16.6667 7.33333ZM7.33333 9.33333V8.66667H8.66667V11.2133C7.89333 10.9333 7.33333 10.2 7.33333 9.33333ZM16.6667 9.33333C16.6667 10.2 16.1067 10.9333 15.3333 11.2133V8.66667H16.6667V9.33333Z" fill="white"/>
    </svg>
  </div>

  <!--  icon: create_a_user_type-->
  <div
    class="icon"
    *ngIf="icon === icons.CreateAUserType"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path d="M15.7384 26.1055L17.0581 26.657V17.7639L14.6649 23.5351C14.2612 24.5396 14.7437 25.6919 15.7384 26.1055V26.1055ZM34.943 22.4616L30.0581 10.6729C29.7528 9.93431 29.0339 9.48128 28.2755 9.46158C28.0195 9.46158 27.7536 9.50097 27.4975 9.60931L20.2392 12.6131C19.5005 12.9184 19.0475 13.6275 19.0278 14.3858C19.018 14.6517 19.0672 14.9176 19.1755 15.1737L24.0604 26.9623C24.3657 27.7108 25.0945 28.1639 25.8627 28.1737C26.1187 28.1737 26.3748 28.1245 26.621 28.026L33.8695 25.0222C34.874 24.6085 35.3566 23.4563 34.943 22.4616V22.4616ZM21.0074 15.3707C20.4657 15.3707 20.0225 14.9275 20.0225 14.3858C20.0225 13.8442 20.4657 13.401 21.0074 13.401C21.549 13.401 21.9922 13.8442 21.9922 14.3858C21.9922 14.9275 21.549 15.3707 21.0074 15.3707V15.3707ZM19.0377 26.204C19.0377 27.2873 19.924 28.1737 21.0074 28.1737H22.4354L19.0377 19.9601V26.204Z" />
      <path d="M10.4776 21.518H13.2468V23.2044H10.4776V26.4935H8.75778V23.2044H5.97403V21.518H8.75778V18.4416H10.4776V21.518Z" />
    </svg>
  </div>

  <!--  icon: create_a_user-->
  <div
    class="icon"
    *ngIf="icon === icons.CreateAUser"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <g clip-path="url(#clip0_2480_9733)">
        <path d="M28.3604 19.8701C29.6896 19.8701 30.7588 18.7914 30.7588 17.4621C30.7588 16.1329 29.6896 15.0541 28.3604 15.0541C27.0312 15.0541 25.9524 16.1329 25.9524 17.4621C25.9524 18.7914 27.0312 19.8701 28.3604 19.8701ZM21.1364 18.9069C22.7353 18.9069 24.0163 17.6162 24.0163 16.0173C24.0163 14.4184 22.7353 13.1277 21.1364 13.1277C19.5374 13.1277 18.2468 14.4184 18.2468 16.0173C18.2468 17.6162 19.5374 18.9069 21.1364 18.9069ZM28.3604 21.7965C26.5977 21.7965 23.0628 22.6827 23.0628 24.4454V26.6126H33.658V24.4454C33.658 22.6827 30.123 21.7965 28.3604 21.7965ZM21.1364 20.8333C18.8921 20.8333 14.3939 21.9603 14.3939 24.2046V26.6126H21.1364V24.4454C21.1364 23.6266 21.4542 22.1915 23.4192 21.103C22.5812 20.9297 21.7721 20.8333 21.1364 20.8333Z" />
      </g>
      <path d="M10.4776 21.518H13.2468V23.2044H10.4776V26.4935H8.75778V23.2044H5.97403V21.518H8.75778V18.4416H10.4776V21.518Z" />
      <defs>
        <clipPath id="clip0_2480_9733">
          <rect width="23.1169" height="23.1169" fill="white" transform="translate(12.4675 8.31171)"/>
        </clipPath>
      </defs>
    </svg>

  </div>

  <!--  icon: create_an_action_plan-->
  <div
    class="icon"
    *ngIf="icon === icons.CreateAnActionPlan"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1061 27.105L22.987 10.0379L32.868 27.105H13.1061ZM29.751 25.3085L22.987 13.622L16.2231 25.3085H29.751ZM22.0887 22.6137V24.4102H23.8853V22.6137H22.0887ZM22.0887 17.2241H23.8853V20.8171H22.0887V17.2241Z" />
      <path d="M10.4776 21.518H13.2468V23.2044H10.4776V26.4935H8.75778V23.2044H5.97403V21.518H8.75778V18.4416H10.4776V21.518Z" />
    </svg>
  </div>

  <!--  icon: view_action_plans-->
  <div
    class="icon"
    *ngIf="icon === icons.ViewActionPlans"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3268 28.4037L22.2078 11.3366L32.0887 28.4037H12.3268ZM28.9718 26.6071L22.2078 14.9207L15.4438 26.6071H28.9718ZM21.3095 23.9123V25.7089H23.1061V23.9123H21.3095ZM21.3095 18.5227H23.1061V22.1158H21.3095V18.5227Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.45671 14.4156C8.22435 12.4677 10.119 11.0877 12.3377 11.0877C14.5563 11.0877 16.451 12.4677 17.2186 14.4156C16.451 16.3635 14.5563 17.7435 12.3377 17.7435C10.119 17.7435 8.22435 16.3635 7.45671 14.4156ZM16.2513 14.4156C15.5192 12.9203 14.0194 11.9751 12.3377 11.9751C10.656 11.9751 9.15617 12.9203 8.42403 14.4156C9.15617 15.9109 10.656 16.8561 12.3377 16.8561C14.0194 16.8561 15.5192 15.9109 16.2513 14.4156ZM12.3377 13.3063C12.95 13.3063 13.447 13.8033 13.447 14.4156C13.447 15.0279 12.95 15.5249 12.3377 15.5249C11.7253 15.5249 11.2284 15.0279 11.2284 14.4156C11.2284 13.8033 11.7253 13.3063 12.3377 13.3063ZM10.3409 14.4156C10.3409 13.3152 11.2372 12.4188 12.3377 12.4188C13.4381 12.4188 14.3344 13.3152 14.3344 14.4156C14.3344 15.516 13.4381 16.4124 12.3377 16.4124C11.2372 16.4124 10.3409 15.516 10.3409 14.4156Z" />
    </svg>
  </div>

  <!--  icon: view_the_action_plans_report-->
  <div
    class="icon"
    *ngIf="icon === icons.ViewTheActionPlansReport"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3658 28.4037L23.2467 11.3366L33.1277 28.4037H13.3658ZM30.0107 26.6072L23.2467 14.9207L16.4828 26.6072H30.0107ZM22.3485 23.9124V25.7089H24.145V23.9124H22.3485ZM22.3485 18.5228H24.145V22.1158H22.3485V18.5228Z" />
      <rect x="6.23373" y="11.6949" width="1.74258" height="4.76306" />
      <rect x="8.9057" y="13.2051" width="1.74258" height="3.25282" />
      <rect x="11.5777" y="12.1596" width="1.74258" height="4.29837" />
      <rect x="14.2496" y="14.0183" width="1.74258" height="2.43962" />
      <rect x="16.9216" y="10.6494" width="1.74258" height="5.80861" />
      <rect x="6.23373" y="16.8065" width="12.4304" height="0.348517" />
    </svg>
  </div>

  <!--  icon: add_evidence_to_the_action_plan-->
  <div
    class="icon"
    *ngIf="icon === icons.AddEvidenceToTheActionPlan"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3268 28.4037L22.2078 11.3366L32.0887 28.4037H12.3268ZM28.9718 26.6072L22.2078 14.9207L15.4438 26.6072H28.9718ZM21.3095 23.9124V25.7089H23.1061V23.9124H21.3095ZM21.3095 18.5228H23.1061V22.1158H21.3095V18.5228Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4808 11.0498H15.8875C16.3755 11.0498 16.7749 11.4491 16.7749 11.9372V17.2619C16.7749 17.75 16.3755 18.1494 15.8875 18.1494H8.78788C8.29979 18.1494 7.90044 17.75 7.90044 17.2619V11.9372C7.90044 11.4491 8.29979 11.0498 8.78788 11.0498H10.1945L11.0065 10.1624H13.6688L14.4808 11.0498ZM8.78788 11.9372V17.2619H15.8874V11.9372H14.0904L13.2784 11.0498H11.397L10.585 11.9372H8.78788ZM12.3377 12.381C11.113 12.381 10.1191 13.3749 10.1191 14.5996C10.1191 15.8243 11.113 16.8182 12.3377 16.8182C13.5623 16.8182 14.5563 15.8243 14.5563 14.5996C14.5563 13.3749 13.5623 12.381 12.3377 12.381ZM11.0065 14.5996C11.0065 15.3317 11.6055 15.9308 12.3377 15.9308C13.0698 15.9308 13.6688 15.3317 13.6688 14.5996C13.6688 13.8674 13.0698 13.2684 12.3377 13.2684C11.6055 13.2684 11.0065 13.8674 11.0065 14.5996Z" />
    </svg>
  </div>

  <!--  icon: complete_an_action_plan-->
  <div
    class="icon"
    *ngIf="icon === icons.CompleteAnActionPlan"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3268 28.4036L22.2078 11.3365L32.0887 28.4036H12.3268ZM28.9718 26.6071L22.2078 14.9206L15.4438 26.6071H28.9718ZM21.3095 23.9123V25.7088H23.1061V23.9123H21.3095ZM21.3095 18.5227H23.1061V22.1158H21.3095V18.5227Z" />
      <path d="M10.9155 16.135L9.06521 14.2846L8.43512 14.9103L10.9155 17.3907L16.2402 12.066L15.6146 11.4404L10.9155 16.135Z" />
    </svg>
  </div>

  <!--  icon: view_the_recidivism_report-->
  <div
    class="icon"
    *ngIf="icon === icons.ViewTheRecidivismReport"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <rect x="7.27269" y="10.1364" width="1.74258" height="4.76306" />
      <rect x="9.94467" y="11.6467" width="1.74258" height="3.25282" />
      <rect x="12.6166" y="10.6011" width="1.74258" height="4.29837" />
      <rect x="15.2886" y="12.4599" width="1.74258" height="2.43962" />
      <rect x="17.9605" y="9.09088" width="1.74258" height="5.80861" />
      <rect x="7.27269" y="15.248" width="12.4304" height="0.348517" />
      <path d="M30.217 17.835C28.6948 16.3128 26.6057 15.368 24.2857 15.368C19.6456 15.368 15.8979 19.1262 15.8979 23.7662C15.8979 28.4063 19.6456 32.1645 24.2857 32.1645C28.2014 32.1645 31.4662 29.4876 32.4005 25.8658H30.217C29.3561 28.3118 27.0256 30.0649 24.2857 30.0649C20.8109 30.0649 17.987 27.241 17.987 23.7662C17.987 20.2915 20.8109 17.4675 24.2857 17.4675C26.0283 17.4675 27.582 18.1919 28.7158 19.3362L25.3355 22.7165H32.684V15.368L30.217 17.835Z" />
    </svg>
  </div>

  <!--  icon: apply_ten_checklists-->
  <div
    class="icon"
    *ngIf="icon === icons.ApplyTenChecklists"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20Z" />
      <path d="M37.9221 20C37.9221 29.8981 29.8981 37.9221 20 37.9221C10.1019 37.9221 2.07793 29.8981 2.07793 20C2.07793 10.1019 10.1019 2.07794 20 2.07794C29.8981 2.07794 37.9221 10.1019 37.9221 20Z" fill="white"/>
      <path d="M11.5165 19.1803H14.2857V20.8667H11.5165V24.1558H9.79672V20.8667H7.01297V19.1803H9.79672V16.1039H11.5165V19.1803Z" />
      <path d="M19.7555 26.7248H17.5456V16.0501L14.2857 17.1642V15.2922L19.4724 13.3837H19.7555V26.7248Z" />
      <path d="M33.0808 21.1272C33.0808 23.0388 32.7216 24.4815 32.0032 25.4556C31.291 26.4235 30.2043 26.9075 28.7433 26.9075C27.3066 26.9075 26.223 26.4357 25.4925 25.4921C24.7681 24.5424 24.3967 23.1392 24.3784 21.2825V18.9813C24.3784 17.0698 24.7346 15.6362 25.4468 14.6804C26.1652 13.7246 27.2579 13.2468 28.725 13.2468C30.18 13.2468 31.2636 13.7125 31.9758 14.6439C32.6942 15.5753 33.0625 16.9694 33.0808 18.8261V21.1272ZM30.8618 18.6435C30.8618 17.3955 30.6913 16.4824 30.3504 15.904C30.0095 15.3196 29.4677 15.0274 28.725 15.0274C28.0006 15.0274 27.4679 15.3044 27.127 15.8584C26.7861 16.4063 26.6065 17.2646 26.5883 18.4335V21.4468C26.5883 22.6887 26.7618 23.6141 27.1088 24.2228C27.4558 24.8316 28.0006 25.136 28.7433 25.136C29.4556 25.136 29.9821 24.8559 30.323 24.2959C30.664 23.7297 30.8435 22.847 30.8618 21.6477V18.6435Z" />
    </svg>
  </div>

  <!--  icon: apply_checklist_on_mobile-->
  <div
    class="icon"
    *ngIf="icon === icons.ApplyChecklistOnMobile"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <g clip-path="url(#clip0_2503_8774)">
        <path d="M25.4654 9.29651H15.6169C14.5335 9.29651 13.6472 10.1829 13.6472 11.2662V14.2208H15.6169V12.2511H25.4654V28.0086H15.6169V26.0389H13.6472V28.9935C13.6472 30.0768 14.5335 30.9632 15.6169 30.9632H25.4654C26.5487 30.9632 27.4351 30.0768 27.4351 28.9935V11.2662C27.4351 10.1829 26.5487 9.29651 25.4654 9.29651ZM13.657 21.5776L11.1457 19.0662L9.89491 20.317L13.6472 24.0692L20.7282 16.9882L19.4775 15.7374L13.657 21.5776Z" />
      </g>
      <defs>
        <clipPath id="clip0_2503_8774">
          <rect width="23.6364" height="23.6364" fill="white" transform="translate(6.75325 8.31165)"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!--  icon: view_indicators_report-->
  <div
    class="icon"
    *ngIf="icon === icons.ViewIndicatorsReport"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.63843 19.87C6.40607 17.9221 8.30077 16.5421 10.5194 16.5421C12.738 16.5421 14.6327 17.9221 15.4003 19.87C14.6327 21.818 12.738 23.198 10.5194 23.198C8.30077 23.198 6.40607 21.818 5.63843 19.87ZM14.433 19.87C13.7009 18.3747 12.2011 17.4296 10.5194 17.4296C8.83767 17.4296 7.33789 18.3747 6.60574 19.87C7.33789 21.3654 8.83767 22.3105 10.5194 22.3105C12.2011 22.3105 13.7009 21.3654 14.433 19.87ZM10.5194 18.7607C11.1317 18.7607 11.6287 19.2577 11.6287 19.87C11.6287 20.4824 11.1317 20.9793 10.5194 20.9793C9.90704 20.9793 9.41007 20.4824 9.41007 19.87C9.41007 19.2577 9.90704 18.7607 10.5194 18.7607ZM8.52263 19.87C8.52263 18.7696 9.41895 17.8733 10.5194 17.8733C11.6198 17.8733 12.5161 18.7696 12.5161 19.87C12.5161 20.9705 11.6198 21.8668 10.5194 21.8668C9.41895 21.8668 8.52263 20.9705 8.52263 19.87Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3681 12.0131H17.9438C16.9676 12.0131 16.1689 12.8118 16.1689 13.788V26.2122C16.1689 27.1884 16.9676 27.9871 17.9438 27.9871H30.3681C31.3443 27.9871 32.143 27.1884 32.143 26.2122V13.788C32.143 12.8118 31.3443 12.0131 30.3681 12.0131ZM30.3681 26.2122H17.9438V13.788H30.3681V26.2122ZM21.4936 18.2252H19.7187V24.4373H21.4936V18.2252ZM23.2685 15.5628H25.0434V24.4373H23.2685V15.5628ZM28.5932 20.8875H26.8183V24.4373H28.5932V20.8875Z" />
    </svg>
  </div>

  <!--  icon: view_unit_audit_report-->
  <div
    class="icon"
    *ngIf="icon === icons.ViewUnitAuditReport"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.23584 25.3246C9.00348 23.3767 10.8982 21.9967 13.1168 21.9967C15.3354 21.9967 17.2301 23.3767 17.9977 25.3246C17.2301 27.2726 15.3354 28.6525 13.1168 28.6525C10.8982 28.6525 9.00348 27.2726 8.23584 25.3246ZM17.0304 25.3246C16.2983 23.8293 14.7985 22.8842 13.1168 22.8842C11.4351 22.8842 9.9353 23.8293 9.20316 25.3246C9.9353 26.82 11.4351 27.7651 13.1168 27.7651C14.7985 27.7651 16.2983 26.82 17.0304 25.3246ZM13.1168 24.2153C13.7291 24.2153 14.2261 24.7123 14.2261 25.3246C14.2261 25.937 13.7291 26.4339 13.1168 26.4339C12.5045 26.4339 12.0075 25.937 12.0075 25.3246C12.0075 24.7123 12.5045 24.2153 13.1168 24.2153ZM11.12 25.3246C11.12 24.2242 12.0164 23.3279 13.1168 23.3279C14.2172 23.3279 15.1135 24.2242 15.1135 25.3246C15.1135 26.4251 14.2172 27.3214 13.1168 27.3214C12.0164 27.3214 11.12 26.4251 11.12 25.3246Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8571 8.87451C18.7526 8.87451 15.4329 12.1942 15.4329 16.2988C15.4329 21.8669 22.8571 30.0866 22.8571 30.0866C22.8571 30.0866 30.2813 21.8669 30.2813 16.2988C30.2813 12.1942 26.9616 8.87451 22.8571 8.87451ZM17.5541 16.2988C17.5541 13.3715 19.9298 10.9957 22.8571 10.9957C25.7844 10.9957 28.1601 13.3715 28.1601 16.2988C28.1601 19.3533 25.1056 23.9245 22.8571 26.7775C20.651 23.9457 17.5541 19.3215 17.5541 16.2988ZM20.2056 16.2988C20.2056 14.8344 21.3927 13.6472 22.8571 13.6472C23.8044 13.6472 24.6797 14.1526 25.1534 14.973C25.627 15.7934 25.627 16.8041 25.1534 17.6245C24.6797 18.4449 23.8044 18.9503 22.8571 18.9503C21.3927 18.9503 20.2056 17.7631 20.2056 16.2988Z" />
    </svg>
  </div>

  <!--  icon: view_user_audit_report-->
  <div
    class="icon"
    *ngIf="icon === icons.ViewUserAuditReport"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <g clip-path="url(#clip0_2592_9761)">
        <path d="M28.3605 19.8701C29.6897 19.8701 30.7589 18.7913 30.7589 17.4621C30.7589 16.1329 29.6897 15.0541 28.3605 15.0541C27.0313 15.0541 25.9525 16.1329 25.9525 17.4621C25.9525 18.7913 27.0313 19.8701 28.3605 19.8701ZM21.1365 18.9069C22.7354 18.9069 24.0164 17.6162 24.0164 16.0173C24.0164 14.4184 22.7354 13.1277 21.1365 13.1277C19.5375 13.1277 18.2469 14.4184 18.2469 16.0173C18.2469 17.6162 19.5375 18.9069 21.1365 18.9069ZM28.3605 21.7965C26.5978 21.7965 23.0629 22.6827 23.0629 24.4453V26.6125H33.6581V24.4453C33.6581 22.6827 30.1232 21.7965 28.3605 21.7965ZM21.1365 20.8333C18.8922 20.8333 14.394 21.9603 14.394 24.2045V26.6125H21.1365V24.4453C21.1365 23.6266 21.4543 22.1914 23.4193 21.103C22.5813 20.9296 21.7722 20.8333 21.1365 20.8333Z" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.41772 16.7532C7.18537 14.8053 9.08006 13.4253 11.2987 13.4253C13.5173 13.4253 15.412 14.8053 16.1796 16.7532C15.412 18.7012 13.5173 20.0811 11.2987 20.0811C9.08006 20.0811 7.18537 18.7012 6.41772 16.7532ZM15.2123 16.7532C14.4802 15.2579 12.9804 14.3127 11.2987 14.3127C9.61697 14.3127 8.11718 15.2579 7.38504 16.7532C8.11718 18.2486 9.61697 19.1937 11.2987 19.1937C12.9804 19.1937 14.4802 18.2486 15.2123 16.7532ZM11.2987 15.6439C11.911 15.6439 12.408 16.1409 12.408 16.7532C12.408 17.3656 11.911 17.8625 11.2987 17.8625C10.6863 17.8625 10.1894 17.3656 10.1894 16.7532C10.1894 16.1409 10.6863 15.6439 11.2987 15.6439ZM9.30192 16.7532C9.30192 15.6528 10.1982 14.7565 11.2987 14.7565C12.3991 14.7565 13.2954 15.6528 13.2954 16.7532C13.2954 17.8536 12.3991 18.75 11.2987 18.75C10.1982 18.75 9.30192 17.8536 9.30192 16.7532Z" />
      <defs>
        <clipPath id="clip0_2592_9761">
          <rect width="23.1169" height="23.1169" fill="white" transform="translate(12.4675 8.31165)"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!--  icon: configure_a_dashboard-->
  <div
    class="icon"
    *ngIf="icon === icons.ConfigureADashboard"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <g clip-path="url(#clip0_2592_9776)">
        <path d="M17.2402 20.9416H23.7337V12.8247H17.2402V20.9416ZM17.2402 27.4351H23.7337V22.565H17.2402V27.4351ZM25.3571 27.4351H31.8506V19.3182H25.3571V27.4351ZM25.3571 12.8247V17.6948H31.8506V12.8247H25.3571Z" />
      </g>
      <g clip-path="url(#clip1_2592_9776)">
        <path d="M14.1546 18.0896C14.1727 17.9533 14.1818 17.8123 14.1818 17.6623C14.1818 17.5169 14.1727 17.3714 14.15 17.2351L15.0727 16.5169C15.1546 16.4533 15.1773 16.3305 15.1273 16.2396L14.2546 14.7305C14.2 14.6305 14.0864 14.5987 13.9864 14.6305L12.9 15.0669C12.6727 14.8942 12.4318 14.7487 12.1636 14.6396L12 13.4851C11.9818 13.376 11.8909 13.2987 11.7818 13.2987H10.0364C9.92728 13.2987 9.84091 13.376 9.82273 13.4851L9.6591 14.6396C9.39091 14.7487 9.14546 14.8987 8.92273 15.0669L7.83637 14.6305C7.73637 14.5942 7.62273 14.6305 7.56819 14.7305L6.70001 16.2396C6.64546 16.3351 6.66364 16.4533 6.75455 16.5169L7.67728 17.2351C7.65455 17.3714 7.63637 17.5214 7.63637 17.6623C7.63637 17.8033 7.64546 17.9533 7.66819 18.0896L6.74546 18.8078C6.66364 18.8714 6.64091 18.9942 6.69091 19.0851L7.56364 20.5942C7.61819 20.6942 7.73182 20.726 7.83182 20.6942L8.91819 20.2578C9.14546 20.4305 9.38637 20.576 9.65455 20.6851L9.81819 21.8396C9.84091 21.9487 9.92728 22.026 10.0364 22.026H11.7818C11.8909 22.026 11.9818 21.9487 11.9955 21.8396L12.1591 20.6851C12.4273 20.576 12.6727 20.4305 12.8955 20.2578L13.9818 20.6942C14.0818 20.7305 14.1955 20.6942 14.25 20.5942L15.1227 19.0851C15.1773 18.9851 15.1546 18.8714 15.0682 18.8078L14.1546 18.0896ZM10.9091 19.2987C10.0091 19.2987 9.27273 18.5623 9.27273 17.6623C9.27273 16.7623 10.0091 16.026 10.9091 16.026C11.8091 16.026 12.5455 16.7623 12.5455 17.6623C12.5455 18.5623 11.8091 19.2987 10.9091 19.2987Z" />
      </g>
      <defs>
        <clipPath id="clip0_2592_9776">
          <rect width="19.4805" height="19.4805" fill="white" transform="translate(14.8052 10.3896)"/>
        </clipPath>
        <clipPath id="clip1_2592_9776">
          <rect width="10.9091" height="10.9091" fill="white" transform="translate(5.45459 12.2078)"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!--  icon: manage_your_notifications-->
  <div
    class="icon"
    *ngIf="icon === icons.ManageYourNotifications"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <g clip-path="url(#clip0_2592_9791)">
        <path d="M14.6741 18.0896C14.6923 17.9533 14.7014 17.8123 14.7014 17.6623C14.7014 17.5169 14.6923 17.3714 14.6695 17.2351L15.5923 16.5169C15.6741 16.4533 15.6968 16.3305 15.6468 16.2396L14.7741 14.7305C14.7195 14.6305 14.6059 14.5987 14.5059 14.6305L13.4195 15.0669C13.1923 14.8942 12.9514 14.7487 12.6832 14.6396L12.5195 13.4851C12.5014 13.376 12.4104 13.2987 12.3014 13.2987H10.5559C10.4468 13.2987 10.3604 13.376 10.3423 13.4851L10.1786 14.6396C9.91045 14.7487 9.66499 14.8987 9.44226 15.0669L8.3559 14.6305C8.2559 14.5942 8.14226 14.6305 8.08772 14.7305L7.21954 16.2396C7.16499 16.3351 7.18317 16.4533 7.27408 16.5169L8.19681 17.2351C8.17408 17.3714 8.1559 17.5214 8.1559 17.6623C8.1559 17.8033 8.16499 17.9533 8.18772 18.0896L7.26499 18.8078C7.18317 18.8714 7.16045 18.9942 7.21045 19.0851L8.08317 20.5942C8.13772 20.6942 8.25136 20.726 8.35135 20.6942L9.43772 20.2578C9.66499 20.4305 9.9059 20.576 10.1741 20.6851L10.3377 21.8396C10.3604 21.9487 10.4468 22.026 10.5559 22.026H12.3014C12.4104 22.026 12.5014 21.9487 12.515 21.8396L12.6786 20.6851C12.9468 20.576 13.1923 20.4305 13.415 20.2578L14.5014 20.6942C14.6014 20.7305 14.715 20.6942 14.7695 20.5942L15.6423 19.0851C15.6968 18.9851 15.6741 18.8714 15.5877 18.8078L14.6741 18.0896ZM11.4286 19.2987C10.5286 19.2987 9.79226 18.5623 9.79226 17.6623C9.79226 16.7623 10.5286 16.026 11.4286 16.026C12.3286 16.026 13.065 16.7623 13.065 17.6623C13.065 18.5623 12.3286 19.2987 11.4286 19.2987Z" />
      </g>
      <path d="M24.0258 28.2035C24.9425 28.2035 25.6925 27.4535 25.6925 26.5368H22.3591C22.3591 27.4535 23.1008 28.2035 24.0258 28.2035ZM29.0258 23.2035V19.0368C29.0258 16.4785 27.6591 14.3368 25.2758 13.7702V13.2035C25.2758 12.5118 24.7175 11.9535 24.0258 11.9535C23.3341 11.9535 22.7758 12.5118 22.7758 13.2035V13.7702C20.3841 14.3368 19.0258 16.4702 19.0258 19.0368V23.2035L17.3591 24.8702V25.7035H30.6925V24.8702L29.0258 23.2035Z" />
      <defs>
        <clipPath id="clip0_2592_9791">
          <rect width="10.9091" height="10.9091" fill="white" transform="translate(5.97412 12.2078)"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!--  icon: access_other_settings-->
  <div
    class="icon"
    *ngIf="icon === icons.AccessOtherSettings"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <g clip-path="url(#clip0_2592_9806)">
        <path d="M27.4181 20.9766C27.4597 20.665 27.4804 20.3429 27.4804 20C27.4804 19.6676 27.4597 19.3351 27.4077 19.0234L29.5168 17.3818C29.7038 17.2364 29.7558 16.9559 29.6415 16.7481L27.6467 13.2987C27.522 13.0702 27.2623 12.9974 27.0337 13.0702L24.5506 14.0676C24.0311 13.6728 23.4804 13.3403 22.8674 13.0909L22.4934 10.452C22.4519 10.2026 22.2441 10.026 21.9947 10.026H18.0051C17.7558 10.026 17.5584 10.2026 17.5168 10.452L17.1428 13.0909C16.5298 13.3403 15.9687 13.6831 15.4597 14.0676L12.9765 13.0702C12.748 12.987 12.4882 13.0702 12.3636 13.2987L10.3791 16.7481C10.2545 16.9663 10.296 17.2364 10.5038 17.3818L12.6129 19.0234C12.561 19.3351 12.5194 19.6779 12.5194 20C12.5194 20.3221 12.5402 20.665 12.5921 20.9766L10.483 22.6182C10.296 22.7637 10.2441 23.0442 10.3584 23.252L12.3532 26.7013C12.4778 26.9299 12.7376 27.0026 12.9661 26.9299L15.4493 25.9325C15.9687 26.3273 16.5194 26.6598 17.1324 26.9091L17.5064 29.5481C17.5584 29.7974 17.7558 29.9741 18.0051 29.9741H21.9947C22.2441 29.9741 22.4519 29.7974 22.483 29.5481L22.8571 26.9091C23.47 26.6598 24.0311 26.3273 24.5402 25.9325L27.0233 26.9299C27.2519 27.013 27.5116 26.9299 27.6363 26.7013L29.6311 23.252C29.7558 23.0234 29.7038 22.7637 29.5064 22.6182L27.4181 20.9766ZM19.9999 23.7403C17.9428 23.7403 16.2597 22.0572 16.2597 20C16.2597 17.9429 17.9428 16.2598 19.9999 16.2598C22.0571 16.2598 23.7402 17.9429 23.7402 20C23.7402 22.0572 22.0571 23.7403 19.9999 23.7403Z" />
      </g>
      <defs>
        <clipPath id="clip0_2592_9806">
          <rect width="24.9351" height="24.9351" fill="white" transform="translate(7.53247 7.53247)"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!--  icon: import_a_checklist-->
  <div
    class="icon"
    *ngIf="icon === icons.ImportAChecklist"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path d="M16.1038 20L15.2798 19.176L12.0129 22.437V15.3247H10.8441V22.437L7.58304 19.1702L6.75317 20L11.4285 24.6754L16.1038 20Z" />
      <g clip-path="url(#clip0_2592_9820)">
        <path d="M22.5967 12.2742C22.8598 12.5541 23.4519 12.8998 24.7727 12.8998C26.8206 12.8998 27.1511 11.8571 27.1979 11.6402H25.7395C25.7395 11.3804 25.6379 11.1312 25.4571 10.9475C25.2763 10.7637 25.0311 10.6605 24.7754 10.6605C24.5198 10.6605 24.2746 10.7637 24.0938 10.9475C23.913 11.1312 23.8114 11.3804 23.8114 11.6402H22.3516C22.3516 11.65 22.3516 11.6598 22.3516 11.671C22.3532 11.8968 22.4411 12.113 22.5967 12.2742Z" />
        <path d="M30.3241 11.6346H27.892C27.8927 11.6462 27.8927 11.6579 27.892 11.6695C27.8837 11.7493 27.6647 13.5995 24.774 13.5995C23.4973 13.5995 22.5884 13.307 22.0747 12.729C21.8025 12.4324 21.6543 12.04 21.6615 11.6346H19.5544C19.2622 11.6346 18.982 11.7525 18.7753 11.9625C18.5687 12.1725 18.4526 12.4572 18.4526 12.7542V27.185C18.4526 27.4819 18.5687 27.7667 18.7753 27.9767C18.982 28.1866 19.2622 28.3046 19.5544 28.3046H30.3324C30.6246 28.3046 30.9048 28.1866 31.1114 27.9767C31.3181 27.7667 31.4341 27.4819 31.4341 27.185V12.757C31.4345 12.609 31.406 12.4624 31.3503 12.3257C31.2946 12.189 31.2127 12.0649 31.1095 11.9605C31.0063 11.8561 30.8837 11.7736 30.7489 11.7176C30.6141 11.6617 30.4697 11.6334 30.3241 11.6346Z" />
        <path d="M23.2224 22.8049L21.0209 20.5677L20.2712 21.3242L23.2224 24.3232L29.5575 17.8852L28.8131 17.1288L23.2224 22.8049Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2592_9820">
          <rect width="12.987" height="17.6623" fill="white" transform="translate(18.4417 10.6493)"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <!--  icon: export_an_applied_checklis-->
  <div
    class="icon"
    *ngIf="icon === icons.ExportAnAppliedChecklist"
    [class.icon--active]="active"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" />
      <circle cx="20" cy="20" r="17.9221" fill="white"/>
      <path d="M6.75336 20L7.57738 20.824L10.8443 17.563L10.8443 24.6753L12.0131 24.6753L12.0131 17.563L15.2741 20.8298L16.104 20L11.4287 15.3246L6.75336 20Z" />
      <g clip-path="url(#clip0_2592_9836)">
        <path d="M22.5967 12.2742C22.8598 12.5541 23.4519 12.8998 24.7727 12.8998C26.8206 12.8998 27.1511 11.8571 27.1979 11.6402H25.7395C25.7395 11.3804 25.6379 11.1312 25.4571 10.9475C25.2763 10.7637 25.0311 10.6605 24.7754 10.6605C24.5198 10.6605 24.2746 10.7637 24.0938 10.9475C23.913 11.1312 23.8114 11.3804 23.8114 11.6402H22.3516C22.3516 11.65 22.3516 11.6598 22.3516 11.671C22.3532 11.8968 22.4411 12.113 22.5967 12.2742Z" />
        <path d="M30.3241 11.6346H27.892C27.8927 11.6462 27.8927 11.6579 27.892 11.6695C27.8837 11.7493 27.6647 13.5995 24.774 13.5995C23.4973 13.5995 22.5884 13.307 22.0747 12.729C21.8025 12.4324 21.6543 12.04 21.6615 11.6346H19.5544C19.2622 11.6346 18.982 11.7525 18.7753 11.9625C18.5687 12.1725 18.4526 12.4572 18.4526 12.7542V27.185C18.4526 27.4819 18.5687 27.7667 18.7753 27.9767C18.982 28.1866 19.2622 28.3046 19.5544 28.3046H30.3324C30.6246 28.3046 30.9048 28.1866 31.1114 27.9767C31.3181 27.7667 31.4341 27.4819 31.4341 27.185V12.757C31.4345 12.609 31.406 12.4624 31.3503 12.3257C31.2946 12.189 31.2127 12.0649 31.1095 11.9605C31.0063 11.8561 30.8837 11.7736 30.7489 11.7176C30.6141 11.6617 30.4697 11.6334 30.3241 11.6346Z" />
        <path d="M23.2224 22.8049L21.0209 20.5677L20.2712 21.3242L23.2224 24.3232L29.5575 17.8852L28.8131 17.1288L23.2224 22.8049Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2592_9836">
          <rect width="12.987" height="17.6623" fill="white" transform="translate(18.4417 10.6493)"/>
        </clipPath>
      </defs>
    </svg>
  </div>

</div>
