import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoftMaxlengthDirective } from './validations/soft-maxlength.directive';
import { NotInCollectionDirective } from './validations/not-in-collection.directive';

@NgModule({
  declarations: [SoftMaxlengthDirective, NotInCollectionDirective],
  imports: [CommonModule],
  exports: [SoftMaxlengthDirective, NotInCollectionDirective],
})
export class SharedUtilGeneralModule {}
