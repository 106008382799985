<div
  role="group"
  class="tel-input-container"
  [formGroup]="parts"
  [attr.aria-labelledby]="_formField?.getLabelId()"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
>
  <span class="tel-input-spacer">(</span>
  <input
    class="tel-input-element"
    formControlName="areaCode"
    size="2"
    maxLength="2"
    [attr.aria-label]="'clFormPhoneInput.areaCode' | transloco"
    autocomplete="tel-area-code"
    (input)="handleInput(parts.controls.areaCode, number)"
    #areaCode
  />
  <span class="tel-input-spacer">)</span>
  <input
    class="tel-input-element"
    formControlName="number"
    maxLength="9"
    size="9"
    [attr.aria-label]="'clFormPhoneInput.phoneNumber' | transloco"
    autocomplete="tel-local"
    (input)="handleInput(parts.controls.number)"
    (keyup.backspace)="autoFocusPrev(parts.controls.number, areaCode)"
    #number
  />
</div>
