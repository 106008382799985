import { Attribute, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cl-form-textfield-mini',
  templateUrl: './textfield-mini.component.html',
  styleUrls: ['./textfield-mini.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextfieldMiniComponent),
      multi: true,
    },
  ],
})
export class TextfieldMiniComponent implements ControlValueAccessor {
  @Input() value = '';
  @Input() name: string | undefined;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() maxlength = 4;
  @Input() max: number | undefined;
  @Input() min: number | undefined;
  @Input() hasError = false;
  onChange = (param: any) => {};
  onTouched = (param: any) => {};

  constructor(@Attribute('type') public type = 'text') {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
