import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { LoadingService } from '@checklistfacil/shared/data-access/loading';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CompanySubscriptionService } from '../company-subscription.service';
import {
  loadChecklistPlansError,
  loadChecklistPlansSuccess,
} from '../state/actions';
import { ChecklistPlansData } from '../state/models';

@Injectable()
export class CompanyPlansResolverService
  implements Resolve<ChecklistPlansData | null>
{
  constructor(
    private service: CompanySubscriptionService,
    private store: Store,
    private loadingService: LoadingService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ChecklistPlansData | null> {
    this.loadingService.showLoading();
    return this.service.loadPlansData().pipe(
      tap((payload) => {
        this.store.dispatch(loadChecklistPlansSuccess({ payload }));
        this.loadingService.hideLoading();
      }),
      catchError((error) => {
        this.store.dispatch(loadChecklistPlansError({ error }));
        return of(null);
      })
    );
  }
}
