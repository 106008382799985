import { Action, createReducer, on } from '@ngrx/store';
import { setUserConfigs } from './actions';
import { State } from './models';

export const initialState: State = {
  user: {
    id: null,
    name: null,
    email: null,
    acceptTerms: null,
    companyId: null,
    companyName: null,
    userTypeId: null,
    userTypeName: null,
    languageId: null,
    avatarUrl: null,
    forcePasswordChange: null,
    onboarding: null,
    onboardingStep: null,
    onboardingHelper: null,
    ticketsPlatformUrl: null,
    helpcenterPlatformUrl: null,
    isAccountManager: null,
    showSelfTrainingLink: null,
    selfTrainingLink: null,
    createdAt: null,
    quickstartStep: null,
    quickstartCompleted: null,
  },
};

const reducer = createReducer(
  initialState,
  on(setUserConfigs, (state, { user }) => {
    return {
      user,
    };
  })
);

export const userConfigsReducer = function (state: State, action: Action) {
  return reducer(state, action);
};
