<button
  mat-button
  type="button"
  [color]="color"
  [disableRipple]="disableRipple"
  [disabled]="disabled"
  (click)="handleClick($event)"
>
  <ng-content></ng-content>
</button>
