<input
  #textfieldInput
  matInput
  autocomplete="off"
  [placeholder]="placeholder"
  [value]="valueDisplaySubject$ | async"
  [disabled]="disabled"
  [readonly]="readonly"
  [required]="required"
  (keyup)="handleKeyUp($event)"
  (focus)="handleFocus()"
  (change)="handleFocusOut()"
  (focusout)="handleFocusOut()"
  (input)="handleChanges(textfieldInput.value)"
/>
<div class="plus-minus">
  <button
    matRipple
    type="button"
    [disabled]="disabled"
    class="material-icons plus-minus__plus"
    (click)="incrementValue()"
    >arrow_drop_up
  </button>
  <button
    matRipple
    type="button"
    [disabled]="disabled"
    class="material-icons plus-minus__minus"
    (click)="decrementValue()"
    >arrow_drop_down
  </button>
</div>
