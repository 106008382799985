import { Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'table[clUiTable], table[cl-ui-table]',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  constructor() {}

  @HostBinding('class.cl-ui-table') mainClass = true;
  @Input() @HostBinding('class.cl-ui-table--disabled') disabled = false;
}
