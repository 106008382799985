import { Component, Input, EventEmitter, Output } from '@angular/core';
import { LinksListItem } from './types';

@Component({
  selector: 'cl-ui-links-list',
  templateUrl: './links-list.component.html',
  styleUrls: ['./links-list.component.scss'],
})
export class LinksListComponent {
  @Input() items: LinksListItem[] = [];
  @Input() activeItemUrl: string | undefined;
  @Output() itemSelected = new EventEmitter<LinksListItem>();

  handleItemClick(event: MouseEvent, item: LinksListItem) {
    event.stopPropagation();
    event.preventDefault();
    this.itemSelected.emit(item);
  }
}
