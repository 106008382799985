<!--WARNING BAR-->
<div *ngIf="showWarningBarType" class="warning-bar" [ngClass]="type">
  <div class="warning__content">
    <mat-icon class="warning__icon">{{ nameIcon }}</mat-icon>
    <span class="warning__text">{{ text }}</span>
  </div>
</div>

<!--WARNING MODAL-->
<div
  *ngIf="showWarningModalType"
  class="warning-modal"
  [ngClass]="type"
>
  <div class="warning__content">
    <div class="warning__box-icon">
      <mat-icon class="warning__icon">{{ nameIcon }}</mat-icon>
    </div>
    <div class="warning__box-text">
      <span class="warning__title">{{ title }}</span>
      <span class="warning__text">{{ text }}</span>
    </div>
  </div>
</div>

<!--WARNING SECTION-->
<div
  *ngIf="showWarningSectionType"
  class="warning-section"
  [ngClass]="type"
>
  <div class="warning__content">
    <div class="warning__box-icon">
      <mat-icon class="warning__icon">{{ nameIcon }}</mat-icon>
    </div>
    <div class="warning__box-text">
      <span class="warning__title">{{ title }}</span>
      <span class="warning__text">{{ text }}</span>
    </div>
  </div>
</div>
