import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import {
  CdkConnectedOverlay,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';

@Component({
  selector: 'cl-popover, *[cl-popover]',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent {
  constructor(
    private elementRef: ElementRef,
    private scrollStrategies: ScrollStrategyOptions
  ) {}

  showPopover = false;
  @Input() text: string | undefined;
  @Input() enablePopover: boolean | null = true;
  @ViewChild('currentOverlay', { static: true })
  overlayReference: CdkConnectedOverlay | null = null;
  scrollStrategy = this.scrollStrategies.close();

  @HostListener('click')
  open() {
    if (this.enablePopover) {
      this.showPopover = true;
    }
  }

  @HostListener('document:click', ['$event'])
  close(event: MouseEvent) {
    if (!this.showPopover) {
      return;
    }
    const target = event.target as Node;
    if (
      !(this.elementRef.nativeElement as HTMLElement).contains(target) &&
      !(
        this.overlayReference !== null &&
        this.overlayReference.overlayRef !== undefined &&
        this.overlayReference.overlayRef.hostElement.contains(target)
      )
    ) {
      this.showPopover = false;
    }
  }

  handleClose() {
    this.showPopover = false;
  }
}
