<ul class="links-list">
  <li
    *ngFor="let item of items"
    class="links-list__item"
    [class.links-list__item--child]="item.isChild"
    [class.links-list__item--active]="item.url === activeItemUrl"
  >
    <a
      class="links-list__link"
      (click)="handleItemClick($event, item)"
      [matTooltip]="item.name"
      [href]="item.url"
      [attr.alt]="item.name"
      >{{ item.name }}</a
    >
  </li>
</ul>
