import { ComponentHarness } from '@angular/cdk/testing';

export class FileSlideshowHarness extends ComponentHarness {
  static hostSelector = 'cl-ui-file-slideshow';

  protected currentSlideImage = this.locatorFor('.slide .slide__image');

  async getCurrentSlideImage() {
    return await this.currentSlideImage();
  }
}
