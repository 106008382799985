import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { setCompanyConfigs } from '@checklistfacil/shared/data-access/company-configs';
import { LoadingService } from '@checklistfacil/shared/data-access/loading';
import {
  setUserConfigs,
  UserInfo,
} from '@checklistfacil/shared/data-access/user';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import * as appActions from './actions';
import { State as AppState } from './reducers';

@Injectable()
export class ChecklistAppEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private service: AppService,
    private snackbarService: MatSnackBar,
    private loadingService: LoadingService
  ) {}

  appLoadSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appActions.appLoadSuccess),
      map(({ payload }) => {
        const { accesses, companyConfigs, features, company } = payload;
        return setCompanyConfigs({
          accesses,
          configs: companyConfigs,
          features,
          company,
          sienge: payload.sienge ?? null,
        });
      })
    );
  });

  appLoadSuccessUserConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appActions.appLoadSuccess),
      map(({ payload }: { payload: { user: UserInfo } }) => {
        const { user } = payload;
        return setUserConfigs({ user });
      })
    );
  });

  appLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.appLoadError),
        map((action: any) => action.error),
        tap(() => {
          this.snackbarService.open(
            'Falha ao carregar dados do usuário.',
            'Fechar',
            {
              duration: 5000,
            }
          );
        })
      ),
    { dispatch: false }
  );

  appLoadingStatusError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.appLoadError),
        mergeMap(() => {
          this.loadingService.appHasError();
          return EMPTY;
        })
      ),
    { dispatch: false }
  );
}
