import { Inject, Injectable, InjectionToken } from '@angular/core';

export const BROWSER_STORAGE = new InjectionToken<Storage>('localStorage', {
  providedIn: 'root',
  factory: () => window.localStorage,
});

// TODO: remover essa classe em favor da StorageService na lib shared-data-access-storage
@Injectable()
export class StorageService {
  constructor(@Inject(BROWSER_STORAGE) private storage: Storage) {}

  has(key: string) {
    return this.get(key) !== null;
  }

  get(key: string) {
    return this.storage.getItem(key);
  }

  set(key: string, data: any) {
    this.storage.setItem(key, data);
  }

  remove(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}
