import { CompanyData, State as CompanyConfigState } from '../+state/models';
import { featureConfigKey } from '../+state/selectors';
import { initialState as companyConfigInitialState } from '../+state/reducers';

export interface CompanyConfigTestingState {
  [featureConfigKey]: CompanyConfigState;
}

export const companyConfigTestingInitialState = {
  [featureConfigKey]: companyConfigInitialState,
};

export const changeCompanyProperties = (
  state: CompanyConfigTestingState,
  changes: Partial<CompanyData>
) => {
  return {
    ...state,
    [featureConfigKey]: {
      ...state[featureConfigKey],
      company: {
        ...state[featureConfigKey].company,
        ...changes,
      },
    },
  };
};
