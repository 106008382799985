import { createAction, props } from '@ngrx/store';
import {
  BasicEntity,
  ChecklistPlansData,
  CompanyCategorization,
  CompanyCurrency,
  CompanyIdentification,
  CompanyRegisterData,
} from './models';
import {
  CityEntity,
  CountryStateEntity,
} from '@checklistfacil/shared/data-access/country';

export const extendTrial = createAction('[Dialog Extend Trial] extend trial');

export const extendTrialSuccess = createAction(
  '[Dialog Extend Trial] extend trial success',
  props<{ remainingDays: number }>()
);

export const extendTrialError = createAction(
  '[Dialog Extend Trial] extend trial error',
  props<{ error: any }>()
);

export const loadCountries = createAction(
  '[Company Subscription] load countries'
);

export const loadCountriesSuccess = createAction(
  '[Company Subscription] load countries success',
  props<{ payload: BasicEntity[] }>()
);

export const loadCountriesError = createAction(
  '[Company Subscription] load countries error',
  props<{ error: { message: string } }>()
);

export const loadCountryStates = createAction(
  '[Company Subscription] load country states',
  props<{ countryId: number }>()
);

export const loadCountryStatesSuccess = createAction(
  '[Company Subscription] load country states success',
  props<{ payload: CountryStateEntity[] }>()
);

export const loadCountryStatesError = createAction(
  '[Company Subscription] load country states error',
  props<{ error: { message: string } }>()
);

export const loadCities = createAction(
  '[Company Subscription] load cities',
  props<{ countryId: number; stateId: number }>()
);

export const loadCitiesSuccess = createAction(
  '[Company Subscription] load cities success',
  props<{ payload: CityEntity[] }>()
);

export const loadCitiesError = createAction(
  '[Company Subscription] load cities error',
  props<{ error: { message: string } }>()
);

export const loadSegments = createAction(
  '[Company Subscription] load segments'
);

export const loadSegmentsSuccess = createAction(
  '[Company Subscription] load segments success',
  props<{ payload: BasicEntity[] }>()
);

export const loadSegmentsError = createAction(
  '[Company Subscription] load segments error',
  props<{ error: { message: string } }>()
);

export const loadSubSegments = createAction(
  '[Company Subscription] load subsegments',
  props<{ segmentId: any }>()
);

export const loadSubSegmentsSuccess = createAction(
  '[Company Subscription] load subsegments success',
  props<{ payload: BasicEntity[] }>()
);

export const loadSubSegmentsError = createAction(
  '[Company Subscription] load subsegments error',
  props<{ error: { message: string } }>()
);

export const loadBusinessAreas = createAction(
  '[Company Subscription] load business areas'
);

export const loadBusinessAreasSuccess = createAction(
  '[Company Subscription] load business areas success',
  props<{ payload: BasicEntity[] }>()
);

export const loadBusinessAreasError = createAction(
  '[Company Subscription] load business areas error',
  props<{ error: { message: string } }>()
);

export const updateCompanyIdentification = createAction(
  '[Company Subscription] update company identification',
  props<{ data: CompanyIdentification }>()
);

export const updateCompanyCategorization = createAction(
  '[Company Subscription] update company categorization',
  props<{ data: CompanyCategorization }>()
);

export const updateCompanyCurrency = createAction(
  '[Company Subscription] update company categorization',
  props<{ data: CompanyCurrency }>()
);

export const loadCompanyRegister = createAction(
  '[Company Subscription] company load register'
);

export const loadCompanyRegisterSuccess = createAction(
  '[Company Subscription] company load register',
  props<{ payload: CompanyRegisterData }>()
);

export const loadCompanyRegisterError = createAction(
  '[Company Subscription] company load register',
  props<{ error: { message: string } }>()
);

export const updateCompanyRegister = createAction(
  '[Company Subscription] update company register',
  props<{
    categorization: CompanyCategorization;
    identification: CompanyIdentification;
    currency: CompanyCurrency;
  }>()
);

export const saveCompanyRegister = createAction(
  '[Company Subscription] save company register',
  props<{ emailUpdatedMessage: boolean }>()
);

export const saveCompanyRegisterSuccess = createAction(
  '[Company Subscription] save company register success',
  props<{ emailUpdatedMessage: boolean }>()
);

export const saveCompanyRegisterError = createAction(
  '[Company Subscription] save company register error',
  props<{ error: { message: string } }>()
);

export const loadChecklistPlans = createAction(
  '[Company Plans] load company plans'
);

export const loadChecklistPlansSuccess = createAction(
  '[Company Plans] load company plans success',
  props<{ payload: ChecklistPlansData }>()
);

export const loadChecklistPlansError = createAction(
  '[Company Plans] load company plans error',
  props<{ error: { message: string } }>()
);

export const accountPlanUpgrade = createAction(
  '[Company Plans] account plan upgrade',
  props<{ planId: number }>()
);

export const accountPlanUpgradeSuccess = createAction(
  '[Company Plans] account plan upgrade success'
);

export const accountPlanUpgradeError = createAction(
  '[Company Plans] account plan upgrade error',
  props<{ error: { message: string } }>()
);

export const clickResendBillingEmail = createAction(
  '[Company Subscription] click resend billing email'
);

export const clickResendBillingEmailSuccess = createAction(
  '[Company Subscription] click resend billing email success'
);

export const clickResendBillingEmailError = createAction(
  '[Company Subscription] click resend billing email error',
  props<{ error: { message: string } }>()
);

export const selectedCountryId = createAction(
  '[Company Subscription] selected field country',
  props<{ country: number[] }>()
);

export const loadMexicanCfdi = createAction(
  '[Company Subscription] load mexican CFDI'
);

export const loadMexicanCfdiSuccess = createAction(
  '[Company Subscription] load mexican CFDI success',
  props<{ payload: BasicEntity[] }>()
);

export const loadMexicanCfdiError = createAction(
  '[Company Subscription] load mexican CFDI error',
  props<{ error: { message: string } }>()
);

export const loadMexicanPaymentForm = createAction(
  '[Company Subscription] load mexican payment form'
);

export const loadMexicanPaymentFormSuccess = createAction(
  '[Company Subscription] load mexican payment form success',
  props<{ payload: BasicEntity[] }>()
);

export const loadMexicanPaymentFormError = createAction(
  '[Company Subscription] load mexican payment form error',
  props<{ error: { message: string } }>()
);

export const loadMexicanPaymentMethod = createAction(
  '[Company Subscription] load mexican payment method'
);

export const loadMexicanPaymentMethodSuccess = createAction(
  '[Company Subscription] load mexican payment method success',
  props<{ payload: BasicEntity[] }>()
);

export const loadMexicanPaymentMethodError = createAction(
  '[Company Subscription] load mexican payment method error',
  props<{ error: { message: string } }>()
);

export const loadMexicanTaxRegime = createAction(
  '[Company Subscription] load mexican tax regime'
);

export const loadMexicanTaxRegimeSuccess = createAction(
  '[Company Subscription] load mexican tax regime success',
  props<{ payload: BasicEntity[] }>()
);

export const loadMexicanTaxRegimeError = createAction(
  '[Company Subscription] load mexican tax regime error',
  props<{ error: { message: string } }>()
);
