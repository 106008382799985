import { TranslocoConfig, TranslocoMissingHandler } from '@ngneat/transloco';
import * as Sentry from '@sentry/browser';

export class MissingHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    const msg = `Missing translation for '${key}'`;

    Sentry.captureMessage(msg);

    if (config.missingHandler?.logMissingKey && !config.prodMode) {
      // eslint-disable-next-line no-console
      console.warn(`%c ${msg}`, 'font-size: 12px; color: red');
    }

    return key;
  }
}
