<ng-container *ngIf="isImage">
  <img
    class="image-thumbnail"
    [ngStyle]="{ width: thumbSize, height: thumbSize }"
    [src]="imageUrl"
    [alt]="file?.name || ''"
    [matTooltip]="file?.caption ?? ''"
    loading="lazy"
  />
</ng-container>
<ng-container *ngIf="!isImage">
  <div class="file-thumbnail" [matTooltip]="file?.caption ?? ''">
    <mat-icon aria-hidden="false">insert_drive_file</mat-icon>
    <span class="file-thumbnail__text">{{ trueFileType }}</span>
  </div>
</ng-container>
<div
  *ngIf="(hasMoreOptionsControl || forceShowMoreOptions) && !file?.isLoading"
  class="gallery-item-more-options"
  [class.gallery-item-more-options__force-show]="forceShowMoreOptions"
  (click)="handleMoreOptionsGalleryItemClick()"
  [ngStyle]="{ width: thumbSize, height: thumbSize }"
>
  <button type="button" [disabled]="disabled">
    <mat-icon>more_vert</mat-icon>
  </button>
</div>
<div
  *ngIf="file?.isLoading"
  class="gallery-item-loading"
>
  <mat-progress-bar class="gallery-item-loading__progress" mode="indeterminate"></mat-progress-bar>
</div>
<div
  *ngIf="file?.hasError"
  class="gallery-item-has-error"
  [matTooltip]="file?.errorTooltip ?? ''"
>
  <mat-icon fontSet="material-icons-outlined">{{ file?.errorIcon }}</mat-icon>

  <span>{{ file?.errorLabel }}</span>
</div>
