import { createAction, props } from '@ngrx/store';
import {
  CompanyConfig,
  CompanyData,
  CompanyFeatures,
  SiengeIntegrationData,
} from './models';

export const setCompanyConfigs = createAction(
  '[Company configs] set company configs',
  props<{
    configs: CompanyConfig[];
    features: CompanyFeatures;
    accesses: number[];
    company: CompanyData;
    sienge: SiengeIntegrationData;
  }>()
);

export const upgradeTrialToBasicPlan = createAction(
  '[Company configs] trail plan upgraded to basic'
);

export const updateBillingEmailState = createAction(
  '[Company configs] change state of billing email when that is update in form',
  props<{ billingEmailConfirmedChanged: boolean }>()
);

/**
 * @TODO refatorar essa mecânica, para que, ao invés de atualizar
 * o remainingTrialDays, seja feito um reload do /me ou algo do tipo.
 * Pois suspeitamos que bastaria o usuário fazer o dispatch da action,
 * no devtools, para "ganhar" dias de uso, já que a API muito provavelmente
 * não está tratando isso...
 * */
export const updateRemainingTrialDays = createAction(
  '[Company configs] update remaining trial days',
  props<{ remainingTrialDays: number }>()
);

export const updateSiengeIntegrationData = createAction(
  '[Company configs] update sienge integration data',
  props<{ data: SiengeIntegrationData }>()
);
