import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';

@Injectable()
export class ClPaginatorIntl extends MatPaginatorIntl {
  constructor(private translocoService: TranslocoService) {
    super();
    this.translocoService
      .selectTranslate(
        [
          'paginatorIntl.itemsPerPage',
          'paginatorIntl.nextPage',
          'paginatorIntl.previousPage',
          'paginatorIntl.firstPage',
          'paginatorIntl.lastPage',
          'paginatorIntl.outOf',
        ],
        {},
        'sharedUtilTranslate'
      )
      .pipe(
        map(
          ([
            itemsPerPageLabel,
            nextPageLabel,
            previousPageLabel,
            firstPageLabel,
            lastPageLabel,
            outOf,
          ]) => {
            this.itemsPerPageLabel = itemsPerPageLabel;
            this.nextPageLabel = nextPageLabel;
            this.previousPageLabel = previousPageLabel;
            this.firstPageLabel = firstPageLabel;
            this.lastPageLabel = lastPageLabel;
            this.getRangeLabel = this.getRangeLabelFn(outOf);
            this.changes.next();
          }
        )
      )
      .subscribe();
  }

  getRangeLabelFn =
    (outOf: string) =>
    (page: number, pageSize: number, length: number): string => {
      if (length === 0 || pageSize === 0) {
        return `0 ${outOf} ` + length;
      }

      length = Math.max(length, 0);
      const startIndex = page * pageSize;

      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} ${outOf} ${length}`;
    };
}
