<div class="input-field">
  <mat-form-field class="chip-field" appearance="outline">
    <mat-chip-list cdkDropListGroup [disabled]="_disabled">
      <div
        cdkDropList
        *ngFor="let option of selectedChips"
        cdkDropListOrientation="horizontal"
        [cdkDropListData]="option"
        [cdkDropListDisabled]="_disabled"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListEnterPredicate]="
          option.blocked ? preventDropPredicate : dropPredicate
        "
      >
        <mat-chip
          cdkDrag
          [cdkDragDisabled]="option.blocked"
          [selectable]="false"
          [removable]="!option.blocked && !this._disabled"
          (removed)="remove(option)"
        >
          {{ terms ? terms[option.token] : '' }}
          <mat-icon
            *ngIf="option.blocked; else chipRemoveIcon"
            fontSet="material-icons-outlined"
            class="lock-icon"
            >lock</mat-icon
          >
          <ng-template #chipRemoveIcon
            ><mat-icon matChipRemove fontSet="material-icons-outlined"
              >cancel</mat-icon
            ></ng-template
          >
        </mat-chip>
      </div>
    </mat-chip-list>
  </mat-form-field>
  <div class="input-extension" [class.disabled]="_disabled" *ngIf="extension">{{
    extension
  }}</div>
</div>
<mat-chip-list [disabled]="_disabled">
  <ng-container *ngFor="let option of _value">
    <mat-chip
      *ngIf="!option.blocked"
      [selectable]="!_disabled"
      [selected]="option.selected"
      [removable]="false"
      (click)="option.selected ? remove(option) : add(option)"
    >
      {{ terms ? terms[option.token] : '' }}
    </mat-chip>
  </ng-container>
</mat-chip-list>
