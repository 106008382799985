import { createAction, props } from '@ngrx/store';

export const appLoad = createAction('[Root] App load');

export const appLoadSuccess = createAction(
  '[Root] App load success',
  props<{ payload: any }>()
);

export const appLoadError = createAction(
  '[Root] App load error',
  props<{ error: any }>()
);

export const loadingShow = createAction('[App] loading show');

export const loadingHide = createAction('[App] loading hide');

export const appHasError = createAction('[App] has error');
