<div
  class="menu-button"
  [ngClass]="{
    'menu-button--only-icon': onlyIcon,
    'new-apply-button': newApplyButton
  }"
  (click)="onClick()"
  [matRippleCentered]="true"
  matRipple
>
  <div
    class="menu-button__icon"
    [ngClass]="{ 'new-apply-button__icon': newApplyButton }"
  >
    <ng-container *ngIf="newApplyButton; else oldVersion">
      <mat-icon fontSet="material-icons-outlined">play_arrow</mat-icon>
    </ng-container>
    <ng-template #oldVersion>
      <svg
        id="Icon_Checklist"
        data-name="Icon / Checklist"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <rect id="Background" width="32" height="32" fill="none" />
        <path
          id="Foreground"
          d="M1053.094-331.338l-.26-.253-.013-.012-2.055-2-.652-.633,0,0-6.677-6.483a1.169,1.169,0,0,1,0-1.688l2.434-2.364a1.256,1.256,0,0,1,1.739,0l6.086,5.909,10.707-10.4a.861.861,0,0,1,1.192,0,4.811,4.811,0,0,1,0,6.946L1057-333.971l0,0-2.435,2.364h0l-.273.265a.854.854,0,0,1-.6.24A.854.854,0,0,1,1053.094-331.338Z"
          transform="translate(-1039.071 356.698)"
          fill="#fff"
        />
      </svg>
    </ng-template>
  </div>

  <div
    class="menu-button__text"
    [ngClass]="{
      hide: menuCollapsed,
      'new-apply-button__text': newApplyButton
    }"
  >
    {{ buttonText }}
  </div>
</div>
