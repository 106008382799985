<div class="header">
  <div class="header__actions">
    <a
      *ngIf="showDownload; else viewNewTab"
      (click)="downloadFile($event)"
      class="material-icons"
      >download</a
    >

    <ng-template #viewNewTab>
      <a (click)="openImageInNewTab($event)" class="material-icons">open_in_new</a>
    </ng-template>

    <a (click)="closeEvent($event)" class="material-icons" href="#">close</a>
  </div>
</div>
<div class="body">
  <div *ngIf="currentSlide" class="slide">
    <img
      *ngIf="fileIsImage(currentSlide); else fileSlide"
      [src]="currentSlideImageUrl"
      class="slide__image"
    />
    <ng-template #fileSlide>
      <div class="slide__file">
        <div class="slide__file-icon">
          <mat-icon>insert_drive_file</mat-icon>
          <span>{{ currentSlide.type }}</span>
        </div>
        <div class="slide__file-name">{{ fileNameFrom(currentSlide) }}</div>
      </div>
    </ng-template>
  </div>
</div>
<a (click)="slideToLeft()" *ngIf="hasPrevButton" class="button button--left">
  <span class="material-icons icon">arrow_back</span>
</a>
<a (click)="slideToRight()" *ngIf="hasNextButton" class="button button--right">
  <span class="material-icons icon">arrow_forward</span>
</a>

<div class="footer">
  <div class="thumbnails-container">
    <cl-ui-file-gallery-item
      (fileClicked)="handleThumbnailClicked(i)"
      *ngFor="let slide of slides; index as i; trackBy: slideTrackBy"
      [file]="slide"
      [hasMoreOptionsControl]="false"
      class="thumbnail"
      thumbSize="65px"
    ></cl-ui-file-gallery-item>
  </div>
</div>
