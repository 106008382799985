import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class AppReleasesWatcherService {
  constructor(private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      swUpdate.available.subscribe(() => {
        if (
          confirm(
            'Nova versão da aplicação disponível. Recarregue antes de continuar.'
          )
        ) {
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        }
      });
    }
  }
}
