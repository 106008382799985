import { ComponentHarness } from '@angular/cdk/testing';

export class AchievementsIconsHarness extends ComponentHarness {
  static hostSelector = 'cl-achievements-icons';

  protected getAchievementIcon = this.locatorForOptional('.icon');
  protected getAchievementTrophyIcon = this.locatorForOptional('.icon-award');
  protected getAchievementCheckIcon = this.locatorForOptional('.icon-check');

  async achievementIconActive() {
    const achievementIcon = await this.getAchievementIcon();
    return achievementIcon?.hasClass('icon--active');
  }

  async achievementIconTrophyActive() {
    const achievementTrophyIcon = await this.getAchievementTrophyIcon();
    return achievementTrophyIcon?.hasClass('icon-award-active');
  }

  async achievementIconCheckActive() {
    const achievementCheckIcon = await this.getAchievementCheckIcon();
    return achievementCheckIcon?.hasClass('icon--active');
  }
}
