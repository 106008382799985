import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedUtilTranslateModule } from '@checklistfacil/shared/util/translate';
import { ColorChromeModule } from 'ngx-color/chrome';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BorderlessTextareaComponent } from './borderless-textarea/borderless-textarea.component';
import { BorderlessTextfieldComponent } from './borderless-textfield/borderless-textfield.component';
import { CheckboxComponent } from './checkbox';
import { ChipInputComponent } from './chip-input/chip-input.component';
import { FileTextfieldComponent } from './file-textfield/file-textfield.component';
import { RangeInputComponent } from './range-input/range-input.component';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';
import { SelectComponent } from './select';
import { SwitchComponent } from './switch/switch.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TextfieldMiniComponent } from './textfield-mini/textfield-mini.component';
import { InputWithSymbolComponent } from './textfield-symbol/input-with-symbol/input-with-symbol.component';
import { TextfieldSymbolComponent } from './textfield-symbol/textfield-symbol.component';
import { TextfieldComponent } from './textfield';
import { PhoneInputComponent } from './phone-input/phone-input.component';

@NgModule({
  declarations: [
    BorderlessTextareaComponent,
    BorderlessTextfieldComponent,
    CheckboxComponent,
    FileTextfieldComponent,
    InputWithSymbolComponent,
    SelectComponent,
    SwitchComponent,
    TextareaComponent,
    TextfieldComponent,
    TextfieldMiniComponent,
    TextfieldSymbolComponent,
    ChipInputComponent,
    SelectMultipleComponent,
    RangeInputComponent,
    PhoneInputComponent,
  ],
  exports: [
    BorderlessTextareaComponent,
    BorderlessTextfieldComponent,
    CheckboxComponent,
    FileTextfieldComponent,
    SelectComponent,
    SwitchComponent,
    TextareaComponent,
    TextfieldComponent,
    TextfieldMiniComponent,
    TextfieldSymbolComponent,
    SelectMultipleComponent,
    RangeInputComponent,
    ChipInputComponent,
    PhoneInputComponent,
    NgxMatSelectSearchModule,
    MatSelectModule,
  ],
  imports: [
    CommonModule,
    ColorChromeModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    OverlayModule,
    PortalModule,
    ReactiveFormsModule,
    ScrollingModule,
    DragDropModule,
    SharedUtilTranslateModule,
  ],
})
export class ClFormModule {}
