import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogInvalidFilesData, InvalidFile, InvalidFileError } from './types';
import { translocoLoader } from '@checklistfacil/shared/util/translate';

@Component({
  selector: 'cl-ui-dialog-invalid-files',
  templateUrl: './dialog-invalid-files.component.html',
  styleUrls: ['./dialog-invalid-files.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiFileUploadGalleryInvalidFilesDialog'
    ),
  ],
})
export class DialogInvalidFilesComponent {
  invalidFiles: InvalidFile[];
  allowedTypes = '';
  allowedMaxSize: number;
  allowedImageDimension: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: DialogInvalidFilesData,
    public dialogRef: MatDialogRef<DialogInvalidFilesComponent>
  ) {
    this.invalidFiles = data.files;
    this.allowedMaxSize = Math.floor((data.allowedMaxSize || 0) / 1024 / 1024);
    this.allowedTypes = data.allowedTypes?.join(', ') || '';
    this.allowedImageDimension = data.allowedImageDimension || '';
  }

  get invalidFilesBySize() {
    return this.invalidFiles
      ? this.invalidFiles.filter((f) => f.error === InvalidFileError.size)
      : [];
  }

  get invalidFilesByType() {
    return this.invalidFiles
      ? this.invalidFiles.filter((f) => f.error === InvalidFileError.type)
      : [];
  }

  get invalidFilesByDimension() {
    return this.invalidFiles
      ? this.invalidFiles.filter((f) => f.error === InvalidFileError.dimension)
      : [];
  }

  confirm() {
    this.dialogRef.close();
  }

  shorten(phrase = ''): string {
    return phrase.length < 45 ? phrase : `${phrase.slice(0, 45)}...`;
  }
}
