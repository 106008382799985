<mat-checkbox
  [checked]="checked"
  [color]="color"
  [disabled]="disabled"
  [indeterminate]="indeterminate"
  [labelPosition]="labelPosition"
  [required]="required"
  (change)="change($event)"
  #component
  >{{ label }}</mat-checkbox
>
