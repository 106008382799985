import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-ui-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss'],
})
export class ReportHeaderComponent {
  @Input() title: string | undefined;
}
