import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { addSkipAuthenticationHeader } from '@checklistfacil/shared/util/general';
import { FeatureDefinition, GrowthBook } from '@growthbook/growthbook';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { FeatureValType } from './types';

const DEFAULT_FEATURES_ENDPOINT = 'https://cdn.growthbook.io/api/features';
export const GROWTHBOOK_CONFIG = new InjectionToken<GrowthBookConfig>(
  'growthbook-singleton GROWTHBOOK_CONFIG'
);

export interface GrowthBookFeatures {
  status: number;
  features: { [key: string]: FeatureDefinition };
}
export interface GrowthBookConfig {
  apiKey: string;
  featuresEndpoint?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GrowthBookService {
  static growthBookSingleton$ = new ReplaySubject<GrowthBook>(1);
  static updateCount$ = new BehaviorSubject<number>(0);

  constructor(
    @Inject(GROWTHBOOK_CONFIG) private config: GrowthBookConfig,
    private http: HttpClient
  ) {}

  updateApiConfiguration(): Observable<GrowthBook> {
    const growthBook = new GrowthBook();

    this.http
      .get<GrowthBookFeatures>(this.makeEndpointWithKey(this.config), {
        headers: addSkipAuthenticationHeader(),
      })
      .pipe(
        map((response) => {
          growthBook.setFeatures(response.features);
        }),
        catchError((err) => of(console.error(err)))
      )
      .subscribe(() => {
        GrowthBookService.growthBookSingleton$.next(growthBook);
      });

    return GrowthBookService.growthBookSingleton$.asObservable();
  }

  setAttributes(attrs: Record<string, FeatureValType>): void {
    GrowthBookService.growthBookSingleton$
      .pipe(first())
      .subscribe((growthBook: GrowthBook) => {
        growthBook.setAttributes(attrs);
        this.triggerUpdate();
      });
  }

  evalFeature<T extends FeatureValType>(
    featureKey: string
  ): Observable<T | null> {
    return combineLatest([
      GrowthBookService.growthBookSingleton$,
      GrowthBookService.updateCount$,
    ]).pipe(
      map(([growthBook]) => growthBook.evalFeature<T>(featureKey)),
      map((evalResult) => evalResult.value)
    );
  }

  featureIsOn(featureKey: string): Observable<boolean> {
    return combineLatest([
      GrowthBookService.growthBookSingleton$,
      GrowthBookService.updateCount$,
    ]).pipe(map(([growthBook]) => growthBook.isOn(featureKey)));
  }

  featureIsOff(featureKey: string): Observable<boolean> {
    return combineLatest([
      GrowthBookService.growthBookSingleton$,
      GrowthBookService.updateCount$,
    ]).pipe(map(([growthBook]) => growthBook.isOff(featureKey)));
  }

  featureValue(
    featureKey: string,
    defaultValue: string | number
  ): Observable<number | string> {
    return combineLatest([
      GrowthBookService.growthBookSingleton$,
      GrowthBookService.updateCount$,
    ]).pipe(
      map(([growthBook]) =>
        growthBook.getFeatureValue(featureKey, defaultValue)
      )
    );
  }

  triggerUpdate(): void {
    GrowthBookService.updateCount$.next(
      GrowthBookService.updateCount$.value + 1
    );
  }

  private makeEndpointWithKey(config: GrowthBookConfig): string {
    let endpoint = '';
    if (typeof config.featuresEndpoint === 'string') {
      endpoint = `${config.featuresEndpoint}/${config.apiKey}`;
    } else {
      endpoint = `${DEFAULT_FEATURES_ENDPOINT}/${config.apiKey}`;
    }

    return endpoint;
  }
}
