import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';
import {
  ApiListParameters,
  ApiResponse,
  BasicEntity,
  HttpClientParams,
  PaginatedPayload,
} from '@checklistfacil/shared/util/general';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyRegistrationData } from './+state/models';
import { endpoints } from './endpoints';

@Injectable({
  providedIn: 'root',
})
export class CompanyRegistrationService {
  private appConfig: Configuration | undefined;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    configService: ConfigAssetLoaderService
  ) {
    configService
      .loadConfigurations()
      .subscribe((config) => (this.appConfig = config));
  }

  emailAlreadyRegistered(email: string): Observable<ApiResponse<undefined>> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http.post<ApiResponse<undefined>>(
      endpoints.verifyEmail(this.appConfig),
      { email }
    );
  }

  companyAlreadyRegistered(name: string): Observable<ApiResponse<undefined>> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http.post<ApiResponse<undefined>>(
      endpoints.verifyName(this.appConfig),
      { name }
    );
  }

  listCountries(params?: ApiListParameters): Observable<BasicEntity[]> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http
      .get<ApiResponse<PaginatedPayload<BasicEntity[]>>>(
        endpoints.listCountries(this.appConfig),
        {
          params: params as HttpClientParams,
        }
      )
      .pipe(map((response) => response.payload.items));
  }

  listStates(
    countryId: number,
    params?: ApiListParameters
  ): Observable<BasicEntity[]> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http
      .get<ApiResponse<PaginatedPayload<BasicEntity[]>>>(
        endpoints.listStates(this.appConfig, countryId),
        {
          params: params as HttpClientParams,
        }
      )
      .pipe(map((response) => response.payload.items));
  }

  listCities(
    countryId: number,
    stateId: number,
    params?: ApiListParameters
  ): Observable<PaginatedPayload<BasicEntity[]>> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http
      .get<ApiResponse<PaginatedPayload<BasicEntity[]>>>(
        endpoints.listCities(this.appConfig, countryId, stateId),
        {
          params: params as HttpClientParams,
        }
      )
      .pipe(map((response) => response.payload));
  }

  listCountryCodes(): Observable<
    { id: string; name: string; active: boolean }[]
  > {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http
      .get<ApiResponse<string[]>>(endpoints.listCountryCodes(this.appConfig))
      .pipe(
        map((response) => response.payload),
        map((countryCodeArray) =>
          countryCodeArray.map(
            (
              code: string
            ): {
              id: string;
              name: string;
              active: boolean;
            } => ({
              id: code,
              name: code,
              active: true,
            })
          )
        )
      );
  }

  listSegments(): Observable<BasicEntity[]> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http
      .get<ApiResponse<BasicEntity[]>>(endpoints.listSegments(this.appConfig))
      .pipe(map((response) => response.payload));
  }

  listEmployeeRanges(): Observable<BasicEntity[]> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http
      .get<ApiResponse<{ id: number; range: string }[]>>(
        endpoints.listEmployeeRanges(this.appConfig)
      )
      .pipe(
        map((response) => response.payload),
        map((ranges: { id: number; range: string }[]) =>
          ranges.map(
            (range: { id: number; range: string }): BasicEntity => ({
              id: range.id,
              name: range.range,
              active: true,
            })
          )
        )
      );
  }

  registerCompany(company: CompanyRegistrationData) {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    const payload = company;

    if (typeof payload.phone !== 'string' || payload.phone.length < 1) {
      delete payload.phone;
    }

    return this.http.post(endpoints.registerCompany(this.appConfig), {
      ...payload,
      ...this.getCookiesParams(),
    });
  }

  getCookiesParams() {
    const getCookieFromParamsUrl = this.cookieService.get('__utmParamsUrl');
    const getCookieFromRD = this.cookieService.get('__utmzz');

    if (getCookieFromParamsUrl) {
      return this.getTrackingCookieParams(getCookieFromParamsUrl);
    }

    if (getCookieFromRD) {
      return this.getTrackingCookieParams(getCookieFromRD);
    }

    return {};
  }

  getTrackingCookieParams(nameCookie: string) {
    const [trafficSource, trafficMedium, trafficCampaign] = nameCookie
      .split('|')
      .map((e) => e.split('=')[1]);

    // trafficSource = utmcsr
    // trafficMedium = utmcmd
    // trafficCampaign = utmccn

    return {
      trafficSource,
      trafficMedium,
      trafficCampaign,
    };
  }
}
