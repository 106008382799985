import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  translocoConfig,
  TranslocoModule,
  TranslocoTestingModule,
  TranslocoTestingOptions,
} from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { availableLangs } from './+state/translate.models';
import { MissingHandler } from './missing-handler/missing-handler';
import { StorageService } from './storage.service';
import { TranslocoHttpLoader } from './transloco-http-loader.service';

@NgModule({
  exports: [TranslocoModule],
  imports: [
    CommonModule,
    TranslocoModule,
    TranslocoMessageFormatModule.init({
      locales: availableLangs,
    }),
  ],
  providers: [
    StorageService,
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: MissingHandler,
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        defaultLang: 'pt-br',
        fallbackLang: 'pt-br',
        reRenderOnLangChange: true,
        prodMode: false,
        availableLangs,
        // flatten: {
        //   aot: true,
        // },
        missingHandler: {
          logMissingKey: true,
        },
      }),
      multi: false,
    },
  ],
})
export class SharedUtilTranslateModule {
  /**
   * Returns TranslocoTestingModule with some preconfigured options to facilitate the use with
   * unit tests and component stories.
   *
   * @usageNotes
   * ### Example
   * ```
   * import ptBr from '../i18n/pt-br.json.json';
   * import theScope from '../i18n/theScope/pt-br.json.json';
   *
   * export default {
   *   title: 'Some Component',
   *   decorators: [
   *     moduleMetadata({
   *       declarations: [SomeComponent],
   *       imports: [
   *         SharedUtilTranslateModule.getTranslocoTestingModule({
   *           langs: {
   *             'pt-br.json': ptBr,
   *             'theScope/pt-br.json': clForm,
   *           },
   *         }),
   *         TranslocoMessageFormatModule.init()
   *       ],
   *     }),
   *   ],
   * };
   * ```
   */
  static getTranslocoTestingModule(
    options: TranslocoTestingOptions = {}
  ): ModuleWithProviders<TranslocoTestingModule> {
    return TranslocoTestingModule.forRoot({
      translocoConfig: {
        availableLangs: ['pt-br'],
        defaultLang: 'pt-br',
      },
      preloadLangs: true,
      ...options,
    });
  }
}
