const translatedMessage = (): string => {
  if (/^en\b/.test(navigator.language)) {
    return 'New version available. Load New Version?';
  }
  if (/^es\b/.test(navigator.language)) {
    return 'Nueva versión disponible. ¿Cargar la nueva versión?';
  }
  return 'Nova versão disponível. Carregar nova versão?';
};

export const chunkLoadingErrorHandler = (
  error: unknown,
  defaultExtractor: (error: unknown) => unknown
) => {
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;

  if (chunkFailedMessage.test((error as { message: string })?.message)) {
    if (confirm(translatedMessage())) {
      window.location.reload();
      return;
    }
  }

  defaultExtractor(error);
};
