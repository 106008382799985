export interface JivoAPI {
  open: (options?: Record<string, any>) => Record<string, any>;
  close: () => Record<string, any>;
  getContactInfo: () => Record<string, any>;
  setContactInfo: (info: JivoContactInfo) => Record<string, any>;
  setUserToken: (token: string) => Record<string, any>;
}

export interface JivoContactInfo {
  name: string;
  email: string;
  phone: string;
  description: string;
}

export interface JivoUserInfo extends JivoContactInfo {
  id: number;
}
