import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';
import { OptionListItem } from './types';

@Component({
  selector: 'cl-ui-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss'],
})
export class OptionListComponent {
  @Input() items: OptionListItem[] = [];

  @Input() showLoadMore = false;
  @Input() showLoading = false;
  @Input() showLoadError = false;
  @Input() hasSelection = false;
  @Input() multiselect = false;
  @Input() hasSuffixIcon = false;
  @Input() suffixIcon = '';

  @Input() loadErrorTerm: string | undefined;
  @Input() loadMoreTerm: string | undefined;

  @Output() itemClicked = new EventEmitter<OptionListItem>();
  @Output() itemsChange = new EventEmitter<OptionListItem[]>();
  @Output() loadMoreClicked = new EventEmitter();
  @Output() suffixIconClicked = new EventEmitter<OptionListItem>();

  @Input() optionNameTemplate: TemplateRef<any> | undefined;

  handleLoadMoreClick() {
    this.loadMoreClicked.emit();
  }

  handleItemClick(checked: boolean, item: OptionListItem) {
    if (!this.hasSelection) {
      this.itemClicked.emit(item);
      return;
    }

    if (this.multiselect) {
      const temp = this.items.map((i) => ({
        ...i,
        checked: i.value === item.value ? checked : i.checked,
      }));

      this.itemsChange.emit(temp);
      return;
    }

    const items = this.items.map((i) => ({
      ...i,
      checked: i.value === item.value,
    }));

    this.itemsChange.emit(items);
  }

  trackByValue(index: number, item: OptionListItem): number {
    return item.value;
  }

  handleSuffixIconClick(event: MouseEvent, item: OptionListItem) {
    event.stopPropagation();
    this.suffixIconClicked.emit(item);
  }
}
