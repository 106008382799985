<div
  class="area-header"
  *transloco="let t; read: 'clUiAreaHeader'"
  [ngClass]="{
    'qa-container-area-header': !isSubArea,
    'qa-container-subarea-header subarea': isSubArea
  }"
>
  <div class="area-label">
    <button
      *ngIf="showCollapse"
      class="fold-button"
      mat-icon-button
      aria-label="Fold/Unfold area"
      (click)="foldUnfoldClick($event, areaTitleSuffix)"
      matTooltipPosition="above"
      [matTooltip]="!collapsed ? t('collapse') : t('expand')"
    >
      <mat-icon *ngIf="!collapsed">unfold_less</mat-icon>
      <mat-icon *ngIf="collapsed">unfold_more</mat-icon>
    </button>
    <span class="area-label__text"
      >{{ isSubArea ? t('subareaLabel') : t('label') }}
      {{ areaTitleSuffix }}</span
    >
  </div>
  <div class="header-actions">
    <button
      *ngIf="!viewMode"
      class="new-item-button"
      mat-button
      color="primary"
      [disabled]="newItemDisabled"
      (click)="newItemClick($event)"
      >{{ t('newItem') }}</button
    >
    <button
      *ngIf="!viewMode && newSubAreaAvailable"
      class="new-subarea-button"
      mat-button
      color="primary"
      [disabled]="newSubAreaDisabled"
      (click)="newSubAreaClick($event)"
      >{{ t('newSubarea') }}</button
    >
    <button
      *ngIf="!viewMode"
      class="qa-more-btn qa-area-more-btn"
      [matMenuTriggerFor]="areaHeaderDropdown"
      mat-icon-button
      aria-label="More options"
      (click)="moreOptionsButtonClick($event)"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu class="menu-account-options qa-area-menu" #areaHeaderDropdown>
      <button
        *ngIf="hasImportItems"
        class="import-items-btn"
        (click)="optionClick($event, 'import-items')"
        type="button"
        mat-menu-item
        >{{ t('actionImportItems') }}</button
      >
      <button
        class="move-btn"
        (click)="optionClick($event, 'move')"
        type="button"
        mat-menu-item
        >{{ t('actionMove') }}</button
      >
      <div class="delete-button-wrapper">
        <span
          *ngIf="deleteDisabled"
          class="delete-button-tooltip"
          [matTooltip]="deleteDisabledMessage"
        ></span>
        <button
          mat-menu-item
          class="delete-btn"
          [disabled]="deleteDisabled"
          (click)="optionClick($event, 'delete')"
          type="button"
          >{{ t('actionDelete') }}</button
        >
      </div>
      <mat-divider *ngIf="!isSubArea && hasActionPlan"></mat-divider>
      <button
        *ngIf="!isSubArea && hasActionPlan"
        class="action-plan-btn"
        (click)="optionClick($event, 'actionPlan')"
        type="button"
        mat-menu-item
        >{{ t('actionActionPlan') }}</button
      >
    </mat-menu>
  </div>
</div>
