import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Configuration } from './types';

/**
 * @deprecated
 * Utilizar a shared/data-access/environments os seletores específicos para
 * as configs de ambiente.
 **/
@Injectable({
  providedIn: 'root',
})
export class ConfigAssetLoaderService {
  private readonly CONFIG_URL = 'assets/config/config.json';
  private configuration$: Observable<Configuration> | undefined;

  constructor(private http: HttpClient) {}

  public loadConfigurations(): Observable<Configuration> {
    if (!this.configuration$) {
      this.configuration$ = this.http
        .get<Configuration>(this.CONFIG_URL)
        .pipe(shareReplay(1));
    }
    return this.configuration$;
  }
}
