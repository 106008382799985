<div class="info">
  <div class="title" [ngClass]="{ 'title--disabled': disabled }">
    {{ title }}
    <cl-popover *ngIf="hasPopover">
      <mat-icon [inline]="true">help_outline</mat-icon>
      <ng-container popoverContent>
        <ng-content select="[popoverContent]"></ng-content>
      </ng-container>
    </cl-popover>
  </div>
  <div *ngIf="description || hasDescriptionProjection" class="description">
    {{ description }}
    <ng-content select="[descriptionTemplate]"></ng-content>
  </div>
</div>
<div class="switch" *ngIf="showSwitch">
  <cl-form-switch
    labelPosition="after"
    [isChecked]="checked"
    [isDisabled]="disabled"
    (changed)="switchChanged($event)"
  ></cl-form-switch>
</div>
