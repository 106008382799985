<div mat-dialog-title>
  <mat-icon color="primary" fontSet="material-icons-outlined">{{
    data?.icon
  }}</mat-icon>
  <span class="dialog-title">{{ data?.title }}</span>
</div>
<mat-dialog-content>
  <span class="message">{{ data?.message }}</span>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="primary">{{
    data?.close
  }}</button>
</mat-dialog-actions>
