import { AbstractControl } from '@angular/forms';

export function multipleEmailsValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  if (!control.value) {
    return null;
  }
  // eslint-disable-next-line max-len
  const rx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emails = control.value.replace(/,/gi, ';').split(';');

  const valid = emails.every((e: string) => rx.test(String(e).trim()));
  return valid ? null : { containsInvalidEmail: { value: control.value } };
}
