import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  companyHasNewApplyButton,
  enableAcessarCentralDeAjuda,
  enableConfigurarJanelaNotifcacoesChecklist,
} from '@checklistfacil/shared/data-access/company-configs';
import {
  MenuFooterInfo,
  MenuHelpInfo,
  MenuListItem,
  UserCardInfo,
} from '@checklistfacil/shared/main-menu/ui';
import { AchievementIcon } from '@checklistfacil/shared/user-achievements/util';
import {
  JivoUserInfo,
  TrackingService,
} from '@checklistfacil/shared/util/tracking';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AccountMenuInfo } from './types';

@Component({
  selector: 'cl-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'menuComponent'
    ),
  ],
})
export class MenuComponent {
  achievementIcon = AchievementIcon;
  footerHiddenIcons$ = this.store.pipe(
    select(enableAcessarCentralDeAjuda),
    map((enabled) => {
      return enabled ? [] : ['helper-center'];
    })
  );

  newApplyButton$ = this.store.pipe(select(companyHasNewApplyButton));

  notificationCompanyConfigEnable$ = this.store.pipe(
    select(enableConfigurarJanelaNotifcacoesChecklist)
  );

  // TODO Pegar da Store
  // Agora que é uma feature, verificar o que pode ser recuperado diretamente da store
  // e reduzir o número de inputs

  @Input() companyLogo: string | undefined;
  @Input() dashboardUrl: string | undefined;
  @Input() userInfo: UserCardInfo | null = {
    name: 'Example User',
    type: 'Example Type',
  };
  @Input() userHasAccessToAchievements = false;
  @Input() userInfoAchievementTrophy: AchievementIcon | null =
    this.achievementIcon.BronzeTrophy;
  @Input() items: MenuListItem[] = [];
  @Input() footerItem: MenuFooterInfo = {};
  @Input() isAccountManager = false;
  @Input() accountMenuItem: AccountMenuInfo = {};
  @Input() accountMenuHiddenItems: string[] = [];
  @Input() @HostBinding('class.menu--collapsed') menuIsCollapsed = false;
  @Input() showMainButton = true;
  @Input() isTrial = false;
  @Input() trialDays: number | undefined;
  @Input() menuHelpInfo: MenuHelpInfo | undefined;
  @Input() companyHasContactInformation = false;
  @Input() jivoChatUrl = '';
  @Input() jivoUserInfo: JivoUserInfo | undefined;

  @Output() menuStateToggled = new EventEmitter<boolean>();
  @Output() menuItemClicked = new EventEmitter();
  @Output() applyChecklistClicked = new EventEmitter();
  @Output() accountSubscriptionSettingsClicked = new EventEmitter();
  @Output() upgradeTrialAccount = new EventEmitter();
  @Output() upgradeIconClicked = new EventEmitter<MenuListItem>();

  @ViewChild('menuHelpTrigger', { static: false })
  menuHelpTrigger: MatMenuTrigger | undefined;

  constructor(private trackingService: TrackingService, private store: Store) {}

  handleMenuStateToggle() {
    this.menuStateToggled.emit(!this.menuIsCollapsed);
  }

  handleMenuListItemClicked(item: MenuListItem) {
    this.menuItemClicked.emit(item);
  }

  handleApplyChecklistClick() {
    this.applyChecklistClicked.emit();
  }

  helpCenterClickAction(event: Event) {
    event.preventDefault();
    this.menuHelpTrigger?.openMenu();
  }

  get hasHelpCenterItem() {
    return this.accountMenuHiddenItems
      ? this.accountMenuHiddenItems.indexOf('helper-center') === -1
      : false;
  }

  get trialDaysString(): string | null {
    if (this.trialDays === undefined) {
      return null;
    }

    if (this.trialDays > 9 || this.trialDays < 0) {
      return String(this.trialDays);
    } else {
      return '0' + String(this.trialDays);
    }
  }

  handleHelpCenterClick(href: string | undefined) {
    if (href) {
      this.trackingService.trackLinkClick(href, 'central_ajuda_dropdown');
    }
  }

  handleAccountSubscriptionSettingsClick(evt: Event) {
    evt.preventDefault();
    this.accountSubscriptionSettingsClicked.emit();
  }

  handleUpgradeButtonClick() {
    this.upgradeTrialAccount.emit();
    this.trackingService.trackLinkClick('/', 'contratacao_botao_menu_upgrade');
  }

  handleUpgradeIconClicked(item: MenuListItem) {
    this.upgradeIconClicked.emit(item);
  }

  handleMyAchievementsClick(href: string | undefined) {
    if (href) {
      this.trackingService.trackLinkClick(
        href,
        'spa_minhas_conquistas_menu_usuario'
      );
    }
  }
}
