export interface State {
  user: UserInfo;
}

export interface UserInfo {
  id: number | null;
  name: string | null;
  email: string | null;
  acceptTerms: boolean | null;
  companyId: number | null;
  companyName: string | null;
  userTypeId: number | null;
  userTypeName: string | null;
  languageId: any | null;
  avatarUrl: string | null;
  forcePasswordChange: boolean | null;
  onboarding: number | null;
  onboardingStep: number | null;
  onboardingHelper: boolean | null;
  ticketsPlatformUrl: string | null;
  helpcenterPlatformUrl: string | null;
  showSelfTrainingLink: boolean | null;
  selfTrainingLink: string | null;
  isAccountManager: boolean | null;
  createdAt: string | null;
  quickstartStep: QuickstartStep | null;
  quickstartCompleted: boolean | null;
}

export enum QuickstartStep {
  Survey = 1,
  Unit = 2,
  Checklist = 3,
  Completed = 4,
}

export enum UserPermissions {
  /**
   * É administrador geral do sistema (com permissões completas)?
   */
  Admin = 1,
  /**
   * Tem acesso bloqueado de acordo com o horário?
   */
  BlockedByTime = 24,
  /**
   * Visualiza checklist não concluídos de outros usuários?
   */
  ViewUncompletedChecklistsOtherUsers = 27,
  /**
   * Aprova agendamentos?
   */
  ApproveSchedules = 28,
  /**
   * Tem o acesso expirado após ficar sem acessar o sistema pelo período configurado.
   */
  ExpirationOdInactivity = 35,
  /**
   * Visualiza agendamentos de outros usuários?
   */
  ViewSchedulesOtherUsers = 34,
  /**
   * Pode reabrir checklist concluído?
   */
  ReopenCompletedChecklist = 16,
  /**
   * Pode aprovar checklist (o checklist precisará ser aprovado antes de ser exibido nos relatórios)?
   */
  ApproveChecklist = 15,
  /**
   * Pode comentar checklist concluído?
   */
  CommentCompleteChecklist = 3,
  /**
   * Pode aplicar checklist?
   */
  ApplyChecklist = 2,
  /**
   * Pode aplicar checklist sem iniciar a rota
   */
  ApplyChecklistWithoutRoute = 41,
  /**
   * Pode finalizar despesa de reembolso
   */
  CanFinishRefundExpense = 51,
  /**
   * Pode configurar suas próprias ausências temporárias?
   */
  TemporaryAbsences = 46,
  /**
   * Pode configurar ausências temporárias de outros usuários
   */
  TemporaryAbsencesOtherUsers = 47,
  /**
   * Pode contestar item
   */
  CanDisputeChecklistItems = 53,
  /**
   * Pode aprovar contestação
   */
  CanAnalyseChecklistItemsContestation = 54,
  /**
   * Pode excluir Avaliações?
   */
  DeleteEvaluation = 63,
  /**
   * Pode editar Avaliações concluídas?
   */
  EditCompletedEvaluation = 61,
  ViewEvaluationAppliedByOtherUsers = 99, //
  /**
   * Pode excluir Agendamentos?
   */
  DeleteSchedules = 66,
  /**
   * Pode cancelar Agendamentos?
   */
  CancelSchedules = 67,
  /**
   * Pode configurar "Unidades"?
   */
  SettingsUnits = 8,
  /**
   * Pode configurar "Departamentos"?
   */
  SettingsDepartments = 9,
  /**
   * Pode configurar "Tipos de Usuários"?
   */
  SettingsUserRoles = 10,
  /**
   * Pode configurar "Usuários"?
   */
  SettingsUsers = 11,
  /**
   * Pode configurar campos do cadastro de usuário
   */
  SettingsUsersAdditionalFields = 48,
  /**
   * Pode configurar "Checklists"?
   */
  SettingsChecklists = 12,
  /**
   * Pode configurar "Agendamentos"?
   */
  SettingsSchedules = 13,
  /**
   * Pode configurar "Outras Configurações"?
   */
  SettingsOtherSettings = 14,
  /**
   * Pode configurar "Alertas/Envio de e-mails"?
   */
  SettingsEmails = 26,
  /**
   * Configurar apenas unidades que possui acesso?
   */
  SettingsUnitsHaveAccess = 29,
  /**
   * Pode configurar "QR Codes de unidades"?
   */
  SettingsUnitsQrcodes = 33,
  /**
   * Pode configurar "QR Codes de Unidades x Checklists"?
   */
  SettingsUnitsChecklistsQrcodes = 65,
  /**
   * Pode configurar "Treinamentos"?
   */
  SettingsTraining = 39,
  /**
   * Pode configurar "Categorias de Produtos"?
   */
  SettingsProductCategories = 40,
  /**
   * Pode configurar reembolso?
   */
  SettingsRefund = 50,
  /**
   * Pode personalizar PA
   */
  SettingsPersonalizePa = 52,
  /**
   * Dashboard
   */
  SettingsDashboard = 55,
  /**
   * Visualiza planos de ação?
   */
  ActionPlanSee = 17,
  /**
   * Pode cadastrar solução?
   */
  ActionPlanAddSolution = 19,
  /**
   * Pode aprovar/recusar solução?
   */
  ActionPlanApproveReproveSolution = 20,
  /**
   * Pode aprovar planos de ação preenchidos pelo responsável?
   */
  ActionPlanApprove = 30,
  /**
   * Preenche planos de ação como responsável?
   */
  ActionPlanFillResponsible = 31,
  /**
   * Pode finalizar planos de ação?
   */
  ActionPlanFinalize = 21,
  /**
   * Pode excluir/cancelar planos de ação?
   */
  ActionPlanDelete = 37,
  /**
   * Pode cancelar planos de ação?
   */
  ActionPlanCancel = 42,
  /**
   * Pode prorrogar planos de ação?
   */
  ActionPlanExtend = 49,
  /**
   * Reabre planos de ação?
   */
  ActionPlanReopen = 59,

  /**
   * Visualiza relatório "Reincidência"?
   */
  ReportRecidivism = 38,
  /**
   * Visualiza relatório "Histórico"?
   */
  ReportHistory = 4,
  /**
   * Visualiza relatório "Indicadores"?
   */
  ReportIndicators = 5,
  /**
   * Visualiza relatório "Planos de Ação"?
   */
  ReportActionPlans = 6,
  /**
   * Visualiza relatório "Agendamentos"
   */
  ReportSchedules = 7,
  /**
   * Visualiza relatório "Auditoria"?
   */
  ReportAudit = 22,
  /**
   * Visualizar itens ocultos nos relatórios
   */
  ReportViewHiddenItems = 36,
  /**
   * Visualiza relatório de reembolso
   */
  ReportRefund = 44,
  /**
   * Visualiza relatório de reembolso de outros usuários
   */
  ReportRefundSeeOthers = 45,
  /**
   * Visualiza "Relatório de arquivos"?
   */
  ReportArchive = 43,
  /**
   * Visualiza "Relatório de arquivos"?
   */
  ReportExport = 56,
  /**
   * Visualiza "Relatório de ORT"? // Customização Votorantim
   */
  ReportOrt = 57,
  /**
   * Visualiza "Relatório de Aderencia de segurança"? // Customização Votorantim
   */
  ReportSafetyAdhesion = 58,
  /**
   * Visualiza "Relatório de conferência"
   */
  ReportConference = 60,
  /**
   * Acessa Relatório Power BI
   */
  ReportPowerBi = 62,
  /**
   * Acessa Relatório analytics BI
   */
  ReportAnalyticsBi = 68,
  /**
   * Ranking
   */
  ReportRanking = 25,
  /**
   * Visualiza todos os dados (não considera vínculos)
   */
  ReportRankingShowAllData = 69,
  /**
   * Visualiza detalhes das avaliações
   */
  ReportRankingShowEvaluationDetails = 70,
  /**
   * Excluir workflows
   */
  DeleteWorkflows = 78,
}
