export enum NotificationType {
  START = 1,
  EXPORT_PDF = 2,
  EXPORT = 3,
  EXPORT_FAIL = 4,
  IMPORT = 5,
  IMPORT_FAIL = 6,
}

export enum NotificationTimer {
  START = 5000,
  OTHERS = 10000,
}

export interface NotificationResponse {
  title: string;
  content: string;
  filename: string;
  openButton: boolean;
  downloadButton: boolean;
  detailsButton: boolean;
  verifyButton: boolean;
  fileUrl: string;
  detailUrl: string;
  type: number;
  show: boolean;
}
