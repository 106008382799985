<ul class="option-list" [class.option-list--selection]="hasSelection">
  <!--  <cdk-virtual-scroll-viewport itemSize="40" class="virtual-scroll">-->
  <li
    class="option-list-item option-list__spinner"
    *ngIf="showLoading && (!items || items.length === 0)"
  >
    <div class="loading-wrapper">
      <mat-spinner [diameter]="24"></mat-spinner>
    </div>
  </li>
  <li
    *ngFor="let item of items; trackBy: trackByValue"
    class="option-list-item"
    [class.option-list-item--has-suffix-icon]="hasSuffixIcon"
  >
    <div class="option-list__action" *ngIf="hasSelection">
      <ng-container *ngIf="!multiselect">
        <mat-radio-button
          [checked]="item.checked"
          (change)="handleItemClick($event.source.checked, item)"
          color="primary"
        >
          <ng-container
            [ngTemplateOutlet]="customOptionName"
            [ngTemplateOutletContext]="{ item: item }"
          ></ng-container>
        </mat-radio-button>
      </ng-container>
      <ng-container *ngIf="multiselect">
        <mat-checkbox
          [checked]="!!(item.checked)"
          (change)="handleItemClick($event.source.checked, item)"
          color="primary"
        >
          <ng-container
            [ngTemplateOutlet]="customOptionName"
            [ngTemplateOutletContext]="{ item: item }"
          ></ng-container>
        </mat-checkbox>
      </ng-container>
    </div>
    <ng-container *ngIf="!hasSelection">
      <ng-container
        [ngTemplateOutlet]="customOptionName"
        [ngTemplateOutletContext]="{ item: item }"
      ></ng-container>
    </ng-container>
  </li>
  <li
    *ngIf="showLoadMore"
    class="option-list-item option-list-item--load-more"
    (click)="handleLoadMoreClick()"
  >
    <cl-ui-button-link-mini
      *ngIf="!showLoading"
      [color]="showLoadError ? 'warn' : 'primary'"
    >
      <ng-container *ngIf="!showLoadError && !showLoading">
        {{ loadMoreTerm }}
      </ng-container>
      <ng-container *ngIf="showLoadError && !showLoading">
        {{ loadErrorTerm }}
      </ng-container>
    </cl-ui-button-link-mini>

    <div class="loading-wrapper" *ngIf="showLoading">
      <mat-spinner [diameter]="24"></mat-spinner>
    </div>
  </li>
  <!--  </cdk-virtual-scroll-viewport>-->
</ul>

<ng-template #customOptionName let-item="item">
  <span *ngIf="!optionNameTemplate" class="option-list__text">
    {{ item.name }}
  </span>
  <ng-container *ngIf="optionNameTemplate">
    <ng-container
      [ngTemplateOutlet]="optionNameTemplate"
      [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
  </ng-container>
  <button
    *ngIf="hasSuffixIcon"
    (click)="handleSuffixIconClick($event, item)"
    class="option-list__suffix-icon"
    mat-icon-button
  >
    <mat-icon fontSet="material-icons-outlined">{{ suffixIcon }}</mat-icon>
  </button>
</ng-template>
