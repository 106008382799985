import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[clNotInCollection]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NotInCollectionDirective,
      multi: true,
    },
  ],
})
export class NotInCollectionDirective implements Validator, OnChanges {
  @Input('clNotInCollection') collection: string[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeFn = () => {};

  ngOnChanges(changes: SimpleChanges) {
    const { collection } = changes;
    if (collection) {
      const { previousValue, currentValue } = collection;
      if (
        previousValue &&
        currentValue &&
        JSON.stringify(previousValue).localeCompare(currentValue) === 0
      ) {
        if (this.onChangeFn) {
          this.onChangeFn();
        }
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.collection) {
      return this.collection.indexOf(
        control.value ? control.value.trim() : ''
      ) > -1
        ? { notInCollection: true }
        : null;
    }
    return null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChangeFn = fn;
  }
}
