import { Injectable } from '@angular/core';

export interface FreshDestkGlobalScope extends Window {
  FreshworksWidget: (state: string) => void;
}

declare let window: FreshDestkGlobalScope;

@Injectable({
  providedIn: 'root',
})
export class FreshdeskService {
  constructor() {}

  setup() {
    if (document.querySelectorAll('.freshdesk-script').length !== 0) {
      return;
    }

    const freshDeskScript = document.createElement('script');
    const freshDeskScript2 = document.createElement('script');
    const freshDeskScript3 = document.createElement('script');

    freshDeskScript.classList.add('freshdesk-script');
    freshDeskScript2.classList.add('freshdesk-script');
    freshDeskScript3.classList.add('freshdesk-script');

    freshDeskScript.src =
      'https://widget.freshworks.com/widgets/70000000579.js';

    freshDeskScript2.innerText = ` var userLang = navigator.language;
        if (userLang.includes('es')) {
            window.fwSettings={
                'widget_id':70000000579,
                'locale': 'es-LA'
            };
        } else {
            window.fwSettings={
                'widget_id':70000000579
            };
        }
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

    freshDeskScript3.innerText = ` FreshworksWidget("setLabels", {
            'en': {
                banner: "Hello! How can we help you?",
                launcher: "Help",
                contact_form: {
                    title: "Open your ticket and our team will help you! 🙂 ✅",
                    submit: "Create ticket",
                    confirmation: "Your ticket has been created! We will return as soon as possible.",
                    close: 'Close'
                }
            },
            'es-LA': {
                banner: "¡Hola! ¿Cómo podemos ayudar?",
                launcher: "Apoyo",
                contact_form: {
                    title: "¡Abra su ticket y nuestro equipe lo ayudará! 🙂 ✅",
                    submit: "Crear ticket",
                    confirmation: "¡Tu ticket ha sido creado! Volveremos lo antes posible."
                }
            }
        });

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.widget === 'open') {
            FreshworksWidget('open');
        }`;

    document.body.append(freshDeskScript, freshDeskScript2, freshDeskScript3);

    this.hideHelpButton();
  }

  showHelpButton() {
    window.FreshworksWidget && window.FreshworksWidget('show');
  }

  hideHelpButton() {
    window.FreshworksWidget && window.FreshworksWidget('hide');
  }
}
