export interface Configuration {
  apiUrl: string;
  appUrl: string;
  spaApiUrl: string;
  atualUrl: string;
  siteUrl: string;
  enableSnowPlow: boolean;
  pusher: {
    key: string;
    cluster: string;
    wsHost: string;
    wsPort: number;
  };
  environment: string;
}
