export const browserIdentification = () => {
  const userAgent = navigator.userAgent;

  let isMobile = false;

  if (
    /Android|Windows IoT|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )
  ) {
    isMobile = true;
  }

  let tem,
    matchBrowser =
      userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

  if (/trident/i.test(matchBrowser[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return { name: 'IE', version: tem[1] || '' };
  }

  if (matchBrowser[1] === 'Chrome') {
    tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null)
      return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
  }

  matchBrowser = matchBrowser[2]
    ? [matchBrowser[1], matchBrowser[2]]
    : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = userAgent.match(/version\/(\d+)/i)) != null)
    matchBrowser.splice(1, 1, tem[1]);

  return {
    manufacturer: matchBrowser[0],
    version: matchBrowser[1],
    information: userAgent,
    isMobile,
  };
};
