<div
  class="scale"
  [class.scale--color-override]="!!overrideColor"
  [ngStyle]="{
    height: boxHeight,
    width: boxWidth
  }"
>
  <div
    *ngIf="scaleData?.na"
    class="icon qa-na-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M-5.424-8.424V-3.2l-3.432-5.22h-2.052V0h2.052V-5.2L-5.424,0h2.052V-8.424ZM-2.508,2.244h1.98L2.688-11.4H.708ZM9.3,0h2.172L8.424-8.424H6.048L3,0H5.148l.5-1.488H8.8ZM8.268-3.072H6.192L7.224-6.156Z"
        transform="translate(12 17)"
        fill="rgba(150, 37, 169, 1)"
      />
    </svg>
  </div>
  <div
    *ngIf="scaleData?.badFace"
    class="icon qa-bad-face-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(14 8)"
        fill="#f44336"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(7 8)"
        fill="#f44336"
      />
      <path
        d="M12,14a5.5,5.5,0,0,0-5.12,3.5H8.55a3.976,3.976,0,0,1,6.9,0h1.67A5.5,5.5,0,0,0,12,14ZM11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        transform="translate(0 0)"
        fill="#f44336"
      />
    </svg>
  </div>
  <div
    *ngIf="scaleData?.averageFace"
    class="icon qa-average-face-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(14 8)"
        fill="#ffc107"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(7 8)"
        fill="#ffc107"
      />
      <path
        d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        transform="translate(0 0)"
        fill="#ffc107"
      />
      <rect width="10" height="2" transform="translate(7 15)" fill="#ffc107" />
    </svg>
  </div>
  <div
    *ngIf="scaleData?.goodFace"
    class="icon qa-good-face-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M11.99,2A10,10,0,1,0,22,12,9.989,9.989,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM13,9.94,14.06,11l1.06-1.06L16.18,11l1.06-1.06L15.12,7.82Zm-4.12,0L9.94,11,11,9.94,8.88,7.82,6.76,9.94,7.82,11ZM12,17.5A5.5,5.5,0,0,0,17.11,14H6.89A5.5,5.5,0,0,0,12,17.5Z"
        transform="translate(0 0)"
        fill="#4caf50"
      />
    </svg>
  </div>
  <div
    *ngIf="scaleData?.no"
    class="icon qa-no-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M2.574-12.636v7.83l-5.148-7.83H-5.652V0h3.078V-7.794L2.574,0H5.652V-12.636Z"
        transform="translate(12 18)"
        fill="#f44336"
      />
    </svg>
  </div>
  <!-- sim em português e espanhol -->
  <div
    *ngIf="scaleData?.sim"
    class="icon scale-sim qa-scale-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M4.761-3.762C4.761-7.992-1.485-7-1.485-9.2a1.12,1.12,0,0,1,1.3-1.152A1.368,1.368,0,0,1,1.269-9.036H4.6c-.126-2.412-1.98-3.78-4.734-3.78-2.646,0-4.608,1.332-4.608,3.726-.036,4.482,6.282,3.222,6.282,5.562C1.539-2.79.963-2.34.063-2.34A1.449,1.449,0,0,1-1.5-3.78H-4.779C-4.689-1.224-2.565.126.153.126,3.105.126,4.761-1.638,4.761-3.762Z"
        transform="translate(12 19)"
        fill="#4caf50"
      />
    </svg>
  </div>
  <div
    *ngIf="scaleData?.yes"
    class="icon scale-sim qa-scale-sim-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M4.761-3.762C4.761-7.992-1.485-7-1.485-9.2a1.12,1.12,0,0,1,1.3-1.152A1.368,1.368,0,0,1,1.269-9.036H4.6c-.126-2.412-1.98-3.78-4.734-3.78-2.646,0-4.608,1.332-4.608,3.726-.036,4.482,6.282,3.222,6.282,5.562C1.539-2.79.963-2.34.063-2.34A1.449,1.449,0,0,1-1.5-3.78H-4.779C-4.689-1.224-2.565.126.153.126,3.105.126,4.761-1.638,4.761-3.762Z"
        transform="translate(12 19)"
        fill="#4caf50"
      />
    </svg>
  </div>
  <!-- sim em português e espanhol end -->
  <!-- sim em inglês -->
  <div
    *ngIf="scaleData?.sim"
    class="icon scale-yes qa-scale-yes-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M-1.539-4.176V0H1.539V-4.176l4.374-8.46H2.457L.027-7.344l-2.448-5.292H-5.913Z"
        transform="translate(12 19)"
        fill="#4caf50"
      />
    </svg>
  </div>
  <div
    *ngIf="scaleData?.yes"
    class="icon scale-yes qa-scale-yes-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M-1.539-4.176V0H1.539V-4.176l4.374-8.46H2.457L.027-7.344l-2.448-5.292H-5.913Z"
        transform="translate(12 19)"
        fill="#4caf50"
      />
    </svg>
  </div>
  <!-- sim em inglês end -->
  <div
    *ngIf="scaleData?.medal"
    class="icon scale-medal qa-scale-medal-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M10116.471-10.964l.653-5.455a8.36,8.36,0,0,1-4.126-7.166,8.546,8.546,0,0,1,8.674-8.414,8.555,8.555,0,0,1,8.689,8.414,8.355,8.355,0,0,1-4.125,7.161l.652,5.458-5.208-3.188-5.208,3.191Zm1.6-6.882a7.048,7.048,0,0,0,3.6.993,7.05,7.05,0,0,0,3.621-1l-3.612-2.1Zm7.066-4.545.963,4a6.665,6.665,0,0,0,2.525-5.191,6.575,6.575,0,0,0-.2-1.564Zm-10.4-1.194a6.636,6.636,0,0,0,2.526,5.191l.962-4-3.289-2.758A6.192,6.192,0,0,0,10114.735-23.585Zm9.029,2.878-.556-2.3,1.832-1.539-2.416-.2-.944-2.153-.946,2.161-2.414.2,1.833,1.539-.548,2.29,2.075-1.211Zm-8.579-5.26,4.357-.362,1.738-3.972A6.939,6.939,0,0,0,10115.186-25.967Zm8.63-.37,4.349.362a6.948,6.948,0,0,0-6.084-4.325Z"
        transform="translate(-10109.68 33.148)"
        fill="#2196f3"
      />
    </svg>
  </div>
  <div
    *ngIf="scaleData?.medal"
    class="icon scale-alert qa-scale-alert-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 2.66663C16.7363 2.66663 17.3333 3.26358 17.3333 3.99996V5.33329C17.3333 6.06967 16.7363 6.66663 16 6.66663C15.2636 6.66663 14.6666 6.06967 14.6666 5.33329V3.99996C14.6666 3.26358 15.2636 2.66663 16 2.66663ZM8.4575 6.57184C7.9368 6.05114 7.09258 6.05114 6.57188 6.57184C6.05118 7.09254 6.05118 7.93676 6.57188 8.45746L7.51469 9.40027C8.03539 9.92096 8.87961 9.92096 9.40031 9.40027C9.92101 8.87957 9.92101 8.03535 9.40031 7.51465L8.4575 6.57184ZM26.6666 14.6666C25.9302 14.6666 25.3333 15.2636 25.3333 16C25.3333 16.7363 25.9302 17.3333 26.6666 17.3333H28C28.7363 17.3333 29.3333 16.7363 29.3333 16C29.3333 15.2636 28.7363 14.6666 28 14.6666H26.6666ZM25.4281 8.4575C25.9488 7.9368 25.9488 7.09258 25.4281 6.57188C24.9074 6.05118 24.0632 6.05118 23.5425 6.57188L22.5997 7.51469C22.079 8.03539 22.079 8.87961 22.5997 9.40031C23.1204 9.92101 23.9646 9.92101 24.4853 9.40031L25.4281 8.4575ZM16 7.99996C11.5817 7.99996 7.99996 11.5817 7.99996 16V24H5.33329C4.59691 24 3.99996 24.5969 3.99996 25.3333V28C3.99996 28.7363 4.59691 29.3333 5.33329 29.3333H26.6666C27.403 29.3333 28 28.7363 28 28V25.3333C28 24.5969 27.403 24 26.6666 24H24V16C24 11.5817 20.4182 7.99996 16 7.99996ZM21.3333 16V24H10.6666V16C10.6666 13.0544 13.0544 10.6666 16 10.6666C18.9455 10.6666 21.3333 13.0544 21.3333 16ZM12 15.9999C12 13.7908 13.7908 12 15.9999 12L16 22.6666H12V15.9999ZM2.66663 16C2.66663 15.2636 3.26358 14.6666 3.99996 14.6666H5.33329C6.06967 14.6666 6.66663 15.2636 6.66663 16C6.66663 16.7363 6.06967 17.3333 5.33329 17.3333H3.99996C3.26358 17.3333 2.66663 16.7363 2.66663 16Z"
        fill="#212121"
      />
    </svg>
  </div>
  <div
    *ngIf="scaleData?.star"
    class="icon qa-star-icon"
    [style.height]="iconHeight"
    [style.width]="iconWidth"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g opacity="0.5">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M0,0H24V24H0Z" fill="none" />
        <path
          d="M12,17.27,18.18,21l-1.64-7.03L22,9.24l-7.19-.61L12,2,9.19,8.63,2,9.24l5.46,4.73L5.82,21Z"
        />
      </g>
    </svg>
  </div>
</div>
