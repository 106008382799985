import { CdkDrag, Point } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cl-form-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RangeInputComponent,
      multi: true,
    },
  ],
})
export class RangeInputComponent implements OnInit, ControlValueAccessor {
  private _value: [number, number] = [0, 0];
  get value(): [number, number] {
    return this._value;
  }

  @Input()
  set value(value: [number, number]) {
    if (value[0] === this._value[0] && value[1] === this._value[1]) {
      return;
    }

    this._value = value;

    this.setupInitialPosition();
    this.updateTrack();
  }

  @Input() disabled = false;
  @Input() minValue = 0;
  @Input() maxValue = 100;
  @Input() @HostBinding('style.width.px') width = 390;
  @Output() valueChange = new EventEmitter<[number, number]>();

  @ViewChild('leftDot', { static: true }) dotLeftRef: CdkDrag | undefined;
  @ViewChild('rightDot', { static: true }) dotRightRef: CdkDrag | undefined;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange: ((value: [number, number]) => void) | undefined;
  dotLeftPositioning: Point | undefined;
  dotRightPositioning: Point | undefined;
  private dotDiameter = 12;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.elementRef.nativeElement.style.setProperty(
      '--dot-diameter',
      `${this.dotDiameter}px`
    );
    this.setupInitialPosition();
    this.updateTrack();
  }

  handleMoved() {
    if (this.disabled) {
      return;
    }
    this._value[0] = this.valueByLeftDot();
    this._value[1] = this.valueByRightDot();
    this.updateTrack();
  }

  handleLeftEnded() {
    const dotLeftValue = this.valueByLeftDot();
    const dotRightValue = this.valueByRightDot();
    if (dotLeftValue > dotRightValue) {
      this.dotLeftPositioning = this.dotRightRef?.getFreeDragPosition();
      this._value[0] = this.valueFromPosition(this.dotLeftPositioning?.x || 0);
    } else {
      this._value[0] = this.valueByLeftDot();
    }
    this.emitValue();
  }

  handleRightEnded() {
    const dotLeftValue = this.valueByLeftDot();
    const dotRightValue = this.valueByRightDot();
    if (dotLeftValue > dotRightValue) {
      this.dotRightPositioning = this.dotLeftRef?.getFreeDragPosition();
      this._value[1] = this.valueFromPosition(this.dotRightPositioning?.x || 0);
    } else {
      this._value[1] = this.valueByRightDot();
    }
    this.emitValue();
  }

  registerOnTouched(fn: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(obj: any): void {
    this._value = obj;

    this.setupInitialPosition();
    this.updateTrack();
  }

  private updateTrack() {
    const leftPosition = this.positionFromValue(this._value[0]);
    const rightPosition = this.positionFromValue(this._value[1]);
    this.elementRef.nativeElement.style.setProperty(
      '--track-left-position',
      `${leftPosition + this.dotDiameter / 2}px`
    );
    this.elementRef.nativeElement.style.setProperty(
      '--track-width',
      `${rightPosition - leftPosition}px`
    );
  }

  private emitValue() {
    this.valueChange.emit(this._value);
    if (this.onChange) {
      this.onChange(this._value);
    }
  }

  private setupInitialPosition() {
    this.dotLeftPositioning = {
      x: this.positionFromValue(this._value[0]),
      y: 14,
    };
    this.dotRightPositioning = {
      x: this.positionFromValue(this._value[1]),
      y: 14,
    };
  }

  private valueByLeftDot() {
    const x = this.dotLeftRef?.getFreeDragPosition().x;
    return this.valueFromPosition(x || 0);
  }

  private valueByRightDot() {
    const x = this.dotRightRef?.getFreeDragPosition().x;
    return this.valueFromPosition(x || 0);
  }

  private valueFromPosition(x: number): number {
    return Math.floor(
      ((this.maxValue - this.minValue) / (this.width - 12)) * x + this.minValue
    );
  }

  private positionFromValue(value: number): number {
    return (
      (value - this.minValue) /
      ((this.maxValue - this.minValue) / (this.width - 12))
    );
  }
}
