import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedMainMenuNotificationModule } from '@checklistfacil/shared/main-menu/notification';
import { SharedMainMenuUiModule } from '@checklistfacil/shared/main-menu/ui';
import { SharedUtilTranslateModule } from '@checklistfacil/shared/util/translate';
import { MenuComponent } from './menu/menu.component';

@NgModule({
  declarations: [MenuComponent],
  imports: [
    CommonModule,
    SharedMainMenuUiModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatIconModule,
    SharedUtilTranslateModule,
    MatFormFieldModule,
    SharedMainMenuNotificationModule,
  ],
  exports: [MenuComponent],
})
export class SharedMainMenuFeatureModule {}
