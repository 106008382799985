import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { ConfirmationDialogData } from './types';

@Component({
  selector: 'cl-ui-confirmation-dialog-content',
  templateUrl: './confirmation-dialog-content.component.html',
  styleUrls: ['./confirmation-dialog-content.component.scss'],
})
export class ConfirmationDialogContentComponent implements OnInit {
  generatedConfirmationCode: string | undefined;
  alertMessage: string | undefined;
  beforeCodeMessage = '';
  afterCodeMessage = '';

  code = new FormControl('');
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}

  ngOnInit() {
    this.generatedConfirmationCode = String(
      this.randomIntFromInterval(10000, 99999)
    );
    this.setupCodeMessages();
    this.alertMessage = this.data.alertMessage;
  }

  setupCodeMessages() {
    if (!this.data.content) {
      return;
    }

    const termsKeys = { key1: ':code', key2: '#code' };

    const finalKey = this.hasTermInDataContent(termsKeys.key1)
      ? termsKeys.key1
      : this.hasTermInDataContent(termsKeys.key2)
      ? termsKeys.key2
      : null;

    if (finalKey) {
      this.defineCodeMessagesForATerm(finalKey);
      return;
    }

    this.beforeCodeMessage = this.data.content;
  }

  hasTermInDataContent(term: string): boolean {
    return this.data.content.indexOf(term) > -1;
  }

  defineCodeMessagesForATerm(term: string): void {
    const [beforeCodeMessage, afterCodeMessage] =
      this.retrieveCodeMessagesOfData(term);

    this.beforeCodeMessage = beforeCodeMessage;
    this.afterCodeMessage = afterCodeMessage;
  }

  retrieveCodeMessagesOfData(term: string): string[] {
    return this.data.content.split(term);
  }

  randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
