import { Scale, ScaleType } from './types';

export function getScaleTypeId(scaleId: number): ScaleType | null {
  if (isEvaluative(scaleId)) {
    return ScaleType.Evaluative;
  }

  if (isText(scaleId)) {
    return ScaleType.Text;
  }

  if (isDate(scaleId)) {
    return ScaleType.Date;
  }

  if (isRegistration(scaleId)) {
    return ScaleType.Registration;
  }

  if (isNumeric(scaleId)) {
    return ScaleType.Numeric;
  }

  if (isSelection(scaleId)) {
    return ScaleType.Selection;
  }

  if (isSpecial(scaleId)) {
    return ScaleType.Special;
  }

  return null;
}

export function isEvaluative(scaleId: number) {
  return [Scale.RedGreen, Scale.RedYellowGreen, Scale.YesNo].includes(scaleId);
}

export function isText(scaleId: number) {
  return [Scale.ShortText, Scale.LongText].includes(scaleId);
}

function isDate(scaleId: number) {
  return [
    Scale.Time24h,
    Scale.Time12h,
    Scale.Time24hS,
    Scale.Time12hS,
    Scale.DateMY,
    Scale.DateDMY,
  ].includes(scaleId);
}

function isRegistration(scaleId: number) {
  return [
    Scale.AddressState,
    Scale.AddressStateCity,
    Scale.CPF,
    Scale.CNPJ,
    Scale.Phone,
    Scale.AddressCEP,
    Scale.LicensePlate,
    Scale.LicensePlateMercosul,
    Scale.Email,
  ].includes(scaleId);
}

export function isNumeric(scaleId: number) {
  return [
    Scale.Monetary,
    Scale.Integer,
    Scale.Decimal,
    Scale.Temperature,
  ].includes(scaleId);
}

export function isSelection(scaleId: number) {
  return [Scale.SelectionSingle, Scale.SelectionMultiple].includes(scaleId);
}

export function isDateDMY(scaleId: number) {
  return Scale.DateDMY === scaleId;
}

export function isDateMonthYear(scaleId: number) {
  return Scale.DateMY === scaleId;
}

export function isTime12h(scaleId: number) {
  return [Scale.Time12h, Scale.Time12hS].includes(scaleId);
}

export function isTime24h(scaleId: number) {
  return Scale.Time24h === scaleId || Scale.Time24hS === scaleId;
}

export function isBarcode(scaleId: number) {
  return Scale.Barcode === scaleId;
}

export function isProduct(scaleId: number) {
  return Scale.Product === scaleId;
}

export function isEmail(scaleId: number) {
  return Scale.Email === scaleId;
}

export function isPlate(scaleId: number) {
  return (
    Scale.LicensePlateMercosul === scaleId || Scale.LicensePlate === scaleId
  );
}

export function isTemperature(scaleId: number) {
  return Scale.Temperature === scaleId;
}

export function isTextWithMask(scaleId: number) {
  return [Scale.AddressCEP, Scale.Phone, Scale.CPF, Scale.CNPJ].includes(
    scaleId
  );
}

export function isStateOrCity(scaleId: number) {
  return [Scale.AddressState, Scale.AddressStateCity].includes(scaleId);
}

function isSpecial(scaleId: number) {
  return [
    Scale.GPS,
    Scale.Barcode,
    Scale.CompetencePeriod,
    Scale.Product,
    Scale.Index,
    Scale.Instruction,
    Scale.SiengeService,
  ].includes(scaleId);
}
