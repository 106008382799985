// TODO - https://rz2mkt.atlassian.net/browse/ODT-6992
export interface FileGalleryItem {
  id?: number | string;
  name: string;
  url: string;
  type: string;
  localFile?: boolean;
  fileInstance?: File;
  canBeRemoved?: boolean;
  formData?: FormData;
}
