import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabItem } from './types';

@Component({
  selector: 'cl-ui-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent {
  @Input() tabs: TabItem[] = [];
  @Input() selectedIndex = 0;
  @Output() selectedIndexChanged = new EventEmitter<number>();

  activeIndexChanged(tabIndex: number) {
    this.selectedIndexChanged.emit(tabIndex);
  }
}
