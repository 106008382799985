import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import * as CompanySelectors from '@checklistfacil/shared/data-access/company-configs';
import {
  companyUsesAlert,
  enableAcessarCentralDeAjuda,
  getCompanyTopLogo,
  getJivoChatUrl,
  getMainColor,
  userCanApplyChecklists,
} from '@checklistfacil/shared/data-access/company-configs';
import { UploadQueueService } from '@checklistfacil/shared/data-access/upload-queue';
import {
  companyCanBeTrackedBySnowplow,
  getCurrentLanguage,
  getJivoUserInfo,
  getUser,
  userAllowedToUseAppcues,
  UserInfo,
  userIsAccountManager,
} from '@checklistfacil/shared/data-access/user';
import {
  MainMenuActions,
  MainMenuSelectors,
} from '@checklistfacil/shared/main-menu/data-access';
import { AccountMenuInfo } from '@checklistfacil/shared/main-menu/feature';
import {
  MenuHelpInfo,
  MenuListItem,
  UserCardInfo,
} from '@checklistfacil/shared/main-menu/ui';
import { AchievementIcon } from '@checklistfacil/shared/user-achievements/util';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';
import {
  ColorPaletteService,
  UploadQueueItem,
} from '@checklistfacil/shared/util/general';
import { DialogService } from '@checklistfacil/shared/util/notifications';

import {
  loadAppcuesScript,
  OnboardingService,
  SnowplowService,
  TrackingService,
} from '@checklistfacil/shared/util/tracking';
import {
  LANGUAGE_IDS,
  translocoLoader,
} from '@checklistfacil/shared/util/translate';
import {
  UserAchievementsActions,
  UserAchievementsSelectors,
} from '@checklistfacil/user/achievements/data-access';
import {
  ProviderScope,
  TRANSLOCO_SCOPE,
  TranslocoService,
} from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap, takeWhile } from 'rxjs/operators';
import { endpoints } from '../endpoints';
import * as rootSelectors from '../state/index';
import * as appState from '../state/reducers';
import { MenuFooterLinks } from '../types';

@Component({
  selector: 'cl-hall',
  templateUrl: './hall.component.html',
  styleUrls: ['./hall.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'hallComponent'
    ),
  ],
})
export class HallComponent implements OnInit, OnDestroy {
  title = 'checklistfacil';

  menuIsCollapsed$: Observable<boolean> = this.store.select(
    MainMenuSelectors.getMenuCollapsed
  );
  menuItems$: Observable<MenuListItem[]> = this.store.select(
    MainMenuSelectors.getMenuItems
  );
  userInfo$: Observable<UserCardInfo> = this.store.pipe(
    select(getUser),
    map((item) => ({
      lang: item.languageId,
      name: item.name ?? '',
      type: item.userTypeName ?? '',
      imageUrl: item.avatarUrl ?? undefined,
      isAccountManager: item.isAccountManager,
    }))
  );
  userInfoAchievementTrophy$: Observable<AchievementIcon | null> =
    this.store.select(UserAchievementsSelectors.userTrophy);
  accountMenuLinks$: Observable<AccountMenuInfo> | undefined;
  uploadFiles$: Observable<UploadQueueItem[]> =
    this.uploadQueueService.uploadFiles$;
  userCanApplyChecklists$: Observable<boolean> = this.store.pipe(
    select(userCanApplyChecklists),
    takeWhile(() => this.componentActive)
  );

  showUploadQueue$: Observable<boolean> =
    this.uploadQueueService.showUploadQueue$;
  uploadQueueIsOpen$: Observable<boolean> =
    this.uploadQueueService.uploadQueueIsOpen$;
  companyLogoUrl$: Observable<string> = this.store.select(getCompanyTopLogo);
  companyInTrial$: Observable<boolean> = this.store.select(
    CompanySelectors.companyInTrial
  );
  companyRemainingTrialDays$: Observable<number | null> = this.store.select(
    CompanySelectors.companyRemainingTrialDays
  );
  companyHasContactInformation$: Observable<boolean> = this.store.select(
    rootSelectors.hasContactInformation
  );
  menuHelpInfo$: Observable<MenuHelpInfo> = this.store.pipe(
    select(getUser),
    map((user: UserInfo) => {
      return {
        helpCenterUrl: user.helpcenterPlatformUrl ?? '',
        ticketsUrl: user.ticketsPlatformUrl ?? '',
      };
    })
  );
  isAccountManager$: Observable<boolean> =
    this.store.select(userIsAccountManager);
  userHasAccessToAchievements$: Observable<boolean> = this.store.select(
    UserAchievementsSelectors.userHasAccessToAchievements
  );

  hiddenMenuItems$: Observable<string[]> = this.store.pipe(
    select(enableAcessarCentralDeAjuda),
    map((enabled) => {
      return enabled ? [] : ['helper-center'];
    })
  );
  footerLinks: MenuFooterLinks = {};

  jivoChatUrl$ = this.store.select(getJivoChatUrl);
  jivoUserInfo$ = this.store.select(getJivoUserInfo);

  private componentActive = true;
  private appConfig: Configuration | undefined;

  constructor(
    private onboardingService: OnboardingService,
    private colorPalette: ColorPaletteService,
    private store: Store<appState.State>,
    private uploadQueueService: UploadQueueService,
    private titleService: Title,
    private router: Router,
    private snowPlowService: SnowplowService,
    private trackingService: TrackingService,
    private dialogService: DialogService,
    @Inject(TRANSLOCO_SCOPE) private scope: ProviderScope,
    private transloco: TranslocoService,
    configService: ConfigAssetLoaderService
  ) {
    configService.loadConfigurations().subscribe((config) => {
      this.appConfig = config;

      this.footerLinks = {
        importExportUrl: endpoints.footerImportExport(config),
        notificationsUrl: endpoints.footerNotifications(config),
        messagesUrl: endpoints.footerMessages(config),
      };

      this.accountMenuLinks$ = this.store.pipe(
        select(getUser),
        map((user: UserInfo) => {
          const { helpcenterPlatformUrl } = user;
          return {
            profileUrl: endpoints.profilePage(config),
            logoffUrl: endpoints.logoutPage(config),
            helpCenterUrl: helpcenterPlatformUrl ?? undefined,
            changePasswordUrl: endpoints.changePasswordPage(config),
          };
        })
      );
    });
  }

  get dashboardUrl() {
    return this.appConfig?.appUrl ?? '';
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Checklist Fácil');

    /* @TODO: alterar responsabilidade de dispatch da action "loadUserAchievements".
     * Esse dispatch e feito aqui pois o menu precisa desses dados para indicar qual trofeu o usuário tem.
     * O dispatch deveria ser feito no menu, mas para isso precisamos mudar o escopo da lib "user"
     */
    this.store.dispatch(UserAchievementsActions.loadUserAchievements());

    this.store.select(getMainColor).subscribe((color) => {
      if (color) {
        this.colorPalette.setPrimaryPalletColorFrom(color);
      }
    });

    /**
     * Ativação do Snowplow
     */
    this.store
      .pipe(
        select(companyCanBeTrackedBySnowplow),
        filter((canUse) => canUse),
        first(),
        switchMap(() => {
          return this.store.pipe(
            select(rootSelectors.userToAnalyticsAndHelpers)
          );
        })
      )
      .subscribe((user) => {
        this.snowPlowService.enableTracking();

        if (user.id) {
          this.snowPlowService.setUserId(user.id);
        }
      });

    /**
     * Ativação do Appcues
     */
    this.store
      .pipe(
        select(userAllowedToUseAppcues),
        filter((isAllowed) => isAllowed),
        first(),
        switchMap(() => {
          return this.store.pipe(
            select(rootSelectors.userToAnalyticsAndHelpers)
          );
        })
      )
      .subscribe((user) => {
        const { acceptTerms } = user;

        loadAppcuesScript(() => {
          if (acceptTerms) {
            (window as any)['Appcues'].identify(user.id, {
              userName: user.name,
              email: user.email,
              language: user.lang,
              onboarding: user.onboarding,
              onboardingStep: user.onboardingStep,
              companyId: user.companyId,
              companyName: user.companyName,
              hasDepartments: user.hasDepartments,
              hasWorkflow: user.hasWorkflow,
              hasUnitTypes: user.hasUnitType,
              isAdmin: user.isAdmin,
              planId: user.companyPlanId,
              hasBillingEmailConfirmed: user.hasBillingEmailConfirmed,
              companyRegisterFilled: user.companyRegisterFilled,
            });
            (window as any)['Appcues'].page();
            this.router.events
              .pipe(filter((e): e is RouterEvent => e instanceof NavigationEnd))
              .subscribe(() => {
                (window as any)['Appcues'].page();
              });
          }
        });
      });

    this.store.pipe(select(companyUsesAlert), first()).subscribe((value) => {
      if (value === true) {
        /**
         * @Goat-sacrifice
         * Apenas colocando a classe no body teremos garantia de que a técnica
         * vai funcionar com scale-icons sendo usados em dialogs.
         *
         * ref: ODT-6265
         */
        document.body.classList.add('company-uses-alert');
      }
    });

    this.store
      .pipe(select(getCurrentLanguage), first())
      .subscribe((languageId) => {
        if (languageId === LANGUAGE_IDS['en-us']) {
          document.body.classList.add('lang-english');
          document.querySelector('html')?.classList.add('gtm-idioma-en');
        } else if (languageId === LANGUAGE_IDS['es-es']) {
          document.querySelector('html')?.classList.add('gtm-idioma-es');
        } else {
          document.querySelector('html')?.classList.add('gtm-idioma-pt');
        }
      });

    this.snowPlowEvents();
  }

  compareIfUserIsCreatedAfterDate(userCreatedDate: string | null) {
    if (!userCreatedDate) {
      return true;
    }

    const startDateToUseTreinaFormatted =
      moment('2020/09/01').format('YYYY-MM-DD');
    const userCreatedDateFormatted =
      moment(userCreatedDate).format('YYYY-MM-DD');

    return moment(userCreatedDateFormatted).isBefore(
      startDateToUseTreinaFormatted
    );
  }

  snowPlowEvents() {
    // this.snowPlowService.setUserId('golaço')
    this.snowPlowService.trackPageView();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.snowPlowService.trackPageView();
      }
    });
  }

  handleMenuStateToggled(menuToggled: boolean) {
    this.store.dispatch(MainMenuActions.menuToggled({ toggled: menuToggled }));
  }

  handleMenuItemClicked(itemClicked: MenuListItem) {
    this.store.dispatch(
      MainMenuActions.menuItemClicked({ menuItem: itemClicked })
    );
  }

  handleItemUploadRetry(item: UploadQueueItem) {
    this.uploadQueueService.retryItem(item);
  }

  handleItemUploadCancel(item: UploadQueueItem) {
    this.uploadQueueService.cancelItem(item);
  }

  handleUploadsCancel() {
    this.uploadQueueService.cancelItems();
  }

  handleUploadsCollapsed() {
    this.uploadQueueService.uploadsCollapsed();
  }

  handleMenuApplyChecklistClicked() {
    // window.location.href = endpoints.applyChecklistUrl;
    this.router.navigate(['/pre-apply']);
  }

  handleAccountSubscriptionSettingsClick() {
    this.router.navigate(['/company/subscription']);
  }

  handleUpgradeTrialAccountClick() {
    this.trackingService.trackLinkClick('/', 'contratacao_botao_menu_upgrade');
    this.router.navigate(['/company/subscription/plans']);
  }

  handleUpgradeIconClicked(item: MenuListItem) {
    this.store
      .select(userIsAccountManager)
      .pipe(
        first(),
        switchMap(() =>
          this.transloco.selectTranslate('flowUpgradeIcon', {}, this.scope)
        )
      )
      .subscribe((flows) => {
        this.onboardingService.startFlow(flows);
      });
  }
}
