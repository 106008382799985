import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { uploadQueueFeatureKey, uploadQueueReducer } from './+state/reducers';
import { UploadQueueService } from './upload-queue.service';
import { FormDataUploadService } from './form-data-upload.service';
import { UploadQueueEffects } from './+state/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(uploadQueueFeatureKey, uploadQueueReducer),
    EffectsModule.forFeature([UploadQueueEffects]),
  ],
  providers: [UploadQueueService, FormDataUploadService],
})
export class SharedDataAccessUploadQueueModule {}
