import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as mainMenuActions from './actions';
import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MainMenuService } from '../main-menu.service';

@Injectable()
export class MainMenuEffects {
  constructor(private actions$: Actions, private service: MainMenuService) {}

  menuToggledPreference$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mainMenuActions.menuToggled),
        map((action: any) => action.toggled),
        mergeMap((toggled) => {
          return this.service.saveMenuToggledPreference(!toggled);
        })
      ),
    { dispatch: false }
  );
}
