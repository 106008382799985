<span class="container" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <ng-content></ng-content>
</span>

<ng-template
  (detach)="handleClose()"
  cdkConnectedOverlay
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="showPopover"
  #currentOverlay="cdkConnectedOverlay"
>
  <span *ngIf="text" class="popover-content">{{ text }}</span>
  <span *ngIf="!text" class="popover-content">
    <ng-content select="[popoverContent]"></ng-content>
  </span>
</ng-template>
