import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { MenuFooterComponent } from './menu-footer/menu-footer.component';
import { MenuHeaderComponent } from './menu-header/menu-header.component';
import { MenuHelpComponent } from './menu-help/menu-help.component';
import { MenuListComponent, MenuListItemComponent } from './menu-list';
import { MenuSurfaceComponent } from './menu-surface/menu-surface.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedUtilTranslateModule } from '@checklistfacil/shared/util/translate';
import { UserCardComponent } from './user-card/user-card.component';
import { SharedUserAchievementsUiModule } from '@checklistfacil/shared/user-achievements/ui';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatRippleModule,
    MatMenuModule,
    MatTooltipModule,
    SharedUtilTranslateModule,
    SharedUserAchievementsUiModule,
    MatButtonModule,
  ],
  declarations: [
    MenuButtonComponent,
    MenuFooterComponent,
    MenuHeaderComponent,
    MenuHelpComponent,
    MenuListComponent,
    MenuListItemComponent,
    MenuSurfaceComponent,
    UserCardComponent,
  ],
  exports: [
    MenuButtonComponent,
    MenuFooterComponent,
    MenuHeaderComponent,
    MenuHelpComponent,
    MenuListComponent,
    MenuListItemComponent,
    MenuSurfaceComponent,
    UserCardComponent,
  ],
})
export class SharedMainMenuUiModule {}
