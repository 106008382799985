import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackingService } from '@checklistfacil/shared/util/tracking';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { MenuFooterInfo } from './types';

@Component({
  selector: 'cl-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiMenuFooter'
    ),
  ],
})
export class MenuFooterComponent {
  @Input() menuFooterInfo: MenuFooterInfo = {};
  @Input() hideIcons = false;
  @Input() hiddenIcons: string[] | null = [];
  @Input() userHasAccessToAchievements = true;
  @Input() isAccountManager = true;


  @Output() helpCenterClicked = new EventEmitter();
  @Output() importExportClicked = new EventEmitter();

  @Output() bugReportClicked = new EventEmitter();
  @Output() messagesClicked = new EventEmitter();

  constructor(private trackingService: TrackingService) {}

  get showHelperCenter(): boolean {
    return this.checkIfShowIconByName('helper-center');
  }

  get showImportExport(): boolean {
    return this.checkIfShowIconByName('import-export');
  }

  get showMessages(): boolean {
    return this.checkIfShowIconByName('messages');
  }

  get showBugReport(): boolean {
    return this.checkIfShowIconByName('bug-report');
  }

  checkIfShowIconByName(iconName: string) {
    return !this.hiddenIcons || !this.hiddenIcons.includes(iconName);
  }

  helpCenterButtonClicked(event: MouseEvent) {
    this.helpCenterClicked.emit(event);
  }

  importExportButtonClicked(event: MouseEvent) {
    this.importExportClicked.emit(event);
  }

  bugReportButtonClicked(event: MouseEvent) {
    this.bugReportClicked.emit(event);
  }

  messagesButtonClicked(event: MouseEvent) {
    this.messagesClicked.emit(event);
  }

  handleMyAchievementsClick(href: string | undefined) {
    if (href) {
      this.trackingService.trackLinkClick(
        href,
        'spa_minhas_conquistas_menu_rodape'
      );
    }
  }
}
