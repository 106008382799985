import { Scale } from './types';

export function checklistItemHasWeight(scaleId: number) {
  const scaleWithWeight = [
    Scale.YesNo,
    Scale.RedGreen,
    Scale.RedYellowGreen,
    Scale.SelectionSingle,
    Scale.SelectionMultiple,
  ];

  const indexOfInexistentItemInArray = -1;
  return scaleWithWeight.indexOf(scaleId) > indexOfInexistentItemInArray;
}
