export const loadAppcuesScript = (callback: () => any) => {
  const appCuesScript = document.getElementById('appCuesScript');

  if (!appCuesScript) {
    const script = document.createElement('script');
    script.id = 'appCuesScript';
    script.src = '//fast.appcues.com/90634.js';

    script.onload = () => {
      if (callback) {
        callback();
      }
    };
    document.body.appendChild(script);
  }

  if (appCuesScript && callback) {
    callback();
  }
};
