import { UploadQueueItemStatus } from '@checklistfacil/shared/util/general';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State as UploadQueueState, uploadQueueFeatureKey } from './reducers';

export const featureSelector = createFeatureSelector<UploadQueueState>(
  uploadQueueFeatureKey
);

export const show = createSelector(featureSelector, (state) => state.show);

export const isCollapsed = createSelector(
  featureSelector,
  (state) => !state.open
);

export const items = createSelector(featureSelector, (state) => state.items);

export const isUploading = createSelector(
  featureSelector,
  (state) => state.uploading
);

export const pendingItems = createSelector(items, (state) =>
  state.filter((item) => {
    return item.status === UploadQueueItemStatus.Pending;
  })
);

export const hasPendingItems = createSelector(
  pendingItems,
  (state) => state.length > 0
);
