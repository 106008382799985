import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';
import { MatFormFieldHarness } from '@angular/material/form-field/testing';
import { MatInputHarness } from '@angular/material/input/testing';

interface TextfieldHarnessFilters extends BaseHarnessFilters {
  labelText?: string;
}

export class TextfieldHarness extends ComponentHarness {
  static hostSelector = 'cl-form-textfield';

  static with(
    options: TextfieldHarnessFilters
  ): HarnessPredicate<TextfieldHarness> {
    return new HarnessPredicate<TextfieldHarness>(
      TextfieldHarness,
      options
    ).addOption('label text', options.labelText, (harness, text) =>
      HarnessPredicate.stringMatches(harness.labelText(), text)
    );
  }

  async labelText(): Promise<string | null> {
    const matFormField = await this.locatorFor(MatFormFieldHarness);
    const field = await matFormField();
    return await field.getLabel();
  }

  async setValue(value: string): Promise<void> {
    const matInput = await this.locatorFor(MatInputHarness);
    const input = await matInput();
    await input.focus();
    return await input.setValue(value);
  }

  async disabled(): Promise<boolean> {
    const matInput = await this.locatorFor(MatInputHarness);
    const input = await matInput();
    return await input.isDisabled();
  }

  async enabled(): Promise<boolean> {
    const matInput = await this.locatorFor(MatInputHarness);
    const input = await matInput();
    return await input.isDisabled().then((d) => !d);
  }
}
