import { BasicEntity } from '@checklistfacil/shared/util/general';
import { Action, createReducer, on } from '@ngrx/store';
import * as CompanyRegistrationActions from './actions';
import { BasicEntityCountryCode, Company, Registration, User } from './models';

export const COMPANY_REGISTRATION_FEATURE_KEY = 'company-registration';

export interface State extends Registration {
  callState: any;
  cities: BasicEntity[] | null;
  countryCodes: BasicEntityCountryCode[] | null;
  segments: BasicEntity[] | null;
  workers: BasicEntity[] | null;
  countries: BasicEntity[] | null;
  states: BasicEntity[] | null;
  registrationDone: boolean;
}

const initialUserState: User = {
  userName: null,
  email: null,
  password: null,
  confirmPassword: null,
  countryCode: null,
  phone: null,
  valid: null,
};

const initialCompanyState: Company = {
  companyName: null,
  segmentId: null,
  workers: null,
  countryId: null,
  stateId: null,
  cityId: null,
  hasAgreedUserTerms: null,
  valid: null,
};

export const initialState: State = {
  user: initialUserState,
  company: initialCompanyState,
  cities: null,
  callState: null,
  countryCodes: null,
  segments: null,
  workers: null,
  countries: null,
  states: null,
  registrationDone: false,
};

const companyRegistrationReducer = createReducer(
  initialState,
  on(CompanyRegistrationActions.init, (state) => ({
    ...state,
  })),
  on(CompanyRegistrationActions.updateUser, (state, { user }) => ({
    ...state,
    user: {
      ...state.user,
      ...user,
    },
  })),
  on(CompanyRegistrationActions.changeStatusUserValid, (state, { valid }) => ({
    ...state,
    user: {
      ...state.user,
      valid,
    },
  })),
  on(CompanyRegistrationActions.updateCompany, (state, { company }) => ({
    ...state,
    company: {
      ...state.company,
      ...company,
      stateId: company.countryId === null ? null : company.stateId,
      cityId:
        company.countryId === null && company.stateId === null
          ? null
          : company.cityId,
    },
  })),
  on(
    CompanyRegistrationActions.changeStatusCompanyValid,
    (state, { valid }) => ({
      ...state,
      company: {
        ...state.company,
        valid,
      },
    })
  ),
  on(
    CompanyRegistrationActions.loadCountryCodeSuccess,
    (state, { countryCodes }) => ({
      ...state,
      countryCodes,
    })
  ),
  on(
    CompanyRegistrationActions.updateUserPhoneDDI,
    (state, { countryCode }) => ({
      ...state,
      user: {
        ...state.user,
        countryCode,
      },
    })
  ),
  on(CompanyRegistrationActions.updateUserPhoneNumber, (state, { phone }) => ({
    ...state,
    user: {
      ...state.user,
      phone,
    },
  })),
  on(CompanyRegistrationActions.updateUserEmail, (state, { email }) => ({
    ...state,
    user: {
      ...state.user,
      email,
    },
  })),
  on(CompanyRegistrationActions.updateUserName, (state, { userName }) => ({
    ...state,
    user: {
      ...state.user,
      userName,
    },
  })),
  on(
    CompanyRegistrationActions.updateCompanyName,
    (state, { companyName }) => ({
      ...state,
      company: {
        ...state.company,
        companyName,
      },
    })
  ),
  on(CompanyRegistrationActions.loadSegmentsSuccess, (state, { segments }) => ({
    ...state,
    segments,
  })),
  on(CompanyRegistrationActions.loadWorkersSuccess, (state, { workers }) => ({
    ...state,
    workers,
  })),
  on(
    CompanyRegistrationActions.loadCountriesSuccess,
    (state, { countries }) => ({
      ...state,
      countries,
      states: [],
    })
  ),
  on(CompanyRegistrationActions.loadStatesSuccess, (state, { states }) => ({
    ...state,
    states,
    cities: [],
  })),
  on(CompanyRegistrationActions.loadCitiesSuccess, (state, { cities }) => ({
    ...state,
    cities,
  })),
  on(CompanyRegistrationActions.updateSegment, (state, { segment }) => ({
    ...state,
    company: {
      ...state.company,
      segmentId: segment,
    },
  })),
  on(CompanyRegistrationActions.updateSize, (state, { worker }) => ({
    ...state,
    company: {
      ...state.company,
      workers: worker,
    },
  })),
  on(CompanyRegistrationActions.registerCompanySuccess, (state) => ({
    ...state,
    registrationDone: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return companyRegistrationReducer(state, action);
}
