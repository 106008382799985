import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { mainMenuFeatureKey, reducer } from './+state';
import { MainMenuEffects } from './+state/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(mainMenuFeatureKey, reducer),
    EffectsModule.forFeature([MainMenuEffects]),
  ],
})
export class SharedMainMenuDataAccessModule {}
