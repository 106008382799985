import {
  company,
  companyBillingEmailConfirmed,
  companyHasAreaImageType,
  companyHasDepartments,
  companyHasSomaPontosScore,
  companyHasUnitType,
  companyHasWorkflows,
  companyPlanId,
  companyRegisterFilled,
  userAccesses,
} from '@checklistfacil/shared/data-access/company-configs';
/**
 * @TODO: alterar referencência a estes seletores
 *
 * Esses seletores originalmente estavam declarados no apps/checklistfacil/state
 * mas pertencem à state do company-configs. A declaração dos seletores foi movida, mas
 * para diminuir o impacto do refactor, foram reexportados aqui.
 */
import { getUser } from '@checklistfacil/shared/data-access/user';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State as RootState } from './reducers';

/**
 * @TODO: alterar referencência a estes seletores
 *
 * Esses seletores originalmente estavam declarados no apps/checklistfacil/state
 * mas pertencem à state do company-configs. A declaração dos seletores foi movida, mas
 * para diminuir o impacto do refactor, foram reexportados aqui.
 */
export {
  companyPlanId,
  hasContactInformation,
  googleAnalyticsTag,
  companyInTrial,
  companyRemainingTrialDays,
} from '@checklistfacil/shared/data-access/company-configs';

export const rootState = createFeatureSelector<RootState>('app');

export const getApiVersion = createSelector(
  rootState,
  (state) => state.apiVersion
);

export const getApiVersionAndUser = createSelector(
  getUser,
  getApiVersion,
  (user, version) => ({ user, version })
);

export const userToAnalyticsAndHelpers = createSelector(
  getUser,
  companyHasAreaImageType,
  companyHasUnitType,
  companyHasDepartments,
  companyHasWorkflows,
  companyPlanId,
  companyBillingEmailConfirmed,
  companyRegisterFilled,
  (
    user,
    hasAreaImage,
    hasUnitType,
    hasDepartments,
    hasWorkflow,
    planId,
    hasBillingEmailConfirmed,
    companyRegisterFilled
  ) => {
    let lang = '';
    switch (user.languageId) {
      case 2:
        lang = 'pt';
        break;
      case 3:
        lang = 'en';
        break;
      case 4:
        lang = 'es';
        break;
      default:
        lang = 'br';
    }
    return {
      ...user,
      hasAreaImage,
      hasUnitType,
      hasDepartments,
      hasWorkflow,
      lang,
      companyPlanId: planId,
      hasBillingEmailConfirmed,
      companyRegisterFilled,
    };
  }
);

export const userCanEditConcludedChecklists = createSelector(
  rootState,
  userAccesses,
  getUser,
  (state, accesses, user) => {
    return (
      (user.isAdmin || accesses.includes(61)) &&
      user.companyId &&
      [566, 610].includes(user.companyId)
    );
  }
);

export const companyMaxScore = createSelector(
  rootState,
  companyHasSomaPontosScore,
  company,
  (state, companyHasSomaPontos, { biggestEvaluationScore }) => {
    if (companyHasSomaPontos) {
      return biggestEvaluationScore;
    } else {
      return 100;
    }
  }
);
