import { createAction, props } from '@ngrx/store';
import { MenuListItem } from './models';

export const menuWasLoaded = createAction('[MainMenu Page] Finished');

export const loadMenu = createAction(
  '[MainMenu Page] Init',
  props<{ menus: []; menuCollapsedMobile: boolean; menuCollapsed: boolean }>()
);

export const menuClose = createAction('[Menu] Menu closed');

export const menuToggled = createAction(
  '[Menu] Menu toggled',
  props<{ toggled?: boolean }>()
);

export const menuOpen = createAction('[Menu] Menu opened');

export const menuItensChanged = createAction(
  '[Menu] Menu items changed',
  props<{ menuItems: MenuListItem[] }>()
);

export const menuItemClicked = createAction(
  '[Menu] Menu item clicked',
  props<{ menuItem: MenuListItem }>()
);
