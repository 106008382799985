import {
  getBrowserCultureLang,
  getBrowserLang,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { availableLangs } from './+state/translate.models';

type ImporterType = (lang: string, root: string) => Promise<unknown>;

export function translocoLoader(
  importer: ImporterType,
  scopeName: string,
  root = 'i18n'
) {
  return {
    provide: TRANSLOCO_SCOPE,
    useValue: {
      scope: scopeName,
      loader: availableLangs.reduce(
        (acc: { [key: string]: ImporterType }, lang) => {
          acc[lang] = () => importer(lang, root);
          return acc;
        },
        {} as any
      ),
    },
  };
}

export function appLangFromBrowserLang(lang?: string): string | null {
  if (lang) {
    // Check if send lang is one of the available languages
    return (
      availableLangs.find((availableLang) => availableLang === lang) || null
    );
  } else {
    const browserLang = getBrowserLang();
    const browserCultureLang = getBrowserCultureLang().toLowerCase();

    // Check if browserCultureLang is one of the available languages
    if (availableLangs.some((val: string) => val === browserCultureLang)) {
      return browserCultureLang || null;
    }

    // Check if browserLang is one of the available languages
    if (availableLangs.some((val: string) => val === browserLang)) {
      return browserLang || null;
    }

    /**
     * Check if browserLang is the first part of one of the available languages
     * For example: if browserLang returns `en` and `en-us` is one of the available languages
     */
    const index = availableLangs.findIndex(
      (val: string) => val.split('-')[0] === browserLang
    );
    if (index !== -1) {
      return availableLangs[index];
    }

    return '';
  }
}
