import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedUiErrorsModule } from '@checklistfacil/shared/ui/errors';
import { NotFoundErrorComponent } from './_errors/not-found-error';
import { AuthenticateCheckGuard } from './_guards/authenticate-check.guard';
import { UserCanAccessNotificationGroupSetupGuard } from './_guards/user-can-access-notification-group-setup.guard';
import { UserCanAccessOnlySubscriptionPlansGuard } from './_guards/user-can-access-only-subscription-plans.guard';
import { UserInfoGuard } from './_guards/user-info.guard';
import { UserIsAccountManagerGuard } from './_guards/user-is-account-manager.guard';
import { HallComponent } from './hall';
import { GoogleTagManagerResolver } from '@checklistfacil/shared/tracking/data-access/google-tag-manager';

const routes: Routes = [
  {
    path: '',
    component: HallComponent,
    canActivate: [AuthenticateCheckGuard, UserInfoGuard],
    canActivateChild: [UserCanAccessOnlySubscriptionPlansGuard],
    resolve: [GoogleTagManagerResolver],
    children: [
      {
        path: '',
        redirectTo: 'checklist',
        pathMatch: 'full',
      },
      {
        path: 'checklist',
        loadChildren: () =>
          import('@checklistfacil/checklist/feature-shell').then(
            (m) => m.ChecklistFeatureShellModule
          ),
      },
      {
        path: 'disputes',
        loadChildren: () =>
          import('@checklistfacil/contestacao/feature-shell').then(
            (m) => m.ContestacaoFeatureShellModule
          ),
      },
      {
        path: 'evaluation',
        loadChildren: () =>
          import(
            '@checklistfacil/visualizar-checklist/feature/view-checklist'
          ).then((m) => m.VisualizarChecklistModule),
      },
      {
        path: 'pre-apply',
        loadChildren: () =>
          import('@checklistfacil/checklist/pre-apply/feature').then(
            (m) => m.PreApplyModule
          ),
      },
      {
        path: 'company/subscription',
        canActivate: [UserIsAccountManagerGuard],
        loadChildren: () =>
          import('@checklistfacil/company/subscription/feature/shell').then(
            (m) => m.CompanySubscriptionFeatureShellModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('@checklistfacil/reports/shell').then(
            (m) => m.ReportsShellModule
          ),
      },
      {
        path: 'workflow',
        loadChildren: () =>
          import('@checklistfacil/workflow/feature-shell').then(
            (m) => m.FeatureShellModule
          ),
      },
      {
        path: 'notifications/setup',
        canActivate: [UserCanAccessNotificationGroupSetupGuard],
        loadChildren: () =>
          import('@checklistfacil/notifications/setup/feature-shell').then(
            (m) => m.NotificationsSetupFeatureShellModule
          ),
      },
      {
        path: 'achievements',
        loadChildren: () =>
          import('@checklistfacil/user/achievements/feature/shell').then(
            (m) => m.UserAchievementsFeatureShellModule
          ),
      },
      {
        path: 'integration/sienge',
        loadChildren: () =>
          import('@checklistfacil/integration/sienge/feature-shell').then(
            (module) => module.IntegrationSiengeFeatureShellModule
          ),
      },
      {
        path: 'analytics-bi/config',
        loadChildren: () =>
          import('@checklistfacil/analytics-bi/feature-config').then(
            (module) => module.AnalyticsBiFeatureConfigModule
          ),
      },
    ],
  },
  {
    path: 'apply',
    resolve: [GoogleTagManagerResolver],
    loadChildren: () =>
      import('@checklistfacil/apply-checklist/feature/shell').then(
        (m) => m.ApplyChecklistFeatureShellModule
      ),
  },
  {
    path: 'company-survey',
    canActivate: [AuthenticateCheckGuard, UserInfoGuard],
    resolve: [GoogleTagManagerResolver],
    loadChildren: () =>
      import('@checklistfacil/company/survey/feature/shell').then(
        (module) => module.CompanySurveyFeatureShellModule
      ),
  },
  {
    path: 'company-registration',
    resolve: [GoogleTagManagerResolver],
    loadChildren: () =>
      import('@checklistfacil/company/registration/feature/shell').then(
        (m) => m.CompanyRegistrationFeatureShellModule
      ),
  },
  {
    path: 'action-plan/solution-by-link',
    loadChildren: () =>
      import(
        '@checklistfacil/action-plans/solution-by-link/feature-shell'
      ).then((m) => m.ActionPlansSolutionByLinkFeatureShellModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@checklistfacil/login/feature/shell').then(
        (m) => m.LoginFeatureShellModule
      ),
  },
  {
    path: 'common-error',
    loadChildren: () =>
      import('@checklistfacil/shared/feature/error').then(
        (module) => module.SharedFeatureErrorModule
      ),
  },
  {
    path: '**',
    component: NotFoundErrorComponent,
  },
];

@NgModule({
  imports: [
    SharedUiErrorsModule,
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
