<mat-form-field
  [appearance]="appearance"
  *transloco="let t; read: 'clFormSelectMultiple'"
>
  <mat-select
    class="select-multiple"
    [disabled]="disabled"
    [value]="selected"
    (selectionChange)="handleSelectChange($event)"
    [multiple]="true"
  >
    <mat-select-trigger>{{
      t('selectedCount', { count: selected.length, name: selectedName })
    }}</mat-select-trigger>
    <mat-option
      class="select-multiple__option"
      [disabled]="!option.active"
      *ngFor="let option of options"
      [value]="option.value"
      >{{ option.name }}</mat-option
    >
  </mat-select>
  <mat-label>{{ label }}</mat-label>
</mat-form-field>
