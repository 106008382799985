import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  loadSegments,
  loadSegmentsError,
  loadSegmentsSuccess,
} from './+state/actions';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreloadSegmentsResolver implements Resolve<boolean> {
  resolve(): Observable<boolean> {
    this.store.dispatch(loadSegments());
    return this.actions$.pipe(
      ofType(loadSegmentsSuccess, loadSegmentsError),
      map(() => true),
      first()
    );
  }

  constructor(private actions$: Actions, private store: Store) {}
}
