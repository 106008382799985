import { AbstractControl } from '@angular/forms';

function inCollection(value: unknown, collection: any[]): boolean {
  if (typeof value === 'string') {
    return (
      collection
        .map((collectionValue: unknown) => {
          return typeof collectionValue === 'string'
            ? collectionValue.toLowerCase()
            : collectionValue;
        })
        .indexOf(value.toLowerCase()) > -1
    );
  } else {
    return collection.indexOf(value) > -1;
  }
}

export function NotInCollectionValidator(collection: any[]) {
  return (control: AbstractControl) => {
    return control.value &&
      collection.length &&
      inCollection(control.value, collection)
      ? {
          inCollection: true,
        }
      : null;
  };
}
