import { Action, createReducer, on } from '@ngrx/store';
import {
  loadBusinessAreas,
  loadBusinessAreasSuccess,
  loadChecklistPlansSuccess,
  loadCities,
  loadCitiesSuccess,
  loadCompanyRegisterSuccess,
  loadCountries,
  loadCountriesSuccess,
  loadCountryStates,
  loadCountryStatesSuccess,
  loadMexicanCfdi,
  loadMexicanCfdiSuccess,
  loadMexicanPaymentForm,
  loadMexicanPaymentFormSuccess,
  loadMexicanPaymentMethod,
  loadMexicanPaymentMethodSuccess,
  loadMexicanTaxRegime,
  loadMexicanTaxRegimeSuccess,
  loadSegments,
  loadSegmentsSuccess,
  loadSubSegments,
  loadSubSegmentsSuccess,
  selectedCountryId,
  updateCompanyRegister,
} from './actions';
import {
  ExternalResource,
  parseCompanyBillingCurrency,
  parseCompanyCategorization,
  parseCompanyIdentification,
  State,
} from './models';

export const COMPANY_SUBSCRIPTION_FEATURE_KEY = 'company-subscription';

export const initialState: State = {
  businessAreas: [],
  cities: [],
  companyCategorization: {
    companyBusinessArea: [],
    companyHasFranchise: false,
    segment: [],
    subsegment: [],
    growthProjection: '',
  },
  companyCurrency: {
    billingCurrency: [],
    paymentForm: [],
  },
  companyInformation: {
    address: '',
    addressComplement: '',
    billingEmail: '',
    city: [],
    cnpj: '',
    companyName: '',
    country: [],
    countryState: [],
    district: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    phone: '',
    postalCode: '',
    number: '',
    rfc: '',
    additionalInvoiceEmail: '',
    mexicanPaymentForm: [],
    mexicanCfdi: [],
    mexicanPaymentMethod: [],
    mexicanTaxRegime: [],
    taxId: '',
  },
  countries: [],
  countryStates: [],
  currentCompanyPlan: null,
  segments: [],
  subsegments: [],
  resourceLoading: [],
  checklistPlans: null,
  mexicanPaymentForms: [],
  mexicanCfdis: [],
  mexicanPaymentMethods: [],
  mexicanTaxRegimes: [],
};

const featureReducer = createReducer(
  initialState,
  on(loadCompanyRegisterSuccess, (state, { payload }) => {
    return {
      ...state,
      companyCategorization: parseCompanyCategorization(payload),
      companyInformation: parseCompanyIdentification(payload),
      companyCurrency: parseCompanyBillingCurrency(payload),
      countries: payload.country ? [payload.country] : [],
      countryStates: payload.countryState ? [payload.countryState] : [],
      cities: payload.city ? [payload.city] : [],
      segments: payload.segment ? [payload.segment] : [],
      subsegments: payload.subsegment ? [payload.subsegment] : [],
      businessAreas: payload.companyBusinessArea
        ? payload.companyBusinessArea
        : [],
      mexicanPaymentForms: payload.mexicanPaymentForm
        ? [payload.mexicanPaymentForm]
        : [],
      mexicanCfdis: payload.mexicanCfdi ? [payload.mexicanCfdi] : [],
      mexicanPaymentMethods: payload.mexicanPaymentMethod
        ? [payload.mexicanPaymentMethod]
        : [],
      mexicanTaxRegimes: payload.mexicanTaxRegime
        ? [payload.mexicanTaxRegime]
        : [],
    };
  }),
  on(loadSegments, (state) => {
    return {
      ...state,
      resourceLoading: [...state.resourceLoading, ExternalResource.SEGMENTS],
    };
  }),
  on(loadSegmentsSuccess, (state, { payload }) => {
    return {
      ...state,
      segments: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.SEGMENTS
      ),
    };
  }),
  on(loadBusinessAreas, (state) => {
    return {
      ...state,
      resourceLoading: [
        ...state.resourceLoading,
        ExternalResource.BUSINESS_AREAS,
      ],
    };
  }),
  on(loadBusinessAreasSuccess, (state, { payload }) => {
    return {
      ...state,
      businessAreas: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.BUSINESS_AREAS
      ),
    };
  }),
  on(loadSubSegments, (state) => {
    return {
      ...state,
      resourceLoading: [...state.resourceLoading, ExternalResource.SUBSEGMENTS],
    };
  }),
  on(loadSubSegmentsSuccess, (state, { payload }) => {
    return {
      ...state,
      subsegments: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.SUBSEGMENTS
      ),
    };
  }),
  on(loadCountries, (state) => {
    return {
      ...state,
      resourceLoading: [...state.resourceLoading, ExternalResource.COUNTRIES],
    };
  }),
  on(loadCountriesSuccess, (state, { payload }) => {
    return {
      ...state,
      countries: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.COUNTRIES
      ),
    };
  }),
  on(loadCountryStates, (state) => {
    return {
      ...state,
      resourceLoading: [
        ...state.resourceLoading,
        ExternalResource.COUNTRY_STATES,
      ],
    };
  }),
  on(loadCountryStatesSuccess, (state, { payload }) => {
    return {
      ...state,
      countryStates: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.COUNTRY_STATES
      ),
    };
  }),
  on(loadCities, (state) => {
    return {
      ...state,
      resourceLoading: [...state.resourceLoading, ExternalResource.CITIES],
    };
  }),
  on(loadCitiesSuccess, (state, { payload }) => {
    return {
      ...state,
      cities: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.CITIES
      ),
    };
  }),
  on(loadMexicanPaymentForm, (state) => {
    return {
      ...state,
      resourceLoading: [
        ...state.resourceLoading,
        ExternalResource.MEXICAN_PAYMENT_FORMS,
      ],
    };
  }),
  on(loadMexicanPaymentFormSuccess, (state, { payload }) => {
    return {
      ...state,
      mexicanPaymentForms: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.MEXICAN_PAYMENT_FORMS
      ),
    };
  }),
  on(loadMexicanPaymentMethod, (state) => {
    return {
      ...state,
      resourceLoading: [
        ...state.resourceLoading,
        ExternalResource.MEXICAN_PAYMENT_FORMS,
      ],
    };
  }),
  on(loadMexicanPaymentMethodSuccess, (state, { payload }) => {
    return {
      ...state,
      mexicanPaymentMethods: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.MEXICAN_PAYMENT_METHODS
      ),
    };
  }),
  on(loadMexicanTaxRegime, (state) => {
    return {
      ...state,
      resourceLoading: [
        ...state.resourceLoading,
        ExternalResource.MEXICAN_TAX_REGIMES,
      ],
    };
  }),
  on(loadMexicanTaxRegimeSuccess, (state, { payload }) => {
    return {
      ...state,
      mexicanTaxRegimes: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.MEXICAN_TAX_REGIMES
      ),
    };
  }),
  on(loadMexicanCfdi, (state) => {
    return {
      ...state,
      resourceLoading: [
        ...state.resourceLoading,
        ExternalResource.MEXICAN_CFDIS,
      ],
    };
  }),
  on(loadMexicanCfdiSuccess, (state, { payload }) => {
    return {
      ...state,
      mexicanCfdis: payload,
      resourceLoading: state.resourceLoading.filter(
        (term) => term !== ExternalResource.MEXICAN_CFDIS
      ),
    };
  }),
  on(
    updateCompanyRegister,
    (state, { categorization, currency, identification }) => {
      return {
        ...state,
        companyCategorization: categorization,
        companyCurrency: currency,
        companyInformation: identification,
      };
    }
  ),
  on(loadChecklistPlansSuccess, (state, { payload }) => {
    return {
      ...state,
      checklistPlans: payload,
    };
  }),
  on(selectedCountryId, (state, { country }) => ({
    ...state,
    companyInformation: {
      ...state.companyInformation,
      country,
    },
  }))
);

export function companySubscriptionReducer(state: State, action: Action) {
  return featureReducer(state, action);
}
