import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonErrorComponent } from './common-error';
import { SharedUtilTranslateModule } from '@checklistfacil/shared/util/translate';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [CommonErrorComponent],
  exports: [CommonErrorComponent],
  imports: [CommonModule, SharedUtilTranslateModule, MatButtonModule],
})
export class SharedUiErrorsModule {}
