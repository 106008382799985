import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { EnvironmentSelectors } from '@checklistfacil/shared/data-access/environment';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerResolver implements Resolve<void> {
  constructor(private store: Store) {}
  resolve(): Observable<void> {
    return this.store.pipe(
      select(EnvironmentSelectors.environmentName),
      first(),
      map((environment) => {
        if (environment !== 'prod') {
          return;
        }
        if (document.querySelectorAll('.gtag').length !== 0) {
          return;
        }
        const gtag = document.createElement('script');

        gtag.classList.add('gtag');
        gtag.innerText = `(function(w, d, s, l, i) {w[l] = w[l] || [];w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', 'GTM-PBMMKH4');`;

        document.head.append(gtag);
      })
    );
  }
}
