import { Injectable } from '@angular/core';
import { ProcessingState } from '@checklistfacil/shared/util/general';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { init, loadEnvironmentSuccess } from './+state/environment.actions';
import { Environment } from './+state/environment.models';
import { getEnvironmentState } from './+state/environment.selectors';

/**
 * @deprecated
 * Essa service existe para a transição da ConfigAssetLoader para a shared/data-access/environment.
 */
@Injectable({
  providedIn: 'root',
})
export class DeprecatedConfigAssetLoaderService {
  constructor(private actions$: Actions, private store: Store) {}

  public init(): Observable<Environment> {
    this.store.dispatch(init());
    return this.actions$.pipe(
      ofType(loadEnvironmentSuccess),
      first(),
      map(({ environment }) => environment)
    );
  }

  public loadConfigurations(): Observable<Environment> {
    return this.store.pipe(
      select(getEnvironmentState),
      filter((state) => state.callState === ProcessingState.LOADED),
      first()
    );
  }
}
