import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigAssetLoaderService } from '@checklistfacil/shared/util/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EnvironmentEffects } from './+state/environment.effects';
import * as fromEnvironment from './+state/environment.reducer';
import { DeprecatedConfigAssetLoaderService } from './config-asset-loader.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromEnvironment.ENVIRONMENT_FEATURE_KEY,
      fromEnvironment.reducer
    ),
    EffectsModule.forFeature([EnvironmentEffects]),
  ],
  providers: [
    DeprecatedConfigAssetLoaderService,
    {
      provide: ConfigAssetLoaderService,
      useClass: DeprecatedConfigAssetLoaderService,
    },
  ],
})
export class SharedDataAccessEnvironmentModule {}
