<div class="editor">
  <!-- toolbar -->
  <div class="toolbar" *transloco="let t; read: 'clUiRichEditor'">
    <!-- font-style -->
    <div class="toolbar__group">
      <cl-form-select
        class="select-format select-format--small qa-select qa-select-font-size"
        [title]="t('font-size')"
        [disabled]="disabled"
        [value]="toolbarState.size"
        [condensed]="true"
        [items]="fontSizes"
        [emptyOption]="false"
        (selectionChange)="formatWithFontSize($event)"
      ></cl-form-select>
    </div>
    <div class="toolbar__group">
      <button
        type="button"
        class="button-format qa-button qa-button-bold"
        mat-icon-button
        [title]="t('bold')"
        [disabled]="disabled"
        [class.button-format--active]="toolbarState.bold"
        (click)="formatWithBold()"
      >
        <mat-icon>format_bold</mat-icon>
      </button>
      <button
        type="button"
        class="button-format qa-button qa-button-italic"
        mat-icon-button
        [title]="t('italic')"
        [disabled]="disabled"
        [class.button-format--active]="toolbarState.italic"
        (click)="formatWithItalic()"
      >
        <mat-icon>format_italic</mat-icon>
      </button>
      <button
        type="button"
        class="button-format qa-button qa-button-underline"
        mat-icon-button
        [title]="t('underline')"
        [disabled]="disabled"
        [class.button-format--active]="toolbarState.underline"
        (click)="formatWithUnderline()"
      >
        <mat-icon>format_underline</mat-icon>
      </button>
      <button
        type="button"
        disabled
        class="button-format qa-button qa-button-strike"
        mat-icon-button
        [title]="t('strike')"
        [disabled]="disabled"
        [class.button-format--active]="toolbarState.strike"
        (click)="formatWithStrike()"
      >
        <mat-icon>format_strikethrough</mat-icon>
      </button>
    </div>
    <!-- font-style end -->
    <!-- color -->
    <div class="toolbar__group">
      <button
        type="button"
        class="button-format qa-button qa-button-text-color"
        [title]="t('color')"
        [disabled]="disabled"
        [clUiMenuSurfaceTrigger]="textColorPicker"
        [menuSurfacePosition]="surfacePosition"
        mat-icon-button
      >
        <mat-icon>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="format_color_text_24px">
              <path
                id="Shape"
                d="M0 18H24V24H0V18Z"
                [attr.fill]="toolbarState.color"
                [attr.fill-opacity]="disabled ? 0.38 : 1"
              />
              <path
                id="Subtract"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.5 15.5L11 1.5H13L18.49 15.5H16.24L15.12 12.5H8.87L7.75 15.5H5.5ZM12 4.17001L9.62 10.5H14.38L12 4.17001Z"
                fill="black"
                [attr.fill-opacity]="disabled ? 0.38 : 0.87"
              />
            </g>
          </svg>
        </mat-icon>
      </button>
      <button
        type="button"
        class="button-format qa-button qa-button-bg-text-color"
        [title]="t('background')"
        [disabled]="disabled"
        [clUiMenuSurfaceTrigger]="fillColorPicker"
        [menuSurfacePosition]="surfacePosition"
        mat-icon-button
      >
        <mat-icon>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="format_color_fill_24px">
              <path
                id="Path"
                d="M0 18H24V24H0V18Z"
                [attr.fill]="toolbarState.background"
                [attr.fill-opacity]="disabled ? 0.38 : 1"
              />
              <path
                id="icon/editor/format_color_fill_24px"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.5601 8.94L7.62006 0L6.21006 1.41L8.59006 3.79L3.44006 8.94C2.85006 9.53 2.85006 10.48 3.44006 11.06L8.94006 16.56C9.23006 16.85 9.62006 17 10.0001 17C10.3801 17 10.7701 16.85 11.0601 16.56L16.5601 11.06C17.1501 10.48 17.1501 9.53 16.5601 8.94ZM5.21006 10L10.0001 5.21L14.7901 10H5.21006ZM17.0001 15C17.0001 13.67 19.0001 11.5 19.0001 11.5C19.0001 11.5 21.0001 13.67 21.0001 15C21.0001 16.1 20.1001 17 19.0001 17C17.9001 17 17.0001 16.1 17.0001 15Z"
                fill="black"
                [attr.fill-opacity]="disabled ? 0.38 : 0.87"
              />
            </g>
          </svg>
        </mat-icon>
      </button>
    </div>
    <!-- color end -->
    <!-- alignment -->
    <div class="toolbar__group">
      <button
        type="button"
        mat-icon-button
        class="button-format qa-button qa-button-align-left"
        [title]="t('align-left')"
        [disabled]="disabled"
        [class.button-format--active]="!toolbarState.align"
        (click)="formatWithAlignLeft()"
      >
        <mat-icon>format_align_left</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        class="button-format qa-button qa-button-align-center"
        [title]="t('align-center')"
        [disabled]="disabled"
        [class.button-format--active]="toolbarState.align === 'center'"
        (click)="formatWithAlignCenter()"
      >
        <mat-icon>format_align_center</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        class="button-format qa-button qa-button-align-right"
        [title]="t('align-right')"
        [disabled]="disabled"
        [class.button-format--active]="toolbarState.align === 'right'"
        (click)="formatWithAlignRight()"
      >
        <mat-icon>format_align_right</mat-icon>
      </button>
    </div>
    <!-- alignment end -->
    <!-- link -->
    <div class="toolbar__group">
      <button
        type="button"
        class="button-format qa-button qa-button-hyperlink"
        mat-icon-button
        [title]="t('hyperlink')"
        [disabled]="disabled"
        (click)="openInsertLinkSurface()"
        [clUiMenuSurfaceTrigger]="insertLinkSurface"
        [menuSurfacePosition]="surfacePosition"
      >
        <mat-icon>insert_link</mat-icon>
      </button>
    </div>
    <!-- link end -->
    <!-- remove format -->
    <div class="toolbar__group">
      <button
        type="button"
        class="button-format qa-button qa-button-format-clear"
        mat-icon-button
        [title]="t('clear')"
        [disabled]="disabled"
        (click)="formatClear()"
      >
        <mat-icon>format_clear</mat-icon>
      </button>
    </div>
    <!-- remove format end -->
  </div>
  <!-- toolbar end -->
  <!-- editor -->
  <div #editor></div>
  <!-- editor end -->
  <!-- surface text color -->
  <cl-menu-surface #textColorPicker>
    <div class="colorpicker-wrapper" *transloco="let t; read: 'clUiRichEditor'">
      <cl-ui-colorpicker [(color)]="toolbarState.color"></cl-ui-colorpicker>
      <button
        class="btn-apply-color qa-button qa-button-apply-text-color"
        type="button"
        mat-button
        (click)="formatWithTextColor()"
        color="primary"
        >{{ t('apply') }}</button
      >
    </div>
  </cl-menu-surface>
  <!-- surface text color end -->

  <!-- surface background color -->
  <cl-menu-surface #fillColorPicker>
    <div class="colorpicker-wrapper" *transloco="let t; read: 'clUiRichEditor'">
      <cl-ui-colorpicker
        [(color)]="toolbarState.background"
      ></cl-ui-colorpicker>
      <button
        class="btn-apply-color qa-button qa-button-apply-bg-text-color"
        type="button"
        mat-button
        (click)="formatWithFillColor()"
        color="primary"
        >{{ t('apply') }}</button
      >
    </div>
  </cl-menu-surface>
  <!-- surface background color end -->

  <!-- surface background color -->
  <cl-menu-surface #insertLinkSurface>
    <div class="insert-link" *transloco="let t; read: 'clUiRichEditor'">
      <cl-form-textfield
        [(ngModel)]="link.text"
        [label]="t('link-text')"
      ></cl-form-textfield>
      <cl-form-textfield
        [(ngModel)]="link.url"
        [label]="t('link-url')"
      ></cl-form-textfield>
      <button
        mat-button
        class="qa-button qa-button-insert-link"
        type="button"
        color="primary"
        (click)="insertLink()"
        >{{ t('insert') }}</button
      >
    </div>
  </cl-menu-surface>
  <!-- surface background color end -->
</div>

<ng-content class="error-container"> </ng-content>
