import { ComponentHarness } from '@angular/cdk/testing';

export class HeaderHarness extends ComponentHarness {
  static hostSelector = 'cl-ui-header';

  protected getHeaderTitle = this.locatorFor('.qa-header-title');
  protected getButtonBack = this.locatorFor('.qa-button-go-back');

  async getTitleText() {
    const headerTitle = await this.getHeaderTitle();
    return await headerTitle.text();
  }

  async isButtonBackAvailable() {
    return (await this.getButtonBack()) !== null;
  }

  async clickButtonBack() {
    const button = await this.getButtonBack();
    return button?.click();
  }
}
