import { AchievementIcon } from '@checklistfacil/shared/user-achievements/util';

export interface AchievementGroups {
  group: string;
  achievements: BasicAchievement[];
  completed: boolean;
}

export interface BasicAchievement {
  token: string;
  completed: boolean;
}

export interface CompanyNumbers {
  appliedChecklists: number | null;
  createdChecklists: number | null;
  createdUnits: number | null;
  registeredUsers: number | null;
  createdActionPlans: number | null;
  completedActionPlans: number | null;
}

// TODO: duplicado da lib de ui
export interface AchievementIconWithActive {
  icon: AchievementIcon;
  active: boolean | undefined;
}
