import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarningComponent } from './warning/warning.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [
    WarningComponent
  ],
  exports: [
    WarningComponent
  ],
})
export class SharedUiWarningsModule {}
