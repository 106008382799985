import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-ui-configuration-extra',
  templateUrl: './configuration-extra.component.html',
  styleUrls: ['./configuration-extra.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ maxHeight: 0 }),
        animate('300ms', style({ maxHeight: 300 })),
      ]),
      transition(':leave', [
        style({ maxHeight: 300 }),
        animate('300ms', style({ maxHeight: 0 })),
      ]),
    ]),
  ],
})
export class ConfigurationExtraComponent {
  @Input() label: string | undefined;
  @Input() description: string | undefined;
  @Input() infoMessage: string | undefined;
}
