<cl-ui-dialog
  *transloco="let t; read: 'clUiDependenciesDialog'"
  [title]="t('dialogTitle')"
  titleIcon="account_tree"
>
  <div dialogContent>
    <div class="dependencies-wrapper">
      <ng-container *ngIf="hasParent">
        <cl-ui-dependency-card-item
          [name]="parent?.name"
          [scaleText]="parent?.scaleText"
          [hasScaleIcons]="!!parent?.hasScaleIcons"
          [scaleIcons]="parent?.scaleIcons"
        ></cl-ui-dependency-card-item>
        <div class="separator">
          <div class="separator__icon">
            <mat-icon [inline]="true">link</mat-icon>
          </div>
          <span class="separator__text">{{ t('blockedBy') }}</span>
        </div>
      </ng-container>
      <cl-ui-dependency-card-item
        [selected]="true"
        [name]="current?.name"
        [scaleText]="current?.scaleText"
        [hasScaleIcons]="!!current?.hasScaleIcons"
        [scaleIcons]="current?.scaleIcons"
      ></cl-ui-dependency-card-item>
      <ng-container *ngIf="hasChildren">
        <div class="separator">
          <div class="separator__icon">
            <mat-icon [inline]="true">link</mat-icon>
          </div>
          <span class="separator__text">{{ t('blocks') }}</span>
        </div>
        <div class="children-wrapper">
          <cl-ui-dependency-card-item
            class="dependency-child"
            *ngFor="let child of children"
            [name]="child?.name"
            [scaleText]="child?.scaleText"
            [hasScaleIcons]="!!child?.hasScaleIcons"
            [scaleIcons]="child?.scaleIcons"
          ></cl-ui-dependency-card-item>
        </div>
      </ng-container>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="true" mat-raised-button color="primary">{{
      t('confirmButtonLabel')
    }}</button>
  </mat-dialog-actions>
</cl-ui-dialog>
