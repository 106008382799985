import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ScaleData } from '@checklistfacil/shared/util/item-scale';

@Component({
  selector: 'cl-ui-scale-icons',
  templateUrl: './scale-icons.component.html',
  styleUrls: ['./scale-icons.component.scss'],
})
export class ScaleIconsComponent implements OnInit, OnChanges {
  @Input() boxHeight = '40px';
  @Input() boxWidth = '96px';
  @Input() iconHeight = '16px';
  @Input() iconWidth = '16px';
  @Input() scaleData: ScaleData | undefined = {};
  @Input() overrideColor: string | undefined;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.setPropertyStyle(this.overrideColor);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.overrideColor) {
      return;
    }
    const { currentValue, previousValue, firstChange } = changes.overrideColor;

    if (currentValue !== previousValue && !firstChange) {
      this.setPropertyStyle(currentValue);
    }
  }

  setPropertyStyle(color: string | undefined) {
    /**
     * @goat-sacrifice
     * Não é possível alterar variáveis css pelo ngStyle,
     * o angular sanitiza e acaba removendo a regra
     * Esse problema foi resolvido na versão 9.
     */

    if (!color) {
      return;
    }

    this.elementRef.nativeElement.style.setProperty('--override-color', color);
  }
}
