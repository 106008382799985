import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { EnvironmentSelectors } from '@checklistfacil/shared/data-access/environment';
import { catchError, filter, map, mergeMap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateCheckGuard implements CanActivate, CanActivateChild {
  canActivateChild() {
    return this.store.pipe(
      select(EnvironmentSelectors.accountsApiUrl),
      filter((accountsApiUrl) => accountsApiUrl !== null),
      mergeMap((accountsApiUrl) =>
        this.http.get(`${accountsApiUrl}/v2/authenticate/check`).pipe(
          map(() => true),
          catchError(() => this.router.navigate(['/login'])),
          shareReplay(1)
        )
      ),
      shareReplay(1)
    );
  }

  canActivate() {
    return this.canActivateChild();
  }

  constructor(
    private router: Router,
    private store: Store,
    private http: HttpClient
  ) {}
}
