import { BasicEntity } from '@checklistfacil/shared/util/general';

export interface DialogSelectData {
  items: DialogSelectOptionItem[];
  icon: string;
  instructionText: string;
  title: string;
  singleSelection: boolean;
  emptyItemsText: string;
}

export interface DialogSelectOptionItem extends BasicEntity {
  checked: boolean;
  indeterminate: boolean;
}
