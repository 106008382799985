import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
})
export class MenuHeaderComponent {
  @Input() companyLogo: string | undefined;
  @Input() dashboardUrl: string | undefined;
  @Output() menuStateToggled = new EventEmitter();

  handleExpandMenuClick() {
    this.menuStateToggled.emit();
  }
}
