import { Component, Inject } from '@angular/core';
import { DependenciesDialogData } from './types';
import { DependencyItemCard } from '../dependency-card-item/types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { translocoLoader } from '@checklistfacil/shared/util/translate';

@Component({
  selector: 'cl-ui-dependencies-dialog',
  templateUrl: './dependencies-dialog.component.html',
  styleUrls: ['./dependencies-dialog.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiDependenciesDialog'
    ),
  ],
})
export class DependenciesDialogComponent {
  parent: DependencyItemCard;
  current: DependencyItemCard;
  children: DependencyItemCard[];

  constructor(
    public dialogRef: MatDialogRef<DependenciesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DependenciesDialogData
  ) {
    this.parent = data.parent;
    this.current = data.current;
    this.children = data.children || [];
  }

  get hasParent() {
    return this.parent !== undefined && this.parent !== null;
  }

  get hasChildren() {
    return this.children.length > 0;
  }
}
