import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { MainMenuActions } from '@checklistfacil/shared/main-menu/data-access';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';
import { browserIdentification } from '@checklistfacil/shared/util/general';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap, retry, tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { endpoints } from '../endpoints';
import { getApiVersion } from '../state';
import { appLoadError, appLoadSuccess } from '../state/actions';
import { State as AppState } from '../state/reducers';

@Injectable({
  providedIn: 'root',
})
export class UserInfoGuard implements CanActivate {
  private appConfig: Configuration | undefined;
  static loaded = false;

  constructor(
    private store: Store<AppState>,
    private appService: AppService,
    configService: ConfigAssetLoaderService
  ) {
    configService
      .loadConfigurations()
      .subscribe((config) => (this.appConfig = config));
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(getApiVersion),
      first(),
      mergeMap((version) => {
        if (version !== '') {
          return of(true);
        }
        return this.appService.getUserInfo().pipe(
          retry(2),
          tap(
            (response) => {
              const { forcePasswordChange } = response.user;
              if (forcePasswordChange !== undefined && forcePasswordChange) {
                if (!this.appConfig) {
                  throw new Error('appConfig not loaded');
                }

                window.location.href = endpoints.appDashboard(this.appConfig);
                return;
              }

              /* @TODO: implementar lógica baseado no "visualizou"
               * O usuário pode ter visualizado a dialog de termos de uso, mas não aceito.
               * Porém hoje o /me não traz essa informação - traz apenas o "acceptTerms".
               * O correto é redirecionar o usuário para a dashboard, garantido que ele ao menos
               * visualize a dialog, caso tenha acessado uma rota da SPA via URL.
               */
              // const { acceptTerms } = response.user;
              //
              // if (acceptTerms !== undefined && acceptTerms !== true) {
              //   window.location.href = endpoints.appDashboard(this.appConfig);
              //   return;
              // }
              this.store.dispatch(appLoadSuccess({ payload: response }));

              const { isMobile } = browserIdentification();

              this.store.dispatch(
                MainMenuActions.loadMenu({
                  menus: response.menus,
                  menuCollapsed: response.menuCollapsed,
                  menuCollapsedMobile: isMobile ?? false,
                })
              );
            },
            (error) => {
              if (error.status !== 401) {
                this.store.dispatch(appLoadError({ error }));
              }
            }
          ),
          map(() => true),
          catchError(() => of(false))
        );
      })
    );
  }
}
