<ng-template transloco let-t translocoRead="clUiMenuListItem">
  <div
    class="list-item-wrapper"
    [matTooltip]="
      item?.availableInPlan
        ? t('availableInPlan', { plan: item?.availableInPlan })
        : undefined
    "
    (click)="handleUpgradeIconClick($event)"
    matTooltipPosition="after"
  >
    <mat-list-item
      class="list-item"
      [ngClass]="{
        'list-item--has-submenu': hasChildren,
        'list-item--selected': item?.selected
      }"
      (click)="handleListItemClick($event)"
      [disabled]="!!item?.availableInPlan"
    >
      <mat-icon
        *ngIf="item?.icon"
        class="list-item__icon"
        aria-hidden="false"
        >{{ item?.icon }}</mat-icon
      >
      <a
        class="list-item__content qa-menu"
        [id]="item?.id"
        [href]="item?.url"
        [target]="target"
        *ngIf="item?.url"
      >
        {{ item?.name }}
      </a>
      <a class="list-item__content qa-menu" [id]="item?.id" *ngIf="!item?.url">
        {{ item?.name }}
      </a>
      <mat-icon class="list-item__upgrade-icon" *ngIf="item?.availableInPlan"
        >lock</mat-icon
      >
      <ng-container *ngIf="hasChildren">
        <mat-icon
          *ngIf="!toggleSubmenu"
          class="list-item__toggle"
          aria-hidden="false"
          >arrow_drop_down</mat-icon
        >
        <mat-icon
          *ngIf="toggleSubmenu"
          class="list-item__toggle"
          aria-hidden="false"
          >arrow_drop_up</mat-icon
        >
      </ng-container>
    </mat-list-item>
    <cl-menu-list
      *ngIf="hasChildren"
      class="list-item__menu qa-submenu"
      [attr.qa-submenu-name]="item?.name"
      [ngClass]="{ 'list-item__menu--collapsed': !toggleSubmenu }"
      [items]="item?.children ?? []"
      (upgradeIconClicked)="handleSubmenuUpgradeIconClick($event)"
    ></cl-menu-list>
  </div>
</ng-template>
