import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ENVIRONMENT_FEATURE_KEY, State } from './environment.reducer';

// Lookup the 'Environment' feature state managed by NgRx
export const getEnvironmentState = createFeatureSelector<State>(
  ENVIRONMENT_FEATURE_KEY
);

export const getEnvironmentLoaded = createSelector(
  getEnvironmentState,
  (state: State) => state.loaded
);

export const getEnvironmentError = createSelector(
  getEnvironmentState,
  (state: State) => state.error
);

export const recaptchaSiteKey = createSelector(
  getEnvironmentState,
  (state: State) => state.recaptchaSiteKey
);

export const atualURL = createSelector(
  getEnvironmentState,
  (state: State) => state.atualUrl
);

export const appURL = createSelector(
  getEnvironmentState,
  (state: State) => state.appUrl
);

export const apiURL = createSelector(
  getEnvironmentState,
  (state: State) => state.apiUrl
);

export const spaApiUrl = createSelector(
  getEnvironmentState,
  ({ spaApiUrl }) => spaApiUrl
);

export const accountsApiUrl = createSelector(
  getEnvironmentState,
  ({ accountsApiUrl }) => accountsApiUrl
);

export const environmentName = createSelector(
  getEnvironmentState,
  (state: State) => state.environment
);
