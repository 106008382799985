/**
 * Elimina caracteres especials de uma string, para que ela possa ser utilizada
 * em uma expressão regular
 *
 * https://stackoverflow.com/a/9310752
 *
 * @param term
 */
export function sanitizeStringForRegex(term: string): string {
  return term.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&');
}
