<div class="main-content" *transloco="let t; read: 'commonError'">
  <div class="content">
    <span class="error-code">
      {{ statusCode }}
    </span>
    <span class="error-code-crash">
      {{ t('something-went-wrong') }}
    </span>
    <span class="error-message">
      {{ t('page-does-not-exist-or-unavailable') }}<br />
    </span>
    <button
      (click)="handleBackButton()"
      class="back-button"
      mat-raised-button
      color="primary"
      >{{ t('label.back') }}</button
    >
  </div>
</div>
