<textarea
  #textareaInput
  cdkTextareaAutosize
  [placeholder]="placeholderText"
  [disabled]="disabled"
  [readonly]="readonly"
  [required]="required"
  (input)="handleChange(textareaInput.value)"
  (blur)="inputBlur($event)"
  [attr.maxlength]="maxlength"
  [value]="value"
></textarea>
