<div class="dependency-card-item">
  <span class="dependency-card-item__title" [title]="name">{{ name }}</span>
  <div class="dependency-card-item__scales">
    <p class="item-scale-text" *ngIf="!hasScaleIcons">{{ scaleText }}</p>
    <cl-ui-scale-icons
      *ngIf="hasScaleIcons"
      boxHeight="28px"
      [scaleData]="scaleIcons"
    ></cl-ui-scale-icons>
  </div>
</div>
