import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarService } from './snackbar.service';
import { DialogService } from './dialog.service';
import { UserTermsNoticeComponent } from './user-terms-notice/user-terms-notice.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  providers: [
    DialogService,
    SnackbarService,
    TranslocoModule,
    TranslocoMessageFormatModule,
  ],
  declarations: [UserTermsNoticeComponent],
  exports: [UserTermsNoticeComponent],
})
export class SharedUtilNotificationsModule {}
