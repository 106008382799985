import {
  UploadQueueItem,
  UploadQueueItemStatus,
} from '@checklistfacil/shared/util/general';
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './actions';

export const uploadQueueFeatureKey = 'upload-queue';

export interface State {
  show: boolean;
  open: boolean;
  items: UploadQueueItem[];
  uploading: boolean;
}

export const initialState: State = {
  show: false,
  open: false,
  items: [],
  uploading: false,
};

const mainReducer = createReducer(
  initialState,
  on(actions.addQueueItem, (state, action) => {
    return {
      ...state,
      items: state.items.concat(action.items),
      show: true,
    };
  }),

  on(actions.startQueue, (state) => {
    return {
      ...state,
      uploading: true,
      open: true,
    };
  }),

  on(actions.endQueue, (state) => {
    return {
      ...state,
      uploading: false,
    };
  }),

  on(actions.queueItemUploadSuccess, (state, action) => {
    return {
      ...state,
      items: state.items.map((item) => {
        if (item.id === action.item.id) {
          return {
            ...item,
            status: UploadQueueItemStatus.Success,
          };
        }
        return item;
      }),
    };
  }),

  on(actions.queueItemUploadError, (state, action) => {
    return {
      ...state,
      items: state.items.map((item) => {
        if (item.id === action.item.id) {
          return {
            ...item,
            status: UploadQueueItemStatus.Error,
            errorMessage: action.error.message,
          };
        }
        return item;
      }),
    };
  }),

  on(actions.retryQueueItem, (state, action) => {
    return {
      ...state,
      items: state.items.map((item) => {
        if (item.id === action.item.id) {
          return {
            ...item,
            status: UploadQueueItemStatus.Pending,
          };
        }
        return item;
      }),
    };
  }),

  on(actions.cancelQueueItem, (state, action) => {
    return {
      ...state,
      items: state.items.filter((item) => item.id !== action.item.id),
    };
  }),

  on(actions.cancelAllQueueItems, (state) => {
    return {
      ...state,
      items: [],
      open: false,
      show: false,
    };
  }),

  on(actions.toggleQueueOpen, (state) => {
    return {
      ...state,
      open: !state.open,
    };
  }),

  on(actions.closeUploadQueue, (state) => {
    return {
      ...state,
      open: false,
    };
  })
);

export function uploadQueueReducer(state: State, action: Action) {
  return mainReducer(state, action);
}
