export interface FileGalleryItem {
  id?: number | string;
  name: string;
  url: string;
  type: string;
  localFile?: boolean;
  fileInstance?: File;
  canBeRemoved?: boolean;
  formData?: FormData;
  caption?: string;
  isLoading?: boolean;
  bypassUnsafeUrl?: boolean;
  hasError?: boolean;
  errorIcon?: string;
  errorTooltip?: string;
  errorLabel?: string;
}
