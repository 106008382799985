import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageApiInterceptorService implements HttpInterceptor {
  constructor(private translocoService: TranslocoService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const update = {
      headers: req.headers.set(
        'Accept-Language',
        this.translocoService.getActiveLang()
      ),
    };

    const reqClone = req.clone(update);
    return next.handle(reqClone);
  }
}
