import { Component, Input } from '@angular/core';
import { AchievementIcon } from '@checklistfacil/shared/user-achievements/util';

@Component({
  selector: 'cl-achievements-icons',
  templateUrl: './achievements-icons.component.html',
  styleUrls: ['./achievements-icons.component.scss'],
})
export class AchievementsIconsComponent {
  public icons = AchievementIcon;

  @Input() icon: AchievementIcon = AchievementIcon.ApplyChecklist;
  @Input() active = false;
  @Input() menuTrophy = false;
}
