import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { MenuSurfaceComponent } from './menu-surface.component';
import {
  ConnectedPosition,
  OverlayPositionBuilder,
} from '@angular/cdk/overlay';

@Directive({
  selector: '[clUiMenuSurfaceTrigger]',
})
export class MenuSurfaceTriggerDirective implements AfterContentInit {
  constructor(
    protected elementRef: ElementRef,
    private overlayPositionBuilder: OverlayPositionBuilder
  ) {}

  @Input('clUiMenuSurfaceTrigger') menuSurfaceRef:
    | MenuSurfaceComponent
    | undefined;
  @Input() menuSurfacePosition: ConnectedPosition | undefined;
  @Input() disabledMenu: boolean | undefined;

  @HostListener('click')
  open() {
    if (this.menuSurfaceRef && !this.disabledMenu) {
      this.menuSurfaceRef.open();
    }
  }

  close() {
    if (this.menuSurfaceRef) {
      this.menuSurfaceRef.close();
    }
  }

  ngAfterContentInit(): void {
    // @todo descobrir meios para melhor posicionar a surface

    // const position = this.overlayPositionBuilder.connectedTo(this.elementRef, {
    //   originX: 'center',
    //   originY: 'bottom'
    // }, {
    //   overlayX: 'center',
    //   overlayY: 'top'
    // });

    const position = this.overlayPositionBuilder.flexibleConnectedTo(
      this.elementRef
    );

    const defaultPositions: ConnectedPosition[] = [
      {
        offsetX: 0,
        offsetY: 0,
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      },
    ];

    if (this.menuSurfacePosition) {
      defaultPositions.push(this.menuSurfacePosition);
    }

    position.withPositions(defaultPositions);

    this.menuSurfaceRef?.setPositionInstance(position);
  }
}
