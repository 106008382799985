import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InformationDialogData } from './types';

@Component({
  selector: 'cl-ui-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss'],
})
export class InformationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: InformationDialogData) {}
}
