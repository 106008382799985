import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyRegistrationData } from './models';
import { COMPANY_REGISTRATION_FEATURE_KEY, State } from './reducer';

// Lookup the 'CompanyRegistration' feature state managed by NgRx
export const getCompanyRegistrationState = createFeatureSelector<State>(
  COMPANY_REGISTRATION_FEATURE_KEY
);

export const company = createSelector(
  getCompanyRegistrationState,
  (state) => state.company
);

export const user = createSelector(
  getCompanyRegistrationState,
  (state) => state.user
);

export const userValid = createSelector(user, (state) =>
  Boolean(state !== null && state.valid)
);

export const companyValid = createSelector(
  company,
  (state) => state !== null && state.valid
);

export const userEmail = createSelector(
  getCompanyRegistrationState,
  (state) => state.user?.email
);

export const userName = createSelector(
  getCompanyRegistrationState,
  (state) => state.user?.userName
);

export const countryCode = createSelector(
  getCompanyRegistrationState,
  (state) => state.user?.countryCode
);

export const companyName = createSelector(
  getCompanyRegistrationState,
  (state) => state.company?.companyName
);

export const segmentId = createSelector(
  getCompanyRegistrationState,
  (state) => state.company?.segmentId
);

export const workers = createSelector(
  getCompanyRegistrationState,
  (state) => state.company?.workers
);

export const countryId = createSelector(
  getCompanyRegistrationState,
  (state) => state.company?.countryId
);

export const stateId = createSelector(
  getCompanyRegistrationState,
  (state) => state.company?.stateId
);

export const cityId = createSelector(
  getCompanyRegistrationState,
  (state) => state.company?.cityId
);

export const cities = createSelector(
  getCompanyRegistrationState,
  (state) => state.cities
);

export const countryAndState = createSelector(
  countryId,
  stateId,
  (countryId, stateId) => {
    return { countryId, stateId };
  }
);

export const countryCodes = createSelector(
  getCompanyRegistrationState,
  countryCode,
  ({ countryCodes }, selectedCountryCode) => {
    if (selectedCountryCode && !countryCodes) {
      return [
        {
          id: String(selectedCountryCode),
          name: String(selectedCountryCode),
          active: true,
        },
      ];
    }

    return countryCodes;
  }
);

export const listSegments = createSelector(
  getCompanyRegistrationState,
  segmentId,
  ({ segments }, selectedSegment) => {
    if (selectedSegment && !segments) {
      return [
        {
          id: Number(selectedSegment),
          name: String(selectedSegment),
          active: true,
        },
      ];
    }

    return segments;
  }
);

export const listWorkers = createSelector(
  getCompanyRegistrationState,
  workers,
  ({ workers }, selectedWorkerRange) => {
    if (selectedWorkerRange && !workers) {
      return [
        {
          id: Number(selectedWorkerRange),
          name: String(selectedWorkerRange),
          active: true,
        },
      ];
    }

    return workers;
  }
);

export const listCountries = createSelector(
  getCompanyRegistrationState,
  countryId,
  ({ countries }, selectedCountries) => {
    if (selectedCountries && !countries) {
      return [
        {
          id: Number(selectedCountries),
          name: String(selectedCountries),
          active: true,
        },
      ];
    }

    return countries;
  }
);

export const listStates = createSelector(
  getCompanyRegistrationState,
  stateId,
  ({ states }, selectedState) => {
    if (selectedState && !states) {
      return [
        {
          id: Number(selectedState),
          name: String(selectedState),
          active: true,
        },
      ];
    }

    return states;
  }
);

export const listCities = createSelector(
  getCompanyRegistrationState,
  cityId,
  ({ cities }, selectedCities) => {
    if (selectedCities && !cities) {
      return [
        {
          id: Number(selectedCities),
          name: String(selectedCities),
          active: true,
        },
      ];
    }

    return cities;
  }
);

export const dataIsValid = createSelector(
  userValid,
  companyValid,
  (userValid, companyValid) => {
    if (userValid && companyValid) {
      return true;
    }
    return false;
  }
);

export const registrationData = createSelector(
  user,
  company,
  dataIsValid,
  (userData, companyData, dataIsValid) => {
    if (!dataIsValid) {
      return null;
    }

    const { userName, email, password, phone, countryCode } = userData;

    const phoneCompleted = `${countryCode}${phone?.areaCode}${phone?.number}`;

    const {
      companyName,
      segmentId,
      workers: employeesNumberId,
      countryId,
      stateId,
      cityId,
    } = companyData;

    return {
      userName,
      email,
      password,
      companyName,
      segmentId,
      employeesNumberId,
      countryId,
      stateId,
      cityId,
      phone: phoneCompleted,
    } as CompanyRegistrationData;
  }
);

export const registrationDone = createSelector(
  getCompanyRegistrationState,
  (state) => {
    return state.registrationDone;
  }
);
