<cl-ui-loading-slider [show]="(isLoading$ | async) ?? false"></cl-ui-loading-slider>

<div class="loader" *ngIf="(isSplash$ | async) && (isLoading$ | async)">
  <div class="loader-icon">
    <div class="loader-icon-inner"></div>
  </div>
</div>
<router-outlet></router-outlet>

<cl-common-error
  *ngIf="appHasError"
  [statusCode]="500"
  (backButtonClicked)="handleErrorBackButtonClicked()"
></cl-common-error>
