import { Component, HostBinding, Input } from '@angular/core';
import { ScaleData } from '@checklistfacil/shared/util/item-scale';

@Component({
  selector: 'cl-ui-dependency-card-item',
  templateUrl: './dependency-card-item.component.html',
  styleUrls: ['./dependency-card-item.component.scss'],
})
export class DependencyCardItemComponent {
  @Input() hasScaleIcons = false;
  @Input() scaleText: string | undefined;
  @Input() name: string | undefined;
  @Input() scaleIcons: ScaleData | undefined;
  @Input() @HostBinding('class.--selected') selected = false;
}
