<div mat-dialog-title [class.dialog--has-icon]="titleIcon">
  <mat-icon
    color="primary"
    fontSet="material-icons-outlined"
    *ngIf="titleIcon"
    >{{ titleIcon }}</mat-icon
  >
  <span class="dialog-title">{{ title }}</span>
</div>
<mat-dialog-content class="dialog-content">
  <ng-content select="[dialogContent]"></ng-content>
</mat-dialog-content>

<div #dialogActions>
  <ng-content select="mat-dialog-actions"></ng-content>
</div>

<ng-container *ngIf="!dialogActions.innerHTML.trim()">
  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      class="dialog-cancel-button"
      [mat-dialog-close]="false"
      >{{ terms?.cancelButtonLabel }}</button
    >
    <button mat-raised-button color="primary" [mat-dialog-close]="true">{{
      terms?.confirmButtonLabel
    }}</button>
  </mat-dialog-actions>
</ng-container>
