import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CompanySubscriptionService } from '../company-subscription.service';
import {
  loadCompanyRegisterError,
  loadCompanyRegisterSuccess,
} from '../state/actions';
import { CompanyRegisterData } from '../state/models';

@Injectable()
export class CompanySubscriptionResolverService
  implements Resolve<CompanyRegisterData | null>
{
  constructor(
    private service: CompanySubscriptionService,
    private store: Store
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CompanyRegisterData | null> {
    return this.service.loadCompanyRegisterData().pipe(
      tap((payload) => {
        this.store.dispatch(loadCompanyRegisterSuccess({ payload }));
      }),
      catchError((error) => {
        this.store.dispatch(loadCompanyRegisterError({ error }));
        return of(null);
      })
    );
  }
}
