import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';
import { ApiResponse } from '@checklistfacil/shared/util/general';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AchievementGroups, CompanyNumbers } from './+state/models';
import { endpoints } from './endpoints';

@Injectable({
  providedIn: 'root',
})
export class UserAchievementsService {
  private appConfig: Configuration | undefined;

  constructor(
    private http: HttpClient,
    configService: ConfigAssetLoaderService
  ) {
    configService
      .loadConfigurations()
      .subscribe((config) => (this.appConfig = config));
  }

  listAchievements(): Observable<AchievementGroups[]> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http
      .get<ApiResponse<AchievementGroups[]>>(
        endpoints.listAchievements(this.appConfig)
      )
      .pipe(map((response) => response.payload));
  }

  companyNumbers(): Observable<CompanyNumbers> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    return this.http
      .get<ApiResponse<CompanyNumbers>>(
        endpoints.companyNumbers(this.appConfig)
      )
      .pipe(map((response) => response.payload));
  }
}
