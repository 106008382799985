import { ComponentHarness } from '@angular/cdk/testing';

export class ScaleIconsHarness extends ComponentHarness {
  static hostSelector = 'cl-ui-scale-icons';

  protected naIcon = this.locatorForOptional('.qa-na-icon');
  protected badFaceIcon = this.locatorForOptional('.qa-bad-face-icon');
  protected averageFaceIcon = this.locatorForOptional('.qa-average-face-icon');
  protected goodFaceIcon = this.locatorForOptional('.qa-good-face-icon');
  protected noIcon = this.locatorForOptional('.qa-no-icon');
  protected scaleIcon = this.locatorForOptional('.qa-scale-icon');
  protected scaleYesIcon = this.locatorForOptional('.qa-scale-yes-icon');
  protected scaleSimIcon = this.locatorForOptional('.qa-scale-sim');
  protected scaleMedalIcon = this.locatorForOptional('.qa-scale-medal-icon');
  protected scaleAlertIcon = this.locatorForOptional('.qa-scale-alert-icon');
  protected starIcon = this.locatorForOptional('. qa-star-icon');

  async getNaIcon() {
    const icon = await this.naIcon();
    return icon?.text();
  }

  async getBadFaceIcon() {
    const icon = await this.badFaceIcon();
    return icon?.text();
  }

  async getAverageFaceIcon() {
    const icon = await this.averageFaceIcon();
    return icon?.text();
  }

  async getGoodFaceIcon() {
    const icon = await this.goodFaceIcon();
    return icon?.text();
  }

  async getNoIcon() {
    const icon = await this.noIcon();
    return icon?.text();
  }

  async getScaleIcon() {
    const icon = await this.scaleIcon();
    return icon?.text();
  }

  async getScaleYesIcon() {
    const icon = await this.scaleYesIcon();
    return icon?.text();
  }

  async getScaleSimIcon() {
    const icon = await this.scaleSimIcon();
    return icon?.text();
  }

  async getScaleMedalIcon() {
    const icon = await this.scaleMedalIcon();
    return icon?.text();
  }

  async getScaleAlertIcon() {
    const icon = await this.scaleAlertIcon();
    return icon?.text();
  }

  async getStarIcon() {
    const icon = await this.starIcon();
    return icon?.text();
  }
}
