<div class="localization-block">
  <h4 class="localization-block__title">
    {{ title }}
  </h4>
  <a
    *ngIf="!coordinatesAreEmpty"
    [href]="coordinatesLink"
    target="_blank"
    class="localization-block__coordinates"
  >
    {{ coordinates }}
  </a>
  <span
    *ngIf="coordinatesAreEmpty"
    class="localization-block__coordinates localization-block__coordinates--empty"
  >
    {{ emptyCoordinatesMessage }}
  </span>
  <span class="localization-block__address">
    {{ address }}
  </span>
</div>
