<ng-template transloco let-t translocoRead="clUiDialogSelect">
  <div mat-dialog-title>
    <mat-icon
      *ngIf="icon !== ''"
      color="primary"
      fontSet="material-icons-outlined"
      >{{ icon }}</mat-icon
    >
    <span class="dialog-title">{{ title }}</span>
  </div>
  <div class="instruction" *ngIf="instructionText">{{ instructionText }}</div>

  <mat-form-field class="cl-condensed-input search-field" appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <input
      type="text"
      matInput
      (input)="handleSearchTermChange($event)"
      [placeholder]="t('searchPlaceholder')"
    />
  </mat-form-field>

  <mat-dialog-content>
    <button
      mat-button
      color="primary"
      class="button-list-action check-all-button"
      (click)="handleCheckAllItems()"
      >{{ t('checkAll') }}</button
    >
    <button
      mat-button
      color="primary"
      class="button-list-action uncheck-all-button"
      (click)="handleUncheckAllItems()"
      >{{ t('uncheckAll') }}</button
    >

    <ng-container *ngIf="(hasItems$ | async) === true; else emptyList">
      <!--  listagem  -->
      <cdk-virtual-scroll-viewport
        itemSize="48"
        maxBufferPx="148"
        class="dialog-select-options"
      >
        <ng-container
          *cdkVirtualFor="let item of itemsList$ | async; trackBy: trackByFn"
        >
          <div
            class="dialog-select-option qa-option qa-dialog-select-option dialog-select-option--with-checkbox"
            *ngIf="!singleSelection; else singleSelectionTmpl"
            [class.dialog-select-option--disabled]="!item.active"
          >
            <mat-checkbox
              (change)="handleItemChange(item)"
              [checked]="item.checked || item.indeterminate"
              [indeterminate]="item.indeterminate"
              color="primary"
              class="dialog-select-option__checkbox qa-input qa-dialog-select-option-checkbox"
            >
              <span
                class="dialog-select-option__text qa-text qa-dialog-select-option-checkbox-text"
                >{{ item.name }}</span
              >
            </mat-checkbox>
          </div>

          <ng-template #singleSelectionTmpl>
            <div
              class="dialog-select-option qa-option qa-dialog-select-option dialog-select-option--without-checkbox"
              (click)="handleItemChange(item)"
              [class.dialog-select-option--disabled]="!item.active"
            >
              <span
                class="dialog-select-option__text qa-text qa-dialog-select-option-checkbox-text"
                >{{ item.name }}</span
              >
            </div>
          </ng-template>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </ng-container>

    <ng-template #emptyList>
      <div *ngIf="(hasItems$ | async) === false" class="empty-list-message">{{
        emptyItemsText
      }}</div>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      class="cancel-button"
      (click)="handleCancel()"
      color="primary"
      >{{ t('cancel') }}</button
    >
    <button
      mat-raised-button
      class="confirm-button"
      (click)="handleConfirm()"
      color="primary"
      >{{ t('select') }}
    </button>
  </mat-dialog-actions>
</ng-template>
