import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-ui-configuration-simple',
  templateUrl: './configuration-simple.component.html',
  styleUrls: ['./configuration-simple.component.scss'],
})
export class ConfigurationSimpleComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() checked = false;
  @Input() showSwitch = true;
  @Output() toggled = new EventEmitter<boolean>();

  handleSwitchChange() {
    this.toggled.emit(!this.checked);
  }
}
