<div class="root">
  <cl-menu
    [dashboardUrl]="dashboardUrl"
    [showMainButton]="(userCanApplyChecklists$ | async) ?? false"
    (menuStateToggled)="handleMenuStateToggled($event)"
    (menuItemClicked)="handleMenuItemClicked($event)"
    (applyChecklistClicked)="handleMenuApplyChecklistClicked()"
    [menuIsCollapsed]="(menuIsCollapsed$ | async) ?? false"
    [items]="(menuItems$ | async) ?? []"
    [userInfo]="userInfo$ | async"
    [userInfoAchievementTrophy]="userInfoAchievementTrophy$ | async"
    [accountMenuItem]="(accountMenuLinks$ | async) ?? {}"
    [accountMenuHiddenItems]="(hiddenMenuItems$ | async) ?? []"
    [footerItem]="footerLinks ?? {}"
    [companyLogo]="(companyLogoUrl$ | async) ?? undefined"
    [isTrial]="(companyInTrial$ | async) ?? false"
    [trialDays]="(companyRemainingTrialDays$ | async) ?? undefined"
    [menuHelpInfo]="(menuHelpInfo$ | async) ?? undefined"
    [companyHasContactInformation]="(companyHasContactInformation$ | async) ?? false"
    (upgradeIconClicked)="handleUpgradeIconClicked($event)"
    [isAccountManager]="(isAccountManager$ | async) ?? false"
    [userHasAccessToAchievements]="(userHasAccessToAchievements$ | async) ?? false"
    (accountSubscriptionSettingsClicked)="
      handleAccountSubscriptionSettingsClick()
    "
    (upgradeTrialAccount)="handleUpgradeTrialAccountClick()"
    [jivoChatUrl]="jivoChatUrl$ | async"
    [jivoUserInfo]="(jivoUserInfo$ | async) ?? undefined"
  ></cl-menu>

  <div class="outlet-container">
    <cl-warning-bar></cl-warning-bar>
    <div class="outlet-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <cl-ui-upload-queue
    [items]="(uploadFiles$ | async) ?? []"
    [collapsed]="(uploadQueueIsOpen$ | async) ?? false"
    [show]="(showUploadQueue$ | async) ?? false"
    (uploadItemRetry)="handleItemUploadRetry($event)"
    (uploadItemCancel)="handleItemUploadCancel($event)"
    (uploadsCancel)="handleUploadsCancel()"
    (uploadsCollapsed)="handleUploadsCollapsed()"
  ></cl-ui-upload-queue>
</div>
