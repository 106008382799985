export interface State {
  companyConfigs: CompanyConfig[];
  companyFeatures: CompanyFeatures;
  accesses: number[];
  company: CompanyData;
  sienge: SiengeIntegrationData | null;
}

export interface SiengeIntegrationData {
  modules: {
    units: {
      enabled: boolean;
    };
    supplyContracts: {
      enabled: boolean;
    };
    purchaseOrders: {
      enabled: boolean;
    };
  };
}

export interface CompanyData {
  billingEmailConfirmed: boolean;
  biggestEvaluationScore?: number;
  currentPlanType: PlanType | null;
  isDemo: boolean;
  contractDate?: string | null;
  contractLink?: string | null;
  googleAnalyticsTag?: string | null;
  hasContactInformation: boolean;
  companyRegisterFilled: boolean;
  isTrial: boolean | null;
  planId: number | null;
  remainingTrialDays: number | null;
  selfSubscribed?: boolean;
  ticketsPlatformUrl?: string;
  canExtendTrial: boolean | null;
  canExtendTrialDays: number | null;
  segmentId: number;
}

export enum PlanType {
  TRIAL = 'trial',
  BASIC = 'basic',
  PROFESSIONAL = 'professional',
  ENTERPRISE = 'enterprise',
}

export interface CompanyConfig {
  config: string;
  type: number;
  value: any;
}

export interface CompanyFeatures {
  agendamento?: boolean;
  agendamento_inicia_antes?: boolean;
  agendamento_repeticao?: boolean;
  bloquear_finalizar_checklist?: boolean;
  bloquear_iniciar_checklist?: boolean;
  categoria_produto?: boolean;
  checklist_agrupar_pa?: boolean;
  checklist_aplicador_recebe_email?: boolean;
  checklist_aprovacao_agendamentos?: boolean;
  checklist_bloqueio_agendamento_duplicado?: boolean;
  checklist_aprovar?: boolean;
  checklist_arquivo_dica?: boolean;
  checklist_assinatura_item?: boolean;
  checklist_avaliacao_concluida_comentario?: boolean;
  checklist_unidade_recebe_email_agendamento: boolean;
  checklist_bloqueia_alteracao_aprovacao?: boolean;
  checklist_campos_adicionais_item?: boolean;
  checklist_campos_adicionais_unidade_pdf?: boolean;
  checklist_capa_pdf?: boolean;
  checklist_carrousel_aprovacao?: boolean;
  checklist_classificacao?: boolean;
  checklist_comentario_obrigatorio_aprovacao?: boolean;
  checklist_comentario_resposta_pdf?: boolean;
  checklist_configura_respostas_pdf?: boolean;
  checklist_configuracao_avancada?: boolean;
  checklist_configurar_tela_conclusao?: boolean;
  checklist_contestacao?: boolean;
  checklist_continuar_web?: boolean;
  checklist_cor_area_pdf?: boolean;
  checklist_dependencia?: boolean;
  checklist_dica_item?: boolean;
  checklist_dica_item_pdf?: boolean;
  checklist_email_nao_concluido?: boolean;
  checklist_escala_codigo_barra?: boolean;
  checklist_escala_gps?: boolean;
  checklist_escala_personalizada?: boolean;
  checklist_escala_temperatura?: boolean;
  checklist_etiqueta_sequencial?: boolean;
  checklist_excluir?: boolean;
  checklist_exibir_data_hora_respostas?: boolean;
  checklist_exibir_nota_area_aplicacao?: boolean;
  checklist_fluxo_aprovacao?: boolean;
  checklist_fluxograma?: boolean;
  checklist_fotos_resposta_pdf?: boolean;
  checklist_galeria_imagens_app?: boolean;
  checklist_historico_pdf?: boolean;
  checklist_imagem?: boolean;
  checklist_modo_exibicao_respostas_pdf?: boolean;
  checklist_pa_area?: boolean;
  checklist_pa_geral?: boolean;
  checklist_pa_item?: boolean;
  checklist_pa_resposta_pdf?: boolean;
  checklist_padrao_imagem?: boolean;
  checklist_penalidade_agendamento_atrasado?: boolean;
  checklist_penalidade_item?: boolean;
  checklist_penalidade_reincidencia?: boolean;
  checklist_personaliza_pdf?: boolean;
  checklist_pesquisa_satisfacao?: boolean;
  checklist_prazo_aprovacao?: boolean;
  checklist_quadro_notas?: boolean;
  checklist_quantidade_aprovacao?: boolean;
  checklist_quantidade_assinaturas?: boolean;
  checklist_quantidade_limite?: number;
  checklist_reabrir?: boolean;
  clonar_checklists_concluidos?: boolean;
  checklist_resultado_parcial?: boolean;
  checklist_somente_agendado?: boolean;
  checklist_subarea?: boolean;
  checklist_unidade_recebe_email?: boolean;
  checklist_valor_personalizado_avaliativas?: boolean;
  checklist_visualiza_itens_ocultos?: boolean;
  dashboard_quantidade_limite?: number;
  departamento?: boolean;
  dias_antecedencia_para_alerta_expiracao_acesso?: boolean;
  email?: boolean;
  finalizar_plano_acao_apos_concluir_avaliacao?: boolean;
  home_central_ajuda?: boolean;
  integracao_api?: boolean;
  integracao_power_bi?: boolean;
  janela_notificacoes: boolean;
  analytics_bi?: boolean;
  outras_configuracoes?: boolean;
  outras_configuracoes_acesso_expira?: boolean;
  outras_configuracoes_alerta_agendamento_vencido?: boolean;
  outras_configuracoes_alerta_pa_nao_preenchido?: boolean;
  outras_configuracoes_alerta_pa_pendente?: boolean;
  outras_configuracoes_alerta_pa_vencido?: boolean;
  outras_configuracoes_arredonda_nota?: boolean;
  outras_configuracoes_background?: boolean;
  outras_configuracoes_bloqueia_sessoes?: boolean;
  outras_configuracoes_cerca_digital?: boolean;
  outras_configuracoes_complexidade_senha?: boolean;
  outras_configuracoes_dashboard?: boolean;
  outras_configuracoes_envia_senha?: boolean;
  outras_configuracoes_exibe_peso_item_checklist?: boolean;
  outras_configuracoes_expira_senha?: boolean;
  outras_configuracoes_gps_app?: boolean;
  outras_configuracoes_gps_obrigatorio_app?: boolean;
  outras_configuracoes_horario_acesso?: boolean;
  outras_configuracoes_logomarca?: boolean;
  outras_configuracoes_manter_sincronizados?: boolean;
  outras_configuracoes_mensagem?: boolean;
  outras_configuracoes_mensagem_pesquisa?: boolean;
  outras_configuracoes_personalizacao_layout?: boolean;
  outras_configuracoes_pontuacao_pdf?: boolean;
  outras_configuracoes_ranking?: boolean;
  outras_configuracoes_remove_agendamentos?: boolean;
  outras_configuracoes_tamanho_senha?: boolean;
  outras_configuracoes_tempo_sessao?: boolean;
  outras_configuracoes_tentativas_login?: boolean;
  outras_configuracoes_ultimas_senhas?: boolean;
  outras_configuracoes_valor_opcoes_respostas?: boolean;
  plano_acao_aprovacao?: boolean;
  plano_acao_area?: boolean;
  plano_acao_arquivo_obrigatorio_solucao?: boolean;
  plano_acao_bloquear_campos?: boolean;
  plano_acao_campos_obrigatorios?: boolean;
  plano_acao_cancelavel?: boolean;
  plano_acao_exibir_campos?: boolean;
  plano_acao_geral?: boolean;
  plano_acao_item?: boolean;
  plano_acao_personalizado?: boolean;
  plano_acao_pre_preencher?: boolean;
  plano_acao_prorrogavel?: boolean;
  plano_acao_responsavel_preenche?: boolean;
  plano_acao_solucao_aprova_automatico?: boolean;
  plano_acao_solucao_obrigatoria?: boolean;
  raio_cerca_digital?: boolean;
  reconhecimento_padrao_imagem?: boolean;
  reembolso?: boolean;
  reembolso_checklist?: boolean;
  reembolso_tipo?: boolean;
  regiao?: boolean;
  regiao_quantidade_limite?: number;
  relatorio_agendamento?: boolean;
  relatorio_arquivos?: boolean;
  relatorio_auditoria?: boolean;
  relatorio_conferencia?: boolean;
  relatorio_exportar?: boolean;
  relatorio_indicadores?: boolean;
  relatorio_reincidencia?: boolean;
  rotas?: boolean;
  rotas_fim?: boolean;
  rotas_inicio?: boolean;
  rotas_limite_requisicao?: boolean;
  tipo_unidade?: boolean;
  tipo_usuario_quantidade_limite?: number;
  unidade_campos_adicionais?: boolean;
  unidade_checklist_qrcode?: boolean;
  unidade_multiplos_emails?: boolean;
  unidade_qrcode?: boolean;
  unidade_quantidade_limite?: number;
  unidade_quantidade_plano?: number;
  usuario_campos_adicionais?: boolean;
  usuario_expira_acesso?: boolean;
  usuario_quantidade_limite?: number;
  usuario_quantidade_plano?: number;
  workflows: boolean;
  workflow_bloqueio_pa: boolean;
  operacao_matematica: boolean;
  integracao_sienge?: boolean;
  aplicar_checklist_somente_app?: boolean;
}

export const defaultCompanyFeatures: CompanyFeatures = {
  agendamento: false,
  agendamento_inicia_antes: false,
  agendamento_repeticao: false,
  bloquear_finalizar_checklist: false,
  bloquear_iniciar_checklist: false,
  categoria_produto: false,
  checklist_agrupar_pa: false,
  checklist_aplicador_recebe_email: false,
  checklist_aprovacao_agendamentos: false,
  checklist_bloqueio_agendamento_duplicado: false,
  checklist_aprovar: false,
  checklist_arquivo_dica: false,
  checklist_assinatura_item: false,
  checklist_avaliacao_concluida_comentario: false,
  checklist_unidade_recebe_email_agendamento: false,
  checklist_bloqueia_alteracao_aprovacao: false,
  checklist_campos_adicionais_item: false,
  checklist_campos_adicionais_unidade_pdf: false,
  checklist_capa_pdf: false,
  checklist_carrousel_aprovacao: false,
  checklist_classificacao: false,
  checklist_comentario_obrigatorio_aprovacao: false,
  checklist_comentario_resposta_pdf: false,
  checklist_configura_respostas_pdf: false,
  checklist_configuracao_avancada: false,
  checklist_configurar_tela_conclusao: false,
  checklist_contestacao: false,
  checklist_continuar_web: false,
  checklist_cor_area_pdf: false,
  checklist_dependencia: false,
  checklist_dica_item: false,
  checklist_dica_item_pdf: false,
  checklist_email_nao_concluido: false,
  checklist_escala_codigo_barra: false,
  checklist_escala_gps: false,
  checklist_escala_personalizada: false,
  checklist_escala_temperatura: false,
  checklist_etiqueta_sequencial: false,
  checklist_excluir: false,
  checklist_exibir_data_hora_respostas: false,
  checklist_exibir_nota_area_aplicacao: false,
  checklist_fluxo_aprovacao: false,
  checklist_fluxograma: false,
  checklist_fotos_resposta_pdf: false,
  checklist_galeria_imagens_app: false,
  checklist_historico_pdf: false,
  checklist_imagem: false,
  checklist_modo_exibicao_respostas_pdf: false,
  checklist_pa_area: false,
  checklist_pa_geral: false,
  checklist_pa_item: false,
  checklist_pa_resposta_pdf: false,
  checklist_padrao_imagem: false,
  checklist_penalidade_agendamento_atrasado: false,
  checklist_penalidade_item: false,
  checklist_penalidade_reincidencia: false,
  checklist_personaliza_pdf: false,
  checklist_pesquisa_satisfacao: false,
  checklist_prazo_aprovacao: false,
  checklist_quadro_notas: false,
  checklist_quantidade_aprovacao: false,
  checklist_quantidade_assinaturas: false,
  checklist_quantidade_limite: 0,
  checklist_reabrir: false,
  clonar_checklists_concluidos: false,
  checklist_resultado_parcial: false,
  checklist_somente_agendado: false,
  checklist_subarea: false,
  checklist_unidade_recebe_email: false,
  checklist_valor_personalizado_avaliativas: false,
  checklist_visualiza_itens_ocultos: false,
  dashboard_quantidade_limite: 0,
  departamento: false,
  dias_antecedencia_para_alerta_expiracao_acesso: false,
  email: false,
  finalizar_plano_acao_apos_concluir_avaliacao: false,
  janela_notificacoes: false,
  home_central_ajuda: false,
  integracao_api: false,
  integracao_power_bi: false,
  outras_configuracoes: false,
  outras_configuracoes_acesso_expira: false,
  outras_configuracoes_alerta_agendamento_vencido: false,
  outras_configuracoes_alerta_pa_nao_preenchido: false,
  outras_configuracoes_alerta_pa_pendente: false,
  outras_configuracoes_alerta_pa_vencido: false,
  outras_configuracoes_arredonda_nota: false,
  outras_configuracoes_background: false,
  outras_configuracoes_bloqueia_sessoes: false,
  outras_configuracoes_cerca_digital: false,
  outras_configuracoes_complexidade_senha: false,
  outras_configuracoes_dashboard: false,
  outras_configuracoes_envia_senha: false,
  outras_configuracoes_exibe_peso_item_checklist: false,
  outras_configuracoes_expira_senha: false,
  outras_configuracoes_gps_app: false,
  outras_configuracoes_gps_obrigatorio_app: false,
  outras_configuracoes_horario_acesso: false,
  outras_configuracoes_logomarca: false,
  outras_configuracoes_manter_sincronizados: false,
  outras_configuracoes_mensagem: false,
  outras_configuracoes_mensagem_pesquisa: false,
  outras_configuracoes_personalizacao_layout: false,
  outras_configuracoes_pontuacao_pdf: false,
  outras_configuracoes_ranking: false,
  outras_configuracoes_remove_agendamentos: false,
  outras_configuracoes_tamanho_senha: false,
  outras_configuracoes_tempo_sessao: false,
  outras_configuracoes_tentativas_login: false,
  outras_configuracoes_ultimas_senhas: false,
  outras_configuracoes_valor_opcoes_respostas: false,
  plano_acao_aprovacao: false,
  plano_acao_area: false,
  plano_acao_arquivo_obrigatorio_solucao: false,
  plano_acao_bloquear_campos: false,
  plano_acao_campos_obrigatorios: false,
  plano_acao_cancelavel: false,
  plano_acao_exibir_campos: false,
  plano_acao_geral: false,
  plano_acao_item: false,
  plano_acao_personalizado: false,
  plano_acao_pre_preencher: false,
  plano_acao_prorrogavel: false,
  plano_acao_responsavel_preenche: false,
  plano_acao_solucao_aprova_automatico: false,
  plano_acao_solucao_obrigatoria: false,
  raio_cerca_digital: false,
  reembolso: false,
  reembolso_checklist: false,
  reembolso_tipo: false,
  regiao: false,
  regiao_quantidade_limite: 0,
  relatorio_agendamento: false,
  relatorio_arquivos: false,
  relatorio_auditoria: false,
  relatorio_conferencia: false,
  relatorio_exportar: false,
  relatorio_indicadores: false,
  relatorio_reincidencia: false,
  rotas: false,
  rotas_fim: false,
  rotas_inicio: false,
  rotas_limite_requisicao: false,
  tipo_unidade: false,
  tipo_usuario_quantidade_limite: 0,
  unidade_campos_adicionais: false,
  unidade_checklist_qrcode: false,
  unidade_multiplos_emails: false,
  unidade_qrcode: false,
  unidade_quantidade_limite: 0,
  unidade_quantidade_plano: 0,
  usuario_campos_adicionais: false,
  usuario_expira_acesso: false,
  usuario_quantidade_limite: 0,
  usuario_quantidade_plano: 0,
  workflows: false,
  workflow_bloqueio_pa: false,
  operacao_matematica: false,
  integracao_sienge: false,
  aplicar_checklist_somente_app: false,
};
