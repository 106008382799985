import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-ui-fieldset',
  templateUrl: './fieldset.component.html',
  styleUrls: ['./fieldset.component.scss'],
})
export class FieldsetComponent {
  @Input() title: string | undefined;
  @Input() helper: string | undefined;
  @Input() invalid = false;
}
