import { State } from './models';
import { Action, createReducer, on } from '@ngrx/store';
import * as mainMenuActions from './actions';

export const mainMenuFeatureKey = 'main-menu';

export const initialState: State = {
  menuCollapsed: false,
  menuCollapsedMobile: false,
  menus: [],
};

export const mainMenuReducer = createReducer(
  initialState,
  on(
    mainMenuActions.loadMenu,
    (state: State, { menus, menuCollapsedMobile, menuCollapsed }) => {
      return {
        ...state,
        menuCollapsed: menuCollapsedMobile ? true : menuCollapsed,
        menus: menus,
      };
    }
  ),
  on(mainMenuActions.menuToggled, (state: State) => {
    return {
      ...state,
      menuCollapsed: !state.menuCollapsed,
    };
  }),
  on(mainMenuActions.menuClose, (state: State) => {
    return {
      ...state,
      menuCollapsed: true,
    };
  }),
  on(mainMenuActions.menuOpen, (state: State) => {
    return {
      ...state,
      menuCollapsed: false,
    };
  }),
  on(mainMenuActions.menuItemClicked, (state: State, action) => {
    return {
      ...state,
      menuCollapsed: false,
      menus: state.menus.map((menuItem) => {
        const tempMenuItem = {
          ...menuItem,
          childrenToggled:
            action.menuItem === menuItem
              ? !action.menuItem.childrenToggled
              : menuItem.childrenToggled,
        };
        return tempMenuItem;
      }),
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return mainMenuReducer(state, action);
}
