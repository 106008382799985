import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { userCanAccessNotificationGroupsConfig } from '@checklistfacil/shared/data-access/company-configs';
import { first, map } from 'rxjs/operators';
import { SnackbarService } from '@checklistfacil/shared/util/notifications';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class UserCanAccessNotificationGroupSetupGuard
  implements CanActivate, CanActivateChild
{
  constructor(
    private store: Store,
    private router: Router,
    private snackbarService: SnackbarService,
    private translocoService: TranslocoService
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(userCanAccessNotificationGroupsConfig),
      first(),
      map((canAccess) => {
        if (!canAccess) {
          this.snackbarService.showMessage(
            this.translocoService.translate(
              'guards.text.user-doesnt-have-access'
            ),
            this.translocoService.translate('guards.label.close')
          );
          this.router.navigate(['error']);
          return false;
        }
        return true;
      })
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
