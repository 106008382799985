import { Configuration } from '@checklistfacil/shared/util/environment';

export const endpoints = {
  verifyEmail: (environment: Configuration) =>
    `${environment.spaApiUrl}/trial/verify-email`,
  verifyName: (environment: Configuration) =>
    `${environment.spaApiUrl}/trial/verify-company`,
  listCountries: (environment: Configuration) =>
    `${environment.spaApiUrl}/geo/countries`,
  listStates: (environment: Configuration, countryId: number) =>
    `${environment.spaApiUrl}/geo/countries/${countryId}/states`,
  listCities: (
    environment: Configuration,
    countryId: number,
    stateId: number
  ) =>
    `${environment.spaApiUrl}/geo/countries/${countryId}/states/${stateId}/cities`,
  listCountryCodes: (environment: Configuration) =>
    `${environment.spaApiUrl}/trial/ddi`,
  listSegments: (environment: Configuration) =>
    `${environment.spaApiUrl}/trial/segments`,
  listEmployeeRanges: (environment: Configuration) =>
    `${environment.spaApiUrl}/trial/employees-number`,
  registerCompany: (environment: Configuration) =>
    `${environment.spaApiUrl}/trial`,
};
