import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemScaleService } from './item-scale.service';
import { SharedUtilTranslateModule } from '@checklistfacil/shared/util/translate';

@NgModule({
  imports: [CommonModule, SharedUtilTranslateModule],
  providers: [ItemScaleService],
})
export class SharedUtilItemScaleModule {}
