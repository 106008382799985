export enum Scale {
  YesNo = 1001,
  RedGreen = 1,
  RedYellowGreen = 2,
  UNDEFINED_3 = 3,
  ShortText = 4,
  Monetary = 5,
  Integer = 6,
  Decimal = 7,
  DateDMY = 8,
  Instruction = 9,
  SelectionSingle = 10,
  SelectionMultiple = 1010,
  UNDEFINED_11 = 11,
  AddressCEP = 12,
  AddressState = 13,
  AddressStateCity = 14,
  Phone = 15,
  CNPJ = 16,
  CPF = 17,
  UNDEFINED_18 = 18,
  UNDEFINED_19 = 19,
  LongText = 20,
  GPS = 21,
  Barcode = 22,
  Time24h = 23,
  Time24hS = 1023,
  Time12h = 24,
  Time12hS = 1024,
  LicensePlate = 25,
  Product = 26,
  DateMY = 27,
  CompetencePeriod = 28,
  Temperature = 29,
  Index = 30,
  Email = 31,
  LicensePlateMercosul = 1025,
  SiengeService = 32,
}

export enum ScaleType {
  Evaluative = 1,
  Text,
  Date,
  Registration,
  Numeric,
  Selection,
  Special,
}

export enum ScaleIcons {
  RedOrNo = 1,
  Yellow = 2,
  GreenOrYes = 3,
  MedalOrAlert = 4,
  NA = 6,
  No = 7,
  Yes = 8,
}

export interface ItemAddons {
  comment: boolean;
  actionPlan: boolean;
  attachment: boolean;
  signature: boolean;
}

export enum ItemRequirements {
  comment = 'comment',
  actionPlan = 'actionPlan',
  attachment = 'attachment',
  signature = 'signature',
}

export interface ScaleData {
  goodFace?: boolean;
  averageFace?: boolean;
  badFace?: boolean;
  yes?: boolean;
  sim?: boolean;
  no?: boolean;
  na?: boolean;
  medal?: boolean;
  star?: boolean;
}

export interface ScaleEvaluative {
  red?: ItemAddons;
  yellow?: ItemAddons;
  green?: ItemAddons;
  no?: ItemAddons;
  yes?: ItemAddons;
  na?: ItemAddons;
  medal?: ItemAddons;
}

export enum Currency {
  Real = 1,
  Dollar = 2,
  Euro = 3,
  MexicanPeso = 4,
}

export interface ScaleMonetaryType {
  moneyId: Currency;
}

export interface SiengeServiceAnswer {
  services: SiengeService[];
}

export interface SiengeService {
  id: number;
  type: SiengeServiceType;
  description: string;
  childType: SiengeServiceType | null;
}

export enum SiengeServiceType {
  Fornecedor = 1,
  Contrato = 2,
  UnidadeConstrutiva = 3,
  CelulaConstrutiva = 4,
  Etapa = 5,
  SubEtapa = 6,
  Servico = 7,
}
