<div
  class="float-container"
  [class.float-container--collapsed]="collapsed"
  *transloco="let t; read: 'clUiUploadQueue'"
>
  <div class="float-container__header">
    <span class="float-container-title">
      <ng-container *ngIf="hasPendingItems">
        {{ t('uploadQueueTitle', { value: pendingItems.length }) }}
      </ng-container>
      <ng-container *ngIf="!hasPendingItems">
        {{ t('uploadTitleDefault') }}
      </ng-container>
    </span>
    <div class="float-container__actions">
      <button
        (click)="toggleCollapsed()"
        type="button"
        class="float-container-action"
      >
        <mat-icon *ngIf="collapsed">expand_less</mat-icon>
        <mat-icon *ngIf="!collapsed">expand_more</mat-icon>
      </button>
      <button
        (click)="close()"
        type="button"
        class="float-container__close-action float-container-action"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="float-container__content">
    <ng-container *ngIf="items.length">
      <div
        class="float-container__sticky"
        *ngIf="items.length > 1 && hasPendingItems"
      >
        <ng-container>
          <span class="float-container__sticky-message">
            {{ t('sendingFilesLabel') }}
          </span>
          <button
            (click)="close()"
            class="float-container__sticky-action"
            type="button"
            >{{ t('cancelUploadsLabel') }}</button
          >
        </ng-container>
      </div>
      <div class="float-container__list">
        <cl-ui-upload-queue-item
          *ngFor="let item of items"
          [item]="item"
          (cancelItemUpload)="handleItemCancel($event)"
          (retryItemUpload)="handleItemRetry($event)"
        ></cl-ui-upload-queue-item>
      </div>
    </ng-container>
  </div>
</div>
<cl-ui-confirmation-dialog #confirmation></cl-ui-confirmation-dialog>
<cl-ui-confirmation-dialog #confirmationFailedItems></cl-ui-confirmation-dialog>
