import { ComponentHarness } from '@angular/cdk/testing';
import { MatSlideToggleHarness } from '@angular/material/slide-toggle/testing';

export class SwitchHarness extends ComponentHarness {
  static hostSelector = 'cl-form-switch';

  protected getSlideToggleHarness = this.locatorFor(MatSlideToggleHarness);

  async toggle() {
    const trigger = await this.getSlideToggleHarness();
    return trigger.toggle();
  }

  async check() {
    const trigger = await this.getSlideToggleHarness();
    return trigger.check();
  }

  async uncheck() {
    const trigger = await this.getSlideToggleHarness();
    return trigger.uncheck();
  }

  async isChecked() {
    const slideToggle = await this.getSlideToggleHarness();
    return await slideToggle.isChecked();
  }

  async isDisabled() {
    const slideToggle = await this.getSlideToggleHarness();
    return await slideToggle.isDisabled();
  }
}
