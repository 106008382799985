import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserAchievementsService } from '../user-achievements.service';
import {
  loadCompanyNumbers,
  loadCompanyNumbersError,
  loadCompanyNumbersSuccess,
  loadUserAchievements,
  loadUserAchievementsError,
  loadUserAchievementsSuccess,
} from './actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { userHasAccessToAchievements } from './selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class UserAchievementsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: UserAchievementsService
  ) {}

  loadUserAchievements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserAchievements),
      withLatestFrom(this.store.select(userHasAccessToAchievements)),
      mergeMap(([, userHasAccessToAchievements]) => {
        if (userHasAccessToAchievements) {
          return this.service.listAchievements().pipe(
            map((achievementGroups) =>
              loadUserAchievementsSuccess({
                achievementGroups,
              })
            ),
            catchError((error) => of(loadUserAchievementsError({ error })))
          );
        }
        return EMPTY;
      })
    )
  );

  loadCompanyNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompanyNumbers),
      mergeMap(() => {
        return this.service.companyNumbers().pipe(
          map((companyNumbers) =>
            loadCompanyNumbersSuccess({ companyNumbers })
          ),
          catchError((error) => of(loadCompanyNumbersError({ error })))
        );
      })
    )
  );
}
