/** @goat-sacrifice
 * por conta do nosso amado firefox,
 * é preciso explicitamente impedir que
 * o usuário digite letras no input number
 */
export function handleNumberOnlyKeydown(event: KeyboardEvent) {
  /**
   * allowedKeys: Backspace, Tab, ArrowLeft, ArrowRight, Delete
   * ctrl + x, ctrl + v, ctrl + c, ctrl + a
   */
  // tslint:disable-next-line: deprecation
  const allowedCtrlKeys =
    event.ctrlKey &&
    // tslint: deprecation
    (event.keyCode === 88 ||
      event.keyCode === 86 ||
      event.keyCode === 67 ||
      event.keyCode === 65);

  const allowedKeys = [8, 9, 37, 39, 46];
  // tslint:disable-next-line: deprecation
  if (allowedKeys.indexOf(event.keyCode) > -1 || allowedCtrlKeys) {
    return event;
  }

  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
  return event;
}
