/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let sentryRelease: string | undefined = undefined;
try {
  // @ts-ignore
  sentryRelease = require('./git-version').gitVersion.commitLong;
} catch (ex) {
  // do nothing
}

export const environment = {
  production: false,
  sentry: {
    enabled: sentryRelease !== undefined,
    release: sentryRelease,
    environment: 'development',
  },
  growthBook: {
    apiKey: 'key_deve_a004cc65a9b6a1f6',
  },
  serviceWorker: {
    enabled: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
