import { ComponentHarness } from '@angular/cdk/testing';
import { SwitchHarness } from '@checklistfacil/cl-form';

export class ConfigurationHeaderHarness extends ComponentHarness {
  static hostSelector = 'cl-ui-configuration-header';

  protected getSwitchHarness = this.locatorFor(SwitchHarness);

  async toggle() {
    const trigger = await this.getSwitchHarness();
    return trigger.toggle();
  }
}
