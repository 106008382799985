import { Injectable } from '@angular/core';
import {
  updateBillingEmailState,
  updateRemainingTrialDays,
} from '@checklistfacil/shared/data-access/company-configs';
import { LoadingService } from '@checklistfacil/shared/data-access/loading';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  finalize,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CompanySubscriptionService } from '../company-subscription.service';
import {
  accountPlanUpgrade,
  accountPlanUpgradeError,
  accountPlanUpgradeSuccess,
  clickResendBillingEmail,
  clickResendBillingEmailError,
  clickResendBillingEmailSuccess,
  extendTrial,
  extendTrialError,
  extendTrialSuccess,
  loadBusinessAreas,
  loadBusinessAreasError,
  loadBusinessAreasSuccess,
  loadCities,
  loadCitiesError,
  loadCitiesSuccess,
  loadCompanyRegisterSuccess,
  loadCountries,
  loadCountriesError,
  loadCountriesSuccess,
  loadCountryStates,
  loadCountryStatesError,
  loadCountryStatesSuccess,
  loadMexicanCfdi,
  loadMexicanCfdiError,
  loadMexicanCfdiSuccess,
  loadMexicanPaymentForm,
  loadMexicanPaymentFormError,
  loadMexicanPaymentFormSuccess,
  loadMexicanPaymentMethod,
  loadMexicanPaymentMethodError,
  loadMexicanPaymentMethodSuccess,
  loadMexicanTaxRegime,
  loadMexicanTaxRegimeError,
  loadMexicanTaxRegimeSuccess,
  loadSegments,
  loadSegmentsError,
  loadSegmentsSuccess,
  loadSubSegments,
  loadSubSegmentsError,
  loadSubSegmentsSuccess,
  saveCompanyRegister,
  saveCompanyRegisterError,
  saveCompanyRegisterSuccess,
} from './actions';
import { companyRegisterPayload } from './selectors';

@Injectable()
export class CompanySubscriptionEffects {
  constructor(
    private actions$: Actions,
    private service: CompanySubscriptionService,
    private loadingService: LoadingService,
    private store: Store
  ) {}

  extendTrial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(extendTrial),
      tap(() => this.loadingService.showLoading()),
      exhaustMap(() => {
        return this.service.extendTrial().pipe(
          tap(() => this.loadingService.hideLoading()),
          map((response) =>
            extendTrialSuccess({
              remainingDays: response.remainingTrialDays,
            })
          ),
          catchError((error) => of(extendTrialError({ error })))
        );
      })
    )
  );

  updateRemainingTrialDays = createEffect(() =>
    this.actions$.pipe(
      ofType(extendTrialSuccess),
      map(({ remainingDays }) =>
        updateRemainingTrialDays({ remainingTrialDays: remainingDays })
      )
    )
  );

  loadCompanyRegisterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadCompanyRegisterSuccess),
        tap(() => this.loadingService.hideLoading())
      ),
    { dispatch: false }
  );

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCountries),
      mergeMap(() => {
        return this.service.loadCountries().pipe(
          map((payload) => loadCountriesSuccess({ payload })),
          catchError((error) => of(loadCountriesError({ error })))
        );
      })
    )
  );

  loadCountryStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCountryStates),
      mergeMap(({ countryId }) => {
        return this.service.loadCountryStates(countryId).pipe(
          map((payload) => loadCountryStatesSuccess({ payload })),
          catchError((error) => of(loadCountryStatesError({ error })))
        );
      })
    )
  );

  loadCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCities),
      mergeMap(({ countryId, stateId }) => {
        return this.service.loadCities(countryId, stateId).pipe(
          map((payload) => loadCitiesSuccess({ payload })),
          catchError((error) => of(loadCitiesError({ error })))
        );
      })
    )
  );

  loadSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSegments),
      mergeMap(() => {
        return this.service.loadSegments().pipe(
          map((payload) => loadSegmentsSuccess({ payload })),
          catchError((error) => of(loadSegmentsError({ error })))
        );
      })
    )
  );

  loadSubsegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSubSegments),
      mergeMap(({ segmentId }) => {
        return this.service.loadSubsegments(segmentId).pipe(
          map((payload) => loadSubSegmentsSuccess({ payload })),
          catchError((error) => of(loadSubSegmentsError({ error })))
        );
      })
    )
  );

  loadBusinessAreas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBusinessAreas),
      mergeMap(() => {
        return this.service.loadBusinessAreas().pipe(
          map((payload) => loadBusinessAreasSuccess({ payload })),
          catchError((error) => of(loadBusinessAreasError({ error })))
        );
      })
    )
  );

  saveCompanyRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveCompanyRegister),
      withLatestFrom(this.store.pipe(select(companyRegisterPayload))),
      concatMap(([{ emailUpdatedMessage }, payload]) => {
        return this.service.saveCompanyRegisterData(payload).pipe(
          map(() =>
            saveCompanyRegisterSuccess({
              emailUpdatedMessage,
            })
          ),
          catchError((error) => of(saveCompanyRegisterError({ error })))
        );
      })
    )
  );

  changeBillingEmailConfirmedWhenEmailChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveCompanyRegister),
        map((action) => {
          if (action.emailUpdatedMessage) {
            return this.store.dispatch(
              updateBillingEmailState({
                billingEmailConfirmedChanged: false,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  accountPlanUpgrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountPlanUpgrade),
      tap(() => this.loadingService.showLoading()),
      switchMap(({ planId }) => {
        return this.service.accountPlanUpgrade({ planId }).pipe(
          map(() => accountPlanUpgradeSuccess()),
          catchError((error) => of(accountPlanUpgradeError({ error }))),
          finalize(() => this.loadingService.hideLoading())
        );
      })
    )
  );

  resendBillingEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clickResendBillingEmail),
      mergeMap(() => {
        return this.service.resendBillingEmail().pipe(
          map(() => {
            return clickResendBillingEmailSuccess();
          }),
          catchError((error) => of(clickResendBillingEmailError({ error })))
        );
      })
    )
  );

  loadMexicanCfdi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMexicanCfdi),
      mergeMap(() => {
        return this.service.loadMexicanCfdi().pipe(
          map((payload) => loadMexicanCfdiSuccess({ payload })),
          catchError((error) => of(loadMexicanCfdiError({ error })))
        );
      })
    )
  );

  loadMexicanPaymentForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMexicanPaymentForm),
      mergeMap(() => {
        return this.service.loadMexicanPaymentForm().pipe(
          map((payload) => loadMexicanPaymentFormSuccess({ payload })),
          catchError((error) => of(loadMexicanPaymentFormError({ error })))
        );
      })
    )
  );

  loadMexicanPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMexicanPaymentMethod),
      mergeMap(() => {
        return this.service.loadMexicanPaymentMethod().pipe(
          map((payload) => loadMexicanPaymentMethodSuccess({ payload })),
          catchError((error) => of(loadMexicanPaymentMethodError({ error })))
        );
      })
    )
  );

  loadMexicanTaxRegime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMexicanTaxRegime),
      mergeMap(() => {
        return this.service.loadMexicanTaxRegime().pipe(
          map((payload) => loadMexicanTaxRegimeSuccess({ payload })),
          catchError((error) => of(loadMexicanTaxRegimeError({ error })))
        );
      })
    )
  );
}
