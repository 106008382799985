import { ConnectedPosition } from '@angular/cdk/overlay/position/flexible-connected-position-strategy';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MenuSurfaceTriggerDirective } from '../menu-surface/menu-surface-trigger.directive';

@Component({
  selector: 'cl-ui-colorpicker-input',
  templateUrl: './colorpicker-input.component.html',
  styleUrls: ['./colorpicker-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ColorpickerInputComponent,
      multi: true,
    },
  ],
})
export class ColorpickerInputComponent implements OnInit, ControlValueAccessor {
  @Input() hasColorIndicator = true;
  @Input() hasFloatLabel = false;
  @Input() label = '';
  @Input() value = '#000000';
  @Input() disabled = false;
  @Output() valueChange = new EventEmitter<string>();
  @ViewChild(MenuSurfaceTriggerDirective, { static: true })
  surface: MenuSurfaceTriggerDirective | undefined;
  currentColor = '#000000';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange: ((changes: string) => void) | undefined;
  surfacePosition: ConnectedPosition = {
    offsetX: 0,
    offsetY: 0,
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  };

  ngOnInit() {
    this.initialColor();
  }

  handleColorpickerChange(color: string) {
    this.currentColor = color;
  }

  applyColorChange() {
    this.value = this.currentColor;
    this.valueChange.emit(this.currentColor);
    if (this.onChange) {
      this.onChange(this.value);
    }
    this.surface?.close();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(obj: any) {
    this.value = obj;
    this.initialColor();
  }

  private initialColor() {
    this.currentColor = this.value ? this.value : '#000000';
  }
}
