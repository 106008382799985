import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Chip } from './types';

@Component({
  selector: 'cl-ui-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent {
  @Input() chips: Chip[] = [];
  @Output() chipClick = new EventEmitter<number | string>();

  chipClicked(id: number | string) {
    this.chipClick.emit(id);
  }

  chipTrackBy(index: number, item: Chip) {
    return item.id;
  }
}
