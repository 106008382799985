import { Scale } from './types';

export type ChecklistItemRequiredAddonMode = 'default' | 'userInput';

export function checkChecklistItemRequiredAddonMode(
  scaleId: number
): ChecklistItemRequiredAddonMode {
  const userInputMode = [
    Scale.YesNo,
    Scale.RedGreen,
    Scale.RedYellowGreen,
    Scale.SelectionSingle,
    Scale.SelectionMultiple,
  ];
  return userInputMode.indexOf(scaleId) > -1 ? 'userInput' : 'default';
}
