import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { selfSubscribed } from '../+state/selectors/company';

@Injectable({
  providedIn: 'root',
})
export class CompanyIsSelfSubscribedGuard implements CanActivate, CanLoad {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.check();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.check();
  }

  private check(): Observable<boolean> {
    return this.store.pipe(
      select(selfSubscribed),
      first(),
      map((permission) => {
        if (!permission) {
          this.router.navigate(['error']);
          return false;
        }
        return true;
      })
    );
  }
}
