import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { takeWhile } from 'rxjs/operators';
import { SelectGroup, SelectItem } from './types';

@Component({
  selector: 'cl-form-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clFormSelect'
    ),
  ],
})
export class SelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() items: (SelectItem | SelectGroup)[] = [];
  @Input() label = '';
  @Input() value: any;
  @Input() textHelper: string | undefined;
  @Input() required = false;
  @Input() disabled = false;
  @Input() @HostBinding('class.cl-form-select--condensed') condensed = false;
  @Input() emptyOption = true;
  @Input() hasSearch = false;
  @Input() searchPlaceholder = '';
  @Input() multiple = false;

  private internalAppearance: MatFormFieldAppearance = 'fill';
  @HostBinding('class.cl-form-select--custom-fill') customFill = false;
  @Input() set appearance(appearance: string) {
    if (appearance === 'cl-fill') {
      this.internalAppearance = 'none' as MatFormFieldAppearance;
      this.customFill = true;
    } else {
      this.internalAppearance = appearance as MatFormFieldAppearance;
    }
  }

  get appearance(): MatFormFieldAppearance {
    return this.internalAppearance;
  }

  @Input() set searchTerm(term: string) {
    this.searchControl.setValue(term);
  }

  @Output() openedChange = new EventEmitter<boolean>();
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() selectionChange = new EventEmitter();

  searchControl = new FormControl('');
  private componentActive = true;

  ngOnInit() {
    if (this.hasSearch) {
      this.searchControl.valueChanges
        .pipe(takeWhile(() => this.componentActive))
        .subscribe((value) => {
          this.searchTermChange.emit(value);
        });
    }
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  onChange(event: any) {
    this.value = event;
    this.selectionChange.emit(event);
  }

  onOpenedChange(event: boolean) {
    this.openedChange.emit(event);
  }

  onTermChange() {
    this.searchTermChange.emit(this.searchControl.value);
  }

  onTouched() {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectItemAsGroup(item: SelectItem | SelectGroup): SelectGroup | null {
    return Object.prototype.hasOwnProperty.call(item, 'items')
      ? (item as SelectGroup)
      : null;
  }

  selectItemAsSingle(item: SelectItem | SelectGroup): SelectItem | null {
    return Object.prototype.hasOwnProperty.call(item, 'items')
      ? null
      : (item as SelectItem);
  }
}
