export enum Warning {
  IconError = 'error',
  IconWarning = 'warning',

  WarningBarHigh = 'warning-bar--high',
  WarningBarMedium = 'warning-bar--medium',
  WarningBarLow = 'warning-bar--low',

  WarningModalHigh = 'warning-modal--high',
  WarningModalMedium = 'warning-modal--medium',
  WarningModalLow = 'warning-modal--low',

  WarningSectionHigh = 'warning-section--high',
  WarningSectionMedium = 'warning-section--medium',
  WarningSectionLow = 'warning-section--low',
}
