import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import FileSaver from 'file-saver';
import { FileGalleryItem } from '../types';
import { DomSanitizer } from '@angular/platform-browser';

export type FileSlideshowCloseReason = '' | 'userClosed' | 'userKeydown';

@Component({
  selector: 'cl-ui-file-slideshow',
  templateUrl: './file-slideshow.component.html',
  styleUrls: ['./file-slideshow.component.scss'],
})
export class FileSlideshowComponent implements AfterViewInit {
  @Input() showDownload = true;
  @Input() currentIndex = 0;
  @Input() slides: FileGalleryItem[] = [];
  @Output() closed = new EventEmitter<FileSlideshowCloseReason>();
  @Output() slideChanged = new EventEmitter<number>();
  @HostBinding() tabindex = 0;

  constructor(private elRef: ElementRef, private sanitizer: DomSanitizer) {}

  get filesCount() {
    return this.slides.length;
  }

  get currentSlide() {
    return this.slides[this.currentIndex];
  }

  get currentSlideImageUrl() {
    if (this.currentSlide.bypassUnsafeUrl) {
      return this.sanitizer.bypassSecurityTrustUrl(this.currentSlide.url);
    }
    return this.currentSlide.url;
  }

  get hasPrevButton() {
    return this.currentIndex !== 0;
  }

  get hasNextButton() {
    return this.currentIndex < this.filesCount - 1;
  }

  @HostListener('keydown', ['$event'])
  onHostKeydown(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.closed.emit('userKeydown');
    }

    if (event.keyCode === 37 && this.hasPrevButton) {
      this.slideToLeft();
    }

    if (event.keyCode === 39 && this.hasNextButton) {
      this.slideToRight();
    }
  }

  ngAfterViewInit(): void {
    this.elRef.nativeElement.focus();
  }

  closeEvent(event: Event) {
    event.preventDefault();
    this.closed.emit('userClosed');
  }

  openImageInNewTab(event: Event) {
    event.preventDefault();
    window.open(this.currentSlide.url);
  }

  downloadFile(event: Event) {
    event.preventDefault();
    FileSaver.saveAs(this.currentSlide.url, this.currentSlide.name);
  }

  fileNameFrom(file: FileGalleryItem) {
    if (file.name !== '') {
      return file.name;
    }
    return file.url.split('/').pop();
  }

  slideToLeft() {
    if (this.currentIndex === 0) {
      this.currentIndex = this.filesCount - 1;
      this.slideChanged.emit(this.currentIndex);
      return;
    }
    this.currentIndex--;
    this.slideChanged.emit(this.currentIndex);
  }

  slideToRight() {
    if (this.currentIndex >= this.filesCount - 1) {
      this.currentIndex = 0;
      this.slideChanged.emit(this.currentIndex);
      return;
    }
    this.currentIndex++;
    this.slideChanged.emit(this.currentIndex);
  }

  slideTrackBy(index: number): number {
    return index;
  }

  handleThumbnailClicked(index: number) {
    this.currentIndex = index;
  }

  fileIsImage(file?: FileGalleryItem): boolean {
    return [
      'jpg',
      'image/jpg',
      'jpeg',
      'image/jpeg',
      'png',
      'image/png',
      'gif',
      'image/gif',
    ].includes(file?.type ?? '');
  }
}
