import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  appLangFromBrowserLang,
  translocoLoader,
} from '@checklistfacil/shared/util/translate';

@Component({
  selector: 'cl-common-error',
  templateUrl: './common-error.component.html',
  styleUrls: ['./common-error.component.scss'],
  providers: [
    translocoLoader(
      (lang: string, root: string) => import(`./${root}/${lang}.json`),
      'commonError'
    ),
  ],
})
export class CommonErrorComponent {
  @Input() statusCode = 404;
  @Output() backButtonClicked = new EventEmitter();

  get errorLanguage(): string | null {
    return appLangFromBrowserLang();
  }

  handleBackButton() {
    this.backButtonClicked.emit();
  }
}
