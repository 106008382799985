import { createAction, props } from '@ngrx/store';
import {
  BasicEntityCountryCode,
  Company,
  CompanyRegistrationData,
  PhoneData,
  User,
} from './models';
import { BasicEntity } from '@checklistfacil/shared/util/general';

export const init = createAction('[CompanyRegistration Page] Init');

export const updateUser = createAction(
  '[User Registration] update user data',
  props<{ user: User }>()
);

export const updateUserName = createAction(
  '[User Registration] update user name',
  props<{ userName: string | null }>()
);

export const updateUserEmail = createAction(
  '[User Registration] update user email',
  props<{ email: string | null }>()
);

export const updateUserPhoneDDI = createAction(
  '[User Registration] update user phone DDI',
  props<{ countryCode: string | undefined }>()
);

export const updateUserPhoneNumber = createAction(
  '[User Registration] update user phone number',
  props<{ phone: PhoneData }>()
);

export const updateCompanyName = createAction(
  '[Company Registration] update company name',
  props<{ companyName: string | null }>()
);

export const updateCompany = createAction(
  '[Company Registration] update company data',
  props<{ company: Company }>()
);

export const updateSegment = createAction(
  '[Company Registration] update segment',
  props<{ segment: number | null }>()
);

export const updateSize = createAction(
  '[Company Registration] update size',
  props<{ worker: number | null }>()
);

export const loadCountryCode = createAction(
  '[User Registration] load country code'
);

export const loadCountryCodeSuccess = createAction(
  '[User Registration] load country code success',
  props<{ countryCodes: BasicEntityCountryCode[] }>()
);

export const loadCountryCodeError = createAction(
  '[User Registration] load country code error',
  props<{ error: { message: string } }>()
);

export const loadSegments = createAction(
  '[Company Registration] load segments'
);

export const loadSegmentsSuccess = createAction(
  '[Company Registration] load segments success',
  props<{ segments: BasicEntity[] }>()
);

export const loadSegmentsError = createAction(
  '[Company Registration] load segments error',
  props<{ error: { message: string } }>()
);

export const loadWorkers = createAction('[Company Registration] load workers');

export const loadWorkersSuccess = createAction(
  '[Company Registration] load workers success',
  props<{ workers: BasicEntity[] }>()
);

export const loadWorkersError = createAction(
  '[Company Registration] load workers error',
  props<{ error: { message: string } }>()
);

export const loadCountries = createAction(
  '[Company Registration] load countries'
);

export const loadCountriesSuccess = createAction(
  '[Company Registration] load countries success',
  props<{ countries: BasicEntity[] }>()
);

export const loadCountriesError = createAction(
  '[Company Registration] load countries error',
  props<{ error: { message: string } }>()
);

export const loadStates = createAction(
  '[Company Registration] load states',
  props<{ countryId: any }>()
);

export const loadStatesSuccess = createAction(
  '[Company Registration] load states success',
  props<{ states: BasicEntity[] }>()
);

export const loadStatesError = createAction(
  '[Company Registration] load states error',
  props<{ error: { message: string } }>()
);

export const loadCities = createAction(
  '[Company Registration] load cities',
  props<{ countryId: any; stateId: any }>()
);

export const loadCitiesSuccess = createAction(
  '[Company Registration] load cities success',
  props<{ cities: BasicEntity[] }>()
);

export const loadCitiesError = createAction(
  '[Company Registration] load cities error',
  props<{ error: { message: string } }>()
);

export const registerCompany = createAction('[Register Company] register');

export const registerCompanySuccess = createAction(
  '[Register Company] register company success',
  props<{ registerCompany: CompanyRegistrationData }>()
);

export const registerCompanyError = createAction(
  '[Register Company] register company error',
  props<{ error: { message: string } }>()
);

export const changeStatusUserValid = createAction(
  '[User Registration] status form user valid',
  props<{ valid: boolean }>()
);

export const changeStatusCompanyValid = createAction(
  '[Company Registration] status form company valid',
  props<{ valid: boolean }>()
);
