import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cl-form-borderless-textfield',
  templateUrl: './borderless-textfield.component.html',
  styleUrls: ['./borderless-textfield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BorderlessTextfieldComponent),
      multi: true,
    },
  ],
})
export class BorderlessTextfieldComponent
  implements ControlValueAccessor, AfterViewInit
{
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange: ((value: any) => void) | undefined;
  @Input() type = 'text';
  @Input() value = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() maxlength: string | undefined;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() autofocus = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Input() @HostBinding('class.borderless-textfield--invalid') invalid = false;
  @Output() blurEvent = new EventEmitter();

  @ViewChild('internalInput', { static: false })
  set internalInput(inputRef: ElementRef<HTMLInputElement>) {
    if (inputRef !== undefined && this.editing) {
      const focusOffset = window.getSelection()?.focusOffset || 0;
      inputRef.nativeElement.focus();
      inputRef.nativeElement.setSelectionRange(focusOffset, focusOffset);
    }
  }

  public editing = false;
  private touched = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.autofocus) {
      this.changeToEdit();
      this.cd.detectChanges();
    }
  }

  get placeholderText() {
    return this.placeholder + (this.required ? '*' : '');
  }

  changeToEdit() {
    this.editing = true;
  }

  changeToView() {
    this.editing = false;
  }

  inputBlur($event: FocusEvent) {
    this.changeToView();
    this.blurEvent.emit($event);
  }

  handleChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.value = value;
    if (!this.onChange) {
      return;
    }
    this.onChange(value);
  }

  onTouched() {
    this.touched = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
