<mat-form-field
  [appearance]="appearance"
  *transloco="let t; read: 'clFormSelect'"
>
  <mat-label *ngIf="!condensed">{{ label }}</mat-label>
  <mat-select
    panelClass="cl-form-panel"
    (openedChange)="onOpenedChange($event)"
    (selectionChange)="onChange($event.value)"
    [multiple]="multiple"
    [disableRipple]="true"
    [disabled]="disabled"
    [required]="required"
    [value]="value"
    [disableOptionCentering]="true"
    [placeholder]="condensed ? label : ''"
  >
    <ngx-mat-select-search
      *ngIf="hasSearch"
      [formControl]="searchControl"
      [clearSearchInput]="false"
      [placeholderLabel]="searchPlaceholder"
    ></ngx-mat-select-search>

    <mat-option *ngIf="emptyOption">{{ t('emptyOption') }}</mat-option>
    <ng-container *ngFor="let item of items">
      <!-- option group -->
      <ng-container *ngIf="item.hasOwnProperty('items'); else optionNotGroup">
        <mat-optgroup [label]="item.name">
          <mat-option
            *ngFor="let option of selectItemAsGroup(item)?.items"
            [value]="option.value"
            >{{ option.name }}</mat-option
          >
        </mat-optgroup>
      </ng-container>
      <!-- option group end -->
      <ng-template #optionNotGroup>
        <mat-option [value]="selectItemAsSingle(item)?.value">{{ item.name }}</mat-option>
      </ng-template>
    </ng-container>
  </mat-select>
  <mat-hint *ngIf="!condensed">{{ textHelper }}</mat-hint>
</mat-form-field>
