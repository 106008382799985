import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuListItem } from '@checklistfacil/shared/main-menu/ui';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from './endpoints';

@Injectable()
export class AppService {
  private appConfig: Configuration | undefined;

  constructor(
    private client: HttpClient,
    configService: ConfigAssetLoaderService
  ) {
    configService
      .loadConfigurations()
      .subscribe((config) => (this.appConfig = config));
  }

  getUserInfo(): Observable<any> {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }
    return this.client.get(endpoints.userInfo(this.appConfig)).pipe(
      map((data: any) => {
        const { payload, version } = data;
        return {
          ...payload,
          apiVersion: version,
          companyFeatures: payload.features,
          menus: payload.menus.map((menuItem: any) => {
            return <MenuListItem>{
              id: menuItem.name,
              name: menuItem.caption,
              icon: menuItem.icon,
              url: menuItem.url,
              target: menuItem.target,
              childrenToggled: false,
              availableInPlan: menuItem.availableInPlan,
              children: menuItem.children.map(
                (childItem: { name: string; caption: string }) => {
                  return {
                    ...childItem,
                    id: childItem.name,
                    name: childItem.caption,
                  };
                }
              ),
            };
          }),
          menuCollapsed:
            payload.userPreferences &&
            !(
              payload.userPreferences.find(
                (pref: { name: string }) => pref.name === 'menu_lateral'
              ) || { value: { open: false } }
            ).value.open,
        };
      })
    );
  }
}
