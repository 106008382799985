import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Sentry from '@sentry/angular';
import { createErrorHandler } from '@sentry/angular';
import { Router } from '@angular/router';
import { chunkLoadingErrorHandler } from './custom-extractor';

@NgModule({
  imports: [CommonModule],
})
export class SharedTrackingDataAccessSentryModule {
  static forRoot(): ModuleWithProviders<SharedTrackingDataAccessSentryModule> {
    return {
      ngModule: SharedTrackingDataAccessSentryModule,
      providers: [
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: ErrorHandler,
          useValue: createErrorHandler({
            showDialog: false,
            extractor: chunkLoadingErrorHandler,
          }),
        },
      ],
    };
  }
}
