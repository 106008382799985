import { Injectable } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';

@Injectable({
  providedIn: 'root',
})
export class ColorPaletteService {
  colorFrom(
    base: string,
    lighten: number,
    darken: number,
    saturate: number
  ): string {
    let color = new TinyColor(base);

    if (lighten) {
      color = new TinyColor(color).lighten(lighten);
    }

    if (darken) {
      color = new TinyColor(color).darken(darken);
    }

    if (saturate) {
      color = new TinyColor(color).saturate(saturate);
    }

    return color.toHexString();
  }

  setPrimaryPalletColorFrom(base: string) {
    const lightThemePrimary500 = base;
    const lightThemePrimary200 = this.colorFrom(base, 12, 0, 0);
    const lightThemePrimary800 = this.colorFrom(base, 0, 12, 0);
    document.documentElement.style.setProperty(
      '--brand-color-lighter',
      lightThemePrimary200
    );
    document.documentElement.style.setProperty(
      '--brand-color-default',
      lightThemePrimary500
    );
    document.documentElement.style.setProperty(
      '--brand-color-darker',
      lightThemePrimary800
    );
  }
}
