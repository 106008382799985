import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { PusherClientService } from '@checklistfacil/shared/data-access/pusher';
import { userId } from '@checklistfacil/shared/data-access/user';
import { select, Store } from '@ngrx/store';
import {
  NotificationResponse,
  NotificationTimer,
  NotificationType,
} from './types';

@Component({
  selector: 'cl-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'notificationComponent'
    ),
  ],
})
export class NotificationComponent implements OnInit {
  @Input() menuIsCollapsed = false;
  @Input() footerIcons: string[] | null = [];
  @ViewChild('notification', { static: true }) notificationWrapper:
    | HTMLDivElement
    | undefined;

  allNotifications: NotificationResponse[] = [];
  notificationType = NotificationType;
  loggedUserId: number | null = 0;
  progressBar = 100;
  notificationProgress: number | undefined;

  constructor(
    private pusherClientService: PusherClientService,
    private store: Store
  ) {}

  connectInWebSocket() {
    const channelName = `cl-notification-${this.loggedUserId}`;
    return this.pusherClientService.connectInChannel(channelName);
  }

  get showButtons(): boolean {
    return this.allNotifications.some(
      (notification) =>
        notification.openButton ||
        notification.downloadButton ||
        notification.detailsButton ||
        notification.verifyButton
    );
  }

  get footerIconsCount(): number | undefined {
    return this.footerIcons?.length;
  }

  get hasManyNotification() {
    return this.allNotifications.length > 1;
  }

  get showNotification() {
    return this.allNotifications.length > 0;
  }

  private processingNotifications() {
    this.connectInWebSocket()?.bind(
      'cl-notification-response',
      (notification: NotificationResponse) => {
        if (this.allNotifications.length === 0) {
          this.startAutoCloseCountDown(notification.type);
        }

        switch (notification.type) {
          case NotificationType.EXPORT:
            notification.downloadButton = true;
            break;
          case NotificationType.EXPORT_PDF:
            notification.downloadButton = true;
            notification.openButton = true;
            break;
          case NotificationType.EXPORT_FAIL:
            notification.detailsButton = true;
            break;
          case NotificationType.IMPORT:
            notification.verifyButton = true;
            notification.filename = '';
            break;
          case NotificationType.IMPORT_FAIL:
            notification.detailsButton = true;
            notification.filename = '';
            break;
          default:
            notification.filename = '';
        }
        this.allNotifications.push({
          ...notification,
        });

        this.allNotifications = this.allNotifications.map((notification) => {
          return {
            ...notification,
            show: false,
          };
        });
        this.allNotifications[0].show = true;
      }
    );
  }

  handleCloseNotification() {
    this.allNotifications.shift();
    if (this.allNotifications.length > 0) {
      this.allNotifications[0].show = true;
      this.startAutoCloseCountDown(this.allNotifications[0].type);
    }
  }

  handleDetails(detailUrl: string) {
    window.open(detailUrl, '_blank');
    this.handleCloseNotification();
  }

  startAutoCloseCountDown(type: number) {
    this.progressBar = 100;
    const totalTime =
      type === NotificationType.START
        ? NotificationTimer.START
        : NotificationTimer.OTHERS;

    const timeStep = totalTime / 100;

    this.notificationProgress = setInterval(() => {
      if (this.progressBar === 0) {
        this.progressBar = 100;
        clearInterval(this.notificationProgress);
        this.handleCloseNotification();
        return;
      }
      this.progressBar--;
    }, timeStep);
  }

  get notificationIndicatorDefault(): boolean {
    return this.footerIconsCount !== 1;
  }

  ngOnInit() {
    this.store.pipe(select(userId)).subscribe((id) => {
      this.loggedUserId = id;
      this.processingNotifications();
    });
  }
}
