<ng-container *ngIf="hasRemoveControls; else itemsWithoutOptions">
  <ng-container *ngFor="let file of files; trackBy: fileTrackBy">
    <cl-ui-file-gallery-item
      *ngIf="hasRemoveControls; else itemNoRemoveControls"
      [disabled]="disabled"
      [thumbSize]="thumbSize"
      [hasMoreOptionsControl]="true"
      (fileClicked)="handleFileClicked($event)"
      [file]="file"
      [matMenuTriggerFor]="itemMenu"
      [matMenuTriggerData]="{ file: file }"
      [forceShowMoreOptions]="itemTrigger.menuOpen"
      #itemTrigger="matMenuTrigger"
    ></cl-ui-file-gallery-item>
    <ng-template #itemNoRemoveControls>
      <cl-ui-file-gallery-item
        [disabled]="disabled"
        [thumbSize]="thumbSize"
        [hasMoreOptionsControl]="true"
        (fileClicked)="handleFileClicked($event)"
        [file]="file"
      ></cl-ui-file-gallery-item>
    </ng-template>
  </ng-container>
  <mat-menu #itemMenu="matMenu">
    <ng-template matMenuContent let-file="file">
      <ng-template transloco let-t translocoRead="clUiFileGallery">
        <button
          [disabled]="disabled"
          mat-menu-item
          (click)="handleFileViewClick(file)">{{
          t('view')
        }}</button>
        <button
          [disabled]="disabled"
          mat-menu-item
          (click)="handleFileRemoveClick(file)">{{
          t('delete')
        }}</button>
      </ng-template>
    </ng-template>
  </mat-menu>
</ng-container>
<ng-template #itemsWithoutOptions>
  <cl-ui-file-gallery-item
    *ngFor="let file of files; trackBy: fileTrackBy"
    [disabled]="disabled"
    [thumbSize]="thumbSize"
    [hasMoreOptionsControl]="false"
    (fileClicked)="handleFileClicked($event)"
    [file]="file"
  ></cl-ui-file-gallery-item>
</ng-template>
<button
  type="button"
  [disabled]="disabled"
  *ngIf="hasNewFileControl"
  class="file-gallery-add"
  (click)="handleFileAddClick()"
>
  <mat-icon [color]="buttonColor">add</mat-icon>
</button>
<cl-ui-file-slideshow
  *ngIf="hasSlideshow && showFileSlider"
  (closed)="handleSliderClosed()"
  [currentIndex]="sliderIndex"
  [slides]="files"
></cl-ui-file-slideshow>
