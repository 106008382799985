import { PlanType } from '@checklistfacil/shared/data-access/company-configs';
import {
  CityEntity,
  CountryStateEntity,
} from '@checklistfacil/shared/data-access/country';

export interface State {
  currentCompanyPlan: CompanyPlan | null;
  companyInformation: CompanyIdentification;
  companyCategorization: CompanyCategorization;
  companyCurrency: CompanyCurrency;
  countries: BasicEntity[];
  countryStates: CountryStateEntity[];
  cities: CityEntity[];
  segments: BasicEntity[];
  subsegments: BasicEntity[];
  businessAreas: BasicEntity[];
  resourceLoading: string[];
  checklistPlans: ChecklistPlansData | null;
  mexicanCfdis: BasicEntity[];
  mexicanPaymentForms: BasicEntity[];
  mexicanPaymentMethods: BasicEntity[];
  mexicanTaxRegimes: BasicEntity[];
}

export interface CompanyPlan {
  id: number;
  name: string;
  description: string;
  price: number;
  priceInfo: string;
  type: 'trial' | 'basic' | 'professional' | 'enterprise';
  contactUrl: string;
}

export interface CompanyIdentification {
  companyName: string;
  cnpj: string | null;
  phone: string;
  billingEmail: string;
  inscricaoMunicipal: string;
  inscricaoEstadual: string;
  country: number[];
  postalCode: string;
  city: number[];
  countryState: number[];
  address: string;
  district: string;
  addressComplement: string;
  number: string;
  rfc: string | null;
  additionalInvoiceEmail: string | null;
  mexicanCfdi: number[];
  mexicanPaymentForm: number[];
  mexicanPaymentMethod: number[];
  mexicanTaxRegime: number[];
  taxId: string | null;
}

export interface CompanyCategorization {
  companyHasFranchise: boolean;
  segment: number[];
  subsegment: number[];
  companyBusinessArea: number[];
  growthProjection: string;
}

export interface CompanyCurrency {
  billingCurrency: Currency[];
  paymentForm: PaymentForm[];
}

export enum Currency {
  REAL = 1,
  DOLAR = 2,
  PESO_ARG = 3,
  EURO = 4,
  PESO_MEX = 5,
}

export enum PaymentForm {
  BOLETO = 1,
  INVOICE = 2,
  SAT = 3,
}

export enum Country {
  BRAZIL = 1,
  MEXICO = 2,
}

export enum ExternalResource {
  COUNTRIES = 'countries',
  COUNTRY_STATES = 'countryStates',
  CITIES = 'cities',
  SEGMENTS = 'segments',
  SUBSEGMENTS = 'subsegments',
  BUSINESS_AREAS = 'businessAreas',
  MEXICAN_CFDIS = 'mexicanCfdis',
  MEXICAN_PAYMENT_FORMS = 'mexicanPaymentForms',
  MEXICAN_PAYMENT_METHODS = 'mexicanPaymentMethods',
  MEXICAN_TAX_REGIMES = 'mexicanTaxRegimes',
}

export interface BasicEntity {
  id: number;
  name: string;
  active?: boolean;
}

export interface CompanyRegisterData {
  companyName: string;
  cnpj: string | null;
  phone: string;
  billingEmail: string;
  inscricaoMunicipal: string;
  inscricaoEstadual: string;
  country: BasicEntity;
  postalCode: string;
  city: CityEntity;
  countryState: CountryStateEntity;
  address: string;
  district: string;
  addressComplement: string;
  companyHasFranchise: boolean;
  segment: BasicEntity;
  subsegment: BasicEntity;
  companyBusinessArea: BasicEntity[];
  billingCurrency: BasicEntity;
  growthProjection: string;
  number: string;
  rfc: string | null;
  additionalInvoiceEmail: string | null;
  mexicanPaymentForm: BasicEntity;
  mexicanCfdi: BasicEntity;
  mexicanPaymentMethod: BasicEntity;
  mexicanTaxRegime: BasicEntity;
  taxId: string | null;
  paymentForm: number | null;
}

export interface CompanyRegisterPayload
  extends Omit<
    CompanyRegisterData,
    | 'country'
    | 'segment'
    | 'subsegment'
    | 'city'
    | 'countryState'
    | 'companyBusinessArea'
    | 'billingCurrency'
    | 'mexicanPaymentForm'
    | 'mexicanCfdi'
    | 'mexicanPaymentMethod'
    | 'mexicanTaxRegime'
    | 'paymentForm'
  > {
  country: number;
  segment: number;
  subsegment: number;
  city: number;
  countryState: number;
  companyBusinessArea: number[];
  billingCurrency: number;
  mexicanPaymentForm: number;
  mexicanCfdi: number;
  mexicanPaymentMethod: number;
  mexicanTaxRegime: number;
  paymentForm: number;
}

export const parseCompanyBillingCurrency = ({
  billingCurrency,
  paymentForm,
}: CompanyRegisterData): CompanyCurrency => {
  return {
    billingCurrency: billingCurrency ? [billingCurrency.id] : [],
    paymentForm: paymentForm ? [paymentForm] : [],
  };
};

export const parseCompanyCategorization = (
  payload: CompanyRegisterData
): CompanyCategorization => {
  const {
    companyHasFranchise,
    segment,
    subsegment,
    companyBusinessArea,
    growthProjection,
  } = payload;

  return {
    companyHasFranchise,
    segment: segment ? [segment.id] : [],
    subsegment: subsegment ? [subsegment.id] : [],
    companyBusinessArea:
      companyBusinessArea && companyBusinessArea.length
        ? companyBusinessArea.map(({ id }) => id)
        : [],
    growthProjection,
  };
};

export const parseCompanyIdentification = (
  payload: CompanyRegisterData
): CompanyIdentification => {
  const {
    companyName,
    cnpj,
    phone,
    billingEmail,
    inscricaoMunicipal,
    inscricaoEstadual,
    country,
    postalCode,
    city,
    countryState,
    address,
    district,
    addressComplement,
    number,
    rfc,
    additionalInvoiceEmail,
    mexicanPaymentForm,
    mexicanCfdi,
    mexicanPaymentMethod,
    mexicanTaxRegime,
    taxId,
  } = payload;

  return {
    companyName,
    cnpj,
    phone,
    billingEmail,
    inscricaoMunicipal,
    inscricaoEstadual,
    country: country ? [country.id] : [],
    postalCode,
    city: city ? [city.id] : [],
    countryState: countryState ? [countryState.id] : [],
    address,
    district,
    addressComplement,
    number,
    rfc,
    additionalInvoiceEmail,
    mexicanPaymentForm: mexicanPaymentForm ? [mexicanPaymentForm.id] : [],
    mexicanCfdi: mexicanCfdi ? [mexicanCfdi.id] : [],
    mexicanPaymentMethod: mexicanPaymentMethod ? [mexicanPaymentMethod.id] : [],
    mexicanTaxRegime: mexicanTaxRegime ? [mexicanTaxRegime.id] : [],
    taxId,
  };
};

export interface ChecklistPlan {
  id: number;
  name: string;
  description: string;
  price: number;
  priceInfo: string;
  type: PlanType;
  contactUrl: string;
  currencySymbol: string;
}

export interface ChecklistFeatureToPlanRelation {
  helperId: string;
  name: string;
  plans: {
    planId: number;
    enabled: boolean;
    description: string;
  }[];
}

export interface ChecklistPlansData {
  plans: ChecklistPlan[];
  features: ChecklistFeatureToPlanRelation[];
}
