import { Injectable, Type } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor() {}

  startFlow(flowId: string) {
    if (!this.client) {
      return;
    }
    this.client.show(flowId);
  }

  private get client() {
    return Object.prototype.hasOwnProperty.call(window, 'Appcues')
      ? (window as any)['Appcues']
      : null;
  }
}
