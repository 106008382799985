export function userNameAbbreviation(name: string) {
  if (!name || typeof name !== 'string') {
    return '';
  }

  let explodedName = name.split(' ');

  // Remove empty strings from array
  explodedName = explodedName.filter((el) => !!el);

  let initials = '';
  if (explodedName.length > 1) {
    initials = explodedName[0][0] + explodedName[explodedName.length - 1][0];
  } else {
    initials = explodedName[0][0];
  }
  return initials.toUpperCase();
}
