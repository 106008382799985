<mat-form-field
  [appearance]="appearance"
  [class.cl-condensed-input]="condensed"
  [floatLabel]="condensed ? 'never' : 'auto'"
  *ngIf="!control; else fieldWithControl"
>
  <mat-label *ngIf="!condensed">{{ label }}</mat-label>
  <button
    *ngIf="prefixIcon"
    type="button"
    mat-icon-button
    matPrefix
    (click)="emitPrefixIconClick($event)"
    [disabled]="disabled"
    ><mat-icon>{{ prefixIcon }}</mat-icon></button
  >
  <input
    #textfieldInput="matInput"
    matInput
    [type]="type"
    [placeholder]="condensed ? label : placeholder"
    [value]="value"
    [disabled]="disabled"
    [readonly]="readonly"
    [required]="required"
    [attr.maxLength]="maxlength !== undefined ? maxlength : null"
    [autocomplete]="autocomplete"
    [errorStateMatcher]="errorStateMatcher"
    (input)="onChange(textfieldInput.value)"
    (change)="handleChange($event)"
  />
  <button
    *ngIf="suffixIcon"
    type="button"
    mat-icon-button
    matSuffix
    (click)="emitSuffixIconClick($event)"
    [disabled]="disabled"
  >
    <mat-icon>{{ suffixIcon }}</mat-icon>
  </button>
  <mat-hint *ngIf="hint && !condensed">{{ hint }}</mat-hint>
  <mat-hint *ngIf="showCount" align="end"
    >{{ count }} / {{ maxlength }}</mat-hint
  >
  <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<ng-template #fieldWithControl>
  <mat-form-field
    [appearance]="appearance"
    [class.cl-condensed-input]="condensed"
    [floatLabel]="condensed ? 'never' : 'auto'"
    *ngIf="control"
  >
    <mat-label *ngIf="!condensed">{{ label }}</mat-label>
    <input
      #textfieldInput="matInput"
      matInput
      [type]="type"
      [required]="required"
      [readonly]="readonly"
      [errorStateMatcher]="errorStateMatcher"
      [attr.maxLength]="maxlength !== undefined ? maxlength : null"
      [attr.max]="max !== undefined ? max : null"
      [attr.min]="min !== undefined ? min : null"
      [placeholder]="condensed ? label : placeholder"
      [autocomplete]="autocomplete"
      (input)="onChange(textfieldInput.value)"
      (change)="handleChange($event)"
      [formControl]="abstractControlAsFormControl(control.control)"
    />
    <button
      *ngIf="suffixIcon"
      type="button"
      mat-icon-button
      matSuffix
      (click)="emitSuffixIconClick($event)"
      [disabled]="disabled"
    >
      <mat-icon>{{ suffixIcon }}</mat-icon>
    </button>
    <mat-hint *ngIf="hint && !condensed">{{ hint }}</mat-hint>
    <mat-hint *ngIf="showCount" align="end"
      >{{ count }} / {{ maxlength }}</mat-hint
    >
    <mat-error>{{ errorMessage }}</mat-error>
  </mat-form-field>
</ng-template>
