import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class DialogService {
  constructor(public matDialog: MatDialog) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  open<T, D = any, R = any>(
    dialogComponent: ComponentType<T>,
    data: D,
    config: MatDialogConfig<D> = {}
  ) {
    const defaultConfig: MatDialogConfig<D> = {
      width: '400px',
      panelClass: 'cl-custom-border-radius',
      data,
    };
    const finalConfig: MatDialogConfig<D> = Object.assign(
      {},
      defaultConfig,
      config
    );
    return this.matDialog.open<T,D,R>(dialogComponent, finalConfig);
  }
}
