import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class FormDataUploadService {
  constructor(protected client: HttpClient) {}

  send<T = any>(endpoint: string, formData: FormData): Observable<T> {
    return this.client.post<T>(endpoint, formData);
  }
}
