import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validatorMaxDate(maxDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!maxDate) {
      return null;
    }
    const jsDate = new Date(control.value);
    return jsDate.getTime() > maxDate.getTime()
      ? { maxDate: { value: jsDate, maxAllowed: maxDate } }
      : null;
  };
}
