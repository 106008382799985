import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FileGalleryItem } from '../file-gallery/types';
import { FileReaderService } from './file-reader.service';
import { ConfirmationDialogData } from '../confirmation-dialog/types';
import { first, map, switchMap } from 'rxjs/operators';
import { DialogInvalidFilesComponent } from './dialog-invalid-files/dialog-invalid-files.component';
import { MatDialog } from '@angular/material/dialog';
import {
  ProviderScope,
  TRANSLOCO_SCOPE,
  TranslocoService,
} from '@ngneat/transloco';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { BehaviorSubject, of } from 'rxjs';
import { DialogLimitFilesComponent } from './dialog-limit-files';

export const getFileExtension = (fileName: string) => {
  if (fileName && fileName.indexOf('.') === -1) {
    return '';
  }
  return fileName.substring(fileName.lastIndexOf('.'));
};

@Component({
  selector: 'cl-ui-file-upload-gallery',
  templateUrl: './file-upload-gallery.component.html',
  styleUrls: ['./file-upload-gallery.component.scss'],
  providers: [
    FileReaderService,
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiFileUploadGallery'
    ),
  ],
})
export class FileUploadGalleryComponent implements OnInit {
  @Input()
  set files(f: FileGalleryItem[]) {
    this.files$.next(f || []);
  }
  get files(): FileGalleryItem[] {
    return this.files$.value;
  }

  @Input()
  set disabled(d: boolean) {
    this.disabledStyle = d;
    this.isDisabled = d;
  }
  get disabled(): boolean {
    return this.isDisabled;
  }
  private isDisabled = false;

  @Input() name: string | undefined;
  @Input() acceptList: any[] = [];
  @Input() maxFileSize = 5e6;
  @Input() hasRemoveConfirmation = false;
  @Input() buttonColor: ThemePalette | undefined;
  @Input() maxNumberOfFiles: number | undefined;

  @ViewChild('internalFile', { static: true }) internalInputFile:
    | ElementRef<HTMLInputElement>
    | undefined;
  @ViewChild('removeConfirmation', { static: true }) removeConfirmationDialog:
    | ConfirmationDialogComponent
    | undefined;

  @Output() filesChanged = new EventEmitter<FileGalleryItem[]>();
  @Output() fileRemoved = new EventEmitter<FileGalleryItem>();
  @HostBinding('class.disabled') disabledStyle = false;
  modernBrowser = true;
  localFilesSelected: File[] = [];
  removeConfirmationDialogData: ConfirmationDialogData | undefined;

  files$ = new BehaviorSubject<FileGalleryItem[]>([]);
  showButtonAddMore$ = this.files$.pipe(
    map((files) => {
      return this.maxNumberOfFiles
        ? files.length < this.maxNumberOfFiles
        : true;
    })
  );

  get accepts() {
    return this.acceptList.join(',');
  }

  get savedFilesName() {
    return this.name + '[]';
  }

  get inputFileName() {
    return this.name;
  }

  get inputFileElement() {
    return this.internalInputFile?.nativeElement as HTMLInputElement;
  }

  get filesFormData() {
    return this.createFilesFormData();
  }

  constructor(
    private fileReaderService: FileReaderService,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    @Inject(TRANSLOCO_SCOPE) private scope: ProviderScope
  ) {}

  ngOnInit() {
    this.modernBrowser =
      typeof DataTransfer !== 'undefined' &&
      DataTransfer !== null &&
      DataTransfer.constructor.name === 'Function';
  }

  handleFileAddClicked() {
    this.inputFileElement.click();
  }

  handleFileRemoveClicked(item: FileGalleryItem) {
    if (this.hasRemoveConfirmation) {
      this.translocoService
        .selectTranslate(
          [
            'confirmRemoveDialog.content',
            'confirmRemoveDialog.contentCancel',
            'confirmRemoveDialog.contentConfirm',
            'confirmRemoveDialog.title',
          ],
          {},
          this.scope
        )
        .pipe(
          first(),
          map(([content, buttonCancel, buttonConfirm, title]) => ({
            requiresCode: false,
            confirmButtonText: buttonConfirm,
            cancelButtonText: buttonCancel,
            content: content,
            title: title,
          })),
          switchMap(
            (removeConfirmationDialogData) =>
              this.removeConfirmationDialog
                ?.open(removeConfirmationDialogData)
                .afterClosed() || of()
          )
        )
        .subscribe((wasConfirmed) => {
          if (wasConfirmed) {
            this.handleFileRemove(item);
          }
        });
    } else {
      this.handleFileRemove(item);
    }
  }

  handleFileRemove(item: FileGalleryItem) {
    if (this.disabled) {
      return;
    }
    if (!item.localFile) {
      this.fileRemoved.emit(item);
      return;
    }
    this.files = this.files.filter((fileSaved) => fileSaved !== item);
    if (item.localFile) {
      this.localFilesSelected = this.localFilesSelected.filter(
        (localFile) => localFile !== item.fileInstance
      );
      this.resetFiles();
    }
    this.emitFilesChange();
  }

  resetFiles() {
    if (this.modernBrowser) {
      this.inputFileElement.files = this.fileReaderService.createFileList(
        this.localFilesSelected
      );
    } else {
      this.files = this.files.filter((file) => !file.localFile);
      this.inputFileElement.value = '';
    }
  }

  resetInputFile() {
    this.inputFileElement.value = '';
  }

  handleFileSelection() {
    if (!this.inputFileElement?.files?.length) {
      this.resetFiles();
      return false;
    }

    this.handleFileSelectionByAppend();
    return true;
  }

  private isSelectedFilesExceedingMaxNumber(files: FileList | null) {
    if (!this.maxNumberOfFiles || !files) {
      return false;
    }
    return (
      files.length > this.maxNumberOfFiles ||
      this.files.length + files.length > this.maxNumberOfFiles
    );
  }

  handleFileSelectionByAppend() {
    const selectedFiles = this.inputFileElement.files;

    const fileDataArray: (string | ArrayBuffer | null)[] = [];
    let rejectedFiles: { file: File; error: string }[] = [];
    let filteredFiles: File[] = [];

    Array.prototype.forEach.call(selectedFiles, (item) => {
      if (!this.acceptList && !this.maxFileSize) {
        filteredFiles = [...filteredFiles, item];
        return;
      }
      if (this.maxFileSize && item.size > this.maxFileSize) {
        rejectedFiles = rejectedFiles.concat({
          file: item,
          error: 'size',
        });
        return;
      }

      const extension = getFileExtension(item.name);

      if (
        this.acceptList &&
        this.acceptList.every(
          (type) => type.toLowerCase() !== extension.toLowerCase()
        )
      ) {
        rejectedFiles = rejectedFiles.concat({
          file: item,
          error: 'type',
        });
        return;
      }
      filteredFiles = [...filteredFiles, item];
    });

    if (rejectedFiles.length > 0) {
      this.dialog.open(DialogInvalidFilesComponent, {
        data: {
          files: rejectedFiles,
          allowedTypes: this.acceptList ? this.acceptList : [],
          allowedMaxSize: this.maxFileSize,
        },
      });
    }

    if (this.isSelectedFilesExceedingMaxNumber(selectedFiles)) {
      this.dialog.open(DialogLimitFilesComponent, {
        width: '400px',
        data: {
          limit: this.maxNumberOfFiles,
        },
      });
      return;
    }

    if (this.modernBrowser) {
      Array.prototype.forEach.call(filteredFiles, (item) => {
        this.localFilesSelected.push(item);
      });
    } else {
      this.localFilesSelected = Array.prototype.map.call(
        filteredFiles,
        (item) => item
      ) as File[];
    }

    const limit = filteredFiles.length;
    let counter = 0;

    const commonHandler = (event: ProgressEvent<FileReader>) => {
      fileDataArray.push(event.target?.result || null);
      counter++;
      if (counter < limit) {
        this.fileReaderService.readFile(filteredFiles[counter], commonHandler);
      } else {
        const addedFiles: FileGalleryItem[] = fileDataArray.map(
          (item, index) => {
            return <FileGalleryItem>(<unknown>{
              url: item,
              type: filteredFiles[index].type,
              name: filteredFiles[index].name,
              localFile: true,
              fileInstance: filteredFiles[index],
              canBeRemoved: this.modernBrowser,
            });
          }
        );

        if (this.modernBrowser) {
          this.files = this.files.concat(addedFiles);
          this.inputFileElement.files = this.fileReaderService.createFileList(
            this.localFilesSelected
          );
        } else {
          this.files = this.files
            .filter((file) => !file.localFile)
            .concat(addedFiles);
        }
        this.emitFilesChange();
      }
    };
    if (filteredFiles.length > 0) {
      this.fileReaderService.readFile(filteredFiles[counter], commonHandler);
    }
  }

  createFilesFormData() {
    return this.files.map((file) => {
      if (file.localFile) {
        const formData = new FormData();
        formData.append(<string>this.inputFileName, <File>file.fileInstance);
        return {
          ...file,
          formData,
        };
      }
      return file;
    });
  }

  emitFilesChange() {
    if (this.disabled) {
      return;
    }
    this.filesChanged.emit(this.createFilesFormData());
  }
}
