import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import Quill, { RangeStatic } from 'quill';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MenuSurfaceTriggerDirective } from '../menu-surface/menu-surface-trigger.directive';
import { fontSizes, ToolbarState } from './types';

@Component({
  selector: 'cl-ui-rich-editor',
  templateUrl: './rich-editor.component.html',
  styleUrls: ['./rich-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichEditorComponent),
      multi: true,
    },
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiRichEditor'
    ),
  ],
})
export class RichEditorComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  public currentSelection: RangeStatic | null = null;
  public link: { text: string; url: string } = {
    text: '',
    url: '',
  };
  public overlayOpened = false;
  public surfacePosition: ConnectedPosition = {
    offsetX: 0,
    offsetY: 0,
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  };
  public toolbarState: ToolbarState = {
    bold: false,
    italic: false,
    underline: false,
    strike: false,
    background: '#ffffff',
    color: '#000000',
    align: 'left',
    size: '12px',
  };

  private componentActive = true;
  private editor!: Quill;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  private onChange: ((changes: string) => void) | undefined;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  private onTouched: any;

  /** disabled */
  @Input()
  get disabled(): boolean {
    return this.isDisabled;
  }

  set disabled(disabled: boolean) {
    this.isDisabled = disabled;
    this.disabledChange$.next(disabled);
  }

  private isDisabled = false;

  private disabledChange$ = new Subject();
  private valueChanged$ = new BehaviorSubject<string>('');
  private selectionChange$ = new Subject();

  @Input() fontSizes: { value: string; name: string }[] = fontSizes;

  @Input()
  set value(value: string) {
    this.valueChanged$.next(value);
  }

  get value(): string {
    return this.valueChanged$.getValue();
  }

  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('insertLinkSurface', { static: true })
  insertLinkSurface: MenuSurfaceTriggerDirective | undefined;
  @ViewChild('textColorPicker', { static: true })
  textColorPicker: MenuSurfaceTriggerDirective | undefined;
  @ViewChild('fillColorPicker', { static: true })
  fillColorPicker: MenuSurfaceTriggerDirective | undefined;
  @ViewChild('editor', { static: true })
  editorContainer!: ElementRef;

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  ngOnInit() {
    this.setupEditor();
  }

  formatClear() {
    const selection = this.editor.getSelection(true);
    this.toolbarState = {
      color: '#000000',
      background: '#ffffff',
      align: '',
      size: '12px',
    };
    if (selection) {
      this.editor.removeFormat(selection.index, selection.length, 'user');
    }
  }

  formatWithBold() {
    this.toolbarState.bold = !this.toolbarState.bold;
    this.editor.format('bold', this.toolbarState.bold);
  }

  formatWithItalic() {
    this.toolbarState.italic = !this.toolbarState.italic;
    this.editor.format('italic', this.toolbarState.italic);
  }

  formatWithUnderline() {
    this.toolbarState.underline = !this.toolbarState.underline;
    this.editor.format('underline', this.toolbarState.underline);
  }

  formatWithStrike() {
    this.toolbarState.strike = !this.toolbarState.strike;
    this.editor.format('strike', this.toolbarState.strike);
  }

  formatWithTextColor() {
    this.editor.format('color', this.toolbarState.color);
    this.textColorPicker?.close();
  }

  formatWithFillColor() {
    this.editor.format('background', this.toolbarState.background);
    this.fillColorPicker?.close();
  }

  formatWithAlignLeft() {
    this.toolbarState.align = '';
    this.editor.format('align', this.toolbarState.align);
  }

  formatWithAlignCenter() {
    this.toolbarState.align = 'center';
    this.editor.format('align', this.toolbarState.align);
  }

  formatWithAlignRight() {
    this.toolbarState.align = 'right';
    this.editor.format('align', this.toolbarState.align);
  }

  formatWithFontSize(event: string) {
    this.toolbarState.size = event;
    this.editor.format('size', this.toolbarState.size);
  }

  getSelection() {
    const { index, length } = this.editor.getSelection(true);
    return {
      index,
      length,
      content: this.editor.getText(),
    };
  }

  insertLink() {
    const { url, text } = this.link;
    if (!text && !url) {
      this.overlayOpened = false;
      this.insertLinkSurface?.close();
      return;
    }

    if (this.currentSelection) {
      const { index, length } = this.currentSelection;
      if (!length) {
        this.editor.insertText(index, text, 'link', url);
      } else {
        this.editor.formatText(index, length, 'link', url);
      }
    } else {
      const index = this.editor.getLength();
      this.editor.insertText(index, text || url, 'link', url);
    }
    this.link = { url: '', text: '' };
    this.insertLinkSurface?.close();
    this.overlayOpened = false;
  }

  openInsertLinkSurface() {
    this.currentSelection = this.editor.getSelection();
    if (this.currentSelection) {
      const { index, length } = this.currentSelection;
      this.link = {
        ...this.link,
        text: this.editor.getText(index, length),
      };
    }
    this.overlayOpened = true;
  }

  onSelectChange() {
    if (this.overlayOpened) {
      return;
    }
    this.toolbarState = {
      background: '#ffffff',
      color: '#000000',
      align: '',
      size: '12px',
      ...this.editor.getFormat(),
    };
  }

  contentAsHTMLString(): string {
    return this.editorContainer?.nativeElement.querySelector('.ql-editor')
      .innerHTML;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectAll() {
    this.editor.setSelection(0, this.value ? this.value.length : 0, 'user');
  }

  // ControlValueAccessor methods

  writeValue(obj: any): void {
    this.value = obj;
    this.editorContainer.nativeElement.querySelector('.ql-editor').innerHTML =
      obj;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  // ControlValueAccessor methods end

  private setupEditor() {
    const SizeStyle = Quill.import('attributors/style/size');
    const AlignStyle = Quill.import('attributors/style/align');

    SizeStyle.whitelist = this.fontSizes.map((s) => s.value);
    Quill.register(SizeStyle, true);
    Quill.register(AlignStyle, true);
    this.editor = new Quill(this.editorContainer.nativeElement, {
      modules: {
        toolbar: false,
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: () => {
                return true;
              },
            },
          },
        },
      },
    });

    if (this.isDisabled) {
      this.editor.disable();
    }

    this.editor.on('selection-change', (event) => {
      this.selectionChange$.next(event);
    });

    this.editor.on('text-change', () => {
      this.value = this.contentAsHTMLString();

      const trimmedValue = this.isEditorEmpty ? '' : this.value;
      this.valueChange.emit(trimmedValue);
      if (this.onChange) {
        this.onChange(trimmedValue);
      }
    });

    this.disabledChange$
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((isDisabled) => {
        if (isDisabled) {
          this.editor.disable();
        } else {
          this.editor.enable();
        }
      });

    this.selectionChange$
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((event) => {
        if (event !== null) {
          this.currentSelection = this.editor.getSelection();
          this.onSelectChange();
        }
      });

    this.valueChanged$
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((value) => {
        const currentValue = this.contentAsHTMLString();
        if (currentValue !== value) {
          this.editorContainer.nativeElement.querySelector(
            '.ql-editor'
          ).innerHTML = value;
          this.editor.update('user');
        }
      });
  }

  get isEditorEmpty() {
    if ((this.editor.getContents().ops || []).length !== 1) {
      return false;
    }

    return this.editor.getText().trim().length === 0;
  }
}
