<div class="user-card" (click)="clicked()">
  <div
    class="user-avatar"
    [ngClass]="userAvatarBorderClass"
  >
    <span class="user-avatar__initials" *ngIf="!userCardInfo?.imageUrl">
      <abbr title="{{ userCardInfo?.name }}">{{ userInitials }}</abbr>
    </span>
    <div class="user-avatar__image" *ngIf="userCardInfo?.imageUrl">
      <img src="{{ userCardInfo?.imageUrl }}" alt="Avatar Image" />
    </div>

    <div class="user-avatar__trophy" *ngIf="userHasAccessToAchievements">
      <!-- trofeu bronze -->
      <cl-achievements-icons
        *ngIf="achievementTrophy === achievementIcon.BronzeTrophy"
        [icon]="achievementIcon.BronzeTrophy"
        [active]="true"
        [menuTrophy]="true"
      ></cl-achievements-icons>

      <!-- trofeu prata -->
      <cl-achievements-icons
        *ngIf="achievementTrophy === achievementIcon.SilverTrophy"
        [icon]="achievementIcon.SilverTrophy"
        [active]="true"
        [menuTrophy]="true"
      ></cl-achievements-icons>

      <!-- trofeu ouro -->
      <cl-achievements-icons
        *ngIf="achievementTrophy === achievementIcon.GoldTrophy"
        [icon]="achievementIcon.GoldTrophy"
        [active]="true"
        [menuTrophy]="true"
      ></cl-achievements-icons>

      <!-- trofeu diamante -->
      <cl-achievements-icons
        *ngIf="achievementTrophy === achievementIcon.Diamond"
        [icon]="achievementIcon.Diamond"
        [active]="true"
        [menuTrophy]="true"
      ></cl-achievements-icons>
    </div>
  </div>
  <div class="user-info">
    <span class="user-info__name">{{ userCardInfo?.name }}</span>
    <span class="user-info__type">{{ userCardInfo?.type }}</span>
  </div>
</div>
