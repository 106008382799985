import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FeatureHideComponent } from './feature-hide';
import { FeatureShowComponent } from './feature-show';
import { FeatureShowDirective } from './feature-show.directive';
import {
  GROWTHBOOK_CONFIG,
  GrowthBookConfig,
  GrowthBookService,
} from './growthbook.service';

@NgModule({
  imports: [CommonModule],
  exports: [FeatureShowComponent, FeatureHideComponent, FeatureShowDirective],
  declarations: [
    FeatureShowComponent,
    FeatureHideComponent,
    FeatureShowDirective,
  ],
  providers: [GrowthBookService],
})
export class SharedUtilGrowthbookModule {
  static forRoot(
    config: GrowthBookConfig
  ): ModuleWithProviders<SharedUtilGrowthbookModule> {
    return {
      ngModule: SharedUtilGrowthbookModule,
      providers: [{ provide: GROWTHBOOK_CONFIG, useValue: config }],
    };
  }
}
