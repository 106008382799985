export interface State {
  menuCollapsed: boolean;
  menuCollapsedMobile: boolean;
  menus: MenuListItem[];
}

export interface MenuListItem {
  id?: string;
  name: string;
  icon?: string;
  selected: boolean;
  url?: string;
  target?: string;
  route?: any;
  children?: MenuListItem[];
  childrenToggled?: boolean;
  availableInPlan?: string;
}
