import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cl-form-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = '';
  @Input() label: string | undefined;
  @Input() value = '';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() required = false;
  @Input() maxlength = '';
  @Input() showCount = false;
  @Input() singleLine = false;
  @Output() valueChange = new EventEmitter<string>();
  private touched = false;

  @ViewChild(CdkTextareaAutosize, { static: true })
  autosizeDirective: CdkTextareaAutosize | undefined;
  @ViewChild('textareaInput', { static: true }) formField:
    | ElementRef
    | undefined;

  ngOnInit() {
    if (this.singleLine) {
      this.value = this.removeLineBreaks(this.value);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange = (delta: any) => {};

  onTouched() {
    this.touched = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  handleChange(value: string) {
    this.valueChange.emit(value);
  }

  onFieldInput(value: string) {
    if (this.singleLine) {
      value = this.removeLineBreaks(value);

      // Workaround for pasting the same content not visually updating the element
      if (this.formField) {
        this.formField.nativeElement.value = value;
      }
      this.autosizeDirective?.resizeToFitContent();
    }

    this.value = value;
    this.onChange(value);
  }

  removeLineBreaks(value: string) {
    return value.replace(/(\r\n|\n|\r)/gm, '');
  }
}
