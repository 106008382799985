import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class SnackbarService {
  constructor(private snackbarService: MatSnackBar) {}

  showMessage(
    message: string,
    closeLabel: string,
    options: MatSnackBarConfig = {}
  ) {
    const finalOptions = Object.assign({}, { duration: 5000 }, options);

    const qaSnackbarClass = 'qa-snackbar';

    if (typeof finalOptions.panelClass === 'string') {
      finalOptions.panelClass = [finalOptions.panelClass, qaSnackbarClass];
    } else if (Array.isArray(finalOptions.panelClass)) {
      finalOptions.panelClass.push(qaSnackbarClass);
    } else {
      finalOptions.panelClass = qaSnackbarClass;
    }

    return this.snackbarService.open(message, closeLabel, finalOptions);
  }

  showMessageWithAction(
    message: string,
    actionLabel: string,
    options: MatSnackBarConfig = {}
  ) {
    return this.showMessage(message, actionLabel, options);
  }
}
