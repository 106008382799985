import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'cl-ui-button-link-mini',
  templateUrl: './button-link-mini.component.html',
  styleUrls: ['./button-link-mini.component.scss'],
})
export class ButtonLinkMiniComponent {
  @Input() color: ThemePalette = 'primary';
  @Input() disableRipple = false;
  @Input() disabled = false;
  @Output() clicked = new EventEmitter<MouseEvent>();

  handleClick(event: MouseEvent) {
    this.clicked.emit(event);
  }
}
