import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClFormModule } from '@checklistfacil/cl-form';
import { SharedUtilTrackingModule } from '@checklistfacil/shared/util/tracking';
import { TranslocoModule } from '@ngneat/transloco';
import { ColorChromeModule } from 'ngx-color/chrome';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AddonIconsComponent } from './addon-icons/addon-icons.component';
import { AreaHeaderComponent } from './area-header/area-header.component';
import { ButtonLinkMiniComponent } from './button-link-mini/button-link-mini.component';
import { ButtonWithLoadingComponent } from './button-with-loading/button-with-loading.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CardFieldComponent } from './card-field/card-field.component';
import { CardComponent } from './card/card.component';
import { ChipListComponent } from './chip-list/chip-list.component';
import { ColorpickerInputComponent } from './colorpicker-input/colorpicker-input.component';
import { ColorpickerComponent } from './colorpicker/colorpicker.component';
import { ConfigurationExtraComponent } from './configuration-extra/configuration-extra.component';
import { ConfigurationHeaderComponent } from './configuration-header/configuration-header.component';
import { ConfigurationSimpleComponent } from './configuration-simple/configuration-simple.component';
import { ConfirmationDialogContentComponent } from './confirmation-dialog/confirmation-dialog-content.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DependenciesDialogTriggerDirective } from './dependencies-dialog/dependencies-dialog-trigger.directive';
import { DependenciesDialogComponent } from './dependencies-dialog/dependencies-dialog.component';
import { DependencyCardItemComponent } from './dependency-card-item/dependency-card-item.component';
import { DialogComponent, DialogTriggerDirective } from './dialog';
import { DialogSelectComponent } from './dialog-select/dialog-select.component';
import { FieldsetComponent } from './fieldset/fieldset.component';
import { FileGalleryItemComponent } from './file-gallery/file-gallery-item/file-gallery-item.component';
import { FileGalleryComponent } from './file-gallery/file-gallery.component';
import { FileSlideshowComponent } from './file-gallery/file-slideshow/file-slideshow.component';
import {
  DialogInvalidFilesComponent,
  FileUploadGalleryComponent,
} from './file-upload-gallery';
import { DialogLimitFilesComponent } from './file-upload-gallery/dialog-limit-files';
import { HeaderComponent } from './header';
import { ImageMapperShowcaseComponent } from './image-mapper-showcase';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { LinksListComponent } from './links-list/links-list.component';
import { LoadingSliderComponent } from './loading-slider/loading-slider.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LocalizationBlockComponent } from './localization-block/localization-block.component';
import { MainContentComponent } from './main-content/main-content.component';
import { MenuSurfaceTriggerDirective } from './menu-surface/menu-surface-trigger.directive';
import { MenuSurfaceComponent } from './menu-surface/menu-surface.component';
import { OptionListComponent } from './option-list/option-list.component';
import { PopoverComponent } from './popover/popover.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { RichEditorComponent } from './rich-editor/rich-editor.component';
import { ScaleIconsComponent } from './scale-icons/scale-icons.component';
import { TableComponent } from './table/table.component';
import { TabsComponent } from './tabs/tabs.component';
import { UploadQueueItemComponent } from './upload-queue/upload-queue-item/upload-queue-item.component';
import { UploadQueueComponent } from './upload-queue/upload-queue.component';

@NgModule({
  declarations: [
    CardComponent,
    FieldsetComponent,
    FileGalleryComponent,
    FileGalleryItemComponent,
    FileUploadGalleryComponent,
    HeaderComponent,
    MainContentComponent,
    TabsComponent,
    FileSlideshowComponent,
    PrimaryButtonComponent,
    AreaHeaderComponent,
    ConfirmationDialogContentComponent,
    ConfirmationDialogComponent,
    ScaleIconsComponent,
    CardFieldComponent,
    ConfigurationExtraComponent,
    ConfigurationHeaderComponent,
    ConfigurationSimpleComponent,
    ColorpickerComponent,
    ColorpickerInputComponent,
    MenuSurfaceComponent,
    MenuSurfaceTriggerDirective,
    TableComponent,
    ButtonLinkMiniComponent,
    OptionListComponent,
    RichEditorComponent,
    UploadQueueComponent,
    UploadQueueItemComponent,
    DialogInvalidFilesComponent,
    LoadingSliderComponent,
    DialogComponent,
    LoadingSpinnerComponent,
    DependencyCardItemComponent,
    DependenciesDialogComponent,
    DependenciesDialogTriggerDirective,
    ReportHeaderComponent,
    LocalizationBlockComponent,
    LinksListComponent,
    AddonIconsComponent,
    ChipListComponent,
    CalendarComponent,
    InformationDialogComponent,
    PopoverComponent,
    ButtonWithLoadingComponent,
    DialogSelectComponent,
    DialogTriggerDirective,
    ImageMapperShowcaseComponent,
    DialogLimitFilesComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatRippleModule,
    MatDividerModule,
    MatMenuModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatChipsModule,
    MatCheckboxModule,
    NgxMatSelectSearchModule,
    MatRadioModule,
    MatTableModule,
    ColorChromeModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatExpansionModule,
    ScrollingModule,
    ClFormModule,
    DragDropModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatMomentDateModule,
    TranslocoModule,
    SharedUtilTrackingModule,
    OverlayModule,
  ],
  exports: [
    HeaderComponent,
    MainContentComponent,
    FileUploadGalleryComponent,
    TabsComponent,
    CardComponent,
    FieldsetComponent,
    PrimaryButtonComponent,
    MatButtonModule,
    ConfirmationDialogComponent,
    MatDialogModule,
    MatIconModule,
    DragDropModule,
    CardFieldComponent,
    ConfigurationSimpleComponent,
    ConfigurationHeaderComponent,
    ConfigurationExtraComponent,
    MatRadioModule,
    ConfirmationDialogContentComponent,
    ColorpickerInputComponent,
    TableComponent,
    ScaleIconsComponent,
    ButtonLinkMiniComponent,
    OptionListComponent,
    RichEditorComponent,
    UploadQueueComponent,
    AreaHeaderComponent,
    LoadingSliderComponent,
    MatMenuModule,
    LoadingSpinnerComponent,
    DependencyCardItemComponent,
    DependenciesDialogComponent,
    ReportHeaderComponent,
    LocalizationBlockComponent,
    MatTooltipModule,
    LinksListComponent,
    MatProgressSpinnerModule,
    AddonIconsComponent,
    ChipListComponent,
    FileGalleryComponent,
    FileGalleryItemComponent,
    FileSlideshowComponent,
    MenuSurfaceComponent,
    MatDatepickerModule,
    CalendarComponent,
    DialogComponent,
    MatListModule,
    InformationDialogComponent,
    PopoverComponent,
    ButtonWithLoadingComponent,
    DialogSelectComponent,
    DialogTriggerDirective,
    ImageMapperShowcaseComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
  ],
})
export class ClUiModule {}
