import { Injectable } from '@angular/core';

@Injectable()
export class FileReaderService {
  private fileReader: FileReader;
  constructor() {
    this.fileReader = new FileReader();
  }

  readFile(file: File, onLoadCallback: (e: ProgressEvent<FileReader>) => void) {
    this.fileReader.onload = onLoadCallback;
    this.fileReader.readAsDataURL(file);
  }

  createFileList(fileArray: File[]): FileList {
    const dt = new DataTransfer();
    fileArray.forEach((item) => {
      dt.items.add(item);
    });
    return dt.files;
  }
}
