<ng-container *transloco="let t; read: 'dialogLimitFilesComponent'">
  <div mat-dialog-title>
    <mat-icon color="primary" fontSet="material-icons-outlined">report_problem</mat-icon>
    <span class="dialog-title">{{ t('title') }}</span>
  </div>
  <mat-dialog-content>
    <div class="dialog-content" [innerHTML]="t('text', {count: data.limit})"></div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      mat-dialog-close="true"
      color="primary">{{t('confirm')}}</button>
  </mat-dialog-actions>
</ng-container>
