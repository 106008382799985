import { createAction, props } from '@ngrx/store';
import { Environment } from './environment.models';

export const init = createAction('[Environment Page] Init');

export const loadEnvironmentSuccess = createAction(
  '[Environment/API] Load Environment Success',
  props<{ environment: Environment }>()
);

export const loadEnvironmentFailure = createAction(
  '[Environment/API] Load Environment Failure',
  props<{ error: any }>()
);
