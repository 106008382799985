import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { FileGalleryItem } from '../types';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cl-ui-file-gallery-item',
  templateUrl: './file-gallery-item.component.html',
  styleUrls: ['./file-gallery-item.component.scss'],
})
export class FileGalleryItemComponent {
  @Input() disabled = false;
  @Input() file: FileGalleryItem | undefined;
  @Input() hasMoreOptionsControl = false;
  @Input() @HostBinding('style.height') @HostBinding('style.width') thumbSize =
    '56px';
  @Input() forceShowMoreOptions = false;
  @Output() fileMoreOptionsClicked = new EventEmitter<FileGalleryItem>();
  @Output() fileClicked = new EventEmitter<FileGalleryItem>();

  @HostListener('click')
  handleHostClick() {
    if (this.disabled) {
      return;
    }
    if (!this.hasMoreOptionsControl) {
      this.fileClicked.emit(this.file);
    }
  }

  get isImage() {
    /**
     * @TODO if adicionado para tratar caso de teste.
     */
    if (!this.file) {
      return false;
    }

    if (!this.file.type || typeof this.file.type !== 'string') {
      return false;
    }

    return (
      this.file.type.indexOf('jpg') !== -1 ||
      this.file.type.indexOf('jpeg') !== -1 ||
      this.file.type.indexOf('png') !== -1 ||
      this.file.type.indexOf('gif') !== -1
    );
  }

  get trueFileType() {
    /**
     * @TODO if adicionado para tratar caso de teste.
     */
    if (!this.file) {
      return '-';
    }
    if (this.file.localFile) {
      const lastIndex = this.file.name.lastIndexOf('.');
      if (lastIndex > -1) {
        return this.file.name.substring(lastIndex + 1, lastIndex + 1 + 4);
      }
    }

    if (this.file.type && typeof this.file.type === 'string') {
      const indexOf = this.file.type.indexOf('/');
      if (indexOf > -1) {
        return this.file.type.substring(indexOf + 1, indexOf + 1 + 4);
      }
      return this.file.type;
    }

    return '-';
  }

  get imageUrl() {
    if (this.file !== undefined) {
      if (this.file.bypassUnsafeUrl) {
        return this.sanitizer.bypassSecurityTrustUrl(this.file.url);
      }
      return this.file.url;
    }
    return '';
  }

  constructor(private sanitizer: DomSanitizer) {}

  handleMoreOptionsGalleryItemClick() {
    if (this.disabled) {
      return;
    }
    this.fileMoreOptionsClicked.emit(this.file);
  }
}
