/** Data structure for holding telephone number. */
export class PhoneNumber {
  static fromFormattedString(formattedString: string): PhoneNumber {
    const parserRegex = /^\((?<areaCode>\d+)\) ?(?<number>\d+)$/;
    const matchArray = (formattedString || '').match(parserRegex);

    return new PhoneNumber(
      matchArray?.groups?.areaCode ?? '',
      matchArray?.groups?.number ?? ''
    );
  }

  constructor(public areaCode: string, public number: string) {}

  get formatted(): string {
    return `(${this.areaCode}) ${this.number}`;
  }

  get unformatted(): string {
    return `${this.areaCode}${this.number}`;
  }
}
