import { UploadQueueItem } from '@checklistfacil/shared/util/general';
import { createAction, props } from '@ngrx/store';

export const addQueueItem = createAction(
  '[Upload queue] add queue item',
  props<{ items: UploadQueueItem[] }>()
);

export const startQueue = createAction('[Upload queue] start queue');

export const endQueue = createAction('[Upload queue] end queue');

export const nextQueueItem = createAction('[Upload queue] queue next item');

export const queueItemUpload = createAction(
  '[Upload queue] queue item upload',
  props<{ item: UploadQueueItem }>()
);

export const queueItemUploadSuccess = createAction(
  '[Upload queue] queue item success',
  props<{ item: UploadQueueItem; payload: any }>()
);

export const queueItemUploadError = createAction(
  '[Upload queue] queue item error',
  props<{ item: UploadQueueItem; error: { message: string } }>()
);

export const retryQueueItem = createAction(
  '[Upload queue] retry queue item',
  props<{ item: UploadQueueItem }>()
);

export const cancelQueueItem = createAction(
  '[Upload queue] cancel queue item',
  props<{ item: UploadQueueItem }>()
);

export const cancelAllQueueItems = createAction(
  '[Upload queue] cancel all queue items'
);

export const toggleQueueOpen = createAction('[Upload queue] queue toggle');

export const closeUploadQueue = createAction('[Upload queue] close queue');
