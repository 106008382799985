<div class="configuration-extra-container">
  <div class="label-container">
    <span class="label">{{ label }}</span>
    <span class="description" *ngIf="description">{{ description }}</span>
  </div>
  <div class="input">
    <ng-content></ng-content>
  </div>
</div>
<div class="configuration-extra-info" *ngIf="infoMessage" [@inOutAnimation]>
  <span class="info-icon">
    <mat-icon fontSet="material-icons-outlined">info</mat-icon>
  </span>
  <span class="info-message">
    {{ infoMessage }}
  </span>
</div>
