import { createSelector } from '@ngrx/store';
import { PlanType } from '../models';
import { featureSelector } from '../selectors';

export const company = createSelector(
  featureSelector,
  (state) => state.company
);

export const companyBillingEmailNotConfirmed = createSelector(
  company,
  (company) => !company.billingEmailConfirmed
);

export const companyBillingEmailConfirmed = createSelector(
  company,
  (company) => company.billingEmailConfirmed
);

export const isDemonstrationCompany = createSelector(
  company,
  (company) => company.isDemo
);

export const companyPlanType = createSelector(
  company,
  (company) => company.currentPlanType
);

export const companyPlanId = createSelector(
  company,
  (company) => company.planId
);

export const companyPlanTypeIsBasic = createSelector(
  companyPlanType,
  (plan) => plan === PlanType.BASIC
);

export const companyPlanTypeIsTrial = createSelector(
  companyPlanType,
  (plan) => plan === PlanType.TRIAL
);

export const companyPlanTypeIsNotTrial = createSelector(
  companyPlanType,
  (plan) => plan !== PlanType.TRIAL
);

export const companyPlanTypeIsAdvanced = createSelector(
  companyPlanType,
  (plan) => plan === PlanType.PROFESSIONAL || plan === PlanType.ENTERPRISE
);

export const contractDate = createSelector(
  company,
  (company) => company.contractDate
);

export const contractLink = createSelector(
  company,
  (company) => company.contractLink
);

export const selfSubscribed = createSelector(company, (company) =>
  Boolean(company.selfSubscribed)
);

export const isTrial = createSelector(company, (company) =>
  Boolean(company.isTrial)
);

export const remainingTrialDays = createSelector(
  company,
  (company) => company.remainingTrialDays
);

export const canExtendTrial = createSelector(company, (company) =>
  Boolean(company.canExtendTrial)
);

export const canExtendTrialDays = createSelector(
  company,
  (company) => company.canExtendTrialDays || 0
);

export const hasContactInformation = createSelector(
  company,
  (state) => state.hasContactInformation
);

export const companyRegisterFilled = createSelector(
  company,
  (state) => state.companyRegisterFilled
);

export const googleAnalyticsTag = createSelector(
  company,
  (state) => state.googleAnalyticsTag
);

export const companyInTrial = createSelector(isTrial, (trial) => trial);
export const companyRemainingTrialDays = createSelector(
  remainingTrialDays,
  (remainingDays) => remainingDays
);

export const companySegmentId = createSelector(
  company,
  (state) => state.segmentId
);
