<ng-container *transloco="let t; read: 'menuComponent'">
  <div class="menu-wrapper">
    <cl-menu-header
      (menuStateToggled)="handleMenuStateToggle()"
      [companyLogo]="companyLogo"
      [dashboardUrl]="dashboardUrl"
    ></cl-menu-header>
    <cl-user-card
      [matMenuTriggerFor]="accountMenu"
      [userCardInfo]="userInfo"
      [achievementTrophy]="userInfoAchievementTrophy"
      [userHasAccessToAchievements]="userHasAccessToAchievements"
    ></cl-user-card>
    <cl-menu-button
      *ngIf="showMainButton"
      [menuCollapsed]="menuIsCollapsed"
      [newApplyButton]="newApplyButton$ | async"
      [buttonText]="
        (newApplyButton$ | async)
          ? t('applyChecklistNewButton')
          : t('applyChecklist')
      "
      (click)="handleApplyChecklistClick()"
    ></cl-menu-button>
  </div>
  <mat-divider class="menu-divider"></mat-divider>
  <div class="menu-wrapper menu-content-wrapper">
    <cl-menu-list
      (menuListItemClicked)="handleMenuListItemClicked($event)"
      (upgradeIconClicked)="handleUpgradeIconClicked($event)"
      [items]="items"
    ></cl-menu-list>
  </div>
  <mat-menu class="menu-account-options" #accountMenu>
    <a [href]="accountMenuItem.profileUrl" target="" mat-menu-item>{{
      t('myProfile')
    }}</a>
    <a
      *ngIf="userHasAccessToAchievements"
      href="/achievements"
      mat-menu-item
      (click)="handleMyAchievementsClick('/achievements')"
      >{{ t('myAchievements') }}
    </a>
    <a
      *ngIf="isAccountManager"
      (click)="handleAccountSubscriptionSettingsClick($event)"
      class="qa-account-subscription"
      mat-menu-item
      >{{ t('accountSettings') }}</a
    >
    <a [href]="accountMenuItem.changePasswordUrl" mat-menu-item>{{
      t('changePassword')
    }}</a>
    <a
      *ngIf="hasHelpCenterItem"
      [href]="accountMenuItem.helpCenterUrl"
      target="_blank"
      mat-menu-item
      (click)="handleHelpCenterClick(accountMenuItem.helpCenterUrl)"
      >{{ t('helpCenter') }}</a
    >
    <a [href]="t('links.privacyPolicy.link')" target="_blank" mat-menu-item>{{
      t('links.privacyPolicy.text')
    }}</a>
    <a [href]="t('links.termsOfUse.link')" target="_blank" mat-menu-item>{{
      t('links.termsOfUse.text')
    }}</a>
    <a [href]="accountMenuItem.logoffUrl" mat-menu-item class="logout-item">{{
      t('logoff')
    }}</a>
  </mat-menu>
  <div class="menu-wrapper menu-footer-wrapper">
    <div *ngIf="isAccountManager" class="upgrade-trial-indicator">
      <button
        (click)="handleUpgradeButtonClick()"
        [matTooltip]="t('upgradeTooltip')"
        class="upgrade-button"
        type="button"
      >
        <mat-icon matPrefix>moving</mat-icon>
        <span class="upgrade-button__text">{{ t('upgrade') }}</span>
      </button>
      <span
        *ngIf="isTrial"
        class="trial-text"
        [class.trial-text--warning]="trialDays && trialDays <= 7"
      >
        <span class="trial-text__days"
          >{{ trialDaysString }} {{ t('days', { count: trialDays }) }}</span
        >
        <span class="trial-text__full">
          {{ t('trialDaysRemaining', { count: trialDays }) }}
        </span>
      </span>
    </div>

    <div class="trial-indicator" *ngIf="isTrial && !isAccountManager">
      <span class="trial-days">{{ trialDaysString }}</span>
      <span class="trial-text__long">{{
        t('days', { count: trialDays }) +
          ' ' +
          t('trialDaysRemaining', { count: trialDays })
      }}</span>
      <span class="trial-text__short">{{
        t('days', { count: trialDays })
      }}</span>
    </div>

    <mat-menu #menuHelp="matMenu" class="menu-help-surface">
      <cl-menu-help
        [menuHelpInfo]="menuHelpInfo"
        [hasContactInformation]="companyHasContactInformation"
        [jivoChatUrl]="jivoChatUrl"
        [jivoUserInfo]="jivoUserInfo"
      ></cl-menu-help>
    </mat-menu>
    <div
      class="menu-help-anchor"
      #menuHelpTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menuHelp"
    ></div>
    <cl-notification
      *ngIf="notificationCompanyConfigEnable$ | async"
      [menuIsCollapsed]="menuIsCollapsed"
      [footerIcons]="footerHiddenIcons$ | async"
    ></cl-notification>
    <cl-menu-footer
      [hideIcons]="menuIsCollapsed"
      [hiddenIcons]="footerHiddenIcons$ | async"
      [menuFooterInfo]="footerItem"
      [userHasAccessToAchievements]="userHasAccessToAchievements"
      (helpCenterClicked)="helpCenterClickAction($event)"
    ></cl-menu-footer>
  </div>
</ng-container>
