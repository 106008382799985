<div [ngClass]="{ 'range-input--disabled': disabled }" class="range-input">
  <span
    cdkDrag
    #leftDot="cdkDrag"
    [cdkDragDisabled]="disabled"
    (cdkDragMoved)="handleMoved()"
    (cdkDragEnded)="handleLeftEnded()"
    [cdkDragFreeDragPosition]="dotLeftPositioning ?? { x: 0, y: 0 }"
    cdkDragBoundary=".range-input"
    cdkDragLockAxis="x"
    class="range-input__dot"
  ></span>
  <span
    cdkDrag
    #rightDot="cdkDrag"
    [cdkDragDisabled]="disabled"
    (cdkDragMoved)="handleMoved()"
    (cdkDragEnded)="handleRightEnded()"
    [cdkDragFreeDragPosition]="dotRightPositioning ?? { x: 0, y: 0 }"
    cdkDragLockAxis="x"
    cdkDragBoundary=".range-input"
    class="range-input__dot"
  ></span>
</div>
