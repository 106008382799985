import { Action, createReducer, on } from '@ngrx/store';
import * as rootActions from './actions';

export interface State {
  userPreferences: any[];
  apiVersion: string;
}

export const initialState: State = {
  userPreferences: [],
  apiVersion: '',
};

export const rootReducer = createReducer(
  initialState,
  on(rootActions.appLoadSuccess, (state: State, action: any) => {
    return {
      ...state,
      ...action.payload,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return rootReducer(state, action);
}
