<div
  *transloco="let t; read: 'clUiMenuHelp'"
  class="popover-container"
  [ngClass]="{ 'popover-container--has-contact-info': hasContactInformation }"
>
  <span class="popover-dismiss">
    <mat-icon>close</mat-icon>
  </span>
  <span class="popover-title">{{ t('title') }}</span>
  <ul class="popover-list">
    <li class="popover-list__item">
      <a
        class="popover-list-button"
        target="_blank"
        [href]="menuHelpInfo?.helpCenterUrl"
        (click)="handleHelpCenterClick(menuHelpInfo?.helpCenterUrl)"
      >
        <span class="popover-list-button__text">{{
          t('helpCenterButton')
        }}</span>
      </a>
    </li>
    <li class="popover-list__item">
      <a
        class="popover-list-button"
        target="_blank"
        [href]="menuHelpInfo?.ticketsUrl"
      >
        <span class="popover-list-button__text">{{ t('ticketsButton') }}</span>
      </a>
    </li>
    <li class="popover-list__item" *ngIf="jivoChatUrl !== ''">
      <a
        class="popover-list-button"
        href="#"
        (click)="openChat($event)"
      >
        <span class="popover-list-button__text">{{ t('chat') }}</span>
      </a>
    </li>
  </ul>
</div>
