import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UploadQueueItem,
  UploadQueueItemStatus,
  UploadQueueItemTypes,
} from '@checklistfacil/shared/util/general';
import { translocoLoader } from '@checklistfacil/shared/util/translate';

@Component({
  selector: 'cl-ui-upload-queue-item',
  templateUrl: './upload-queue-item.component.html',
  styleUrls: ['./upload-queue-item.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiUploadQueueItem'
    ),
  ],
})
export class UploadQueueItemComponent {
  @Input() item: UploadQueueItem = {
    status: UploadQueueItemStatus.Pending,
    type: UploadQueueItemTypes.Other,
    endpoint: '',
    file: {} as FormData,
    name: '',
    errorMessage: '',
  };

  @Output() cancelItemUpload = new EventEmitter<UploadQueueItem>();
  @Output() retryItemUpload = new EventEmitter<UploadQueueItem>();

  get typeIconName(): string {
    if (this.item.type === UploadQueueItemTypes.Image) {
      return 'image';
    }
    if (this.item.type === UploadQueueItemTypes.Compressed) {
      return 'folder_special';
    }
    return 'insert_drive_file';
  }

  get itemSuccess() {
    return this.item.status === UploadQueueItemStatus.Success;
  }

  get itemError() {
    return this.item.status === UploadQueueItemStatus.Error;
  }

  get itemPending() {
    return this.item.status === UploadQueueItemStatus.Pending;
  }

  handleRetry() {
    this.retryItemUpload.emit(this.item);
  }

  handleCancel() {
    this.cancelItemUpload.emit(this.item);
  }
}
