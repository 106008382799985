export const LANGUAGE_CODES: Record<number, string> = {
  1: 'pt-br',
  2: 'pt-br',
  3: 'en-us',
  4: 'es-es',
};

export const LANGUAGE_IDS: Record<string, number> = {
  'pt-br': 1,
  'pt-pt': 2,
  'en-us': 3,
  'es-es': 4,
};

export const availableLangs = ['en-us', 'es-es', 'pt-br'];
