<div
  [ngClass]="{
    'calendar--disabled': this.disabled,
    'calendar--selected-to-right': this.selectedDirection === 'right',
    'calendar--selected-to-left': this.selectedDirection === 'left',
    'calendar--invalid': control.invalid
  }"
  class="calendar"
>
  <div class="form-field">
    <mat-icon class="form-field__icon" fontSet="material-icons-outlined"
      >today_24</mat-icon
    >
    <mat-form-field appearance="standard" [floatLabel]="'always'">
      <input
        class="form-field__input"
        autocomplete="off"
        matInput
        [formControl]="control"
        (dateChange)="changeByInput($event)"
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="picker"
        [placeholder]="pattern"
      />
      <mat-label>{{ label }}</mat-label>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <mat-calendar
    #calendar
    [dateClass]="todayClassFn"
    [minDate]="momentMinDate"
    [maxDate]="momentMaxDate"
    [startAt]="momentValue"
    (selectedChange)="handleSelectedChange($event)"
    [selected]="momentValue"
  ></mat-calendar>
</div>
