import {
  companyPlanType,
  isDemonstrationCompany,
  PlanType,
  userAccesses,
  userIsAdmin,
} from '@checklistfacil/shared/data-access/company-configs';
import { LocaleByLanguageCode } from '@checklistfacil/shared/util/general';
import { JivoUserInfo } from '@checklistfacil/shared/util/tracking';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuickstartStep, State, UserInfo, UserPermissions } from './models';

export const USER_CONFIGS_FEATURE_KEY = 'user-configs';

export const featureSelector = createFeatureSelector<State>(
  USER_CONFIGS_FEATURE_KEY
);

export const getUser = createSelector(
  featureSelector,
  userIsAdmin,
  (state, isAdmin) => {
    return {
      ...state.user,
      isAdmin,
    };
  }
);

export const getJivoUserInfo = createSelector(getUser, (user): JivoUserInfo => {
  return {
    id: user.id ?? 0,
    email: user.email ?? '',
    name: user.name ?? '',
    description: '',
    phone: '',
  };
});

export const getCurrentLanguage = createSelector(
  getUser,
  (state) => state.languageId
);

export const localeId = createSelector(getCurrentLanguage, (languageId) => {
  switch (languageId) {
    case LocaleByLanguageCode.en_US:
      return 'en-US';
    case LocaleByLanguageCode.es_ES:
      return 'es-ES';
    case LocaleByLanguageCode.pt_PT:
    case LocaleByLanguageCode.pt_BR:
    default:
      return 'pt';
  }
});

export const userCreatedAt = createSelector(getUser, (user) => user?.createdAt);

export const userIsAccountManager = createSelector(
  getUser,
  (user) => user.isAccountManager ?? false
);

export const userIdIsSameOfLoggedUser = createSelector(
  getUser,
  (user: UserInfo, { props }: { props: { userId: number } }) =>
    user.id === props.userId
);

export const trainingBannerLink = createSelector(
  featureSelector,
  (state) => state.user.selfTrainingLink
);

export const currentQuickstartStep = createSelector(
  getUser,
  ({ quickstartStep, quickstartCompleted }) => {
    return quickstartCompleted === true
      ? QuickstartStep.Completed
      : quickstartStep;
  }
);

export const quickstartIsCompleted = createSelector(
  getUser,
  (user) => user.quickstartCompleted
);

export const companyCanBeTrackedBySnowplow = createSelector(
  isDemonstrationCompany,
  companyPlanType,
  (isDemo, planType) => {
    if (isDemo) {
      return true;
    }
    return planType === PlanType.BASIC || planType === PlanType.TRIAL;
  }
);

/**
 * Dado que sou um usuário administrador
 ou posso configurar checklist
 ou posso configurar unidade
 ou posso configurar usuário
 ou posso ver relatório de PA
 ou posso ver relatório Ranking
 ou posso ver relatório de Reincidência
 ou tenho qualquer acesso de PA
 Então devo ter o script do Appcues habilitado
 */
export const userAllowedToUseAppcues = createSelector(
  userIsAdmin,
  userAccesses,
  (isAdmin, accesses) => {
    if (isAdmin) {
      return true;
    }

    const permissions = [
      UserPermissions.SettingsChecklists,
      UserPermissions.SettingsUnits,
      UserPermissions.SettingsUsers,
      UserPermissions.ReportActionPlans,
      UserPermissions.ReportRanking,
      UserPermissions.ReportRecidivism,
      UserPermissions.ActionPlanSee,
      UserPermissions.ActionPlanAddSolution,
      UserPermissions.ActionPlanApproveReproveSolution,
      UserPermissions.ActionPlanApprove,
      UserPermissions.ActionPlanFillResponsible,
      UserPermissions.ActionPlanFinalize,
      UserPermissions.ActionPlanDelete,
      UserPermissions.ActionPlanCancel,
      UserPermissions.ActionPlanExtend,
      UserPermissions.ActionPlanReopen,
      UserPermissions.ActionPlanReopen,
    ];

    const intersection = new Set();
    for (const access of accesses) {
      if (permissions.includes(access)) {
        intersection.add(access);
      }
    }

    return intersection.size > 0;
  }
);

export const userCompanyId = createSelector(getUser, (user) => user.companyId);
export const userEmail = createSelector(getUser, (user) => user.email);
export const userId = createSelector(getUser, (user) => user.id);
