import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { localeId } from './state/selectors';

@Injectable({
  providedIn: 'root',
})
export class LocaleFromUserService {
  private userLocale$ = new BehaviorSubject('pt');

  constructor(private store: Store) {
    this.store
      .pipe(
        select(localeId),
        tap((localeId) => this.userLocale$.next(localeId))
      )
      .subscribe();
  }

  locale(): string {
    return this.userLocale$.getValue();
  }
}
