import { Component, Input, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'cl-form-textfield-symbol',
  templateUrl: './textfield-symbol.component.html',
  styleUrls: ['./textfield-symbol.component.scss'],
})
export class TextfieldSymbolComponent implements ControlValueAccessor {
  @Input() emptyText = '';
  @Input() hint: string | undefined;
  @Input() label: string | undefined;
  @Input() symbol: string | undefined;
  @Input() appearance: MatFormFieldAppearance = 'fill';

  @Input()
  get disabled(): boolean {
    return this.isDisabled;
  }

  set disabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  private isDisabled = false;
  onChange: ((value: any) => void) | undefined;
  onTouch: (() => void) | undefined;
  value: number | null = null;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  handleChange(value: any) {
    if (!this.onChange) {
      return;
    }
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  abstractControlAsFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }
}
