<div class="menu-header">
  <div class="menu-header__action" (click)="handleExpandMenuClick()">
    <mat-icon
      class="menu-header__icon"
      aria-hidden="false"
      aria-label="Retrair menu"
      >menu</mat-icon
    >
  </div>
  <div class="menu-header__content">
    <a [href]="dashboardUrl">
      <img [src]="companyLogo" alt="logo" />
    </a>
  </div>
</div>
