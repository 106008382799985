import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { appLangFromBrowserLang } from '@checklistfacil/shared/util/translate';

@Injectable({
  providedIn: 'root',
})
export class LanguageSettingGuard implements CanActivate {
  constructor(private translocoService: TranslocoService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const langBrowser = appLangFromBrowserLang();
    const lang = route.queryParams.lang ?? langBrowser;

    this.translocoService.setActiveLang(lang);

    return true;
  }
}
