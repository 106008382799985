import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlanType } from '@checklistfacil/shared/data-access/company-configs';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';
import {
  ApiResponse,
  PaginatedPayload,
} from '@checklistfacil/shared/util/general';
import { map } from 'rxjs/operators';
import {
  BasicEntity,
  ChecklistPlansData,
  CompanyRegisterData,
  CompanyRegisterPayload,
} from './state/models';
import {
  CityEntity,
  CountryStateEntity,
} from '@checklistfacil/shared/data-access/country';

@Injectable({
  providedIn: 'root',
})
export class CompanySubscriptionService {
  appConfig: Configuration = {} as Configuration;

  constructor(
    private http: HttpClient,
    configService: ConfigAssetLoaderService
  ) {
    configService.loadConfigurations().subscribe((config) => {
      this.appConfig = config;
    });
  }

  extendTrial() {
    return this.http
      .post<
        ApiResponse<{
          remainingTrialDays: number;
        }>
      >(`${this.appConfig.spaApiUrl}/upgrade-account/extend-trial`, null)
      .pipe(map((response) => response.payload));
  }

  loadCompanyRegisterData() {
    return this.http
      .get<ApiResponse<CompanyRegisterData>>(
        `${this.appConfig.spaApiUrl}/company-register`
      )
      .pipe(
        map((response: ApiResponse<CompanyRegisterData>) => {
          return response.payload;
        })
      );
  }

  saveCompanyRegisterData(payload: CompanyRegisterPayload) {
    return this.http.post(
      `${this.appConfig.spaApiUrl}/company-register`,
      payload
    );
  }

  loadCountries() {
    return this.http
      .get<ApiResponse<PaginatedPayload<BasicEntity[]>>>(
        `${this.appConfig.apiUrl}/geo/countries`
      )
      .pipe(
        map((response: ApiResponse<PaginatedPayload<BasicEntity[]>>) => {
          return response.payload.items;
        })
      );
  }

  loadCountryStates(countryId: number) {
    return this.http
      .get<ApiResponse<PaginatedPayload<CountryStateEntity[]>>>(
        `${this.appConfig.apiUrl}/geo/countries/${countryId}/states`
      )
      .pipe(
        map((response: ApiResponse<PaginatedPayload<CountryStateEntity[]>>) => {
          return response.payload.items;
        })
      );
  }

  loadCities(countryId: number, stateId: number) {
    return this.http
      .get<ApiResponse<PaginatedPayload<CityEntity[]>>>(
        `${this.appConfig.spaApiUrl}/geo/countries/${countryId}/states/${stateId}/cities`
      )
      .pipe(
        map((response: ApiResponse<PaginatedPayload<CityEntity[]>>) => {
          return response.payload.items;
        })
      );
  }

  loadSegments() {
    return this.http
      .get<ApiResponse<BasicEntity[]>>(
        `${this.appConfig.spaApiUrl}/company-register/segments`
      )
      .pipe(
        map((response: ApiResponse<BasicEntity[]>) => {
          return response.payload;
        })
      );
  }

  loadSubsegments(segmentId: number) {
    return this.http
      .get<ApiResponse<BasicEntity[]>>(
        `${this.appConfig.spaApiUrl}/company-register/segments/${segmentId}/subsegments`
      )
      .pipe(
        map((response: ApiResponse<BasicEntity[]>) => {
          return response.payload;
        })
      );
  }

  loadBusinessAreas() {
    return this.http
      .get<ApiResponse<BasicEntity[]>>(
        `${this.appConfig.spaApiUrl}/company-register/sectors`
      )
      .pipe(
        map((response: ApiResponse<BasicEntity[]>) => {
          return response.payload;
        })
      );
  }

  loadPlansData() {
    return this.http
      .get<ApiResponse<ChecklistPlansData>>(
        `${this.appConfig.spaApiUrl}/upgrade-account`
      )
      .pipe(
        map((response: ApiResponse<ChecklistPlansData>) => {
          return response.payload;
        })
      );
  }

  accountPlanUpgrade(payload: { planId: number }) {
    return this.http.post(
      `${this.appConfig.spaApiUrl}/upgrade-account`,
      payload
    );
  }

  registerPlanUpgradeInterest(plan: PlanType) {
    return this.http.post(
      `${this.appConfig.spaApiUrl}/register-interest/upgrade`,
      { plan }
    );
  }

  resendBillingEmail() {
    return this.http.post(
      `${this.appConfig.spaApiUrl}/company-register/resend-billing-mail-confirmation`,
      {}
    );
  }

  loadMexicanCfdi() {
    return this.http
      .get<ApiResponse<BasicEntity[]>>(
        `${this.appConfig.spaApiUrl}/company-register/mexican-cfdis`
      )
      .pipe(
        map((response: ApiResponse<BasicEntity[]>) => {
          return response.payload;
        })
      );
  }

  loadMexicanPaymentForm() {
    return this.http
      .get<ApiResponse<BasicEntity[]>>(
        `${this.appConfig.spaApiUrl}/company-register/mexican-payment-forms`
      )
      .pipe(
        map((response: ApiResponse<BasicEntity[]>) => {
          return response.payload;
        })
      );
  }

  loadMexicanPaymentMethod() {
    return this.http
      .get<ApiResponse<BasicEntity[]>>(
        `${this.appConfig.spaApiUrl}/company-register/mexican-payment-methods`
      )
      .pipe(
        map((response: ApiResponse<BasicEntity[]>) => {
          return response.payload;
        })
      );
  }

  loadMexicanTaxRegime() {
    return this.http
      .get<ApiResponse<BasicEntity[]>>(
        `${this.appConfig.spaApiUrl}/company-register/mexican-tax-regimes`
      )
      .pipe(
        map((response: ApiResponse<BasicEntity[]>) => {
          return response.payload;
        })
      );
  }
}
