<div mat-dialog-title>
  <mat-icon
    color="primary"
    fontSet="material-icons-outlined"
    *ngIf="data.titleIconName"
    >{{ data.titleIconName }}</mat-icon
  >
  {{ data.title }}
</div>
<mat-dialog-content>
  <div class="checklist-alerts" *ngIf="data.alertMessage">
    <mat-icon class="checklist-alerts__icon">warning</mat-icon>
    <span [innerHTML]="alertMessage">
      </span>
  </div>
  <div>
    <span [innerHTML]="beforeCodeMessage"></span>
    <ng-container *ngIf="data.requiresCode">
      <span class="confirmation-code"
        ><strong>{{ generatedConfirmationCode }}</strong></span
      >
      {{ afterCodeMessage }}
    </ng-container>
  </div>

  <div class="confirmation-input" *ngIf="data.requiresCode">
    <cl-form-textfield
      appearance="outline"
      autocomplete="off"
      [label]="data.codeInputLabel ?? ''"
      [formControl]="code"
    ></cl-form-textfield>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container *ngIf="data.reverseButtons">
    <button
      mat-raised-button
      color="primary"
      class="dialog-confirm-button"
      [mat-dialog-close]="true"
      [disabled]="data.requiresCode && code.value !== generatedConfirmationCode"
    >
      {{ data.confirmButtonText }}
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="dialog-cancel-button"
      [mat-dialog-close]="false">
      {{ data.cancelButtonText }}
    </button>
  </ng-container>

  <ng-container *ngIf="!data.reverseButtons">
    <button
      color="primary"
      mat-stroked-button
      class="dialog-cancel-button"
      [mat-dialog-close]="false">
      {{ data.cancelButtonText }}
    </button>
    <button
      mat-raised-button
      color="primary"
      class="dialog-confirm-button"
      [mat-dialog-close]="true"
      [disabled]="data.requiresCode && code.value !== generatedConfirmationCode"
    >
      {{ data.confirmButtonText }}
    </button>
  </ng-container>
</mat-dialog-actions>
