import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@checklistfacil/shared/util/environment';
import Pusher from 'pusher-js/with-encryption';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PusherClientService {
  private pusher: Pusher | undefined;

  constructor(
    private readonly configAssetLoaderService: ConfigAssetLoaderService
  ) {
    this.setupPusher();
  }

  private setupPusher() {
    this.configAssetLoaderService
      .loadConfigurations()
      .pipe(
        map(({ pusher }) => ({
          key: pusher.key,
          cluster: pusher.cluster,
          wsHost: pusher.wsHost,
          wsPort: pusher.wsPort,
        }))
      )
      .subscribe(({ key, cluster, wsPort, wsHost }) => {
        this.pusher = new Pusher(key, {
          cluster,
          wsHost,
          wsPort,
          // TODO: verificar se encrypted pode ser removido com segurança sem afetar o comportamento
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          encrypted: true,
        });
      });
  }

  connectInChannel(channel: string) {
    if (!this.pusher) {
      return null;
    }

    return this.pusher.subscribe(channel);
  }
}
