export interface InvalidFile {
  file: File;
  error: InvalidFileError;
}

export interface InvalidDialogTerms {
  fileCannotBeLargerThan: string;
  uploadInvalidType: string;
  titleErrorSending: string;
  imageDimensionError: string;
}

export enum InvalidFileError {
  size = 'size',
  type = 'type',
  dimension = 'dimension',
}

export interface DialogInvalidFilesData {
  files: InvalidFile[];
  allowedTypes?: string[];
  allowedMaxSize?: number;
  allowedImageDimension?: string;
  terms: InvalidDialogTerms;
}
