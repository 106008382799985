import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SharedUtilTranslateModule } from '@checklistfacil/shared/util/translate';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
  declarations: [NotificationComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    SharedUtilTranslateModule,
    MatProgressBarModule,
  ],
  exports: [NotificationComponent],
})
export class SharedMainMenuNotificationModule {}
