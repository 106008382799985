<div >
  <ng-container *ngFor="let notification of allNotifications; index as notificationIndex">
    <div
      class="notification-wrapper"
      [ngClass]="notificationIndicatorDefault ? 'notification-indicator__complete' : 'notification-indicator__incomplete'"
      [class.notification-many]="hasManyNotification"
      [class.notification-aside]="menuIsCollapsed"
      *ngIf="notification.show"
    >
      <mat-progress-bar mode="determinate" [value]="progressBar"></mat-progress-bar>

      <div class="notification__container">
        <button class="notification__close" (click)="handleCloseNotification()">
          <mat-icon>close</mat-icon>
        </button>
        <h3 class="notification__title qa-title">{{notification.title}}</h3>
        <span class="notification__content qa-content">{{notification.content}}</span>
        <span class="notification__file qa-file__name" *ngIf="notification.filename !== ''">{{notification.filename}}</span>

        <div class="notification-footer" *ngIf="showButtons">
          <ng-template let-t transloco="" translocoRead="notificationComponent">
            <button
              class="action-options__button qa-details-button"
              mat-raised-button
              color="primary"
              *ngIf="notification.detailsButton"
              (click)="handleDetails(notification.detailUrl)"
            >
              {{t('details')}}
            </button>
            <button
              class="action-options__button qa-verify-button"
              mat-raised-button
              color="primary"
              *ngIf="notification.verifyButton"
              (click)="handleDetails(notification.detailUrl)"
            >
              {{t('verifyButton')}}
            </button>
            <a
              class="action-options__button qa-download-button"
              mat-raised-button
              color="primary"
              *ngIf="notification.downloadButton"
              target="_blank"
              [href]="notification.fileUrl"
              (click)="handleCloseNotification()"
            >
              {{t('downloadButton')}}
            </a>
            <a
              class="action-options__button qa-open-button"
              mat-stroked-button
              color="primary"
              target="_blank"
              [href]="notification.fileUrl"
              (click)="handleCloseNotification()"
              *ngIf="notification.openButton"
            >
              {{t('openButton')}}
            </a>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</div>
