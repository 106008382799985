export interface ApiResponse<PayloadType> {
  success: boolean;
  message: string;
  code: number | string;
  payload: PayloadType;
}

export interface HttpClientParams {
  [param: string]: string | string[];
}

export interface ApiListParameters {
  search?: string;
  page?: string;
  size?: string;
}

export interface PaginatedPayload<ItemType> {
  pagination: {
    page: number;
    size: number;
    total: number;
  };
  items: ItemType;
}

export interface PaginatedPayloadV2<ItemType> {
  data: ItemType[];
  links: PaginatedPayloadV2Links;
  meta: PaginatedPayloadV2Meta;
}

export interface PaginatedPayloadV2Links {
  first: string;
  last: string;
  prev: string | null;
  next: string | null;
}

export interface PaginatedPayloadV2Meta {
  currentPage: number;
  from: number;
  lastPage: number;
  path: string;
  perPage: number;
  to: number;
  total: number;
}

export function createPaginatedPayloadV2<ItemType>(
  data: ItemType[],
  page = 1,
  limit = 20,
  path = ''
): PaginatedPayloadV2<ItemType> {
  const lastPage = Math.ceil(data.length / limit);
  const hasNextPage = page < lastPage;
  const hasPrevPage = page > 1;

  const possibleTo = page * limit;
  const to = possibleTo > data.length ? data.length : possibleTo;

  return {
    data,
    links: {
      first: `${path}?limit=${limit}&page=1`,
      last: `${path}?limit=${limit}&page=${lastPage}`,
      next: hasNextPage ? `${path}?limit=${limit}&page=${page + 1}` : null,
      prev: hasPrevPage ? `${path}?limit=${limit}&page=${page - 1}` : null,
    },
    meta: {
      lastPage,
      path,
      currentPage: page,
      perPage: limit,
      total: data.length,
      from: (page - 1) * limit + 1,
      to,
    },
  };
}

export enum ChecklistType {
  Normal = 1,
  Survey = 2,
  StandalonePA = 3,
  SiengeServiceInspection = 4,
  SiengeOrderInspection = 5,
}

export interface BasicEntity {
  id: number;
  name: string;
  active: boolean;
}

export enum LocaleByLanguageCode {
  pt_BR = 1,
  pt_PT,
  en_US,
  es_ES,
}

export enum ActionPlanStatus {
  AguardandoPreenchimento = 1,
  PreenchimentoAnalise = 2,
  PreenchimentoReprovado = 3,
  Cancelado = 4,
  AguardandoSolucao = 5,
  SolucaoEmAnalise = 6,
  Concluido = 7,
  AguardandoConclusao = 8,
  SolucaoReprovada = 10,
}

export interface ApiError {
  message: string;
}

export const allowedFileExtensions = [
  '.jpg',
  '.png',
  '.jpeg',
  '.gif',
  '.tiff',
  '.bmp',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.docx',
  '.ppt',
  '.pptx',
  '.ods',
  '.odt',
  '.rtf',
  '.avi',
  '.flv',
  '.wmv',
  '.mov',
  '.mp4',
  '.3gp',
  '.mp3',
  '.aac',
  '.flac',
  '.wma',
  '.pcm',
  '.aiff',
  '.zip',
  '.7z',
  '.rar',
  '.tar',
  '.gz',
  '.mpg',
  '.mpeg',
  '.mkv',
  '.webm',
  '.ts',
  '.vob',
  '.ogg',
  '.ogv',
  '.m4v',
  '.m4a',
  '.mp4a',
  '.mpga',
  '.svg',
  '.webp',
  '.heic',
];

export enum AppId {
  Web = 1,
  WebWhiteLabel = 4,
}

export const enum ProcessingState {
  INIT = 'INIT',
  PROCESSING = 'PROCESSING',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export type CallState = ProcessingState | ErrorState;

export type Error = ApiError | null;

export interface ApiValidationError {
  [key: string]: string[];
}

export interface ErrorState {
  errorMessages?: Error;
  status: number;
}

export interface ApiError {
  message: string;
  errors?: ApiValidationError;
}

export interface ListOption<IdType> {
  id: IdType;
  name: string | null;
  active: boolean;
  readonly?: boolean;
}

export interface ListOptionGroup<IdType> {
  id?: IdType;
  name: string;
  active?: boolean;
  items: ListOption<IdType>[];
}

export enum ChecklistItemDisputeStatus {
  Analyzing,
  Approved,
  Reproved,
}

/**
 * Movido de libs/shared/data-access/upload-queue/src/lib/+state/models.ts
 */
export enum UploadQueueItemTypes {
  Image = 1,
  Compressed = 2,
  Other = 3,
}

export enum UploadQueueItemStatus {
  Pending = 1,
  Success = 2,
  Error = 3,
}

export interface UploadQueueItem {
  id?: string;
  type: UploadQueueItemTypes;
  name: string;
  status: UploadQueueItemStatus;
  endpoint: string;
  file: FormData;
  errorMessage?: string;
  url?: string;
  scope?: string;
  scopeData?: any;
}
