import { Action, createReducer, on } from '@ngrx/store';

import * as EnvironmentActions from './environment.actions';
import { Environment } from './environment.models';
import { ProcessingState } from '@checklistfacil/shared/util/general';

export const ENVIRONMENT_FEATURE_KEY = 'environment';

export interface State extends Environment {
  loaded: boolean; // has the Environment list been loaded
  error?: string | null; // last known error (if any)
  callState: ProcessingState;
}

export interface EnvironmentPartialState {
  readonly [ENVIRONMENT_FEATURE_KEY]: State;
}

export const initialState: State = {
  // set initial required properties
  atualUrl: '',
  callState: ProcessingState.INIT,
  loaded: false,
  environment: null,
  error: null,
  apiUrl: '',
  appUrl: '',
  enableSnowPlow: null,
  pusher: null,
  siteUrl: '',
  spaApiUrl: '',
  accountsApiUrl: null,
  recaptchaSiteKey: null,
};

const environmentReducer = createReducer(
  initialState,
  on(EnvironmentActions.init, (state) => ({
    ...state,
    callState: ProcessingState.LOADING,
    loaded: false,
    error: null,
  })),
  on(EnvironmentActions.loadEnvironmentSuccess, (state, { environment }) => ({
    ...state,
    ...environment,
    callState: ProcessingState.LOADED,
    loaded: true,
    error: null,
  })),
  on(EnvironmentActions.loadEnvironmentFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return environmentReducer(state, action);
}
