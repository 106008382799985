import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import {
  companyPlanType,
  PlanType,
} from '@checklistfacil/shared/data-access/company-configs';
import { EnvironmentSelectors } from '@checklistfacil/shared/data-access/environment';
import {
  appFinishedLoading,
  appHasError as appHasErrorSelector,
  appSplash,
  LoadingService,
} from '@checklistfacil/shared/data-access/loading';
import { getUser, localeId } from '@checklistfacil/shared/data-access/user';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';
import { LANGUAGE_CODES } from '@checklistfacil/shared/util/translate';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { Observable } from 'rxjs';
import { filter, first, map, take, tap } from 'rxjs/operators';
import { getApiVersionAndUser, googleAnalyticsTag } from './state';
import { GrowthBookService } from '@checklistfacil/shared/util/growthbook';

@Component({
  selector: 'cl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  appHasError = false;
  isLoading$: Observable<boolean>;
  isSplash$: Observable<boolean>;

  private appConfig: Configuration | undefined;

  constructor(
    private titleService: Title,
    private store: Store,
    private loading: LoadingService,
    private router: Router,
    private translocoService: TranslocoService,
    private growthBookService: GrowthBookService,
    configService: ConfigAssetLoaderService
  ) {
    configService.loadConfigurations().subscribe((config) => {
      this.appConfig = config;

      if (
        this.appConfig.environment === 'prod' ||
        this.appConfig.environment === 'emb' ||
        this.appConfig.environment === 'tenant'
      ) {
        document.querySelector('html')?.classList.add('gtm-ambiente-producao');
      } else {
        document.querySelector('html')?.classList.add('gtm-ambiente-local');
      }
    });
    this.isLoading$ = this.loading.loading$;
    this.isSplash$ = this.store.pipe(select(appSplash), take(2));
  }

  ngOnInit(): void {
    this.titleService.setTitle('Checklist Fácil');
    this.setupStoreBinds();

    this.router.events
      .pipe(filter((e): e is RouterEvent => e instanceof NavigationEnd))
      .subscribe((e: RouterEvent) => {
        if (Object.prototype.hasOwnProperty.call(window, 'gtag')) {
          (window as any)['gtag']('set', 'page', e.url);
          (window as any)['gtag']('send', 'pageview');
        }
      });
  }

  private setupStoreBinds() {
    this.store.select(appHasErrorSelector).subscribe((hasError) => {
      this.appHasError = hasError;
    });

    this.store.select(googleAnalyticsTag).subscribe((tag) => {
      if (
        Object.prototype.hasOwnProperty.call(window, 'gtag') &&
        tag !== null
      ) {
        (window as any)['gtag']('config', tag);
      }
    });

    this.store
      .pipe(
        select(getApiVersionAndUser),
        filter((value) => value.version !== '' && Boolean(value.user.id)),
        map(({ user }) =>
          user.languageId ? LANGUAGE_CODES[user.languageId] : null
        ),
        tap((userLanguage) => {
          if (userLanguage) {
            this.translocoService.setActiveLang(userLanguage);
          }
        }),
        first()
      )
      .subscribe(() => this.store.dispatch(appFinishedLoading()));

    this.store.pipe(select(companyPlanType)).subscribe((planType) => {
      switch (planType) {
        case PlanType.BASIC: {
          document.querySelector('html')?.classList.add('gtm-plano-basic');
          break;
        }
        case PlanType.TRIAL: {
          document.querySelector('html')?.classList.add('gtm-plano-trial');
          break;
        }
        case PlanType.PROFESSIONAL: {
          document
            .querySelector('html')
            ?.classList.add('gtm-plano-professional');
          break;
        }
        case PlanType.ENTERPRISE: {
          document.querySelector('html')?.classList.add('gtm-plano-enterprise');
          break;
        }
      }
    });

    this.store
      .pipe(
        select(getUser),
        filter((user) => user.id !== null)
      )
      .subscribe((user) => {
        Sentry.setUser({
          id: String(user.id),
          name: user.name,
          email: user.email ?? undefined,
          isAccountManager: user.isAccountManager,
          isAdmin: user.isAdmin,
          languageId: user.languageId,
          company: {
            id: user.companyId,
            name: user.companyName,
          },
          userType: {
            id: user.userTypeId,
            name: user.userTypeName,
          },
        });
      });

    this.store
      .select(localeId)
      .subscribe((locale) => Sentry.setTag('page_locale', locale));

    this.store
      .pipe(
        select(EnvironmentSelectors.environmentName),
        filter((env) => Boolean(env))
      )
      .subscribe((environment) =>
        Sentry.setTag('environment.detail', environment)
      );

    this.store
      .pipe(
        select(getUser),
        filter((user) => user.id !== null)
      )
      .subscribe((user) => {
        this.growthBookService.setAttributes({
          userId: user.id,
          isAdmin: user.isAdmin,
          companyId: user.companyId,
          loggedIn: user.id !== null,
        });
      });
  }

  handleErrorBackButtonClicked() {
    if (!this.appConfig) {
      throw new Error('appConfig not loaded');
    }

    window.location.href = this.appConfig.appUrl;
  }
}
