<button
  [type]="type"
  [color]="color"
  [disabled]="(isBlocked$ | async) ?? false"
  mat-button
  [ngClass]="[
    matButtonType,
    isColoredVariant ? 'colored-variant' : 'white-variant'
  ]"
  [attr.form]="form"
>
  <ng-content></ng-content>

  <span class="button-spinner-wrapper" *ngIf="(processing$ | async) ?? false">
    <mat-spinner [diameter]="19" class="mat-spinner-color"></mat-spinner>
  </span>
</button>
