import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureConfigKey } from './+state/selectors';
import { reducer } from './+state/reducers';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(featureConfigKey, reducer)],
})
export class DataAccessCompanyConfigsModule {}
