<mat-form-field [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <cl-form-input-with-symbol
    *ngIf="!(ngControl && ngControl.control); else inputWithControl"
    [symbol]="symbol"
    [disabled]="disabled"
    [emptyText]="emptyText"
    [value]="value"
    (valueChange)="handleChange($event)"
  ></cl-form-input-with-symbol>
  <ng-template #inputWithControl>
    <cl-form-input-with-symbol
      [formControl]="abstractControlAsFormControl(ngControl.control)"
      [emptyText]="emptyText"
      [symbol]="symbol"
      (valueChange)="handleChange($event)"
    ></cl-form-input-with-symbol>
  </ng-template>
  <mat-error>
    <ng-content select="[errors]"></ng-content>
  </mat-error>
  <mat-hint *ngIf="hint !== undefined">{{ hint }}</mat-hint>
</mat-form-field>
