import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.sentry.enabled) {
  Sentry.init({
    dsn: 'https://5cd7eb116f9647898c397f09bb7f5187@sentry.checklistfacil.com.br/4',
    environment: environment.sentry.environment,
    release: environment.sentry.release,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          /localhost(:\d{1,5})?/,
          /(.*\.)?checklistfacil\.com(\.br)?/,
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    ignoreErrors: [
      /*
        Ignora erro gerado por um bot da Microsoft, provavelmente relacionado ao Outlook Safe Link
        https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
        https://github.com/getsentry/sentry-javascript/issues/3440

        Também ignora erro relacionado ao recaptcha
        https://github.com/getsentry/sentry-javascript/issues/2514
      */
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
