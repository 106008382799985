import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChipInputOption } from './types';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'cl-form-chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipInputComponent),
      multi: true,
    },
  ],
})
export class ChipInputComponent implements ControlValueAccessor {
  @Input() extension = '';
  @Input() terms: { [key: string]: string } | undefined;
  @Input() set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  onTouch: (() => void) | undefined;
  _value: ChipInputOption[] = [];
  _disabled = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: any) => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(obj: ChipInputOption[]): void {
    if (obj) {
      this._value = obj.map((opt) => {
        return <ChipInputOption>{
          ...opt,
          order: opt.selected ? opt.order : null,
        };
      });
    } else {
      this._value = [];
    }
  }

  get selectedChips(): ChipInputOption[] {
    if (this._value) {
      return this._value
        .filter((opt) => opt.selected)
        .sort((a, b) => Number(a.order) - Number(b.order));
    }

    return [];
  }

  get nextOrderValue() {
    return this.selectedChips.length + 1;
  }

  remove(removedOption: ChipInputOption): void {
    if (this._disabled) {
      return;
    }

    this._value = this._value.map((opt) => {
      if (opt === removedOption) {
        return <ChipInputOption>{ ...opt, selected: false, order: null };
      }

      if (Number(opt.order) > Number(removedOption.order)) {
        return { ...opt, order: opt.selected ? Number(opt.order) - 1 : null };
      }

      return opt;
    });

    this.onChange(this._value);
  }

  add(option: ChipInputOption): void {
    if (this._disabled) {
      return;
    }

    this._value = this._value.map((value) => {
      if (value === option) {
        return { ...value, selected: true, order: this.nextOrderValue };
      }

      return value;
    });

    this.onChange(this._value);
  }

  /**
   * @goat-sacrifice
   * Drag and drop horizontal multinha não é suportado normalmente.
   * Usa múltiplos DropLists pra emular o comportamento.
   */
  drop(event: CdkDragDrop<ChipInputOption>) {
    if (this._disabled) {
      return;
    }

    const newIndex = event.container.data.order;
    const previousIndex = event.previousContainer.data.order;

    const tempValue = this._value.map((opt) => {
      if (opt.order === previousIndex) {
        return { ...opt, order: null };
      } else if (Number(opt.order) > Number(previousIndex)) {
        return { ...opt, order: Number(opt.order) - 1 };
      }
      return opt;
    });

    this._value = tempValue.map((opt) => {
      if (Number(opt.order) >= Number(newIndex)) {
        return { ...opt, order: Number(opt.order) + 1 };
      }

      if (opt.selected && opt.order === null) {
        return { ...opt, order: newIndex };
      }

      return opt;
    });

    this.onChange(this._value);
  }

  preventDropPredicate() {
    return false;
  }

  dropPredicate() {
    return true;
  }
}
