import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import {
  MatCalendar,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cl-ui-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  control: FormControl;
  private isDisabled = false;

  @Input()
  set disabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    // if (!this.control) {
    //   return;
    // }

    this.control[this.disabled ? 'disable' : 'enable']();
  }

  get disabled() {
    return this.isDisabled;
  }

  get momentMinDate(): (Moment & string) | null {
    return (this.minDate as Moment & string) ?? null;
  }

  get momentMaxDate(): Moment | null {
    return (this.maxDate as Moment & string) ?? null;
  }

  @Input() label = '';
  @Input() locale = 'pt';
  @Input() minDate: string | undefined;
  @Input() maxDate: string | undefined;
  @Input() pattern = '';
  @Input() selectedDirection: 'right' | 'left' | null = null;

  private value$ = new BehaviorSubject<string | null>(null);

  @Input()
  set value(value: string | null) {
    if (value === this.value$.getValue()) {
      return;
    }
    this.value$.next(value);
  }

  get value(): string | null {
    return this.value$.getValue();
  }

  get momentValue(): (Moment & string) | null {
    const value = this.value$.getValue();
    return (value as Moment & string) ?? null;
  }

  get invalid(): boolean {
    return this.control.invalid;
  }

  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('calendar', { static: true }) calendar:
    | MatCalendar<moment.Moment>
    | undefined;

  constructor(
    private dateAdapter: DateAdapter<MomentDateAdapter>,
    private cdr: ChangeDetectorRef
  ) {
    this.control = new FormControl({
      value: this.value,
      disabled: this.disabled,
    });
  }

  ngOnInit() {
    this.dateAdapter.setLocale(this.locale);
    this.value$.subscribe((value) => {
      this.control.patchValue(value, { emitEvent: false });
      this.cdr.detectChanges();
      if (value && this.calendar) {
        this.calendar._goToDateInView(moment(value), 'month');
      }
    });
  }

  changeByInput(event: MatDatepickerInputEvent<string, string | null>) {
    const { value } = event;
    if (value !== null) {
      this.valueChange.emit(moment(value).toISOString());
    }
  }

  handleSelectedChange(value: Moment | null) {
    const isoValue = value ? value.toISOString() : null;
    this.value = isoValue;
    this.valueChange.emit(isoValue ?? '');
  }

  todayClassFn(date: Moment) {
    return { 'calendar__day--today': date.isSame(moment(), 'day') };
  }
}
