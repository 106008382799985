<mat-tab-group
  (selectedIndexChange)="activeIndexChanged($event)"
  [selectedIndex]="selectedIndex"
>
  <mat-tab *ngFor="let tab of tabs; let i = index" [disabled]="tab.disabled">
    <ng-template mat-tab-label>
      <span [class.qa-active-tab]="i === selectedIndex">{{ tab.name }}</span>
    </ng-template>
  </mat-tab>
</mat-tab-group>
