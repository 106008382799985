import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@checklistfacil/shared/util/environment';
import { Store } from '@ngrx/store';
import { first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MainMenuService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private configService: ConfigAssetLoaderService
  ) {}

  saveMenuToggledPreference(menuToggled: boolean) {
    const payload = {
      nome: 'MENU_LATERAL',
      valor: {
        open: menuToggled,
      },
    };

    return this.configService.loadConfigurations().pipe(
      first(),
      switchMap((environment) => {
        return this.http.post(
          `${environment.apiUrl}/users/preference`,
          payload
        );
      })
    );
  }
}
