import { Component, Input } from '@angular/core';
import {
  JivoChatService,
  JivoUserInfo,
  TrackingService,
} from '@checklistfacil/shared/util/tracking';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { MenuHelpInfo } from './types';

@Component({
  selector: 'cl-menu-help',
  templateUrl: './menu-help.component.html',
  styleUrls: ['./menu-help.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiMenuHelp'
    ),
  ],
})
export class MenuHelpComponent {
  @Input() hasContactInformation = false;
  @Input() menuHelpInfo: MenuHelpInfo | undefined;
  @Input() jivoChatUrl = '';
  @Input() jivoUserInfo: JivoUserInfo | undefined;

  constructor(
    private trackingService: TrackingService,
    private jivoChatService: JivoChatService
  ) {}

  handleHelpCenterClick(href: string | undefined) {
    if (href) {
      this.trackingService.trackLinkClick(href, 'central_ajuda_popover');
    }
  }

  openChat(event: MouseEvent) {
    event.preventDefault();
    this.jivoChatService.setup(this.jivoChatUrl, true, this.jivoUserInfo);
  }
}
