<mat-form-field
  [clUiMenuSurfaceTrigger]="colorPickerMenu"
  [menuSurfacePosition]="surfacePosition"
  [disabledMenu]="disabled"
  appearance="fill"
  [floatLabel]="hasFloatLabel ? 'auto' : 'never'"
>
  <mat-label *ngIf="hasFloatLabel">{{ label }} </mat-label>
  <span
    class="color-indicator"
    matPrefix
    [ngStyle]="{ backgroundColor: value }"
    *ngIf="hasColorIndicator"
  ></span>
  <input
    readonly
    matInput
    [placeholder]="!hasFloatLabel ? label : ''"
    [value]="value"
    [disabled]="disabled"
    type="text"
  />
  <button type="button" mat-icon-button matSuffix [disabled]="disabled">
    <mat-icon fontSet="material-icons-outlined">color_lens</mat-icon>
  </button>
</mat-form-field>

<cl-menu-surface class="colorpicker-menu" #colorPickerMenu>
  <div class="colorpicker-wrapper">
    <cl-ui-colorpicker
      [color]="currentColor"
      (colorChange)="handleColorpickerChange($event)"
    ></cl-ui-colorpicker>
    <button
      class="btn-apply-color"
      mat-button
      color="primary"
      (click)="applyColorChange()"
    >
      Aplicar
    </button>
  </div>
</cl-menu-surface>
