import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import * as EnvironmentActions from './environment.actions';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { Environment } from './environment.models';

@Injectable()
export class EnvironmentEffects {
  private readonly CONFIG_URL = 'assets/config/config.json';

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.init),
      fetch({
        run: (action) => {
          return this.http.get<Environment>(this.CONFIG_URL).pipe(
            shareReplay(1),
            map((environment) =>
              EnvironmentActions.loadEnvironmentSuccess({ environment })
            )
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return EnvironmentActions.loadEnvironmentFailure({ error });
        },
      })
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}
