import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { concat, Observable, of } from 'rxjs';
import {
  appHasError,
  loadingHide,
  loadingShow,
  resetErrorFlag,
} from './+state/actions';
import { LoadingState } from './+state/models';
import { appIsLoading } from './+state/selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loading$: Observable<boolean>;

  constructor(private store: Store<LoadingState>) {
    this.loading$ = concat(of(true), this.store.pipe(select(appIsLoading)));
  }

  showLoading() {
    this.store.dispatch(loadingShow());
  }

  hideLoading() {
    this.store.dispatch(loadingHide());
  }

  appHasError() {
    this.store.dispatch(appHasError());
  }

  resetErrorFlag() {
    this.store.dispatch(resetErrorFlag());
  }
}
