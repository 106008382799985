import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';
import { MatCheckboxHarness } from '@angular/material/checkbox/testing';

interface CheckboxHarnessFilters extends BaseHarnessFilters {
  labelText?: string;
  styleClass?: string;
}

export class CheckboxHarness extends ComponentHarness {
  static hostSelector = 'cl-form-checkbox';

  getMatCheckbox = this.locatorFor(MatCheckboxHarness);

  static with(
    options: CheckboxHarnessFilters
  ): HarnessPredicate<CheckboxHarness> {
    return new HarnessPredicate<CheckboxHarness>(CheckboxHarness, options)
      .addOption('label text', options.labelText, (harness, text) =>
        HarnessPredicate.stringMatches(harness.labelText(), text)
      )
      .addOption(
        'containing css class',
        options.styleClass,
        (harness, styleClass) => harness.hasClass(styleClass)
      );
  }

  async hasClass(cssClass: string): Promise<boolean> {
    const host = await this.host();
    return host.hasClass(cssClass);
  }

  async check(): Promise<void> {
    const checkbox = await this.getMatCheckbox();
    return checkbox.check();
  }
  async checked(): Promise<boolean> {
    const checkbox = await this.getMatCheckbox();
    return checkbox.isChecked();
  }
  async uncheck(): Promise<void> {
    const checkbox = await this.getMatCheckbox();
    return checkbox.uncheck();
  }
  async unchecked(): Promise<boolean> {
    const checkbox = await this.getMatCheckbox();
    return checkbox.isChecked().then((c) => !c);
  }
  async toggle(): Promise<void> {
    const checkbox = await this.getMatCheckbox();
    return checkbox.toggle();
  }

  async disabled(): Promise<boolean> {
    const checkbox = await this.getMatCheckbox();
    return checkbox.isDisabled();
  }
  async enabled(): Promise<boolean> {
    const checkbox = await this.getMatCheckbox();
    return checkbox.isDisabled().then((d) => !d);
  }

  async labelText(): Promise<string> {
    const checkbox = await this.getMatCheckbox();
    return checkbox.getLabelText();
  }
}
