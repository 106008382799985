import { ComponentHarness } from '@angular/cdk/testing';

export class MenuButtonHarness extends ComponentHarness {
  static hostSelector = 'cl-menu-button';

  textApplyButton = this.locatorFor('.menu-button__text');

  async textApllyButtonText() {
    return (await this.textApplyButton()).text();
  }
}
