import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AchievementIcon } from '@checklistfacil/shared/user-achievements/util';
import { userNameAbbreviation } from '@checklistfacil/shared/util/strings';
import { UserCardInfo } from './types';

@Component({
  selector: 'cl-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
  achievementIcon = AchievementIcon;

  @Input() userCardInfo: UserCardInfo | null = {
    name: 'Example User',
    type: 'Example Type',
  };
  @Input() achievementTrophy: AchievementIcon | null =
    this.achievementIcon.BronzeTrophy;
  @Input() userHasAccessToAchievements = false;

  @Output() cardClicked = new EventEmitter();

  get userAvatarBorderClass() {
    return {
      'bronze-border':
        this.achievementTrophy === this.achievementIcon.BronzeTrophy &&
        this.userHasAccessToAchievements,
      'silver-border':
        this.achievementTrophy === this.achievementIcon.SilverTrophy &&
        this.userHasAccessToAchievements,
      'gold-border':
        this.achievementTrophy === this.achievementIcon.GoldTrophy &&
        this.userHasAccessToAchievements,
      'diamond-border':
        this.achievementTrophy === this.achievementIcon.Diamond &&
        this.userHasAccessToAchievements,
    };
  }

  get userInitials() {
    if (!this.userCardInfo || !this.userCardInfo.name) {
      return undefined;
    }

    return userNameAbbreviation(this.userCardInfo.name);
  }

  clicked() {
    this.cardClicked.emit();
  }
}
