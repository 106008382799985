import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Directive({
  selector: '[clSoftMaxlength]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SoftMaxlengthDirective,
      multi: true,
    },
  ],
})
export class SoftMaxlengthDirective implements Validator {
  @Input('clSoftMaxlength') maxlength = 255;

  validate(control: AbstractControl): ValidationErrors | null {
    return Validators.maxLength(this.maxlength)(control);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  registerOnValidatorChange?(fn: () => void): void {}
}
