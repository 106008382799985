<cl-form-textfield
  (click)="handleUploadClick($event)"
  [readonly]="true"
  appearance="outline"
  [value]="displayText"
  [label]="label"
  [disabled]="disabled"
  (suffixIconClick)="handleUploadClick($event)"
  suffixIcon="attach_file"
>
</cl-form-textfield>
<input
  (change)="handleFileChange()"
  #internalFile
  type="file"
  [accept]="accept"
  style="display: none"
/>
