import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { GrowthBookService } from '../growthbook.service';
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'cl-feature-show',
  template: `<ng-container *ngIf="(show$ | async) ?? default === 'show'"
    ><ng-content></ng-content
  ></ng-container>`,
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FeatureShowComponent implements OnDestroy {
  active = true;
  @Input()
  set featureKey(key: string) {
    this.featureKey$.next(key);
  }
  get featureKey(): string {
    return this.featureKey$.value;
  }
  @Input() default: 'show' | 'hide' = 'hide';

  featureKey$ = new BehaviorSubject<string>('');

  show$ = this.featureKey$.pipe(
    switchMap((key: string) => this.service.featureIsOn(key)),
    takeWhile(() => this.active)
  );

  ngOnDestroy() {
    this.active = false;
  }

  constructor(private service: GrowthBookService) {}
}
