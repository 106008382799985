import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState } from './models';
import { loadingFeatureKey } from './reducer';

export const featureSelector =
  createFeatureSelector<LoadingState>(loadingFeatureKey);

export const appIsLoading = createSelector(
  featureSelector,
  (state) => state.isLoading
);

export const appHasError = createSelector(featureSelector, (state) =>
  Boolean(state.appError && state.appError.hasError)
);

export const appSplash = createSelector(
  featureSelector,
  (state) => state.splash
);
