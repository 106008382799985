import { Action, createReducer, on } from '@ngrx/store';
import * as TranslateActions from './translate.actions';

export const TRANSLATE_FEATURE_KEY = 'translate';

export interface State {
  loaded: boolean; // has the Translate list been loaded
  error?: string | null | undefined; // last known error (if any)
}

export const initialState: State = {
  // set initial required properties
  loaded: false,
};

const translateReducer = createReducer(
  initialState,
  on(
    TranslateActions.loadTranslateSuccess,
    () =>
      <State>{
        loaded: true,
        error: undefined,
      }
  ),
  on(TranslateActions.loadTranslateFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return translateReducer(state, action);
}
