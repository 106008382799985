import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ColorEvent } from 'ngx-color/color-wrap.component';

@Component({
  selector: 'cl-ui-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
})
export class ColorpickerComponent {
  @Input() color = '#FFF000';
  @Output() colorChange = new EventEmitter<string>();

  handleColorChange(event: ColorEvent) {
    this.colorChange.emit(event.color.hex);
  }
}
