import {
  BaseHarnessFilters,
  ComponentHarness,
  HarnessPredicate,
} from '@angular/cdk/testing';
import { MatSelectHarness } from '@angular/material/select/testing';
import { MatFormFieldHarness } from '@angular/material/form-field/testing';

interface SelectHarnessFilters extends BaseHarnessFilters {
  labelText?: string;
  styleClass?: string;
}

export class SelectHarness extends ComponentHarness {
  static hostSelector = 'cl-form-select';

  getMatSelect = this.locatorFor(MatSelectHarness);

  static with(options: SelectHarnessFilters): HarnessPredicate<SelectHarness> {
    return new HarnessPredicate<SelectHarness>(SelectHarness, options)
      .addOption('label text', options.labelText, (harness, text) =>
        HarnessPredicate.stringMatches(harness.labelText(), text)
      )
      .addOption(
        'containing css class',
        options.styleClass,
        (harness, styleClass) => harness.hasClass(styleClass)
      );
  }

  async labelText(): Promise<string | null> {
    const formField = await this.locatorFor(MatFormFieldHarness);
    const field = await formField();
    return await field.getLabel();
  }

  async hasClass(className: string): Promise<boolean> {
    const host = await this.host();
    return await host.hasClass(className);
  }

  async enabled(): Promise<boolean> {
    const select = await this.getMatSelect();
    return await select.isDisabled().then((d) => !d);
  }
  async disabled(): Promise<boolean> {
    const select = await this.getMatSelect();
    return await select.isDisabled();
  }
  async selectOption(optionText: string): Promise<void> {
    const select = await this.getMatSelect();
    return await select.clickOptions({ text: optionText });
  }
  async optionIsSelected(optionText: string): Promise<boolean> {
    const select = await this.getMatSelect();
    const options = await select.getOptions({
      text: optionText,
      isSelected: true,
    });
    return options.length > 0;
  }
}
