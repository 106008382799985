import { Action, createReducer, on } from '@ngrx/store';
import * as UserAchievementsActions from './actions';
import { AchievementGroups, CompanyNumbers } from './models';

export const USER_ACHIEVEMENTS_FEATURE_KEY = 'userAchievements';

export interface State {
  achievementGroups: AchievementGroups[] | null;
  companyNumbers: CompanyNumbers;
}

export const initialCompanyNumbersState: CompanyNumbers = {
  appliedChecklists: null,
  createdChecklists: null,
  createdUnits: null,
  registeredUsers: null,
  createdActionPlans: null,
  completedActionPlans: null,
};

export const initialState: State = {
  achievementGroups: [],
  companyNumbers: initialCompanyNumbersState,
};

const userAchievementsReducer = createReducer(
  initialState,
  on(UserAchievementsActions.init, (state) => ({ ...state })),
  on(
    UserAchievementsActions.loadUserAchievementsSuccess,
    (state, { achievementGroups }) => ({
      ...state,
      achievementGroups,
    })
  ),
  on(UserAchievementsActions.loadUserAchievementsError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    UserAchievementsActions.loadCompanyNumbersSuccess,
    (state, { companyNumbers }) => ({
      ...state,
      companyNumbers,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return userAchievementsReducer(state, action);
}
