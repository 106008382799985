<header class="header" [class.header-border]="!disableBorder">
  <button
    class="header__button-nav header__go-back qa-button-go-back"
    mat-icon-button
    (click)="handleButtonNavClick()"
    [matTooltip]="'clUiHeader.navButtonTitle' | transloco"
  >
    <mat-icon>{{ icon }}</mat-icon>
  </button>
  <div class="header__content">
    <div class="header__columns">
      <div class="col">
        <h2 class="header__title qa-header-title">{{ title }}</h2>
        <ol class="breadcrumbs" *ngIf="breadcrumbs && breadcrumbs.length > 0">
          <li
            class="breadcrumbs__crumb"
            *ngFor="let breadcrumb of breadcrumbs"
            >{{ breadcrumb }}</li
          >
        </ol>
      </div>
      <div class="col" *ngIf="titleTooltip">
        <ng-container *ngIf="!usePopover">
          <mat-icon
            class="header__info"
            fontSet="material-icons-outlined"
            [matTooltip]="titleTooltip"
            >info</mat-icon
          >
        </ng-container>

        <ng-container *ngIf="usePopover">
          <div class="popover-wrapper" cl-popover [text]="titleTooltip">
            <mat-icon class="header__info" fontSet="material-icons-outlined"
              >info</mat-icon
            >
          </div>
        </ng-container>
      </div>
    </div>
    <ng-content select="[append]"></ng-content>
  </div>
  <div class="header__aside">
    <ng-container *ngIf="hasActionButton">
      <button
        mat-raised-button
        class="header-action-button"
        color="primary"
        (click)="handleActionButtonClick()"
      >
        {{ actionButtonLabel }}
      </button>
    </ng-container>
    <ng-content select="[shortcuts]"></ng-content>
    <ng-container *ngIf="moreOptions?.length">
      <button
        class="header__button-more qa-more-btn"
        mat-icon-button
        (click)="handleButtonMoreClick()"
        [matMenuTriggerFor]="headerDropdown"
        ><mat-icon>more_vert</mat-icon></button
      >
      <mat-menu class="menu-account-options qa-area-menu" #headerDropdown>
        <span
          *ngFor="let option of moreOptions"
          [matTooltip]="option.tooltipContent ?? ''"
        >
          <button
            (click)="optionClicked($event, option.id)"
            type="button"
            mat-menu-item
            [disabled]="option.disabled"
            >{{ option.label }}</button
          >
        </span>
      </mat-menu>
    </ng-container>
  </div>
</header>
<ng-content></ng-content>
