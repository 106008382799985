import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { isTrialExpired } from '@checklistfacil/company/subscription/data-access';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserCanAccessOnlySubscriptionPlansGuard
  implements CanActivate, CanActivateChild
{
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    if (state?.url?.startsWith('/company/subscription')) {
      return of(true);
    }
    return this.store.pipe(
      select(isTrialExpired),
      first(),
      map((expired) => {
        if (expired) {
          return this.router.parseUrl('/company/subscription/plans');
        }
        return true;
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
