import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, TRANSLATE_FEATURE_KEY } from './translate.reducer';

// Lookup the 'Translate' feature state managed by NgRx
export const getTranslateState = createFeatureSelector<State>(
  TRANSLATE_FEATURE_KEY
);

export const getTranslateLoaded = createSelector(
  getTranslateState,
  (state: State) => state.loaded
);

export const getTranslateError = createSelector(
  getTranslateState,
  (state: State) => state.error
);
