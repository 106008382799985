import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-ui-localization-block',
  templateUrl: './localization-block.component.html',
  styleUrls: ['./localization-block.component.scss'],
})
export class LocalizationBlockComponent {
  @Input() title: string | undefined;
  @Input() coordinates: string | undefined;
  @Input() coordinatesLink: string | undefined;
  @Input() address: string | undefined;
  @Input() emptyCoordinatesMessage: string | undefined;

  get coordinatesAreEmpty() {
    return (
      !this.coordinates ||
      this.coordinates.trim() === '' ||
      this.coordinates === null ||
      this.coordinates === undefined
    );
  }
}
