import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BasicEntity } from '@checklistfacil/shared/util/general';
import { normalizer } from '@checklistfacil/shared/util/strings';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';
import { DialogSelectData, DialogSelectOptionItem } from './types';

@Component({
  selector: 'cl-ui-dialog-select',
  templateUrl: './dialog-select.component.html',
  styleUrls: ['./dialog-select.component.scss'],
  providers: [
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clUiDialogSelect'
    ),
  ],
})
export class DialogSelectComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogSelectData,
    private dialogRef: MatDialogRef<DialogSelectComponent>
  ) {}

  singleSelection = this.data.singleSelection;
  icon = this.data.icon;
  title = this.data.title;
  instructionText = this.data.instructionText;
  emptyItemsText = this.data.emptyItemsText;
  originalItems: DialogSelectOptionItem[] = this.data.items;
  items$ = new BehaviorSubject<DialogSelectOptionItem[]>(this.data.items);
  currentSearchTerm$ = new BehaviorSubject<string>('');

  debouncedSearchObs$ = new Subject<string>();

  @ViewChild('searchInput', { static: true }) searchInput:
    | ElementRef
    | undefined;

  itemsList$: Observable<DialogSelectOptionItem[]> = this.items$.pipe(
    switchMap((items) => {
      return this.currentSearchTerm$.pipe(
        map((search) => {
          return {
            search,
            items,
          };
        })
      );
    }),
    map(({ search, items }) => {
      if (search === '') {
        return items;
      }
      return items.filter((item) => {
        return normalizer(item.name).indexOf(normalizer(search)) > -1;
      });
    })
  );
  hasItems$ = this.itemsList$.pipe(map((items) => items.length > 0));

  ngOnInit() {
    this.debouncedSearchObs$.pipe(debounceTime(450)).subscribe((term) => {
      this.currentSearchTerm$.next(term);
    });
  }

  handleCancel() {
    this.dialogRef.close(null);
  }

  handleConfirm() {
    this.items$.pipe(first()).subscribe((currentItems) => {
      this.dialogRef.close(
        currentItems.filter(({ checked }) => checked).map(({ id }) => id)
      );
    });
  }

  handleCheckAllItems() {
    const baseItems = this.items$.getValue();
    this.items$.next(
      baseItems.map((item) => {
        return {
          ...item,
          indeterminate: false,
          checked: true,
        };
      })
    );
  }

  handleUncheckAllItems() {
    const baseItems = this.items$.getValue();
    this.items$.next(
      baseItems.map((item) => {
        return {
          ...item,
          indeterminate: false,
          checked: false,
        };
      })
    );
  }

  handleItemChange(itemChanged: DialogSelectOptionItem) {
    const baseItems = this.items$.getValue();
    this.items$.next(
      baseItems.map((item) => {
        if (item.id === itemChanged.id) {
          return {
            ...item,
            indeterminate: false,
            checked: !item.checked,
          };
        }
        return item;
      })
    );
  }

  handleSearchTermChange(event: Event) {
    const term = (event.target as HTMLInputElement).value;
    this.debouncedSearchObs$.next(term);
  }

  trackByFn(idx: number, item: BasicEntity) {
    return item.id;
  }
}
