/**
 * Interface for the 'Environment' data
 */
export interface Environment {
  accountsApiUrl: string | null;
  apiUrl: string;
  appUrl: string;
  atualUrl: string | null;
  enableSnowPlow: boolean | null;
  environment: string | null;
  pusher: {
    key: string;
    cluster: string;
    wsHost: string;
    wsPort: number;
  } | null;
  recaptchaSiteKey: string | null;
  spaApiUrl: string;
  siteUrl: string;
}
