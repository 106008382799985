export interface ToolbarState {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strike?: boolean;
  background: string;
  color: string;
  align?: string;
  size?: string;
}

export const fontSizes = [
  { value: '8px', name: '8' },
  { value: '10px', name: '10' },
  { value: '12px', name: '12' },
  { value: '16px', name: '16' },
  { value: '20px', name: '20' },
  { value: '24px', name: '24' },
  { value: '28px', name: '28' },
  { value: '32px', name: '32' },
  { value: '36px', name: '36' },
  { value: '48px', name: '48' },
  { value: '56px', name: '56' },
];
