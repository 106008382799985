import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-ui-loading-slider',
  templateUrl: './loading-slider.component.html',
  styleUrls: ['./loading-slider.component.scss'],
})
export class LoadingSliderComponent {
  @Input() show = true;
}
