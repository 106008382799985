import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogContentComponent } from './confirmation-dialog-content.component';
import { ConfirmationDialogData } from './types';

@Component({
  selector: 'cl-ui-confirmation-dialog',
  template: '',
  styles: [''],
})
export class ConfirmationDialogComponent {
  constructor(public matDialog: MatDialog) {}

  open(data: ConfirmationDialogData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = data.width || '400px';
    dialogConfig.panelClass = 'cl-custom-border-radius';
    dialogConfig.data = data;

    return this.matDialog.open(
      ConfirmationDialogContentComponent,
      dialogConfig
    );
  }
}
