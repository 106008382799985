import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'cl-ui-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent {
  @Input() disabled: boolean | null = false;
  @Input() hasFullWidth = false;
  @HostBinding('class.qa-primary-btn') qaClass = true;
  @Output() clicked = new EventEmitter<any>();

  handleClick(event: MouseEvent) {
    this.clicked.emit(event);
  }
}
