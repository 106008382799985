import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigAssetLoaderService,
  Configuration,
} from '@checklistfacil/shared/util/environment';
import {
  headerHasSkipAuthentication,
  removeSkipAuthenticationHeader,
} from '@checklistfacil/shared/util/general';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptorService implements HttpInterceptor {
  private appConfig: Configuration | undefined;

  constructor(
    private cookieService: CookieService,
    configService: ConfigAssetLoaderService
  ) {
    configService
      .loadConfigurations()
      .subscribe((config) => (this.appConfig = config));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const cookie = this.cookieService.get('CHECKLIST_DATA');
    // const { accessToken } = cookie
    //   ? JSON.parse(cookie)
    //   : { accessToken: undefined };
    const update = {
      // setHeaders: {
      //   Authorization: `Bearer ${accessToken}`,
      // }
      withCredentials: true,
    };

    const hasSkipAuthOption = headerHasSkipAuthentication(req.headers);

    // Não adiciona header Authorization antes de carregar o appConfig
    let reqClone =
      this.appConfig || !hasSkipAuthOption ? req.clone(update) : req;

    if (hasSkipAuthOption) {
      reqClone = req.clone({
        headers: removeSkipAuthenticationHeader(req.headers),
      });
    }

    return next.handle(reqClone).pipe(
      map((event) => event),
      catchError((err) => {
        const { status } = err;
        switch (status) {
          case 401: {
            if (err.url.indexOf('v2/signin') < 0) {
              if (!this.appConfig) {
                throw new Error('appConfig not loaded');
              }

              window.location.href = endpoints.loginUrl(this.appConfig);
            }
            // this.router.navigateByUrl(endpoints.loginUrl);
            break;
          }
          case 400: {
            console.error(err);
          }
        }
        return throwError(err);
      })
    );
  }
}
