import { LoadingState } from './models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  appFinishedLoading,
  appHasError,
  loadingHide,
  loadingShow,
  resetErrorFlag,
} from './actions';

export const loadingFeatureKey = 'app-loading-status';

export const initialState: LoadingState = {
  appError: null,
  isLoading: true,
  splash: true,
};

const reducer = createReducer(
  initialState,
  on(loadingShow, (state: LoadingState) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(loadingHide, (state: LoadingState) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(appHasError, (state: LoadingState) => {
    return {
      ...state,
      isLoading: false,
      appError: { hasError: true } as { hasError: boolean } | null,
    };
  }),
  on(resetErrorFlag, (state: LoadingState) => {
    return {
      ...state,
      appError: { hasError: false } as { hasError: boolean } | null,
    };
  }),
  on(appFinishedLoading, (state: LoadingState) => {
    return {
      ...state,
      splash: false,
    };
  })
);

export function loadingReducer(state: LoadingState, action: Action) {
  return reducer(state, action);
}
