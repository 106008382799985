import { createAction } from '@ngrx/store';

export const loadingShow = createAction('[App Loading Status] loading show');

export const loadingHide = createAction('[App Loading Status] loading hide');

export const appHasError = createAction('[App Loading Status] has error');

export const resetErrorFlag = createAction(
  '[App Loading Status] reset error flag'
);

export const appFinishedLoading = createAction(
  '[App Loading Status] App finished loading'
);
