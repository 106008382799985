export enum LocaleByLanguageCode {
  pt_BR = 1,
  pt_PT = 2,
  en_US = 3,
  es_ES = 4,
}

export class MockStorage implements Storage {
  private storage: { [x: string]: string } = {};
  [name: string]: unknown;

  readonly length: number = 0;

  clear(): void {
    this.storage = {};
  }

  getItem(key: string): string | null {
    if (this.storage[key]) {
      return this.storage[key];
    }
    return null;
  }

  key(): string | null {
    return null;
  }

  removeItem(): void {}

  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }
}
