import {
  canExtendTrial,
  companyBillingEmailNotConfirmed,
  companyPlanType,
  isTrial,
  PlanType,
  remainingTrialDays,
} from '@checklistfacil/shared/data-access/company-configs';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyRegisterPayload,
  Country,
  ExternalResource,
  State,
} from './models';
import { COMPANY_SUBSCRIPTION_FEATURE_KEY } from './reducers';

const featureSelector = createFeatureSelector<State>(
  COMPANY_SUBSCRIPTION_FEATURE_KEY
);

export const cities = createSelector(featureSelector, (state) => state.cities);

export const countries = createSelector(
  featureSelector,
  (state) => state.countries
);

export const countryStates = createSelector(
  featureSelector,
  (state) => state.countryStates
);

export const segments = createSelector(
  featureSelector,
  (state) => state.segments
);

export const subsegments = createSelector(
  featureSelector,
  (state) => state.subsegments
);

export const businessAreas = createSelector(
  featureSelector,
  (state) => state.businessAreas
);

export const companyCategorization = createSelector(
  featureSelector,
  (state) => state.companyCategorization
);

export const companyInformation = createSelector(
  featureSelector,
  (state) => state.companyInformation
);

export const companyCurrency = createSelector(
  featureSelector,
  (state) => state.companyCurrency
);

export const mexicanCfdi = createSelector(
  featureSelector,
  (state) => state.mexicanCfdis
);

export const mexicanPaymentForm = createSelector(
  featureSelector,
  (state) => state.mexicanPaymentForms
);

export const mexicanPaymentMethod = createSelector(
  featureSelector,
  (state) => state.mexicanPaymentMethods
);

export const mexicanTaxRegime = createSelector(
  featureSelector,
  (state) => state.mexicanTaxRegimes
);

export const companyRegisterData = createSelector(featureSelector, (state) => {
  return {
    identification: state.companyInformation,
    categorization: state.companyCategorization,
    currency: state.companyCurrency,
  };
});

export const companyRegisterBillingEmail = createSelector(
  featureSelector,
  (state) => {
    return state.companyInformation
      ? state.companyInformation.billingEmail
      : '';
  }
);

export const showLinkResendBillingEmail = createSelector(
  companyBillingEmailNotConfirmed,
  companyRegisterBillingEmail,
  (billingEmailNotConfirmed, fieldBillingEmail) => {
    return billingEmailNotConfirmed && fieldBillingEmail !== null;
  }
);

export const isCountryBrazil = createSelector(
  companyInformation,
  (state) => JSON.stringify(state.country) === JSON.stringify([Country.BRAZIL])
);

export const isCountryMexico = createSelector(
  companyInformation,
  (state) => JSON.stringify(state.country) === JSON.stringify([Country.MEXICO])
);

export const isOtherCountries = createSelector(
  isCountryBrazil,
  isCountryMexico,
  (countryBrazil, countryMexico) => !countryBrazil && !countryMexico
);

export const countryIdSelected = createSelector(
  companyInformation,
  (state) => state.country
);

export const companyRegisterPayload = createSelector(
  companyRegisterData,
  ({ categorization, currency, identification }) => {
    return {
      ...categorization,
      ...identification,
      ...currency,
      country: identification.country[0],
      countryState: identification.countryState[0],
      city: identification.city[0],
      segment: categorization.segment[0],
      subsegment: categorization.subsegment[0],
      companyBusinessArea: categorization.companyBusinessArea,
      mexicanPaymentForm: identification.mexicanPaymentForm[0],
      mexicanCfdi: identification.mexicanCfdi[0],
      mexicanPaymentMethod: identification.mexicanPaymentMethod[0],
      mexicanTaxRegime: identification.mexicanTaxRegime[0],
      billingCurrency: currency.billingCurrency[0],
      paymentForm: currency.paymentForm[0],
    } as CompanyRegisterPayload;
  }
);

export const canEditCompanyRegister = createSelector(
  companyPlanType,
  (planType) => planType === PlanType.TRIAL
);

export const countriesAreLoading = createSelector(featureSelector, (state) => {
  return state.resourceLoading.indexOf(ExternalResource.COUNTRIES) > -1;
});

export const countryStatesAreLoading = createSelector(
  featureSelector,
  (state) => state.resourceLoading.indexOf(ExternalResource.COUNTRY_STATES) > -1
);

export const citiesAreLoading = createSelector(
  featureSelector,
  (state) => state.resourceLoading.indexOf(ExternalResource.CITIES) > -1
);

export const segmentsAreLoading = createSelector(
  featureSelector,
  (state) => state.resourceLoading.indexOf(ExternalResource.SEGMENTS) > -1
);

export const subsegmentsAreLoading = createSelector(
  featureSelector,
  (state) => state.resourceLoading.indexOf(ExternalResource.SUBSEGMENTS) > -1
);

export const businessAreasAreLoading = createSelector(
  featureSelector,
  (state) => state.resourceLoading.indexOf(ExternalResource.BUSINESS_AREAS) > -1
);

export const companyHasMissingFields = createSelector(
  featureSelector,
  ({ companyCategorization, companyInformation, companyCurrency }) => {
    return (
      companyInformation.address === '' ||
      companyInformation.companyName === '' ||
      companyInformation.cnpj === '' ||
      companyInformation.phone === '' ||
      companyInformation.billingEmail === '' ||
      companyInformation.postalCode === '' ||
      companyInformation.country.length === 0 ||
      companyInformation.countryState.length === 0 ||
      companyInformation.city.length === 0 ||
      companyInformation.district === '' ||
      companyCategorization.companyHasFranchise === null ||
      companyCategorization.segment.length === 0 ||
      companyCategorization.subsegment.length === 0 ||
      companyCategorization.companyBusinessArea.length === 0 ||
      companyCurrency.billingCurrency === null ||
      companyCurrency.paymentForm === null
    );
  }
);

export const checklistPlansList = createSelector(
  featureSelector,
  companyPlanType,
  ({ checklistPlans }, currentCompanyPlan) => {
    if (!checklistPlans) {
      return [];
    }

    if (currentCompanyPlan === PlanType.ENTERPRISE) {
      return checklistPlans.plans.filter(
        (item) =>
          item.type !== PlanType.TRIAL &&
          item.type !== PlanType.BASIC &&
          item.type !== PlanType.PROFESSIONAL
      );
    }

    return checklistPlans.plans.filter(
      (item) => item.type !== PlanType.TRIAL && item.type !== PlanType.BASIC
    );
  }
);

export const checklistBasicPlan = createSelector(featureSelector, (state) => {
  if (state.checklistPlans) {
    return state.checklistPlans.plans.find((item) => {
      return item.type === PlanType.BASIC;
    });
  }
  return null;
});

export const checklistPlansFeatureList = createSelector(
  featureSelector,
  (state) => {
    if (state.checklistPlans) {
      return state.checklistPlans.features;
    }
    return [];
  }
);

export const shouldShowExtendTrialDialog = createSelector(
  isTrial,
  canExtendTrial,
  remainingTrialDays,
  (trial, canExtend, remainingDays) => {
    if (!trial) {
      return false;
    }

    if (!canExtend) {
      return false;
    }

    return !(remainingDays && remainingDays > 0);
  }
);

export const shouldWarnAboutTrialFinished = createSelector(
  isTrial,
  canExtendTrial,
  remainingTrialDays,
  (trial, canExtend, remainingDays) => {
    if (!trial) {
      return false;
    }

    if (canExtend) {
      return false;
    }

    if (remainingDays !== 0) {
      return false;
    }

    return true;
  }
);

export const isTrialExpired = createSelector(
  isTrial,
  remainingTrialDays,
  (trial, remainingDays) => {
    if (!trial) {
      return false;
    }
    return remainingDays === null ? false : remainingDays <= 0;
  }
);
