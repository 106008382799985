<input
  [value]="value"
  [name]="name"
  [disabled]="disabled"
  [readonly]="readonly"
  [attr.maxlength]="maxlength"
  [type]="type"
  [attr.max]="max !== undefined ? max : null"
  [attr.min]="min !== undefined ? min : null"
  type="text"
  (change)="onChange($event)"
  (blur)="onTouched($event)"
  [class.error]="hasError"
  class="text-field-mini"
/>
