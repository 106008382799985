import { Component, Input } from '@angular/core';
import { AddonData } from './types';

@Component({
  selector: 'cl-ui-addon-icons',
  templateUrl: './addon-icons.component.html',
  styleUrls: ['./addon-icons.component.scss'],
})
export class AddonIconsComponent {
  @Input() boxHeight = '40px';
  @Input() boxWidth = '96px';
  @Input() iconHeight = '16px';
  @Input() iconWidth = '16px';
  @Input() data: Partial<AddonData> = {};
}
