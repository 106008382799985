import { Component, HostBinding, OnInit } from '@angular/core';
import {
  appLangFromBrowserLang,
  StorageService,
  translocoLoader,
} from '@checklistfacil/shared/util/translate';

@Component({
  selector: 'cl-user-terms-notice',
  templateUrl: './user-terms-notice.component.html',
  styleUrls: ['./user-terms-notice.component.scss'],
  providers: [
    translocoLoader(
      (lang: string, root: string) => import(`./${root}/${lang}.json`),
      'userTermsNotice'
    ),
    StorageService,
  ],
})
export class UserTermsNoticeComponent implements OnInit {
  constructor(private storage: StorageService) {}

  @HostBinding('class.user-terms-notice--show')
  show = false;

  get currentUserLanguage(): string | undefined {
    return appLangFromBrowserLang() ?? undefined;
  }

  ngOnInit(): void {
    this.checkIfUserAlreadyDismissed();
  }

  checkIfUserAlreadyDismissed() {
    if (!this.storage.has('user-terms-notice')) {
      this.show = true;
    }
  }

  handleUserDismissed() {
    this.storage.set('user-terms-notice', 'dismissed');
    this.show = false;
  }
}
