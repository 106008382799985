export interface SelectItem {
  name: string;
  value: any;
  active?: boolean;
}

export interface SelectGroup {
  name: string;
  items: SelectItem[];
}
