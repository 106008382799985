import { Injectable } from '@angular/core';
import { loadCountriesError } from '@checklistfacil/company/subscription/data-access';
import { LoadingService } from '@checklistfacil/shared/data-access/loading';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { CompanyRegistrationService } from '../company-registration.service';
import {
  loadCities,
  loadCitiesError,
  loadCitiesSuccess,
  loadCountries,
  loadCountriesSuccess,
  loadCountryCode,
  loadCountryCodeError,
  loadCountryCodeSuccess,
  loadSegments,
  loadSegmentsError,
  loadSegmentsSuccess,
  loadStates,
  loadStatesError,
  loadStatesSuccess,
  loadWorkers,
  loadWorkersError,
  loadWorkersSuccess,
  registerCompany,
  registerCompanyError,
  registerCompanySuccess,
} from './actions';
import { countryAndState, countryId, registrationData } from './selectors';

@Injectable()
export class CompanyRegistrationEffects {
  constructor(
    private actions$: Actions,
    private service: CompanyRegistrationService,
    private store: Store,
    private loadingService: LoadingService
  ) {}

  loadCountryCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCountryCode),
      mergeMap(() => {
        return this.service.listCountryCodes().pipe(
          tap(() => this.loadingService.hideLoading()),
          map((countryCodes) => loadCountryCodeSuccess({ countryCodes })),
          catchError((error) => of(loadCountryCodeError({ error })))
        );
      })
    )
  );

  loadSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSegments),
      mergeMap(() => {
        return this.service.listSegments().pipe(
          map((segments) => loadSegmentsSuccess({ segments })),
          catchError((error) => of(loadSegmentsError({ error })))
        );
      })
    )
  );

  loadWorkers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWorkers),
      mergeMap(() => {
        return this.service.listEmployeeRanges().pipe(
          tap(() => this.loadingService.showLoading()),
          map((workers) => loadWorkersSuccess({ workers })),
          catchError((error) => of(loadWorkersError({ error })))
        );
      })
    )
  );

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCountries),
      mergeMap(() => {
        return this.service.listCountries().pipe(
          tap(() => this.loadingService.hideLoading()),
          map((countries) => loadCountriesSuccess({ countries })),
          catchError((error) => of(loadCountriesError({ error })))
        );
      })
    )
  );

  loadStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStates),
      withLatestFrom(this.store.select(countryId)),
      mergeMap(([, countryId]) => {
        if (!countryId) {
          return of(
            loadStatesError({
              error: {
                message: 'countryId is undefined',
              },
            })
          );
        }

        return this.service.listStates(countryId).pipe(
          tap(() => this.loadingService.hideLoading()),
          map((states) => loadStatesSuccess({ states })),
          catchError((error) => of(loadStatesError({ error })))
        );
      })
    )
  );

  loadCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCities),
      withLatestFrom(this.store.select(countryAndState)),
      mergeMap(([, countryAndState]) => {
        if (!countryAndState.countryId) {
          return of(
            loadCitiesError({
              error: {
                message: 'countryAndState.countryId is undefined',
              },
            })
          );
        }

        if (!countryAndState.stateId) {
          return of(
            loadCitiesError({
              error: {
                message: 'countryAndState.stateId is undefined',
              },
            })
          );
        }

        return this.service
          .listCities(countryAndState.countryId, countryAndState.stateId)
          .pipe(
            tap(() => this.loadingService.hideLoading()),
            map((payload) => loadCitiesSuccess({ cities: payload.items })),
            catchError((error) => of(loadCitiesError({ error })))
          );
      })
    )
  );

  registrationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerCompany),
      withLatestFrom(this.store.select(registrationData)),
      mergeMap(([, registerCompany]) => {
        if (!registerCompany) {
          return of(
            loadCitiesError({
              error: {
                message: 'registerCompany is undefined',
              },
            })
          );
        }

        return this.service.registerCompany(registerCompany).pipe(
          tap(() => this.loadingService.hideLoading()),
          map(() => {
            return registerCompanySuccess({
              registerCompany,
            });
          }),
          catchError((error) => of(registerCompanyError({ error })))
        );
      })
    )
  );
}
