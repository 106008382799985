import { Injectable } from '@angular/core';
import { SnowplowService } from '../snowplow/snowplow.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private snowplowService: SnowplowService) {}

  trackLinkClick(targetUrl: string, elementId: string) {
    this.snowplowService.trackLinkClick({ targetUrl, elementId });
  }
}
