import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { loadingFeatureKey, loadingReducer } from './+state/reducer';
import { LoadingService } from './loading.service';

@NgModule({
  providers: [LoadingService],
  imports: [StoreModule.forFeature(loadingFeatureKey, loadingReducer)],
})
export class SharedDataAccessLoadingModule {}
