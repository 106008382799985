export enum FileType {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  File = 'file',
  NoMedia = 'noMedia',
}

export function filetypeFromExtension(extension: string): FileType {
  if (
    [
      'jpg',
      'jpeg',
      'jpe',
      'jif',
      'jfif',
      'jfi',
      'png',
      'apng',
      'webp',
      'gif',
      'bmp',
      'dib',
      'svg',
      'tiff',
      'tif',
      'ico',
    ].includes(extension)
  ) {
    return FileType.Image;
  }

  if (['mp4', 'mov', 'webm', 'ogv'].includes(extension)) {
    return FileType.Video;
  }

  if (
    [
      'mp3',
      'm4a',
      'm4b',
      'm4r',
      'aac',
      'l16',
      'wav',
      'aiff',
      'au',
      'pcm',
      'opus',
      'flac',
      'ogg',
    ].includes(extension)
  ) {
    return FileType.Audio;
  }

  return FileType.File;
}
