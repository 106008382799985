import { Scale } from '@checklistfacil/shared/util/item-scale';
import { createSelector, createSelectorFactory, defaultMemoize } from '@ngrx/store';
import { featureSelector } from '../selectors';
import {
  companyFeatures,
  enableAnalyticsBI,
  enableDepartamentos,
  enableIntegracaoSienge,
  enableItemEscalaCodigoDeBarras,
  enableItemEscalaGPS,
  enableItemEscalaPersonalizada,
  enableItemEscalaTemperatura,
  enableOperacaoMatematica,
  enableRelatorioArquivos,
  enableTipoUnidade,
  enableWorkflowBloqueioPA,
  enableWorkflows
} from './features';
import * as SiengeSelectors from './sienge';
import { purchaseOrdersEnabled, supplyContractsEnabled } from './sienge';

// @todo casos de testes para os seletores do companyConfigs
export const companyConfigs = createSelector(
  featureSelector,
  (state) => state.companyConfigs
);

export const getMainColor = createSelector(featureSelector, (state) => {
  const companyConfig = state.companyConfigs;
  const mainColor = companyConfig
    ? companyConfig.find((c) => c.config === 'cor_principal')
    : null;
  return mainColor ? mainColor.value : null;
});

export const getCompanyTopLogo = createSelector(featureSelector, (state) => {
  const config = state.companyConfigs.find(
    (c) => c.config === 'logo_checklist_topo'
  );
  return config && config.value
    ? config.value
    : 'https://app.checklistfacil.com.br/img/checklistfacil.png';
});

export const companyHasSomaPontosScore = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'calculo_nota_por_soma_pontos'
    );
    return found && found.value;
  }
);

export const companyHasTemperatureScale = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'escala_temperatura'
    );
    return found && found.value;
  }
);

export const companyHasRotasReembolso = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'rotas'
    );
    return found && found.value;
  }
);

export const companyHasCategoriaProduto = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'categoria_produto'
    );
    return found && found.value;
  }
);

export const companyHasEscalaIndice = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'possui_escala_indice'
    );
    return found && found.value;
  }
);

export const companyHasPesquisaSatisfacao = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'pesquisa_satisfacao'
    );
    return found && found.value;
  }
);

export const getCompanyPesquisaSatisfacaoMessage = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'mensagem_pesquisa_satisfacao'
    );
    return found && found.value ? found.value : null;
  }
);

export const companyHasAplicarLaravel = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'aplicar_laravel'
    );
    return found && found.value;
  }
);

export const companyHasDepartments = createSelector(
  featureSelector,
  enableDepartamentos,
  (state, hasDepartamentosFeature) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'departamento'
    );
    return found && found.value && hasDepartamentosFeature;
  }
);

export const companyHasCercaDigital = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'cerca_digital'
    );
    return found && found.value;
  }
);

export const companyHasReconhecimentoPadraoImagem = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'reconhecimento_padrao_imagem'
    );
    return found && found.value;
  }
);

export const enablePesquisaSatisfacao = createSelector(
  companyConfigs,
  companyFeatures,
  (configs, features) => {
    return (
      features.checklist_pesquisa_satisfacao === true &&
      configs.some(
        (c) => c.config === 'pesquisa_satisfacao' && c.value === true
      )
    );
  }
);

export const companyHasUnitType = createSelector(
  featureSelector,
  enableTipoUnidade,
  (state, hasTipoUnidadeFeature) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'tipo_unidade'
    );
    return found && found.value && hasTipoUnidadeFeature;
  }
);

export const companyHasCustomActionPlans = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'personalizacao_pa'
    );
    return found && found.value;
  }
);

export const companyHasAreaImageType = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'checklist_imagem'
    );
    return found && found.value;
  }
);

export const companyUsesAlert = createSelector(featureSelector, (state) => {
  const found = state.companyConfigs.find(
    (config) => config.config === 'opcao_alerta'
  );
  return found && found.value;
});

export const companyHasEscalaTemperaturaIntegracao = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'escala_temperatura_integracao'
    );
    return found && found.value;
  }
);

export const companyHasIndiceExecucao = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'relatorio_indice_execucao'
    );
    return found && found.value;
  }
);

export const companyHasEtiquetaSequencial = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'etiqueta_sequencial'
    );
    return found && found.value;
  }
);

export const companyHasSiengeIntegration = createSelector(
  featureSelector,
  enableIntegracaoSienge,
  (state, hasSiengeIntegrationFeature) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'integracao_sienge'
    );
    return found && found.value && hasSiengeIntegrationFeature;
  }
);

export const companyUnavailableScales = createSelectorFactory(defaultMemoize)(
  companyHasTemperatureScale,
  companyHasRotasReembolso,
  companyHasCategoriaProduto,
  companyHasEscalaIndice,
  companyHasSiengeIntegration,
  SiengeSelectors.supplyContractsEnabled,
  enableItemEscalaGPS,
  enableItemEscalaCodigoDeBarras,
  enableItemEscalaTemperatura,
  enableItemEscalaPersonalizada,
  (
    temperatureScale: boolean,
    rotasReembolso: boolean,
    categoriaProduto: boolean,
    escalaIndice: boolean,
    siengeIntegration: boolean,
    siengeSupplyContractsModule: boolean,
    escalaGPS: boolean,
    codigoDeBarras: boolean,
    temperatura: boolean,
    listaDeSelecao: boolean
  ) => {
    const unavailableScales: Scale[] = [];

    if (!temperatureScale) {
      unavailableScales.push(Scale.Temperature);
    }

    if (!rotasReembolso) {
      unavailableScales.push(Scale.CompetencePeriod);
    }

    if (!categoriaProduto) {
      unavailableScales.push(Scale.Product);
    }

    if (!escalaIndice) {
      unavailableScales.push(Scale.Index);
    }
    if (!escalaGPS) {
      unavailableScales.push(Scale.GPS);
    }
    if (!codigoDeBarras) {
      unavailableScales.push(Scale.Barcode);
    }
    if (!temperatura) {
      unavailableScales.push(Scale.Temperature);
    }

    if (!listaDeSelecao) {
      unavailableScales.push(Scale.SelectionMultiple);
      unavailableScales.push(Scale.SelectionSingle);
    }

    if (!siengeIntegration || !siengeSupplyContractsModule) {
      unavailableScales.push(Scale.SiengeService);
    }

    return unavailableScales;
  }
);

export const companySurveyUnavailableScales = createSelector(
  companyUnavailableScales,
  (scales) => {
    scales.push(Scale.GPS);
    scales.push(Scale.Barcode);
    scales.push(Scale.CompetencePeriod);
    scales.push(Scale.Product);
    scales.push(Scale.Index);
    scales.push(Scale.Instruction);
    scales.push(Scale.SiengeService);

    return scales;
  }
);

export const companyHasPowerBIConfigsSelector = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'powerbi_integration'
    );
    return found && found.value;
  }
);

export const companyMaxScore = createSelector(
  featureSelector,
  companyHasSomaPontosScore,
  (state, companyHasSomaPontos) => {
    if (companyHasSomaPontos) {
      return state.company.biggestEvaluationScore ?? null;
    } else {
      return 100;
    }
  }
);

export const companyHasWorkflows = createSelector(
  featureSelector,
  enableWorkflows,
  (state, hasWorkflowsEnabledForPlan) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'workflows'
    );
    return found && found.value && hasWorkflowsEnabledForPlan;
  }
);

export const companyWorkflowStepsLimit = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'workflow_limite_etapas'
    );
    return found && found.value;
  }
);

export const companyWorkflowBloqueioPA = createSelector(
  featureSelector,
  enableWorkflowBloqueioPA,
  (state, hasWorkflowsBloqueiaPA) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'workflow_bloqueio_pa'
    );
    return found && found.value && hasWorkflowsBloqueiaPA;
  }
);

export const companyHasOperacaoMatematica = createSelector(
  featureSelector,
  enableOperacaoMatematica,
  (state, hasOperacaoMatematicaEnabled) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'operacao_matematica'
    );
    return found && found.value && hasOperacaoMatematicaEnabled;
  }
);

export const companyHasReportMedia = createSelector(
  featureSelector,
  enableRelatorioArquivos,
  (state, hasRelatorioArquivosEnabled) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'relatorio_arquivos'
    );
    return found && found.value && hasRelatorioArquivosEnabled;
  }
);

export const getJivoChatUrl = createSelector(featureSelector, (state) => {
  return state.companyConfigs.find((config) => config.config === 'jivochat')
    ?.value;
});

export const companyHasContestacao = createSelector(
  featureSelector,
  (state) => {
    return state.companyConfigs.find(
      (config) => config.config === 'possui_contestacao_item'
    )?.value;
  }
);

export const canApplySiengeServiceInspection = createSelector(
  companyHasSiengeIntegration,
  supplyContractsEnabled,
  (hasIntegration, supplyContractsEnabled) =>
    Boolean(hasIntegration && supplyContractsEnabled)
);

export const canApplySiengeOrderInspection = createSelector(
  companyHasSiengeIntegration,
  purchaseOrdersEnabled,
  (hasIntegration, purchaseOrdersEnabled) =>
    Boolean(hasIntegration && purchaseOrdersEnabled)
);

export const companyHasNewApplyButton = createSelector(
  featureSelector,
  companyHasWorkflows,
  canApplySiengeServiceInspection,
  (state, hasWorkflows, canApplySiengeServiceInspection) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'novo_botao_aplicar'
    );

    return (
      found && found.value && (hasWorkflows || canApplySiengeServiceInspection)
    );
  }
);

export const companyHasAnalyticsBI = createSelector(
  featureSelector,
  enableAnalyticsBI,
  (state, hasAnalyticsBI) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'analytics_bi'
    );
    return found && found.value && hasAnalyticsBI;
  }
);

export const hasValueInterval = createSelector(featureSelector, (state) => {
  const found = state?.companyConfigs.find(
    (config) => config.config === 'intervalo_item_numerico'
  );

  return found && found.value;
});

export const companyAnalyticsBIBilledByChecklist = createSelector(
  featureSelector,
  (state) => {
    const found = state.companyConfigs.find(
      (config) => config.config === 'analytics_bi_cobrar_por_checklist_aplicado'
    );
    return found && found.value;
  }
);
