import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiWarningsModule } from '@checklistfacil/shared/ui/warnings';
import { WarningBarComponent } from './warning-bar/warning-bar.component';

@NgModule({
  imports: [CommonModule,SharedUiWarningsModule],
  declarations: [
    WarningBarComponent
  ],
  exports: [
    WarningBarComponent
  ],
})
export class SharedFeatureWarningBarsModule {}
