import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'cl-form-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  set checked(checked: boolean) {
    this.isChecked = checked;
  }

  get checked(): boolean {
    return this.isChecked;
  }
  @Input() color: string | undefined;
  @Input() disabled = false;
  @Input() indeterminate = false;
  @Input() label: string | undefined;
  @Input() labelPosition: 'after' | 'before' = 'after';
  @Input() required = false;
  @Output() checkedChange = new EventEmitter<boolean>();
  @ViewChild('component', { static: true }) component: MatCheckbox | undefined;

  private touched = false;
  private isChecked = false;
  private value: any;

  change(event: MatCheckboxChange) {
    this.checkedChange.emit(event.checked);
    this.onChange(event.checked);
  }

  focus() {
    this.component?.focus();
  }

  toggle() {
    this.component?.toggle();
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-empty-function
  onChange = (delta: any) => {};

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onTouched() {
    this.touched = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.checked = obj;
    this.value = this.checked;
  }
}
