import { Injectable } from '@angular/core';
import {
  UploadQueueItem,
  UploadQueueItemStatus,
  UploadQueueItemTypes,
} from '@checklistfacil/shared/util/general';
import { uniqId } from '@checklistfacil/shared/util/strings';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import * as actions from './+state/actions';
import { State as UploadQueueState } from './+state/reducers';
import * as selectors from './+state/selectors';
import { FileGalleryItem } from './types';

@Injectable()
export class UploadQueueService {
  isUploading$: Observable<boolean>;

  constructor(private store: Store<UploadQueueState>) {
    this.isUploading$ = this.store.pipe(select(selectors.isUploading));
  }

  get uploadQueueIsOpen$() {
    return this.store.pipe(select(selectors.isCollapsed));
  }

  get uploadFiles$() {
    return this.store.pipe(select(selectors.items));
  }

  get showUploadQueue$() {
    return this.store.pipe(select(selectors.show));
  }

  public fileGalleryItemToUploadQueueItem(
    fileItem: FileGalleryItem,
    { endpoint, scope, scopeData }: any
  ): UploadQueueItem {
    if (!fileItem.formData) {
      throw new Error('fileItem.formData is undefined');
    }

    const id = uniqId();
    return {
      file: fileItem.formData,
      name: fileItem.name,
      endpoint,
      id,
      status: UploadQueueItemStatus.Pending,
      type: UploadQueueItemTypes.Other,
      scope,
      scopeData,
    };
  }

  public addItems(files: UploadQueueItem[]) {
    this.store.dispatch(
      actions.addQueueItem({
        items: files,
      })
    );

    setTimeout(() => {
      this.startQueue();
    }, 10);
  }

  public retryItem(item: UploadQueueItem) {
    this.store.dispatch(actions.retryQueueItem({ item }));
  }

  public cancelItem(item: UploadQueueItem) {
    this.store.dispatch(actions.cancelQueueItem({ item }));
  }

  public cancelItems() {
    this.store.dispatch(actions.cancelAllQueueItems());
  }

  public uploadsCollapsed() {
    this.store.dispatch(actions.toggleQueueOpen());
  }

  private startQueue() {
    this.isUploading$.pipe(first()).subscribe((state) => {
      if (!state) {
        this.store.dispatch(actions.startQueue());
      }
    });
  }
}
