import { Injectable } from '@angular/core';
import { getScaleTypeId } from './getScaleTypeId';
import { Scale, ScaleData, ScaleType } from './types';

@Injectable()
export class ItemScaleService {
  isScaleEvaluative(scaleId: number) {
    return getScaleTypeId(scaleId) === ScaleType.Evaluative;
  }

  isScaleSelectionList(scaleId: number) {
    return getScaleTypeId(scaleId) === ScaleType.Selection;
  }

  hasWeightConfig(scaleId: number) {
    return (
      this.isScaleEvaluative(scaleId) || this.isScaleSelectionList(scaleId)
    );
  }

  getScaleIconsData(
    scaleId: Scale | null,
    hasMedal = false,
    hasNotApply = false
  ): ScaleData {
    const result: ScaleData = {
      goodFace: false,
      averageFace: false,
      badFace: false,
      yes: false,
      sim: false,
      no: false,
      na: false,
      medal: false,
      star: false,
    };

    if (scaleId === Scale.YesNo) {
      result.sim = true;
      result.no = true;
    } else if (scaleId === Scale.RedGreen) {
      result.goodFace = true;
      result.badFace = true;
    } else if (scaleId === Scale.RedYellowGreen) {
      result.goodFace = true;
      result.averageFace = true;
      result.badFace = true;
    }

    if (hasMedal) {
      result.medal = true;
    }

    if (hasNotApply) {
      result.na = true;
    }

    return result;
  }
}
