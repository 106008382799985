export interface Registration {
  user: User;
  company: Company;
}

export interface User {
  userName: string | null;
  email: string | null;
  password: string | null;
  confirmPassword: string | null;
  countryCode?: string | null;
  phone?: PhoneData | null;
  valid: boolean | null;
}

export interface Company {
  companyName: string | null;
  segmentId: number | null;
  workers: number | null;
  countryId: number | null;
  stateId: number | null;
  cityId: number | null;
  hasAgreedUserTerms: boolean | null;
  valid: boolean | null;
}

export interface BasicEntityCountryCode {
  id: string;
  name: string;
  active?: boolean;
}

export interface CompanyRegistrationData {
  userName: string;
  email: string;
  password: string;
  companyName: string;
  segmentId: number;
  employeesNumberId: number;
  countryId: number;
  stateId: number;
  cityId: number;
  phone?: string;
}

export interface PhoneData {
  areaCode: string;
  number: string;
}
