import { createSelector } from '@ngrx/store';
import { featureSelector } from '../selectors';
import { companyHasPowerBIConfigsSelector } from './companyConfigs';

export const userAccesses = createSelector(
  featureSelector,
  (state) => state.accesses
);

export const userIsAdmin = createSelector(userAccesses, (list) => {
  return list.indexOf(1) > -1;
});

export const userCanApplyChecklists = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => isAdmin || list.includes(2)
);

export const userCanConfigureChecklists = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => isAdmin || list.includes(12)
);

export const userCanAccessReportRecidivism = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => list.includes(38) || isAdmin
);

export const userCanAccessReportRanking = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => isAdmin || list.includes(25)
);

export const userCanAccessReportActionPlan = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => isAdmin || list.includes(71)
);

export const userCanAccessReportEvaluations = createSelector(
  userAccesses,
  userIsAdmin,
  (accesses, isAdmin) => isAdmin || accesses.includes(4)
);

export const userCanAccessReportRankingUnitsDetails = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => isAdmin || list.includes(70)
);

export const userCanAccessReportUnitAudit = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => list.includes(22) || isAdmin
);

export const userCanAccessReportUserAudit = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => list.includes(72) || isAdmin
);

export const userCanAccessReportMedia = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => list.includes(43) || isAdmin
);

export const userCanAccessReportIndicators = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => list.includes(5) || isAdmin
);

export const userCanAccessNotificationGroupsConfig = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => list.includes(26) || isAdmin
);

export const userCanConfigureUnits = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => {
    return isAdmin || list.includes(8);
  }
);

export const userHasSomePermissionToConfigure = createSelector(
  userAccesses,
  userIsAdmin,
  (permissions, isAdmin) => {
    if (isAdmin) {
      return true;
    }
    return permissions.some((permission) => {
      return [
        8, 9, 29, 10, 11, 12, 13, 47, 46, 55, 50, 14, 26, 52, 65, 33, 40, 48,
      ].includes(permission);
    });
  }
);

const idOfPermissionToAccessPowerBi = 62;
export const userCanAccessReportPowerBiSelector = createSelector(
  userIsAdmin,
  userAccesses,
  companyHasPowerBIConfigsSelector,
  (isAdmin, permissions, companyHasConfigsPowerBI) => {
    if (!companyHasConfigsPowerBI) {
      return false;
    }

    return isAdmin || permissions.includes(idOfPermissionToAccessPowerBi);
  }
);

export const userCanAccessScheduleReport = createSelector(
  userIsAdmin,
  userAccesses,
  (isAdmin, permissions) => {
    const accessScheduleReportID = 7;
    return isAdmin || permissions.includes(accessScheduleReportID);
  }
);

export const userCanAccessWorkflows = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => isAdmin || list.includes(73)
);

export const userCanCancelWorkflows = createSelector(
  userAccesses,
  userIsAdmin,
  (list, isAdmin) => isAdmin || list.includes(74)
);

export const userCanAnalyseItemDisputes = createSelector(
  userAccesses,
  userIsAdmin,
  (accesses, isAdmin) => isAdmin || accesses.includes(54)
);
