import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'cl-form-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() isChecked = false;
  @Input() isDisabled = false;
  @Input() labelPosition: 'before' | 'after' = 'before';
  @Output() changed = new EventEmitter<boolean>();
  private touched = false;

  @HostBinding('class.qa-switch') qaClass = true;

  sliderHasChanged(sliderChangeInfo: MatSlideToggleChange) {
    this.isChecked = sliderChangeInfo.checked;
    this.onChange(sliderChangeInfo.checked);
    this.changed.emit(sliderChangeInfo.checked);
    this.isChecked = sliderChangeInfo.checked;
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange = (delta: any) => {};

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onTouched() {
    this.touched = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.isChecked = obj;
  }
}
