import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { SelectItem } from '../select/types';

@Component({
  selector: 'cl-form-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectMultipleComponent,
      multi: true,
    },
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clFormSelectMultiple'
    ),
  ],
})
export class SelectMultipleComponent implements ControlValueAccessor {
  @Input() label: string | undefined;
  @Input() disabled = false;
  @Input() options: SelectItem[] = [];
  @Input() selected: any[] = [];
  @Output() selectedChange = new EventEmitter<number[]>();

  private internalAppearance: MatFormFieldAppearance = 'fill';
  @HostBinding('class.cl-form-select--custom-fill') customFill = false;
  @Input() set appearance(appearance: string) {
    if (appearance === 'cl-fill') {
      this.internalAppearance = 'none' as MatFormFieldAppearance;
      this.customFill = true;
    } else {
      this.internalAppearance = appearance as MatFormFieldAppearance;
    }
  }

  get appearance(): MatFormFieldAppearance {
    return this.internalAppearance;
  }
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange = (value: number[]) => {};
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onTouched = () => {};

  handleSelectChange(event: MatSelectChange) {
    this.selected = [...event.value];
    this.selectedChange.emit(this.selected);
    if (this.onChange) {
      this.onChange(this.selected);
    }
  }

  get selectedName(): string | null {
    return this.selected.length === 1
      ? this.options.find((o) => o.value === this.selected[0])?.name || null
      : null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.selected = obj;
  }
}
