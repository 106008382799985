import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import numeral from 'numeral';

import { BehaviorSubject, Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldControl } from '@angular/material/form-field';

@Component({
  selector: 'cl-form-input-with-symbol',
  templateUrl: './input-with-symbol.component.html',
  styleUrls: ['./input-with-symbol.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: InputWithSymbolComponent },
  ],
})
export class InputWithSymbolComponent
  implements
    OnInit,
    OnDestroy,
    MatFormFieldControl<number>,
    ControlValueAccessor
{
  static nextId = 0;

  @Input() emptyText: string | null = null;

  @Input()
  get value(): number | null {
    return this.realValue$.getValue();
  }

  set value(v: number | null) {
    this.realValue = v;
    this.updateDisplayedValue();
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this.localPlaceholder || '';
  }

  set placeholder(text: string) {
    this.localPlaceholder = text;
    this.stateChanges.next();
  }

  private localPlaceholder: string | undefined;

  @Input()
  get required() {
    return this.isRequired;
  }

  set required(isRequired: boolean) {
    this.isRequired = isRequired;
    this.stateChanges.next();
  }

  private isRequired = false;

  @Input()
  get disabled(): boolean {
    return this.isDisabled;
  }

  set disabled(isDisabled: boolean) {
    this.isDisabled = coerceBooleanProperty(isDisabled);
    this.stateChanges.next();
  }

  private isDisabled = false;

  @Input()
  get errorState() {
    return this.ngControl && this.ngControl.errors !== null;
  }

  focused = false;
  controlType = 'textfield-with-symbol';

  @Input() symbol: string | undefined;
  @Input() readonly = false;

  @Output() valueChange = new EventEmitter<number | null>();

  @HostBinding() id = `input-with-symbol-${InputWithSymbolComponent.nextId++}`;

  @HostBinding('attr.aria.describedby') describedBy = '';

  @HostBinding('class.floating') get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @ViewChild('textfieldInput', { static: true }) nativeInput:
    | ElementRef<HTMLInputElement>
    | undefined;

  public realValue$ = new BehaviorSubject<number | null>(null);
  public stateChanges = new Subject<void>();
  public valueDisplaySubject$ = new BehaviorSubject<string>('');

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  private onChange: ((_: any) => void) | undefined;
  private touched = false;
  private initialized = false;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    focusMonitor.monitor(elementRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  get empty(): boolean {
    return this.realValue === undefined;
  }

  get realValue(): number | null {
    return this.realValue$.getValue();
  }

  set realValue(value: number | null) {
    this.realValue$.next(numeral(value).value());
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.initialized) {
      this.valueChange.emit(this.realValue);
    }
    this.stateChanges.next();
  }

  ngOnInit() {
    this.updateDisplayedValue();
    this.initialized = true;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elementRef.nativeElement.querySelector('input')?.focus();
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  handleKeyUp(event: KeyboardEvent) {
    const { key } = event;

    if (key === 'ArrowUp') {
      this.incrementValue();
      return;
    }

    if (key === 'ArrowDown') {
      this.decrementValue();
      return;
    }
  }

  handleFocus() {
    this.updateDisplayedValue();
  }

  handleFocusOut() {
    this.updateDisplayedValue();
  }

  incrementValue() {
    if (this.realValue === null) {
      this.realValue = 0;
      this.updateDisplayedValue();
      return;
    }

    this.realValue += 1;
    this.updateDisplayedValue();
  }

  decrementValue() {
    if (this.realValue === null) {
      return;
    }
    if (this.realValue === 0) {
      this.realValue = null;
      this.updateDisplayedValue();
      return;
    }
    this.realValue -= 1;
    this.updateDisplayedValue();
  }

  updateDisplayedValue() {
    if (this.realValue === null) {
      this.valueDisplaySubject$.next(this.emptyText || '');
      return;
    }
    this.valueDisplaySubject$.next(`${this.realValue}${this.symbol || ''}`);
  }

  onTouched() {
    this.touched = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.realValue = obj;
    this.updateDisplayedValue();
  }

  handleChanges(value: any) {
    const sanitizedRaw = value.replace(',', '.').replace('%', '');

    if (sanitizedRaw === '' || !/[0-9]+/.test(sanitizedRaw)) {
      this.realValue = null;
      this.updateDisplayedValue();
      return;
    }
    const sanitizedNumber = Number(
      value.replace(',', '.').replace(this.symbol, '')
    );
    this.realValue = numeral(
      isNaN(sanitizedNumber) ? null : sanitizedNumber
    ).value();
    this.updateDisplayedValue();
  }
}
