import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent {
  @Input() onlyIcon = false;
  @Input() menuCollapsed = false;
  @Input() buttonText = '';
  @Input() newApplyButton: boolean | null = false;
  @Output() clicked = new EventEmitter();

  onClick() {
    this.clicked.emit();
  }
}
