/* eslint-disable @typescript-eslint/no-unused-vars */
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NgControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { translocoLoader } from '@checklistfacil/shared/util/translate';
import { Subject } from 'rxjs';
import { PhoneNumber } from './phone-number';

/**
 * @GOAT-SACRIFICE não há como implementar a interface Validators junto com a
 * interface MatFormFieldControl, pois isso gera um erro de injeção de dependência.
 * öm
 * @param control
 */
const phoneInputValidator = function (
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;

  const { areaCode, number } = control.value;

  if (!areaCode || !number) {
    return { required: true };
  }

  const onlyNumbers = /^[0-9]*$/;

  if (!onlyNumbers.test(areaCode) || !onlyNumbers.test(number)) {
    return { pattern: true };
  }

  if (areaCode.length !== 2 || number.length > 9 || number.length < 8) {
    return { pattern: true };
  }

  return null;
};

/** Custom `MatFormFieldControl` for telephone number input. */
@Component({
  selector: 'cl-form-phone-input',
  templateUrl: 'phone-input.component.html',
  styleUrls: ['phone-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: PhoneInputComponent },
    translocoLoader(
      (lang, root) => import(`./${root}/${lang}.json`),
      'clFormPhoneInput'
    ),
    {
      provide: NG_VALIDATORS,
      useValue: phoneInputValidator,
      multi: true,
    },
  ],
})
export class PhoneInputComponent
  implements ControlValueAccessor, MatFormFieldControl<PhoneNumber>, OnDestroy
{
  static nextId = 0;
  static ngAcceptInputType_disabled: BooleanInput;
  static ngAcceptInputType_required: BooleanInput;

  @ViewChild('areaCode') areaCodeInput: HTMLInputElement | undefined;
  @ViewChild('number') numberInput: HTMLInputElement | undefined;

  @HostBinding('id') id = `tel-input-${PhoneInputComponent.nextId++}`;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string | undefined;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'tel-input';

  private _placeholder = '';
  private _required = false;
  private _disabled = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-empty-function
  onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  get empty() {
    if (!this.parts) return true;

    const {
      value: { areaCode, number },
    } = this.parts;

    return !areaCode && !number;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): PhoneNumber | null {
    const {
      value: { areaCode, number },
    } = this.parts;
    if (areaCode === '' && number === '') return null;
    return new PhoneNumber(areaCode, number);
  }
  set value(tel: PhoneNumber | null) {
    const { areaCode, number } = tel || new PhoneNumber('', '');
    this.parts.setValue({ areaCode, number });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return (
      (this.ngControl.invalid || this.parts.invalid) &&
      (this.touched || (this.ngControl?.touched ?? false))
    );
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.parts = formBuilder.group({
      // validadores minLenght e maxLenght no areaCode são necessários para
      // o funcionamento do autoFocus
      areaCode: [null, [Validators.minLength(2), Validators.maxLength(2)]],
      number: [null],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.tel-input-container'
    );
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    const { areaCode, number } = this.parts.value;
    if (!areaCode && this.areaCodeInput) {
      this._focusMonitor.focusVia(this.areaCodeInput, 'program');
      return;
    }
    if (!number && this.numberInput) {
      this._focusMonitor.focusVia(this.numberInput, 'program');
      return;
    }
    if (this.numberInput && this.parts.controls.number.valid) {
      this._focusMonitor.focusVia(this.numberInput, 'program');
    } else if (this.numberInput && this.parts.controls.areaCode.valid) {
      this._focusMonitor.focusVia(this.numberInput, 'program');
    } else if (this.areaCodeInput) {
      this._focusMonitor.focusVia(this.areaCodeInput, 'program');
    }
  }

  writeValue(tel: PhoneNumber | null): void {
    this.value = tel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
}
